
import { Menu } from 'antd';
import { MailOutlined, AppstoreOutlined,PieChartOutlined, FolderFilled,DashboardOutlined,MenuUnfoldOutlined,
  PlusOutlined,TagOutlined,ToolOutlined,TeamOutlined,BlockOutlined  } from '@ant-design/icons';
import { useHistory, NavLink, Redirect } from "react-router-dom"
import { FormattedMessage } from 'react-intl';
import {useSelector} from "react-redux";
import ServiciosIconBlanco from "../image/servicios_icon2.svg"
import MembersIcon from "../image/member_center_icon.svg";
import refacciones_icon from "../image/detail/refacciones_icon_white.svg";
import { tenantSelected } from '../lib/utils/axiosApi';
import { Global, css } from '@emotion/react';

const MovilHeaderLayout = () => {
    const history = useHistory()
    const settings = useSelector(state => state.settings);
  return (
    <>

        <Global
          styles={css`
          .fixedMenu {
            position: fixed;
            width: 100%; /* Asegrate de que el men ocupe todo el ancho */
            top: 0; /* Posición fija en la parte superior */
            z-index: 1000; /* Asegrate de que el men esté sobre otros elementos */
          }
          `}
          />
    <Menu 
    mode="horizontal"
    theme='dark'
    className='fixedMenu'
    triggerSubMenuAction='click'
    defaultSelectedKeys={['mail']}>
    <Menu.SubMenu style={{color:'white'}}  key="SubMenu" title="Menú" icon={<MenuUnfoldOutlined />}>
            <Menu.Item key="Dashboard" icon={<AppstoreOutlined />} onClick={() => history.push("/")}>
            <FormattedMessage id="services.dash" />
          </Menu.Item>
          <Menu.Item key="analitica" icon={<PieChartOutlined />} onClick={() => history.push("/analytics")}>
            <FormattedMessage id="services.analytics" />
          </Menu.Item>
          <Menu.Item key="electroplan" icon={<DashboardOutlined />} onClick={() => history.push("/electroplan_")}>
            <FormattedMessage id="services.page.electroplan" />
          </Menu.Item>
          <Menu.Item key="expedientes" icon={<ToolOutlined />} onClick={() => history.push("/services/list")}>
            {/*<img src={ index === 'expedientes' ? ServiciosIconBlanco : ServiciosIcon} width="19px" height="19px" /> &nbsp; <FormattedMessage id="services.service" />*/}
            <FormattedMessage id="services.service" />
          </Menu.Item>
          {
              settings && settings.valuationIsActive && tenantSelected!=='jacmexico' && (<Menu.Item key="parts" icon={<BlockOutlined />} onClick={() => history.push("/services/parts")}>
                {/*<img src={ index === 'expedientes' ? ServiciosIconBlanco : ServiciosIcon} width="19px" height="19px" /> &nbsp; <FormattedMessage id="services.service" />*/}
                 <FormattedMessage id="servicesDetails.spareParts" />
              </Menu.Item>)
          }
          <Menu.Item key="drive" icon={<FolderFilled />} onClick={() => history.push("/drive")}>
            <FormattedMessage id="drive.my_drive" />
          </Menu.Item>
          <Menu.Item key="members" icon={
            // <img src={MembersIcon} width="19px" height="19px"  style={ index !== 'members' ? { filter:'invert(100%)' } : null} />
            <TeamOutlined />
          } onClick={() => history.push("/members_center_users")}>
            {/* <img src={ index === 'members' ? UsuariosAzul : UsuariosNegro} width="19px" height="19px" /> &nbsp;  */}
            <FormattedMessage id="services.members_center" />
          </Menu.Item>
          <Menu.Item key="promos" icon={<TagOutlined />} onClick={() => history.push("/promos")}>
            Promociones
        </Menu.Item>
    </Menu.SubMenu>
    <Menu.Item key="order" style={{color:'white'}} icon={<PlusOutlined />} onClick={() => history.push("/services/new")}>
     Nueva orden
    </Menu.Item>
  </Menu>

    </>

  )
}

export default MovilHeaderLayout

