import { Button, Col, Modal, Row, Space, Spin, Typography, Form, Input, message } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import turn from "../../image/turn_icon_kikert.svg";
import { FormattedMessage, injectIntl } from "react-intl";
import { API, axiosApi} from '../../lib/utils/axiosApi'
import { connect } from "react-redux";
import { useEffect } from "react";

const CustomModal = styled(Modal)`
  .ant-modal-body {
    padding: 20px 30px;
  }
  .ant-modal-content {
    border-radius: 20px;
  }
`;

const ChangePassword = ({
  isVisible = false,
  closeModal,
  loading = false,
  intl,
  membersState, ...props
}) => {
  const { Text, Title } = Typography;
  const [form] = Form.useForm();
  const [saving, setSaving] = useState(false);

  const ruleRequired = {
    required: true,
    message: intl.formatMessage({ id: "form.is_required" }),
  };

  const ruleConfirmPassword = ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue("password_one") === value) {
        return Promise.resolve();
      }
      return Promise.reject(
        new Error(intl.formatMessage({ id: "form.confirmPassword" }))
      );
    },
  });

  useEffect(() => {
    console.log("membersState", membersState);
  }, [membersState]);
  

  const saveinfo = async (values) => {
    console.log(values);
    setSaving(true);
    try {
      await axiosApi.post(`${API.SECURITY.USER_PROFILE}${membersState.userForEdit.id}/change_user_password/`,values);
      message.success("Contraseña actualizada");
      setSaving(false);
      closeModal();
    } catch (error) {
      setSaving(false);
    }
  };

  return (
    <CustomModal
      title=""
      footer=""
      width={600}
      visible={isVisible === true}
      onCancel={closeModal}
    >
      <Spin spinning={saving}>
        <Form onFinish={saveinfo} form={form} layout="vertical">
          
          <Row justify="space-between">
            <Col md={24} style={{ borderBottom: "solid 1px #341eff" }}>
              <Title level={2} style={{ marginBottom: 0 }}>
                <img
                  src={turn}
                  alt="logo"
                  style={{ height: 64, width: 28, transform: "scaleX(-1)" }}
                />{" "}
                Actualizar contraseña
              </Title>
            </Col>
            <Col span={24} style={{ marginTop: 30 }}>
              <Form.Item
                name={"password_one"}
                label="Ingresa tu nueva contraseña"
                rules={[ruleRequired]}
              >
                <Input.Password size="large" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name={"password_two"}
                label="Confirmar contraseña"
                rules={[ruleRequired, ruleConfirmPassword]}
              >
                <Input.Password size="large" />
              </Form.Item>
            </Col>
            <Col span={24} style={{ textAlign: "center", marginTop: 40 }}>
              <Space>
                <Button
                  size="large"
                  className="btn-string-blue btn-report-action"
                   onClick={() => closeModal()}
                >
                  <FormattedMessage id="layout.cancel" />
                </Button>
                <Button
                  className="btn-string-blue-low btn-report-action"
                  size="large"
                  htmlType="submit"
                >
                  <FormattedMessage id="update" />
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Spin>
    </CustomModal>
  );
};

const mapState = (state) => ({
  membersState: state.members,
  businessState: state.business
});

export default injectIntl(connect(mapState)(ChangePassword))