import React, { useState, useEffect } from 'react'
import { Table, Layout, Form, Button, Modal, Input, Upload, Popconfirm } from 'antd'
import { injectIntl } from "react-intl";
import { changeLangAction } from "../redux/settingsDuck";
import { FormattedMessage } from "react-intl";
import { connect } from 'react-redux';

const DeleteLinkComponent = ({ changeLangAction, intl, settings, title = null, ...props }) => {

    return (
        <Popconfirm
            title={title ? title : <FormattedMessage id="deleted.ask" />}
            onConfirm={props.onDelete}
        >
            <Button type={'link'} danger><FormattedMessage id="layout.delete" /></Button>
        </Popconfirm>
    )
}

//export default DeleteLinkComponent
function mapState(state) {
    return {
        settings: state.settings,
    }
}

export default injectIntl(connect(mapState, {
    changeLangAction,
})(DeleteLinkComponent))