import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {Descriptions} from "antd";
import _ from "lodash";
import {injectIntl} from "react-intl";

const ServiceDetailTable = ({ serviceOrder,intl, ...props }) => {


    return (
        <>
            {
                serviceOrder?.serviceDetail &&  <Descriptions
                    bordered
                    layout="vertical"
                    size={'small'}
                >
                    <Descriptions.Item label="Folio">{serviceOrder?.serviceDetail?.folio}</Descriptions.Item>
                    <Descriptions.Item label="Núm. Orden">{serviceOrder?.serviceDetail?.order_number}</Descriptions.Item>
                    <Descriptions.Item label="Cliente">{serviceOrder?.serviceDetail?.customer?.first_name} {serviceOrder?.serviceDetail?.customer?.last_name}</Descriptions.Item>
                    {
                        serviceOrder.serviceDetail['WORKSHOP'] &&
                        <>
                            <Descriptions.Item label="Unidad">{serviceOrder.serviceDetail['WORKSHOP'].vehicle?.model?.brand?.name} {serviceOrder?.serviceDetail['WORKSHOP']?.vehicle?.model?.name} {serviceOrder.serviceDetail['WORKSHOP']?.vehicle?.year}</Descriptions.Item>
                            <Descriptions.Item label="Color">{serviceOrder.serviceDetail['WORKSHOP']?.vehicle?.color?.name}</Descriptions.Item>
                        </>
                    }
                    <Descriptions.Item label="Comentario">{serviceOrder.serviceDetail.comment}</Descriptions.Item>
                    <Descriptions.Item label="Fecha de entrega">{_.get(serviceOrder, 'serviceDetail.closing_datetime','--') }</Descriptions.Item>
                    <Descriptions.Item label="Razón de entrega">{ _.get(serviceOrder, 'serviceDetail.closing_reason.name','--') }</Descriptions.Item>
                    <Descriptions.Item label="Locación actual">{ _.get(serviceOrder, 'serviceDetail.current_location.name','--') }</Descriptions.Item>
                </Descriptions>
            }

        </>
    )
}


export default injectIntl(ServiceDetailTable)