import React, {useEffect, useState} from "react";
import {
    Select,
    Form
} from "antd";
import { connect } from 'react-redux'
import { injectIntl, FormattedMessage } from "react-intl";
import KSelect from "../common/elements/KSelect/KSelect";
import _ from "lodash";
import {ruleRequired} from "../../lib/utils/rules";
import axiosApi, {API} from "../../lib/utils/axiosApi";
const { Option } = Select;

const SelectJacLevels=({name='metaLevels', label='Nivel', required=false,...props})=>{

    const levels = [
        {
            label: 'Nivel 1',
            value: '1_250'
        },
        {
            label: 'Nivel 2',
            value: '2_350'
        },
        {
            label: 'Nivel 3',
            value: '3_350'
        },
        {
            label: 'Nivel 4',
            value: '4_350'
        }
    ];

    return (
        <Form.Item
            label={label ? label : <FormattedMessage id="Color" />}
            name={name}
            rules={required? [ruleRequired]:[]}
            className="item-expediente-lg mb-0 label-margin"
        >
            <KSelect
                {...props}
                options={
                    levels && _.map(levels, (o)=>{
                        return {
                            value:o.value,
                            label:`${o.label}`
                        }
                    })
                }
            />
        </Form.Item>
    )
}


export default injectIntl(
    SelectJacLevels
);
