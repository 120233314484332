import React, {useState, useEffect} from 'react'
import {CustomModal} from '../MyComponents';
import turn from '../../image/turn_icon_kikert.svg'
import { Button, Col,  Form,  Input,  message,  Row, Space, Spin, Typography } from 'antd'
import { FormattedMessage, injectIntl } from 'react-intl';
import {axiosApi, API} from '../../lib/utils/axiosApi';
import {getProfiles} from '../../redux/membersDuck';
import { connect } from 'react-redux';
import CloseIcon from '../../image/close-outline.svg';


const CreateGroup = ({isVisible = false, closeModal=null, intl, getProfiles,  ...props}) => {

    const [sending, setSending] = useState(false)
    const { Text, Title } = Typography;

    const [form] = Form.useForm()

    useEffect(() => {
        if(!isVisible){
            form.resetFields()
        }
    }, [isVisible])
    

    const submitForm = async (values) => {
        setSending(true)
        values['perms'] = [];
        try {
            let response = await axiosApi.post(API.GROUPS.PROFILES, values);
            
            if (response.status === 201){
                message.success("Grupo creado correctamente")
                setSending(false)
                closeModal();
                getProfiles();
                form.resetFields();
            }
        } catch (error) {
            message.error("No se pudo crear")
            setSending(false)
        }

    }
    
    return (
        <CustomModal
        title=""
        footer=""
        width={600}
        visible={isVisible === true}
        onCancel={() => closeModal()}
        closeIcon={<img src={CloseIcon} style={{width:15}} />}
        >
        <Row justify="space-between">
            <Col md={24} style={{ borderBottom: "solid 1px #341eff" }}>
                <Title level={2} style={{ marginBottom: 0 }}>
                    <img
                    src={turn}
                    alt="logo"
                    style={{ height: 60, width: 30, transform: "scaleX(-1)", marginRight:10 }}
                    />
                    <FormattedMessage id="members.table.create_group" />
                </Title>
            </Col>
            <Col span={24} style={{ textAlign: "center", marginTop: 30 }}>
                <Spin spinning={sending}>
                    <Form size="large" form={form} onFinish={submitForm}  >
                        <Form.Item className="mb-0" name={"name"} 
                            rules={[{ required: true, message: intl.formatMessage({
                                id: "layout.name_is_required",
                            }) }]}
                        >
                        <Input
                            size="large"
                            placeholder={intl.formatMessage({
                                id: "members.table.create_group_label",
                            })}
                            className="br-10"
                            style={{ height: 50 }}
                            allowClear
                            /* onChange={onClear} */
                        />
                        </Form.Item>
                    </Form>
                </Spin>
            </Col>
            <Col span={24} style={{ textAlign: "center", marginTop: 40 }}>
                <Space>
                    <Button
                    size="large"
                    className="btn-string-blue btn-report-action"
                    onClick={() => closeModal()}
                    >
                    <FormattedMessage id="layout.cancel" />
                    </Button>
                    <Button
                    className="btn-blue btn-report-action"
                    size="large"
                    onClick={() => form.submit()}
                    >
                    <FormattedMessage id="services.members.create.save" />
                    </Button>
                </Space>
            </Col>
        </Row>
        </CustomModal>
  )
}

const mapState = (state) => ({
    membersState: state.members
})

export default injectIntl(
    connect(mapState, {getProfiles})(CreateGroup))