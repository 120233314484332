import React, {useEffect, useState} from 'react'
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";


const LineChartKikert =({
                           data=null,
                           idChart='chartDiv',
                           withScroll=false,
                           categoryField='',
                           valueField='',
                           isDate=false,
                           ...props})=> {

    const root=null;

    useEffect(()=>{
        if(data && !root && data.length>0 ){
            let root = am5.Root.new(idChart);

            root.setThemes([
                am5themes_Animated.new(root)
            ]);

            // Create chart
            let chart = root.container.children.push(
                am5xy.XYChart.new(root, {
                    panY: false,
                    wheelY: "zoomX",
                    layout: root.verticalLayout
                })
            );


            if(withScroll){
                chart.set("scrollbarX", am5.Scrollbar.new(root, {
                    orientation: "horizontal"
                }));
            }

            let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
                behavior: "none"
            }));
            cursor.lineY.set("visible", false);
            cursor.lineX.set("visible", false);

// Create Y-axis
            let yAxis = chart.yAxes.push(
                am5xy.ValueAxis.new(root, {
                    extraTooltipPrecision: 1,
                    renderer: am5xy.AxisRendererY.new(root, {})
                })
            );


// Create X-Axis
            let xAxis={};



            if(isDate){
               xAxis =  chart.xAxes.push(
                    am5xy.CategoryDateAxis.new(root, {
                        maxDeviation: 0.2,
                        categoryField:categoryField,
                        baseInterval: { timeUnit: "day", count: 1 },
                        renderer: am5xy.AxisRendererX.new(root, {}),
                        tooltip: am5.Tooltip.new(root, {})
                    })
                );
            }else{
                xAxis = chart.xAxes.push(
                    am5xy.CategoryAxis.new(root, {
                        maxDeviation: 0.2,
                        renderer: am5xy.AxisRendererX.new(root, {}),
                        categoryField:categoryField
                    })
                );
            }
            xAxis.data.setAll(data);

            // Create series
            const createSeries=(name)=> {
                let series = {};

                if(!isDate){
                    series = chart.series.push(
                        am5xy.LineSeries.new(root, {
                            name: name,
                            xAxis: xAxis,
                            yAxis: yAxis,
                            valueYField: valueField,
                            categoryXField: categoryField,
                            tooltip: am5.Tooltip.new(root, {
                                labelText: "{valueY}"
                            })
                        })
                    );
                }else{
                    series = chart.series.push(
                        am5xy.LineSeries.new(root, {
                            name: name,
                            xAxis: xAxis,
                            yAxis: yAxis,
                            valueYField: valueField,
                            categoryXField: categoryField,
                            tooltip: am5.Tooltip.new(root, {
                                labelText: "{valueY}"
                            })
                        })
                    );
                }



                    series.strokes.template.setAll({
                        strokeWidth: 3,
                        strokeDasharray: [10,5]
                    });
                    series.fills.template.setAll({
                        fillOpacity: 0.5,
                        visible: true
                    });



                series.data.setAll(data);
            }



            createSeries("Series #1"+categoryField);

            return () => {root.dispose();};
        }


    },[data])





    return (
        <div id={idChart} style={{width: "100%", height: "500px"}}/>
    );

}


export default LineChartKikert;