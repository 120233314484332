import React, { useState, useEffect } from 'react'
import {Col, DatePicker, Form, Input, message, Row, Spin} from "antd";
import KSelect from "../common/elements/KSelect/KSelect";
import KButton from "../common/elements/KButton/KButton";
import {FormattedMessage, injectIntl} from "react-intl";
import { connect } from 'react-redux'
import {getServiceDetail, changePromiseDateOrder} from "../../redux/serviceOrderDuck";
import {getWorkFlow, getUsersWorkFlow} from "../../redux/workflowDuck";
import _ from 'lodash'
import moment from "moment";
import locale from "antd/es/date-picker/locale/es_ES";

const FormChangePromiseDate=({loading=false,
                                 orderId,
                                 intl,
                                 currentDatePromise=moment(),
                                 changePromiseDateOrder,
                                 ...props})=>{

    let fieldRequiredLabel = intl.formatMessage({ id: "services.saleOrder.obligatory" })

    const [form] = Form.useForm();

    const onFinish= async (values)=>{

        try{
            let dateStr = moment(values.datetime).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]')
            let data = {
                reason: values.reason,
                datetime: dateStr
            }
            const res = await  changePromiseDateOrder(orderId,data)
            message.success(intl.formatMessage({ id: "update_success" }))
            props.onCancel()
            form.resetFields()
        }catch (e){
            console.log(e)
            message.error(`${e}`)
        }finally {

        }
    }


    return (
      <Spin spinning={loading}>
          <Form
              layout={'vertical'}
              form={form}
              initialValues={{
                  // datetime: new Date(),  TODO ver cargar la fecha promesa
                  reason:'' }}
              onFinish={onFinish}>
              <Form.Item
                  name={'datetime'}
                  rules={[{ required: true, message: fieldRequiredLabel}]}
                  label={intl.formatMessage({ id: "servicesDetails.newdate" })}
              >
                  <DatePicker
                      locale={locale}
                      className={'kinput'}
                      style={{width:'100%'}}
                      format={'DD/MM/YYYY, h:mm A'}
                      showTime={true}
                      placeholder={intl.formatMessage({ id: "saleOrder.datedelivery" })}
                  />
              </Form.Item>
              <Form.Item
                  label={'Razón de cambio'}
                  rules={[{ required: true, message: 'Campo requerido' }]}
                  name={'reason'}
              >
                  <Input className={'kinput'}/>
              </Form.Item>
              <div style={{textAlign:'center'}}>
                  <KButton type={'secondary'} onClick={props.onCancel} text={intl.formatMessage({ id: "cancel" })}/>
                  <KButton htmlType={'submit'} text={intl.formatMessage({ id: "accept" })}/>
              </div>
          </Form>
      </Spin>
    );

}

const mapStateToProps = state => ({
    settings: state.settings,
    serviceOrder: state.serviceOrder,
    workflow: state.workflow
})


export default injectIntl(connect(mapStateToProps,{ getServiceDetail, changePromiseDateOrder})(FormChangePromiseDate))