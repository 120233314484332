import React, { useState, useEffect } from 'react'
import DashboardLayout from '../../layouts/DashboardLayout';
import { Table, Layout, Form, Button, Modal, Typography, Input, Upload, Space, Popconfirm,Checkbox, message, Row, Col } from 'antd'
import { UploadOutlined } from '@ant-design/icons';
import DeleteLinkComponent from "../../components/DeleteLinkComponent";
import { connect } from 'react-redux'
import { injectIntl } from "react-intl";
import { FormattedMessage } from "react-intl";
import {
    changeLangAction,
    getTypesAction,
    editTypeAction,
    saveTypeAction,
    deleteTypeAction
} from "../../redux/settingsDuck"
import turn from '../../image/turn_icon_kikert.svg'
import {CustomTable} from "../../components/KComponents";
import KButton from "../../components/common/elements/KButton/KButton";
import KModal from '../../components/common/elements/KModal/KModal';
import {permissionsExist} from "../../lib/utils/utils";
import DeleteModal from "../../components/modal/DeleteModal";
import DeleteIcon from "../../image/delete.svg";
import EditIcon from "../../image/edit.svg";
import {onlyNumeric} from "../../lib/utils/rules";
import MainLayoutv2 from "../../layouts/MainLayoutv2";
import TabsConfig from '../../components/common/elements/TabsMenuConfig/TabsMenuConfig';

const { Header, Content, Footer } = Layout;

const VehicleType = ({ changeLangAction, intl, settings, getTypesAction, editTypeAction, saveTypeAction, deleteTypeAction, permissions }) => {

    const {Title} = Typography

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentVehicleType, setCurrentVehicleType] = useState(false);
    const [actionView,setActionView] = useState(true);
    const [form] = Form.useForm();
    const [forDelete, setForDelete] = useState(null)
    const [modalDelete, setModalDelete] = useState(false)
    const validateVIN = Form.useWatch('vin_validation', form);

    useEffect(() => {
        getTypesAction()
    }, [])

    const showModal = (isEdit = false) => {
        if (!isEdit) {
            form.resetFields()
            setCurrentVehicleType(null)
        }
        setIsModalVisible(true);
    };

    const handleOk = () => {
        form.submit();

    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setCurrentVehicleType(null)
        form.resetFields()
    };

    const closeModal = () =>{
        setModalDelete(false);
        setForDelete(null);
    }

    const deleteItem = (current) => {
        console.log(current);
        setModalDelete(true);
        setForDelete(current);
    }

    const onDeleteType = async () => {
        try {
            const res = await deleteTypeAction(forDelete.id)
            if (res.success) {
                message.success(intl.formatMessage({ id: "item.deleteSuccess" }))
                await getTypesAction()
                await closeModal()

            } else {
                message.error(intl.formatMessage({ id: "item.brand.registerErr" }))
                await closeModal()
            }
        } catch (e) {
            message.error(intl.formatMessage({ id: "item.brand.registerErr" }))
            await closeModal()

        }
    }

    const onSaveType = async values => {
        if (values) {
            let res = await saveTypeAction(values)
            if (res.success) {
                message.success(intl.formatMessage({ id: "item.saveSuccess" }))
                await getTypesAction()
            } else {
                message.error(intl.formatMessage({ id: "item.brand.element" }))
            }

            form.resetFields()
            setIsModalVisible(false);
            setCurrentVehicleType(null)
        }
        console.log('Received values of form: ', values);
    };

    const onEditType = async values => {
        if (values) {
            let req = {
                id: currentVehicleType.id,
                ...values
            }
            let res = await editTypeAction(req)
            if (res.success) {
                message.success(intl.formatMessage({ id: "item.editSuccess" }))
                await getTypesAction()
            } else {
                message.error(intl.formatMessage({ id: "item.brand.element" }))
            }

            form.resetFields()
            setIsModalVisible(false);
            setCurrentVehicleType(null)
        }
        console.log('Received values of form: ', values);
    };

    const editType = (type = null) => {
        setCurrentVehicleType(type)
        if (type) {
            console.log(type)
            form.setFieldsValue({
                name: type.name,
                vin_validation: type.vin_validation,
                vin_length: type.vin_length
            })
        }
        showModal(true)
    }

    const valActions=()=>{
        if (!permissionsExist(permissions.permissionsList,"change_vehicletype") &&
            !permissionsExist(permissions.permissionsList,"delete_vehicletype")){
            setActionView(false)
        }else {
            setActionView(true)
        }
    }

    useEffect(()=>{
        valActions()
    },[])

    const columns = [
        {
            title: <FormattedMessage id="vehicleType.nameType" />,
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Validación de VIN',
            dataIndex: 'vin_validation',
            key: 'vin_validation',
            render: (validate)=><p>{validate?'Si':'No'}</p>
        },
        {
            title: 'Longitud de VIN',
            dataIndex: 'vin_length',
            key: 'vin_length'
        },
        ...(actionView ?
                [{
                    title: <FormattedMessage id="layout.actions" />,
                    dataIndex: 'id',
                    key: 'actions',
                    render: (id, record) => {
                        return <Space size={30}>
                            {
                                permissionsExist(permissions.permissionsList,"change_vehicletype") &&
                                <img key="EditIcon" src={EditIcon} style={{cursor:'pointer',  width:12}}
                                onClick={() => editType(record)}/>
                            }
                            {
                                permissionsExist(permissions.permissionsList,"delete_vehicletype")&&
                                <img key="DeleteIcon" src={DeleteIcon} style={{cursor:'pointer', width:12}} onClick={() => deleteItem(record)}/>
                            }
                        </Space>
                    }

                }]
                :
                []
        ),
    ];

    return (
      <MainLayoutv2
        title={intl.formatMessage({ id: "vehicleType.title" })}
        index="9"
        openKeys={["sub1"]}
      >
        <KModal
          title=""
          footer=""
          visible={isModalVisible}
          /* onOk={handleOk} */
          onCancel={handleCancel}
        >
            <Row>
                <Col span={24}>
                    <Title level={2} style={{ marginBottom: 0 }}>
                        <img
                        src={turn}
                        alt="logo"
                        style={{ height: 60, width: 30, transform: "scaleX(-1)", marginRight:10 }}
                        />
                        <FormattedMessage id={currentVehicleType ? "vehicleType.editType": "vehicleType.addType" } />
                    </Title>
                </Col>
                <Col span={24}>
                    <Form
                        layout={"vertical"}
                        form={form}
                        onFinish={currentVehicleType ? onEditType : onSaveType}>
                            <Form.Item
                                name={"name"}
                                label={intl.formatMessage({ id: "vehicleType.nameType" })}
                                rules={[{ required: true }]}
                            >
                                <Input className='kinput'
                                    placeholder={intl.formatMessage({ id: "vehicleType.nameType" })}
                                />
                            </Form.Item>

                            <Form.Item name="vin_validation" valuePropName="checked">
                                <Checkbox>Validar longitud de VIN</Checkbox>
                            </Form.Item>

                            {
                                validateVIN &&   <Form.Item rules={[onlyNumeric,{ required: true }]} label={'Longitud'} name="vin_length">
                                    <Input className='kinput' />
                                </Form.Item>
                            }

                    </Form>
                </Col>
                <Col span={24} style={{ textAlign: "center" }}>
                    <Space>
                        <Button
                            size="large"
                            className="btn-string-blue btn-report-action"
                            onClick={() => handleCancel()}
                        >
                            <FormattedMessage id="layout.cancel" />
                        </Button>
                        <Button
                            className="btn-blue btn-report-action"
                            size="large"
                            onClick={() => handleOk()       }
                            >
                            <FormattedMessage id="services.members.create.save" />
                        </Button>
                    </Space>
                </Col>
            </Row>
        </KModal>

        <Content
          style={{ padding: "10px 10px", background: "white" }}
        >
            <TabsConfig keyActive={'7'}/>
            {
                permissionsExist(permissions.permissionsList,"add_vehicletype") &&
                <KButton
                    onClick={showModal}
                    type="primary"
                    style={{ float: "right", marginBottom: 20 }}
                    size={"large"}
                    text={<FormattedMessage id="layout.createNew" />}
                />
            }

            

          <CustomTable
            loading={settings.fetching}
            fixed={true}
            scroll={{ x: 1500 }}
            dataSource={settings.vehicleTypeList}
            columns={columns}
          />
        </Content>
          <DeleteModal actionDelete={() => onDeleteType()} isVisible={modalDelete}
                       closeModal={closeModal}
                       modalTitle={<FormattedMessage id="deleted.ask"/>}
                       description={intl.formatMessage({ id:'deleted.description'},{ obj: forDelete && forDelete.name })} />
      </MainLayoutv2>
    );
}

const mapStateToProps = state => ({
    settings: state.settings,
    permissions:state.permissions
})


export default injectIntl(connect(mapStateToProps, {
    getTypesAction,
    changeLangAction,
    saveTypeAction,
    deleteTypeAction,
    editTypeAction
})(VehicleType))

