import React from "react";
import {Form, Input, Modal} from "antd";
import {FormattedMessage} from "react-intl";

const CreateFolderModal =({isModalVisible,handleOk,handleCancel,form,saveFolder,...props})=>{
    return(
        <Modal title={<FormattedMessage id="services.files.newFolder"/>} visible={isModalVisible} onOk={handleOk}
               onCancel={handleCancel}>
            <Form
                layout={'vertical'}
                form={form}
                onFinish={saveFolder}
            >

                <Form.Item name={'name'} label={<FormattedMessage id="layout.name"/>}>

                    <Input placeholder={""}/>
                </Form.Item>
            </Form>
        </Modal>
    )
}
export default CreateFolderModal
