import React, { useEffect } from "react";
import {
    Select,
    Form
} from "antd";
import { connect, useSelector } from 'react-redux'
import { injectIntl, FormattedMessage } from "react-intl";
import KSelect from "../common/elements/KSelect/KSelect";
import {getCategoriesSupplier} from "../../redux/businessDuck";
import _ from "lodash";
const { Option } = Select;

const SelectCategoriesSuppliers=({name='categories',getCategoriesSupplier, label=null, ...props})=>{

    const categories = useSelector((state) => state.business.categories)

    useEffect(()=>{
        getCategoriesSupplier()
    },[])


    return (
        <Form.Item
            label={label ? label :'Categorías'}
            name={name}
            className="item-expediente-lg label-margin"
        >
            <Select
                mode="multiple"
                allowClear
                filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                showSearch
                options={categories? categories.map((ele)=> {
                    return {value: ele.id, label: ele.name}
                }):[]}
            />

        </Form.Item>
    )
}

const mapStateToProps = (state) => ({

});

export default injectIntl(
    connect(mapStateToProps, { getCategoriesSupplier })(SelectCategoriesSuppliers)
);
