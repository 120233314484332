import React from 'react'
import styles from '../login.module.css'
import SecurityLayout from "../../../layouts/SecurityLayout";
import { FormattedMessage } from "react-intl";
import { connect } from 'react-redux'
import { Button, message } from 'antd';
import { changeLangAction } from '../../../redux/settingsDuck'
import { getConfirmAction } from '../../../redux/securitryDuck'
import { Form, Input, Radio, Checkbox, } from 'antd';
import { injectIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom"



const ConfirmPasswordPage = ({ changeLangAction, getConfirmAction, settings, security, intl }) => {
    const [form] = Form.useForm();

    const { uid, token } = useParams();

    const history = useHistory()

    const onFinish = (values) => {
        // Armamos el json
        let data = {};
        data.new_password1 = values.new_password1;
        data.new_password2 = values.new_password2;
        data.uid = uid;
        data.token = token;
        // Usamos redux
        getConfirmAction(data)
    }
    const style = {
        Dash: {
            border: 'none',
            borderTop: '1px dashed #341EFF',
            marginLeft: 5,
            color: '#341EFF',
            backgroundColor: '#341EFF',
            height: 5,
            width: '7%',
        },
    }

    if (security.isPasswordConfirmed) {
        //message.success("Se ha cambiado su contraseña")
        message.success(intl.formatMessage({ id: "security.forgot.messageDonePassword" }));
        history.push('/login')
    }
    return (
        <SecurityLayout className={styles.container}>
            <div style={style.Dash}></div>
            <h1 style={{ color: 'white', fontSize: 35, fontFamily: 'Sharp Grotesk' }}>
                <FormattedMessage id="security.confirm.title" />
            </h1>
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
            >
                <Form.Item required tooltip="This is a required field" name="new_password1" rules={[{ required: true, message: <FormattedMessage id="security.login.messagePass" /> }]} >
                    <Input size="large" placeholder={intl.formatMessage({ id: 'security.login.password' })} type={'password'} />
                </Form.Item>
                <Form.Item required tooltip="This is a required field" name="new_password2" rules={[{ required: true, message: <FormattedMessage id="security.login.messagePass" /> }]} >
                    <Input size="large" placeholder={intl.formatMessage({ id: 'security.login.password' })} type={'password'} />
                </Form.Item>
                <Form.Item>
                    <Button loading={false} size={'large'} block className={'button-secondary'} type="primary" style={{ textAlign: 'left' }} htmlType="submit" ><FormattedMessage id="security.login.signin" /></Button>
                </Form.Item>
            </Form>
            <Button size={'large'} block type={'link'} style={{ color: 'white', textAlign: 'right' }} onClick={changeLangAction}>
                <FormattedMessage id="changelang" />
            </Button>
            <Button size={'large'} type={'link'} block style={{ color: 'white', textAlign: 'right', fontSize: 14 }} onClick={() => history.push('/login')} >
                <FormattedMessage id="security.forgot.login" />
            </Button>
        </SecurityLayout>
    )
}

function mapState(state) {
    return {
        settings: state.settings,
        security: state.security
    }
}

export default injectIntl(connect(mapState, {
    changeLangAction,
    getConfirmAction,
})(ConfirmPasswordPage))
