import React, { useEffect, useState } from "react";
import {injectIntl, FormattedMessage} from "react-intl";
import DashboardLayout from "../../layouts/DashboardLayout";
import {Button, Alert,Col, Form, Layout, Row, message, Image,Space, Popconfirm} from "antd";
import {CustomTable} from "../../components/KComponents";
import moment from "moment";
import {axiosApi, API} from "../../lib/utils/axiosApi";
import {CheckCircleOutlined} from "@ant-design/icons";
import MainLayoutv2 from "../../layouts/MainLayoutv2";
import ModalCreateEditPromo from "./components/ModalCreateEditPromo";
import KButton from "../../components/common/elements/KButton/KButton";
const {Header, Content, Footer} = Layout;

const PromoListPage=()=>{
    const [loading, setLoading] = useState(false)
    const [promos, setPromos] = useState([])
    const [filterText, setFilterText] = useState(null)
    const [count, setCount] = useState(null)
    const [showModal, setShowModal]=useState(false)
    const [editData, setEditData]=useState(null)

    useEffect(()=>{
        getPromos()
    },[])


    const getPromos = async (filter='', page=1)=>{
        setLoading(true)
        try{
            let res = await axiosApi.get(`${API.BUSINESS.PROMOS_LIST}?search=${filter?filter:''}&page=${page}`)
            console.log(res.data.results)
            setPromos(res.data?.results)
            setCount(res.data?.count)
        }catch (e){
            setPromos([])
            setCount(null)
        }finally {
            setLoading(false)
        }
    }

    const changePagination = (page) => {
        console.log('page', page)
        getPromos(filterText, page);
    }

    const confirmDelete=async(idPromo)=>{
        setLoading(true)
        try{
            let res = await axiosApi.delete(`${API.BUSINESS.PROMOS_LIST}${idPromo}/`)
            message.success('Eliminado correctamente')
            getPromos()
        }catch (e){
            message.error('No se pudo eliminar el elemento, por favor intenta mas tarde.')
        }finally {
            setLoading(false)
        }
    }

    const columns = [
        {
            title: 'Imagen',
            key:'image',
            render: (item)=>item?.image ? <Image src={item?.image}/> : <p>Sin imagen</p>
        },
        {
            title: 'Título',
            dataIndex: 'title',
            key: 'title'
        },
        {
            title: 'Descripción',
            key:'description',
            dataIndex:'description'
        },
        {
            title: 'Link',
            key:'link',
            render: (item)=><a href={item?.link}>{item?.link}</a>
        },
        {
            title: '¿Permanente?',
            key:'is_permanent',
            render: (item)=><p>{item?.is_permanent?'Si':'No'}</p>
        },
        {
            title: 'Fecha inicio de promoción',
            key:'start_date',
            render: (item)=><p>{item?.start_date?moment(item.start_date).format('DD/MM/YYYY'):'--'}</p>
        },
        {
            title: 'Fecha fin de promoción',
            key:'end_date',
            render: (item)=><p>{item?.end_date?moment(item.end_date).format('DD/MM/YYYY'):'--'}</p>
        },{
            title: 'Fecha fin de promoción',
            key:'end_date',
            render: (item)=><p>{item?.end_date?moment(item.end_date).format('DD/MM/YYYY'):'--'}</p>
        },{
            title: '¿Activo?',
            key:'is_active',
            render: (item)=><p>{item?.is_active?<CheckCircleOutlined style={{color:'green'}} />:'--'}</p>
        },{
            title: 'Acciones',
            key:'actions',
            render: (item)=><Space>
                <a onClick={()=>{
                    setEditData(item)

                    setShowModal(true)
                }
                }>Editar</a>
                <Popconfirm
                    title="Eliminar promoción"
                    description="¿Deseas eliminar la promoción?"
                    okText="Si"

                    onConfirm={()=>confirmDelete(item.id)}
                    cancelText="No"
                >
                    <a style={{color:'red'}}>Eliminar</a>
                </Popconfirm>

            </Space>
        },
    ]

    const onCreatePromotion=async(values,image)=>{
        setLoading(true)
        if(values.is_active===undefined){
            values.is_active=true
        }

        if(values.image===undefined){
            delete values.image;
        }

        if(values.is_permanent===undefined){
            values.is_permanent=false
        }

        if(values.link===undefined || values.link===null){
            delete values.link;
        }


        if(values.is_permanent){
            delete values.end_date
            delete values.start_date
        }else{
            values.end_date = moment(values.end_date).format('YYYY-MM-DD')
            values.start_date = moment(values.start_date).format('YYYY-MM-DD')
        }


        let formData = new FormData();

        for ( let key in values ) {
            formData.append(key, values[key]);
        }


        if(image && image.length>0){
            formData.append('image',image[0])
        }


        try{
            let res;
            if(editData){
                res = await axiosApi.put(`${API.BUSINESS.PROMOS_LIST}${editData.id}/`,formData)
                message.success('Actualizado correctamente')
            }else{
                res = await axiosApi.post(`${API.BUSINESS.PROMOS_LIST}`,formData)
                message.success('Creado correctamente')
            }
            setShowModal(false)
            getPromos()
        }catch (e){
            message.error('Por favor revise la información e intente nuevamente')
        }finally {
            setLoading(false)
        }
    }

    const onShowModal=()=>{
        setShowModal(true)
    }

    return (
        <MainLayoutv2
        title={<FormattedMessage id="promos" />}
        index="promos"
    >
           <Content
                style={{padding: "10px 10px"}}
            >
                <Row gutter={[10]} justify="space-between">
                    <Col flex="auto">
                    <KButton
                    onClick={()=>{
                        onShowModal()
                        setEditData(null)
                    }}
                    type="primary"
                    style={{ float: "right",marginBottom:20 }}
                    size={"large"}
                    text={<FormattedMessage id="layout.createNew" />}
                />
                    </Col>
                </Row>
                
                        <CustomTable
                        loading={loading}
                        dataSource={promos}
                        columns={columns}
                        scroll={{ x: 1000 }}
                        pagination={{total: count, pageSize: 20, onChange: changePagination}}
                    />
               
                
                                
               

                <ModalCreateEditPromo dataEdit={editData}
                                      loading={loading}
                                      onCreatePromotion={onCreatePromotion}
                                      onCancel={() => {
                                          setShowModal(false)
                                          setEditData(null)
                                      }} visible={showModal}/>
            </Content>
    </MainLayoutv2>)
}

export default injectIntl(PromoListPage);