import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {injectIntl } from "react-intl";
import {
    Layout,
    Card,
    Row,
    Col,
    Select,
    Input,
    Spin,
    Form,
    message,
    Descriptions
} from 'antd'
import ServiceLayout from "../../layouts/ServiceLayout";
import MenuServiceDetail from "../../components/services/MenuServiceDetail";
import SectionTitle from "../../components/common/elements/SectionTitle/SectionTitle";
import KButton from "../../components/common/elements/KButton/KButton";
import {useHistory, useParams} from "react-router-dom";
import {getServiceDetail, getClosingReasons, closeOrder, reOpenOrder} from '../../redux/serviceOrderDuck'
import _ from "lodash";
import useSettingsKikert from "../../hooks/useSettingsKikert";
import {ruleRequired} from "../../lib/utils/rules";
import axiosApi, {API} from "../../lib/utils/axiosApi";
import MainLayoutv2 from '../../layouts/MainLayoutv2';

const ServiceInvoicing=({intl,serviceOrder,getServiceDetail,getClosingReasons,closeOrder, reOpenOrder, ...props})=>{

    let { id } = useParams();
    let history = useHistory();
    const {settingsKikert, getMetaValue} = useSettingsKikert()
    const HIDE_LICENSE_PLATE_INPUT=getMetaValue('HIDE_LICENSE_PLATE_INPUT');
    const [loading, setLoading] = useState(false)

    const [form] = Form.useForm();

    const value_is_invoiced = Form.useWatch('is_invoiced', form);


    const setInvoiceData=async(data)=>{
        setLoading(true)
        try{
            const response = await axiosApi.post(API.WORKSHOP.WORKSHOP_ORDER_DATA_INVOICE.replace('{orderId}',id),data);
            console.log(response)
            getServiceDetail(id)
            message.success('Guardado correctamente');
        }catch (e){
            console.log(e)
            message.error('Hubo un error al realizar la petición, porfavor intenta mas tarde    ');
        }finally {
            setLoading(false)
        }
    }


    useEffect(()=>{
        if (id) {
            getServiceDetail(id);
            getClosingReasons();
        }
    },[])

    useEffect(()=>{
         if(serviceOrder?.serviceDetail){
             const {invoice_reference, is_invoiced} = serviceOrder?.serviceDetail;
            form.setFieldsValue({
                invoice_reference,
                is_invoiced
            })
         }
    },[serviceOrder?.serviceDetail])

    const onFinish=(dataForm)=>{
        console.log(dataForm)
        setInvoiceData(dataForm)
    }


    return <>
        <MainLayoutv2 isServiceDetail={true} title={intl.formatMessage({ id: "service.dataInvoice" })} index="invoicing">
            <Spin spinning={serviceOrder.loading || loading}>
            <Layout style={{ marginTop: 10 }}>
                <Card className="card_details_service">
                    <MenuServiceDetail menuSelected={6} />
                    <SectionTitle title={intl.formatMessage({ id: "service.dataInvoice" })} />
                    <Row gutter={16} style={{marginBottom:20}}>
                        <Col xs={24} md={12}>
                            <Form
                                onFinish={onFinish}
                                style={{marginTop:40}}
                                form={form}
                                wrapperCol={{
                                    span: 14,
                                }}
                                layout="vertical"
                            >
                                <Form.Item rules={[ruleRequired]} name={'is_invoiced'} label="¿Está facturada la orden?">
                                    <Select>
                                        <Select.Option value={false}>No Facturado</Select.Option>
                                        <Select.Option value={true}>Facturado</Select.Option>
                                    </Select>
                                </Form.Item>

                                {
                                    value_is_invoiced && <Form.Item  name={'invoice_reference'} label="Referencia">
                                        <Input />
                                    </Form.Item>
                                }



                                <Form.Item>
                                    <KButton type={'primary'} htmlType={'submit'} text={intl.formatMessage({ id: "save" })}/>
                                </Form.Item>

                            </Form>
                        </Col>
                        <Col xs={24} lg={12}>
                            <br/>
                            {
                                serviceOrder?.serviceDetail &&  <Descriptions
                                    bordered
                                    layout="vertical"
                                    title={intl.formatMessage({ id: "servicesDetails.orderDetail" })}
                                    size={'small'}
                                >
                                    <Descriptions.Item label="Folio">{serviceOrder.serviceDetail.folio}</Descriptions.Item>
                                    <Descriptions.Item label="Núm. Orden">{serviceOrder.serviceDetail.order_number}</Descriptions.Item>
                                    <Descriptions.Item label="Cliente">{serviceOrder.serviceDetail.customer.first_name} {serviceOrder.serviceDetail.customer.last_name}</Descriptions.Item>
                                    {
                                        serviceOrder.serviceDetail['WORKSHOP'] &&
                                        <>
                                            <Descriptions.Item label="Unidad">{serviceOrder.serviceDetail['WORKSHOP'].vehicle?.model?.brand?.name} {serviceOrder.serviceDetail['WORKSHOP'].vehicle?.model?.name} {serviceOrder.serviceDetail['WORKSHOP']?.vehicle?.year}</Descriptions.Item>
                                            {
                                                HIDE_LICENSE_PLATE_INPUT==='0' && <Descriptions.Item label="Placas">{serviceOrder.serviceDetail['WORKSHOP']?.vehicle?.license_plate}</Descriptions.Item>
                                            }
                                           
                                            <Descriptions.Item label="Color">{serviceOrder.serviceDetail['WORKSHOP']?.vehicle?.color.name}</Descriptions.Item>
                                        </>
                                    }
                                    <Descriptions.Item label="Comentario">{serviceOrder.serviceDetail.comment}</Descriptions.Item>
                                    <Descriptions.Item label="Fecha de entrega">{_.get(serviceOrder, 'serviceDetail.closing_datetime','--') }</Descriptions.Item>
                                    <Descriptions.Item label="Razón de entrega">{ _.get(serviceOrder, 'serviceDetail.closing_reason.name','--') }</Descriptions.Item>
                                    <Descriptions.Item label="Locación actual">{ _.get(serviceOrder, 'serviceDetail.current_location.name','--') }</Descriptions.Item>
                                </Descriptions>
                            }
                        </Col>


                    </Row>
                    <Row>

                    </Row>
                </Card>
            </Layout>
            </Spin>
        </MainLayoutv2>
    </>
}


const mapStateToProps = state => ({
    settings: state.settings,
    serviceOrder: state.serviceOrder
})


export default injectIntl(connect(mapStateToProps,{getServiceDetail,getClosingReasons,closeOrder, reOpenOrder})(ServiceInvoicing));