import React, { useEffect, useState } from 'react';
import {FormattedMessage, injectIntl} from "react-intl";
import locale from "antd/es/date-picker/locale/es_ES";
import {
    Form,
    Input,
    Button,
    Radio,
    DatePicker,
    Space,
    InputNumber,
    Select,
    message,
    Skeleton,
    Divider,
    Typography,
    Switch,
    Row,
    Col,
    Card,
    Avatar,
    Breadcrumb,
    Tabs,
} from "antd";
import axiosApi, {API} from "../../lib/utils/axiosApi";
import _ from "lodash";
import { useHistory, useParams } from "react-router-dom"
import {ItemForm} from "../MyComponents";
import {vinRule,ruleRequired} from "../../lib/utils/rules";
import KButton from "../common/elements/KButton/KButton";
import KInput from "../common/elements/KInput/KInput";
import SelectAsesor from '../Selects/SelectAsesor';
import SelectLocations from '../Selects/SelectLocations';
import moment from 'moment'

const ServiceFormJAC = (changeLangAction, intl, settings, ...props) => {

    const [form] = Form.useForm();
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [carBrands, setCarBrands] = useState([])
    const [carModels, setCarModels] = useState([])
    const [carBrandDefault, setCarBrandDefault] = useState(null)
    const [carColors, setCarColors] = useState([])
    const [orderID, setOrderId] = useState(null)
    const { id } = useParams();
    const isUpd = id ? true : false
    const valuesConformidades=[
        "gi",
        "ox",
        "ry",
        "br",
        "ba",
        "po",
        "sc",
        "esc",
        "rb",
        "mu",
        "mr",
        "pr",
        "t",
        "pe"]



    const { Option } = Select;

    useEffect(() => {
        if(carBrands.length === 1){
            console.log("carBrands", carBrands);
            setCarBrandDefault("carBrand", carBrands[0]["id"]);
        }
    }, [carBrands]);

    useEffect(() => {
        if(id){
            loadData(id)
        }
    }, [id]);


    const setInitialDataMap = (initialDataIn) => {

        console.log(initialDataIn)
    
        const initial = {
           
        };

        if(initialDataIn.created_at) initial.created_at = moment(initialDataIn.created_at)

        if(initialDataIn?.WORKSHOP?.vehicle?.vin) initial.vin = initialDataIn?.WORKSHOP?.vehicle?.vin
        if(initialDataIn?.WORKSHOP?.vehicle?.model) initial.model_id = initialDataIn?.WORKSHOP?.vehicle?.model?.id
        if(initialDataIn?.WORKSHOP?.vehicle?.color) initial.color_id = initialDataIn?.WORKSHOP?.vehicle?.color?.id

        if(initialDataIn?.current_location) initial.location_id = initialDataIn?.current_location?.id


        form.setFieldsValue(initial);
      }


    const loadData = async (expedientId) => {
        try {
          setLoading(true)
          setOrderId(expedientId)
          const url = `${API.WORKSHOP.WORKSHOP_ORDER_JAC}${expedientId}/`
          const response = await axiosApi.get(url)
          console.log("response===>", response)
          setInitialDataMap(response.data)
        } catch (error) {
          console.error('No se pudo obtener información', error)
        } finally {
          setLoading(false)
        }
      }

    const onFinish = async (values) => {
        let arrayValue = []
        valuesConformidades.map((ele)=>{
            console.log((values[ele]==='' || values[ele]===undefined)?'0':values[ele])
            arrayValue.push((values[ele]==='' || values[ele]===undefined)?'0':values[ele]);
        })

        let confimidadesStrRequest = arrayValue.join();


        try{
            values.no_conformities = confimidadesStrRequest;
            let response = null;
            if(orderID){
                // es update
                response = await axiosApi.put(API.WORKSHOP.WORKSHOP_ORDER_JAC+orderID, values)
                message.success('Actualizado correctamente')
            }else{
                response = await axiosApi.post(API.WORKSHOP.WORKSHOP_ORDER_JAC, values)
                message.success('Creado correctamente')
            }
            console.log(response)

            history.push("/services/list/")
        }catch(e){
            console.log(e)
        }


    };


    const getCarColors = async () => {
        const response = await axiosApi.get(API.WORKSHOP.WORKSHOP_CARCOLOR)
        setCarColors(response.data.results)
    }

    useEffect(()=>{
        getCarBrands()
        getCarColors()
        getCarModels()
    },[])


    const getCarModels = async () => {
        console.log('getCarModels===')
        const responseModels = await axiosApi.get(`${API.WORKSHOP.WORKSHOP_CARMODEL}?page_size=100000`)
        console.log(responseModels.data.results);
        setCarModels(responseModels.data.results)
    }

    const getCarBrands = async () => {
        const response = await axiosApi.get(API.WORKSHOP.WORKSHOP_CARBRAND+'?page_size=100000')
        setCarBrands(response.data.results)
        /* if (response.data.results.length === 1) {
          setDefaultUnitValue("carBrand", response.data.results[0]["id"]);
        } */
    }



    return (
        <Row style={{paddingTop:30}}>
            <Col span={24}>

                <Form
                    form={form}
                    onFinish={onFinish}
                    name="wrap"
                    labelCol={{
                        flex: '300px',
                    }}
                    labelAlign="left"
                    labelWrap
                    wrapperCol={{
                        flex: 1,
                    }}
                    colon={false}
                >
                    <Form.Item
                        name="created_at"
                        label="Fecha y  hora de ingreso"
                        rules={[ruleRequired]}
                    >
                        <DatePicker  showTime style={{width:'100%'}} locale={locale} />
                    </Form.Item>

                    <SelectLocations required={true} name='location_id' label='Locación'/>
                    <br/>

                    <ItemForm
                        label={<FormattedMessage id="services.createNew.serialNumber" />}
                        name="vin"
                        rules={[ruleRequired]}
                    >
                        <Input
                            maxLength={17}
                            placeholder="Número de serie"
                        />
                    </ItemForm>
                    <ItemForm
                        label={<FormattedMessage id="layout.model" />}
                        rules={[ruleRequired]}
                        name="model_id"
                        tooltip="Campo requerido"
                    >
                        <Select
                            loading={loading}
                            style={{ width: "100%" }}
                            /* value={carModelSelected} */
                            showSearch
                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        >
                            {/*LISTAR MODELOS*/}
                            {carModels
                                ? carModels.map((item) => (
                                    <Option value={item.id} key={item.id}>
                                        {item.name}
                                    </Option>
                                ))
                                : ""}
                        </Select>
                    </ItemForm>
                    <ItemForm
                        label={<FormattedMessage id="services.createNew.colour" />}
                        rules={[ruleRequired]}
                        name="color_id"
                        tooltip="Campo requerido"
                    >
                        <Select
                            loading={loading}
                            style={{ width: "100%" }}
                            /* value={carModelSelected} */
                            showSearch
                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        >
                            {/*LISTAR MODELOS*/}
                            {carColors
                                ? carColors.map((item) => (
                                    <Option value={item.id} key={item.id}>
                                        {item.name}
                                    </Option>
                                ))
                                : ""}
                        </Select>
                    </ItemForm>
                    <Row >
                        <Col xs={24} md={6}><p>
                            <Form.Item
                                name=""
                                label="No conformidades"
                            >
                            </Form.Item>
                        </p></Col>
                        <Col md={15} xs={24}>
                            <div style={styles.conformidades}>
                                <Form.Item
                                    name="gi"
                                    label="Gl"
                                >
                                    <InputNumber min={1} max={99}/>
                                </Form.Item>
                            </div>
                            <div style={styles.conformidades}>
                                <Form.Item
                                    name="ox"
                                    label="Ox"
                                >
                                    <InputNumber min={1} max={99}/>
                                </Form.Item>
                            </div>
                            <div style={styles.conformidades}>
                                <Form.Item
                                    name="ry"
                                    label="Ry"
                                >
                                    <InputNumber min={1} max={99}/>
                                </Form.Item>
                            </div>
                            <div style={styles.conformidades}>
                                <Form.Item
                                    name="br"
                                    label="Br"
                                >
                                    <InputNumber min={1} max={99}/>
                                </Form.Item>
                            </div>
                            <div style={styles.conformidades}>
                                <Form.Item
                                    name="ba"
                                    label="Ba"
                                >
                                    <InputNumber min={1} max={99}/>
                                </Form.Item>
                            </div>
                            <div style={styles.conformidades}>
                                <Form.Item
                                    name="po"
                                    label="Po"
                                >
                                    <InputNumber min={1} max={99}/>
                                </Form.Item>
                            </div>
                            <div style={styles.conformidades}>
                                <Form.Item
                                    name="sc"
                                    label="Sc"
                                >
                                    <InputNumber min={1} max={99}/>
                                </Form.Item>
                            </div>
                            <div style={styles.conformidades}>
                                <Form.Item
                                    name="esc"
                                    label="Esc"
                                >
                                    <InputNumber min={1} max={99}/>
                                </Form.Item>
                            </div>
                            <div style={styles.conformidades}>
                                <Form.Item
                                    name="rb"
                                    label="Rb"
                                >
                                    <InputNumber min={1} max={99}/>
                                </Form.Item>
                            </div>
                            <div style={styles.conformidades}>
                                <Form.Item
                                    name="mu"
                                    label="Mu"
                                >
                                    <InputNumber min={1} max={99}/>
                                </Form.Item>
                            </div>
                            <div style={styles.conformidades}>
                                <Form.Item
                                    name="mr"
                                    label="Mr"
                                >
                                    <InputNumber min={1} max={99}/>
                                </Form.Item>
                            </div>
                            <div style={styles.conformidades}>
                                <Form.Item
                                    name="pr"
                                    label="Pr"
                                >
                                    <InputNumber min={1} max={99}/>
                                </Form.Item>
                            </div>
                            <div style={styles.conformidades}>
                                <Form.Item
                                    name="t"
                                    label="T"
                                >
                                    <InputNumber min={1} max={99}/>
                                </Form.Item>
                            </div>
                            <div style={styles.conformidades}>
                                <Form.Item
                                    name="pe"
                                    label="Pe"
                                >
                                    <InputNumber min={1} max={99}/>
                                </Form.Item>
                            </div>
                        </Col>

                    </Row>

                    <Form.Item
                        name="repair_start"
                        label="Hora Cajón"
                    >
                        <DatePicker showTime style={{width:'100%'}} locale={locale} />
                    </Form.Item>
                    <SelectAsesor name='repair_by_id' label='Acondicionador' />
                    <br/>
                    <SelectAsesor name='qa_repair_by_id' label='Calidad' />
                    <br/>

                    <Form.Item
                        name="repair_end"
                        label="Hora salida"
                    >
                        <DatePicker showTime style={{width:'100%'}} locale={locale} />
                    </Form.Item>

                    <Row justify="center">
            <Col>
              <Space size={10}>
                <Button
                  type="default"
                  htmlType="button"
                  loading={loading}
                  onClick={() => history.push("/services/list/")}
                  className="btn-blue btn-report-action"
                  size="large"
                >
                  <FormattedMessage id="layout.cancel" />
                </Button>
                <Button
                  type="primary"
                  loading={loading}
                  htmlType="submit"
                  className="btn-string-blue btn-report-action"
                  size="large"
                >
                  <FormattedMessage id="services.filter.save" />
                </Button>
              </Space>
            </Col>
          </Row>

                </Form>
            </Col>

        </Row>
    )
}


const styles={
    conformidades:{
        float:'left',
        width:'50px'
    }
}



export default injectIntl(ServiceFormJAC)