import React, { useState, useEffect } from 'react'
import axiosApi, { tenantSelected, API } from "../../../lib/utils/axiosApi";
import CardStatistics from "../../../components/CardStatistics/CardStatistics";
import Chart from "react-apexcharts";
import {message, Row, Col, Divider, Empty, Card} from 'antd';

const JACTypeCharts = ({filters,colors1}) => {

    const [loadingData, setLoadingData] = useState(false);
    const [data, setData] = useState([]);
    const [dataZones, setDataZones] = useState([]);
    const [dataDamages, setDataDamages] = useState([]);


    const transformElements = (_elements=[]) => {
        const series = [];
        const labels = [];
        
        Object.values(_elements).forEach(ele => {
            series.push(ele.count);
            labels.push(ele.name);
        });
    
        return { series, labels };
    };




    const getOrderGlobalDashboard=async (filters,codeWidget='NUM_ORDERS')=>{
        const url = 'service/order-global-dashboard/?order_type=WORKSHOP';
        try{
           const res = await axiosApi.get(url+`&widget_code=${codeWidget}&${filters}`);
           return res;
        }catch (e){
           return e;
        }
    }

    const processJacDamages = (element)=>{
        try{
            const _element = Object.values(element).map(value => value);
            const attributes = Object.keys(element);
            return { data:_element, attributes };
        }catch(e){
            return {data:[], attributes:[]}    
        }
    }

    useEffect(() => {
        if(filters){
            setLoadingData(true)
            getOrderGlobalDashboard(filters,'JAC_ZONES').then((res)=>{
                console.log(res)
                const dataElements = res?.data?.data;
                setDataZones(transformElements(dataElements))

            }).catch(()=>{}).finally(()=>{setLoadingData(false)})

            getOrderGlobalDashboard(filters,'JAC_DAMAGES').then((res)=>{
                console.log('jac damages',res?.data?.data)
                let dataBars = processJacDamages(res?.data?.data)
                setDataDamages(dataBars)
            }).catch(()=>{}).finally(()=>{setLoadingData(false)})

            getOrderGlobalDashboard(filters,'JAC_PAINTED_PARTS_BY_VEHICLE_TYPE').then((res)=>{
                // es?.data?.top_parts
                
                //res.data = {...res.data, ...dataElements}
                let datacards =res.data;
                datacards = datacards.map(item=>{
                    return {
                        ...item,
                        ...transformElements(item.top_parts)
                    }
                })
                setData(datacards)
                // res?.data?.top_parts  
                
            }).catch(()=>{}).finally(()=>{setLoadingData(false)})
        }
    }, [filters]);

    const processTotalServicesPaintedPercent = (item)=>{
        return item.total_services_painted_percent ? `${item.total_services_painted_percent.toFixed(2)}%` : '0%'
    }

    const processAvgPartsPainted = (item)=>{
        return item.avg_parts_painted ? `${item.avg_parts_painted.toFixed(2)}` : '0'
    }


  return <>
        {
            dataZones && dataZones?.labels?.length > 0 && (
                <Row gutter={16}>
                    <Col md={12} xs={24}>
                    <Card>
                        <h2>Zonas</h2>
                        <Chart
                        options={{
                            chart: {
                                width: '100%',
                                type: 'donut',
                                id: `chartxx-zone`,
                                toolbar: {
                                    show: true
                                }
                            },
                            colors:colors1,
                            labels:dataZones?.labels,
                            responsive: [{
                                breakpoint: 480,
                                options: {
                                    legend: {
                                        position: 'bottom'
                                    }
                                }
                            }]
                        }}
                        series={dataZones?.series}
                        type="donut"
                        width="100%"
                                height={'700'}
                            />  
                    </Card>
                    </Col>
                    <Col md={12} xs={24}>
                    <Card title="Daños">
                        <Chart
                            options={{
                                chart: {
                                    type: 'bar',
                                    id: `chartxx-damage`,
                                    toolbar: {
                                    show: true
                                    }
                                },
                                colors: colors1,
                                        xaxis: {
                                    categories: dataDamages?.attributes
                                },
                                yaxis: {
                                    title: {
                                            text: 'Cantidad'
                                            }
                                    },
                                    plotOptions: {
                                        dataLabels: {
                                            enabled: true,
                                            formatter: function (val) {
                                              return val;
                                            },
                                            style: {
                                              fontSize: '12px',
                                              colors: ['#282525']
                                            }
                                        },
                                        bar: {
                                            //horizontal: false,
                                            columnWidth: '90%',
                                            endingShape: 'rounded'
                                        },  
                                        },
                                        stroke: {
                                            show: true,
                                            width: 2,
                                            colors: colors1
                                        },
                                        responsive: [{
                                                breakpoint: 480,
                                                options: {
                                                    legend: {
                                                    position: 'bottom'
                                                    }
                                                }
                                        }]
                                    }}
                                    series={[{
                                        name: 'Daños',
                                        data: dataDamages?.data
                                    }]}
                                type="bar"
                                width="100%"
                                height={'400'}
                            />
                    </Card>
                    </Col>
                   
                </Row>
            )
        }
        {
            data && data.length > 0 && (
                <div>
                    {
                        data.map((item,index)=>(
                            <Row key={index}>
                                <Col span={24} style={{marginBottom:10}}>
                                    <h2>{item.vehicle_type_name}</h2>
                                    
                                        <Row gutter={16}>
                                            <Col md={5} xs={24}>
                                                    <CardStatistics
                                                    fontSize={40}
                                                    title={'Prom. Piezas pintadas'}
                                                    value={processAvgPartsPainted(item)}/>
                                            </Col>
                                            <Col md={5} xs={24}>
                                                    <CardStatistics
                                                    fontSize={40}
                                                title={`U's. Pintura`}
                                                value={processTotalServicesPaintedPercent(item)}/>
                                            </Col>
                                            <Col md={12} xs={24}>
                                            <h3 style={{marginBottom:10, textAlign:'center'}}>Piezas con mayor tendencia</h3>
                                            {
                                                item.labels.length > 0 ? (
                                                        <Chart
                                                            options={{
                                                                chart: {
                                                                    width: 380,
                                                                    type: 'donut',
                                                                    id: `chartxx-${index}`,
                                                                    toolbar: {
                                                                        show: true
                                                                    }
                                                                },
                                                                colors:colors1,
                                                                labels:item?.labels,
                                                                responsive: [{
                                                                    breakpoint: 480,
                                                                    options: {
                                                                        chart: {
                                                                            width: 200
                                                                        },
                                                                        legend: {
                                                                            position: 'bottom'
                                                                        }
                                                                    }
                                                                }]
                                                            }}
                                                            series={item.series}
                                                            type="donut"
                                                            width="100%"
                                                                    height={'600'}
                                                                />  
                                                    
                                                ): <Empty description="No hay datos"/>
                                            }
                                            </Col>
                                            
                                        </Row>
                                </Col>

                                <Divider/>
                            </Row>
                        ))
                    }
                    
                </div>
            )
        }
  </>;
};

export default JACTypeCharts;