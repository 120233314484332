import { axiosApi, API } from '../lib/utils/axiosApi';

// initial state
let initialData = {
  fetching: false,
  financialPlanList: [],
  financialPlan: null,
  carAvailabilty: null,
  carAvailabiltyList: [],
  openModalFilter: false,
  filterData: {
    brand: "",
    model: "",
    order__current_step: "",
    sale_type: "",
    start_date: '',
    end_date: '',
    asigned_to: ''
  },
};

const GET_FINANCIAL_PLAN = "GET_FINANCIAL_PLAN";
const GET_FINANCIAL_PLAN_SUCCESS = "GET_FINANCIAL_PLAN_SUCCESS";
const GET_FINANCIAL_PLAN_ERROR = "GET_FINANCIAL_PLAN_ERROR";

const GET_CAR_AVAILABILITY = "GET_CAR_AVAILABILITY";
const GET_CAR_AVAILABILITY_SUCCESS = "GET_CAR_AVAILABILITY_SUCCESS";
const GET_CAR_AVAILABILITY_ERROR = "GET_CAR_AVAILABILITY_ERROR";
const MODAL_FILTER = "MODAL_FILTER";
const FILTER_DATA = "FILTER_DATA";
const CLEAR_FILTER_DATA = "CLEAR_FILTER_DATA"


// Reducer
export default function reducer(state=initialData, action) {
    switch (action.type) {
      case GET_FINANCIAL_PLAN:
        return { ...state, fetching: true };
      case GET_FINANCIAL_PLAN_SUCCESS:
        return { ...state, fetching: false, ...action.payload };
      case GET_FINANCIAL_PLAN_ERROR:
        return { ...state, fetching: false, financialPlanList: [] };
      case GET_CAR_AVAILABILITY:
        return { ...state, fetching: true };
      case GET_CAR_AVAILABILITY_SUCCESS:
        return { ...state, fetching: false, ...action.payload };
      case GET_CAR_AVAILABILITY_ERROR:
        return { ...state, fetching: false, carAvailabiltyList: [] };
      case MODAL_FILTER:
        return { ...state, openModalFilter: action.payload };
      case FILTER_DATA:
        return { ...state, filterData: action.payload };
      case CLEAR_FILTER_DATA:
        let form_clean = {
          brand: '',
          model: '',
          order__current_step: '',
          sale_type: '',
          start_date: '',
          end_date: '',
          asigned_to: ''
        }
        console.log({ ...state, filterData: form_clean });
        return { ...state, filterData: form_clean };
      default:
        return state;
    }
}

// Actions

export let getFinancialPlans = () => async ( dispatch, getState ) => {
    dispatch({
        type: GET_FINANCIAL_PLAN
    })
    await axiosApi.get(API.SALE.FINANCIAL_PLAN)
    .then((response) => {
        dispatch({
            type: GET_FINANCIAL_PLAN_SUCCESS,
            payload: { financialPlan: response.data, financialPlanList: response.data.results }
        });
    })
    .catch((error) => {
        dispatch({
            type: GET_FINANCIAL_PLAN_ERROR
        });
    })
}


export let getCarAvailabilityList = () => async ( dispatch, getState ) => {
    dispatch({
        type: GET_CAR_AVAILABILITY
    })
    await axiosApi.get(API.SALE.CAR_AVAILABILITY)
    .then((response) => {
        dispatch({
            type: GET_CAR_AVAILABILITY_SUCCESS,
            payload: { carAvailabilty: response.data, carAvailabiltyList: response.data.results }
        });
    })
    .catch((error) => {
        dispatch({
            type: GET_CAR_AVAILABILITY_ERROR
        });
    })
}

export let setModalFilter = (open) => async (dispatch, getState) => {
  dispatch({
    type: MODAL_FILTER,
    payload: open,
  });
};

export let setFilterData = (data) => async (dispatch, getState) => {
  dispatch({
    type: "FILTER_DATA",
    payload: data
  });
};

export let clearFilterSales = () => async (dispatch, getState) => {
  dispatch({
    type: CLEAR_FILTER_DATA
  });
}