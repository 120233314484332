import React, { useState, useEffect } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { changeLangAction } from '../../redux/settingsDuck';
import { connect } from 'react-redux'
import {
  Button,
  Input,
  Layout,
  Space,
  Table,
  Tooltip,
  Row,
  Menu,
  Col,
  Form,
  Dropdown,
} from "antd";
import {
  DashboardOutlined,
  DownOutlined,
  FileTextOutlined,
  LineChartOutlined,
  MobileOutlined,
  SettingOutlined,
  FullscreenOutlined
} from "@ant-design/icons";
import { useHistory, Link, Redirect } from "react-router-dom";
import { Global, css } from "@emotion/react";



const TabsOptions = ({
  changeLangAction,
  intl,
  settings,
  fullScreen = false,
  ...props
}) => {
  const history = useHistory();
  const [path, setPath] = useState(null);
  console.log("history", history);
  const {
    location: { pathname },
  } = history;

  useEffect(() => {
    if (pathname) {
      setPath(pathname.replace("/", ""));
    }
  }, []);

  return (
    <>
      {/* background-color: var(--secondaryColor); */}
      <Global
        styles={css`
          .selected {
            background-color: var(--secondaryColor) !important;
            color: #ffff;
          }
        `}
      />
      <Row>
        <Col span={24}>
          <div style={{ display:'flex', justifyContent:'space-between' }}>
            <Space>
              <Button
                className={`btns_services ${
                  path === "electroplan" && "selected"
                }`}
                icon={<DashboardOutlined />}
                style={{ paddingRight: 9 }}
                onClick={() => history.push("/electroplan")}
              >
                Electroplán <DownOutlined style={{ fontSize: 10 }} />
              </Button>
              <Button
                className="btns_services"
                icon={<FileTextOutlined />}
                style={{ paddingRight: 9 }}
              >
                <FormattedMessage id="services.page.reports" />{" "}
                <DownOutlined style={{ fontSize: 10 }} />
              </Button>
              <Button className="btns_services" icon={<LineChartOutlined />}>
                <FormattedMessage id="services.page.analitycs" />
              </Button>
              <Button className="btns_services" icon={<SettingOutlined />}>
                <FormattedMessage id="services.page.configurations" />
              </Button>
              <Button
                className="btns_services"
                icon={<MobileOutlined />}
                style={{ paddingRight: 9 }}
              >
                <FormattedMessage id="services.page.apps" />{" "}
                <DownOutlined style={{ fontSize: 10 }} />
              </Button>
            </Space>
            {fullScreen && (
              <Button
                className="btns_services"
                icon={<FullscreenOutlined />}
                onClick={() => fullScreen(true)}
              >
                FullScreen
              </Button>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

function mapState(state) {
  return {
    settings: state.settings
  }
}

export default injectIntl(connect(mapState, {
  changeLangAction,
})(TabsOptions))
