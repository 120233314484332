import React from 'react'
import {Typography} from 'antd'

const TagDescription =({size=16,title='',icon=null, width=0, value='', ...props })=>{
    
    const {Text} = Typography
    return (
      <p style={{ marginBottom: 1, fontSize: size }}>
        {icon ? (
          <img src={icon} width={10} alt="" style={{ marginRight: 5 }} />
        ) : (
          <Text
            style={{
              marginRight: 5,
              fontWeight: "bolder",
              display: 'inline-block',
            }}
            className="text-secondary"
          >
            {title}:
          </Text>
        )}
        {value}
      </p>
    );
}


export default TagDescription