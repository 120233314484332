import { 
    Table,
    Form,
    Input,
    Popconfirm,
    Space,
    Checkbox,
    message,
    Typography,
    Row,
    Col,
} from "antd"
import { useEffect, useState } from "react"
import { FormattedMessage, injectIntl } from "react-intl";
import { axiosApi, API, urlfull } from "../../lib/utils/axiosApi";
import { floatNumbers, ruleRequired } from "../../lib/utils/rules";
import KSelect from "../../components/common/elements/KSelect/KSelect";
import KButton from "../../components/common/elements/KButton/KButton";
import { fileToBase64 } from "../../services/kikert/utils"

const PreviewAudatexData = ({ 
    data, jobTypes, estatusList, fileList,
    setOpenModal, getValuation, setLoadingModal,
    serviceOrder, valuationId, orderId }) => {

    const [dataAdapter, setDataAdapter] = useState(null)
    const [dataAdapterToUpload, setDataAdapterToUpload] = useState(null)
    const [currentEdit, setCurrentEdit] = useState(null)
    const [loading, setLoading] = useState(false)
    const [ formEditBudget ] = Form.useForm();
    const [openConfirm, setOpenConfirm] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [previousDelete, setPreviousDelete] = useState(false)
    const [totalParts, setTotalParts] = useState(0)
    const [totalLabor, setTotalLabor] = useState(0)
    const [totalPaint, setTotalPaint] = useState(0)
    const [countTotalParts, setCountTotalParts] = useState(0)
    const [counttTotalLabor, setCountTotalLabor] = useState(0)
    const [counttTotalPaint, setCountTotalPaint] = useState(0)
    const [onlyParts, setOnlyParts] = useState(false)
    const [onlyLabor, setOnlyLabor] = useState(false)
    const [onlyPaint, setOnlyPaint] = useState(false)

    const { Text, Link } = Typography;

    const selectForEdit = (item) => {
        setCurrentEdit(item.id);
    
        formEditBudget.setFieldsValue({
          qty: item.qty,
          job_type: item.job_type,
          concept: item.concept,
          cost: item.cost,
          price: item.price,
          total: item.total_cost,
          status: item.status,
        });
      };

    const jobTypesLocal = {
        OBRA: "Mano de obra",
        PAINT: "Pintura",
        PARTS: "Refacciones"
    } 
    
    const deleteBudgetItem = () => {}


    const saveBulkBudget = async () =>{
        try {
            setLoading(true)
            setLoadingModal(true)
            let fileToLoad = fileList[0];
            const values = {
              "orderId": orderId,
              "budget": dataAdapterToUpload,
              "fileBase64": await fileToBase64(fileToLoad),
              "delete_previous": previousDelete
            }
            console.log("values", values)
            let resp = await axiosApi.post(API.WORKSHOP.WORKSHOP_BULK_BUDGET, values);
            if( 200 <= resp.status < 400){
                message.success("Datos guardados");
                setOpenModal(false)
                getValuation()
            }
        } catch (error) {
            message.error("No se pudieron guardar los datos");
        }finally{
            setLoading(false)
            setLoadingModal(false)
        }
      }

    const  currencyFormat = (num) => {
        return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
     }

    const columns = [
        {
          title: "Cantidad",
          dataIndex: "qty",
          key: "qty",
          render: (text, item, idx) => {
            return currentEdit === item.id ? (
              <Form.Item name="qty" rules={[floatNumbers, ruleRequired]}>
                <Input className={"kinput"} />
              </Form.Item>
            ) : (
              <b>{text}</b>
            );
          },
        },
        {
          title: "Tipo de trabajo",
          dataIndex: "job_type",
          key: "job_type",
          render: (text, item, idx) => <b>{jobTypesLocal[text]}</b>
        },
        {
          title: "Concepto",
          dataIndex: "concept",
          key: "concept",
          render: (text, item, idx) => {
            return currentEdit === item.id ? (
              <Form.Item name="concept" rules={[ruleRequired]}>
                <Input className={"kinput"} maxLength={50} />
              </Form.Item>
            ) : (
              <b>{text}</b>
            );
          },
        },
        {
          title: "Costo unitario",
          dataIndex: "cost",
          key: "cost",
          render: (text, item, idx) => {
            return currentEdit === item.id ? (
              <Form.Item
                name="cost"
                rules={[floatNumbers, ruleRequired]}
              >
                <Input className={"kinput"} />
              </Form.Item>
            ) : (
              <b>{ 
                currencyFormat( parseFloat(text))}</b>
            );
          },
        },
        {
          title: "Precio unitario",
          dataIndex: "price",
          key: "price",
          render: (text, item, idx) => {
            return currentEdit === item.id ? (
              <Form.Item name="price" rules={[floatNumbers, ruleRequired]}>
                <Input className={"kinput"} />
              </Form.Item>
            ) : (
              <b>{currencyFormat(parseFloat(text))}</b>
            );
          },
        },
        {
          title: "Precio total",
          dataIndex: "total_price",
          key: "total_price",
          render: (text, item, idx) => {
            return currentEdit === item.id ? (
              <Form.Item name="total">
                <Input readOnly className={"kinput"} />
              </Form.Item>
            ) : (
              <b>{currencyFormat(parseFloat(text))}</b>
            );
          },
        },
        {
          title: "Diferencia",
          key: "diff",
          render: (text, item, idx) => {
              const diff = (parseFloat(item?.qty || 0) * parseFloat(item.price)) - (parseFloat(item?.qty || 0) * parseFloat(item.cost))
              return (
                <b>{currencyFormat(diff)}</b>
              )
            
          },
        },
        {
          title: "Estatus ",
          dataIndex: "status",
          key: "status",
          render: (text, item, idx) => {
            let status = estatusList.find((element) => element.value === text);
            return currentEdit === item.id ? (
              <Form.Item name="status" rules={[ruleRequired]}>
                <KSelect
                  options={estatusList}
                  style={{ width: 150 }}
                  disabled={
                    !serviceOrder.serviceDetail?.order_dynamic_data.valuation
                  }
                />
              </Form.Item>
            ) : (
              <b>{status.label}</b>
            );
          },
        },
        // {
        //   title: "Acciones",
        //   key: "status",
        //   render: (text, item, idx) => {
        //     return currentEdit === item.id ? (
        //       <Space size={20}>
        //         <Link onClick={() => formEditBudget.submit()}>
        //           <FormattedMessage id="save" />
        //         </Link>
        //         <Link onClick={() => setCurrentEdit(null)}>
        //           <FormattedMessage id="cancel" />
        //         </Link>
        //       </Space>
        //     ) : (
        //       <Space size={20}>
        //         <Link onClick={() => selectForEdit(item)}>
        //           <FormattedMessage id="layout.edit" />
        //         </Link>
        //         <Popconfirm
        //           placement="top"
        //           title={"¿Eliminar este elemento?"}
        //           onConfirm={() => deleteBudgetItem(item)}
        //           okText="Si"
        //           cancelText="No"
        //         >
        //           <Link>Eliminar</Link>
        //         </Popconfirm>
        //       </Space>
        //     );
        //   },
        // },
      ];

      const onChangeFilter = (jobType, checked) => {
        console.log('JobType', jobType, checked)
        if(jobType === 'PARTS'){
          setOnlyParts(checked)
        }
        if(jobType === 'OBRA'){
          setOnlyLabor(checked)
        }
        if(jobType === 'PAINT'){
          setOnlyPaint(checked)
        }
      };

    useEffect(
        () => {
            if(data){
                const {
                    labor_items,
                    paint_items,
                    parts_items,
                } = data
                let totalLaborAmount = 0
                let totalPartsAmount = 0
                let totalPaintAmount = 0
                const mapItemsLabor = (item, i) => {
                    const lastIndex = item.length - 1
                    // const price =  Number(item[3].replace(/[^0-9.-]+/g,""))
                    const price =  Number(item[lastIndex].replace(/[^0-9.-]+/g,""))
                    totalLaborAmount = totalLaborAmount + price
                    return {
                        job_type: 'OBRA',
                        concept: item[1],
                        cost: price,
                        id: i,
                        price: price,
                        qty: 1,
                        status: "AUTH",
                        total_cost: price,
                        total_price: price,
                        valuation: valuationId
                    }
                }
                const mapItemsParts = (item, i) => {
                    const lastIndex = item.length - 1
                    // const price =  Number(item[4].replace(/[^0-9.-]+/g,""))
                    const price =  Number(item[lastIndex].replace(/[^0-9.-]+/g,""))
                    totalPartsAmount = totalPartsAmount + price
                    return {
                        job_type: 'PARTS',
                        concept: item[1],
                        cost: price,
                        id: i,
                        price: price,
                        qty: 1,
                        status: "AUTH",
                        total_cost: price,
                        total_price: price,
                        valuation: valuationId
                    }
                }
                const mapItemsPaint = (item, i) => {
                    const lastIndex = item.length - 1
                    // const price =  Number(item[3].replace(/[^0-9.-]+/g,""))
                    const price =  Number(item[lastIndex].replace(/[^0-9.-]+/g,""))
                    totalPaintAmount = totalPaintAmount + price
                    return {
                        job_type: 'PAINT',
                        concept: item[1],
                        cost: price,
                        id: i,
                        price: price,
                        qty: 1,
                        status: "AUTH",
                        total_cost: price,
                        total_price: price,
                        valuation: valuationId
                    }
                }
                const new_labor_items = labor_items.items.map(mapItemsLabor)
                const new_paint_items = paint_items.items.map(mapItemsPaint)
                const new_parts_items = parts_items.items.map(mapItemsParts)

                setTotalLabor(totalLaborAmount)
                setTotalParts(totalPartsAmount)
                setTotalPaint(totalPaintAmount)

                setCountTotalLabor(new_labor_items.length)
                setCountTotalPaint(new_paint_items.length)
                setCountTotalParts(new_parts_items.length)
                
                let newData = []
                if(!onlyLabor && !onlyPaint && !onlyParts){
                  newData = new_parts_items.concat(new_labor_items).concat(new_paint_items)
                }else{
                  if(onlyLabor)
                    newData = newData.concat(new_labor_items)
                  if(onlyPaint)
                    newData = newData.concat(new_paint_items)
                  if(onlyParts)
                    newData = newData.concat(new_parts_items)
                }
                setDataAdapterToUpload(new_parts_items.concat(new_labor_items).concat(new_paint_items))
                setDataAdapter(newData)
            }
        }, [data, onlyLabor, onlyPaint, onlyParts]
    )

    const onChange = (e) => {
      console.log(`checked = ${e.target.checked}`)
      setPreviousDelete(e.target.checked)
    };

    const showPopconfirm = () => {
      setOpenConfirm(true)
    };

    const handleOk = () => {
      saveBulkBudget()
    };
    const handleCancel = () => {
      setOpenConfirm(false);
    };

    return (
        <>
            {
                dataAdapter && (
                    <>
                    <h3>Revisa los datos cargados y si todo esta bien, porfavor dale click en guardar.</h3>

                    <Row justify="center" gutter={50} style={{ marginTop: 30 }}>
                        <Col
                          style={{ fontSize: 17, background: "rgb(230, 230, 255)" }}
                        >
                          <Space direction="vertical" align="center">
                            <Checkbox onChange={(e) => onChangeFilter('PARTS', e.target.checked)}><Text strong>{'Refacciones'}</Text></Checkbox>
                            
                            <Text>
                              {countTotalParts} registros
                            </Text>
                            <Text strong type="secondary">
                              {/*{JSON.stringify(valuation)}*/}
                              {currencyFormat(totalParts ?? 0)}

                            </Text>

                          </Space>
                        </Col>
                        <Col
                          style={{ fontSize: 17, background: "rgb(230, 230, 255)" }}
                        >
                          <Space direction="vertical" align="center">
                            <Checkbox onChange={(e) => onChangeFilter('OBRA', e.target.checked)}><Text strong>{'Mano de obra'}</Text></Checkbox>
                            <Text>
                              {counttTotalLabor} registros
                            </Text>
                            <Text strong type="secondary">
                              {/*{JSON.stringify(valuation)}*/}
                              {currencyFormat(totalLabor ?? 0)}

                            </Text>

                          </Space>
                        </Col>
                        <Col
                          style={{ fontSize: 17, background: "rgb(230, 230, 255)" }}
                        >
                          <Space direction="vertical" align="center">
                            <Checkbox onChange={(e) => onChangeFilter('PAINT', e.target.checked)}><Text strong>{'Pintura'}</Text></Checkbox>
                            <Text>
                              {counttTotalPaint} registros
                            </Text>
                            <Text strong type="secondary">
                              {/*{JSON.stringify(valuation)}*/}
                              {currencyFormat(totalPaint ?? 0)}

                            </Text>

                          </Space>
                        </Col>
                          
                    </Row>
                    <br/>

                    <Table dataSource={dataAdapter} columns={columns} />
                    <Space size={15}>
                      <Checkbox onChange={onChange}>Eliminar datos anteriores</Checkbox>
                      {
                        previousDelete ? (
                          <Popconfirm
                            title="¿Estás seguro de eliminar el presupuesto anterior?"
                            description="Open Popconfirm with async logic"
                            open={openConfirm}
                            placement="topLeft"
                            onConfirm={handleOk}
                            okButtonProps={{ loading: loading }}
                            onCancel={handleCancel}
                            okText="Guardar"
                            cancelText="Cancelar"
                          >
                              <KButton
                                type={"primary"}
                                text={
                                  "Eliminar anterior y guardar"
                                }
                                disabled={
                                  !dataAdapter
                                }
                                onClick={showPopconfirm}
                                loading={loading}
                                style={{ marginTop: "auto", marginBottom: "auto" }}
                              />
                          </Popconfirm>
                        ) : (
                          <KButton
                            type={"primary"}
                            text={
                              "Guardar datos"
                            }
                            disabled={
                              !dataAdapter
                            }
                            onClick={saveBulkBudget}
                            loading={loading}
                            style={{ marginTop: "auto", marginBottom: "auto" }}
                          />
                        )
                      }
                    </Space>
                    </>
                )
            }
        </>
    )
}

export default PreviewAudatexData