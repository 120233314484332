import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import styled from 'styled-components'

const FormModal = ({form, visible, onOk, onCancel,title,okText,cancelText,...props})=> {
    return (
        <Modal
        visible={visible}
        title={title}
        okText={okText}
        cancelText={cancelText}
        onCancel={onCancel}
        onOk={() => {
            form
            .validateFields()
            .then(values => {
                onOk(values);
            })
            .catch((info) => {
                console.log('Validated field', info);
            })
        }}
        >
            {props.children}
        </Modal>
    )
}

export default FormModal;