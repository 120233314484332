import React , {useEffect, useState} from "react";
import {injectIntl, FormattedMessage} from "react-intl";
import KModal from "../../../components/common/elements/KModal/KModal";
import {Alert, Col, Form, Row, Image, Input, Checkbox, DatePicker, Button} from "antd";
import DragAndDrop from "../../../components/DragAndDrop";
import moment from 'moment'
import locale from "../../../locale/es_mx.json";
const { TextArea } = Input;
const ModalCreateEditPromo=({intl,visible,onCancel,isEdit=false,dataEdit,onCreatePromotion,loading=false,...props})=>{

    const [fileList,setFileList] = useState([])
    const [form] = Form.useForm();
    const is_permanent = Form.useWatch('is_permanent', form);

    const submitForm=(values)=>{
        onCreatePromotion(values,fileList)
    }

    useEffect(()=>{
        if(dataEdit){
            setFileList([])
            form.setFieldsValue({
                description:dataEdit.description,
                title: dataEdit.title,
                is_permanent:dataEdit.is_permanent,
                is_active:dataEdit.is_active,
                link:dataEdit.link,
                start_date: dataEdit.start_date?moment(dataEdit.start_date):null,
                end_date: dataEdit.start_date?moment(dataEdit.end_date):null
            })
        }
    },[dataEdit])

    return (
        <KModal
            title={intl.formatMessage({ id: isEdit?"promos.edit":"promos.add" })}
            onCancel={onCancel}
            loading={false}
            visible={visible}>
            <Col span={24}>
                <b>Elige una imagen</b>
                <DragAndDrop accept="image/png, image/jpeg" fileList={fileList} setFileList={setFileList} />
                <br/>
                {
                    dataEdit && dataEdit?.image ?<> <Image src={dataEdit?.image} style={{width:'100%'}} alt=""/>
                        <Alert message="Si no se elige una imagen nueva el sistema mantendrá el actual." type="info"  />
                        <br/>
                    </>:null
                }
                <Form
                    onFinish={submitForm}
                    layout={"vertical"}
                    form={form}
                >
                    <Form.Item
                        name={"title"}
                        label={'Título'}
                        rules={[{ required: true }]}
                    >
                        <Input className='kinput'
                        />
                    </Form.Item>

                    <Form.Item
                        name={"description"}
                        label={'Descripción'}
                        rules={[{ required: true }]}
                    >
                        <TextArea className='kinput'
                        />
                    </Form.Item>
                    <Form.Item
                        name="is_permanent"
                        valuePropName="checked"
                    >
                        <Checkbox>¿Es promoción permanente?</Checkbox>
                    </Form.Item>

                    {
                        !is_permanent && <Row gutter={[16]}>
                            <Col span={12}>
                                <Form.Item label="Fecha inicio promoción" name={'start_date'} >
                                    <DatePicker style={{ width: "100%" }} format="DD/MM/YYYY" locale={locale}/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Fecha fin promoción" name={'end_date'} >
                                    <DatePicker style={{ width: "100%" }} format="DD/MM/YYYY" locale={locale}/>
                                </Form.Item>
                            </Col>
                        </Row>
                    }

                    <Form.Item
                        name="link"
                        label={"Link de promoción"}
                    >
                        <Input type={'url'} className='kinput' />
                    </Form.Item>

                    <Form.Item
                        name="is_active"
                        rules={[{ required: true }]}
                        valuePropName="checked"
                    >
                        <Checkbox>¿Activo?</Checkbox>
                    </Form.Item>


                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                        className="btn-string-blue-low btn-report-action"
                    >
                        <FormattedMessage id="services.filter.save" />
                    </Button>

                </Form>
            </Col>
        </KModal>
    )
}

export default injectIntl(ModalCreateEditPromo);