import React, { useRef, useLayoutEffect } from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5radar from "@amcharts/amcharts5/radar";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const Speedometer = ({value, ...props}) => {

    useLayoutEffect(() => {
    
        

        var rootSpeed = am5.Root.new("chartdivSpeed");

        rootSpeed.setThemes([
            am5themes_Animated.new(rootSpeed)
          ]);
          
          // Create chart
          // https://www.amcharts.com/docs/v5/charts/radar-chart/
          let chart = rootSpeed.container.children.push(
            am5radar.RadarChart.new(rootSpeed, {
              panX: false,
              panY: false,
              startAngle: 180,
              endAngle: 360
            })
          );
          
          chart.getNumberFormatter().set("numberFormat", "#'%'");
          
          // Create axis and its renderer
          // https://www.amcharts.com/docs/v5/charts/radar-chart/gauge-charts/#Axes
          let axisRenderer = am5radar.AxisRendererCircular.new(rootSpeed, {
            innerRadius: 50,
            strokeOpacity: 0.1

          });
          
          axisRenderer.labels.template.set("forceHidden", true);


          
          axisRenderer.grid.template.setAll({
            stroke: rootSpeed.interfaceColors.get("background"),
            visible: true,
            strokeOpacity: 0.8
          });
          
          let xAxis = chart.xAxes.push(
            am5xy.ValueAxis.new(rootSpeed, {
              maxDeviation: 0,
              min: 0,
              max: 100,
              strictMinMax: true,
              renderer: axisRenderer,
            })
          );
          // Add clock hand
          // https://www.amcharts.com/docs/v5/charts/radar-chart/gauge-charts/#Clock_hands
          let axisDataItem = xAxis.makeDataItem({});
          
          let clockHand = am5radar.ClockHand.new(rootSpeed, {
            pinRadius: 0,
            radius: am5.percent(100),
            innerRadius: 50,
            bottomWidth: 0,
            topWidth: 0
          });
          
          clockHand.pin.setAll({
            fillOpacity: 0,
            strokeOpacity: 0.5,
            stroke: am5.color(0x000000),
            strokeWidth: 0,
            strokeDasharray: [2, 2]
          });
          clockHand.hand.setAll({
            fillOpacity: 0,
            strokeOpacity: 0.5,
            stroke: am5.color(0x000000),
            strokeWidth: 0.5
          });
          
          let bullet = axisDataItem.set("bullet",
            am5xy.AxisBullet.new(rootSpeed, {
              sprite: clockHand
            })
          );
          
          xAxis.createAxisRange(axisDataItem);
          
          let label = chart.radarContainer.children.push(
            am5.Label.new(rootSpeed, {
              centerX: am5.percent(50),
              textAlign: "center",
              centerY: am5.percent(50),
              fontSize: "1.5em"
            })
          );
          
          axisDataItem.set("value", value > 0 ? parseFloat(value).toFixed(2) : 0);
          bullet.get("sprite").on("rotation", function () {
            let value = axisDataItem.get("value");
            label.set("text", Math.round(value).toString() + "%");
          });
          
          
          
          chart.bulletsContainer.set("mask", undefined);
          
          let colorSet = am5.ColorSet.new(rootSpeed, {});
          
          let axisRange0 = xAxis.createAxisRange(
            xAxis.makeDataItem({
              above: true,
              value: 0,
              endValue: value
            })
          );
          
          axisRange0.get("axisFill").setAll({
            visible: true,
            fill: colorSet.getIndex(0)
          });
          
          axisRange0.get("label").setAll({
            text: "",
            forceHidden: true
          });
          
          let axisRange1 = xAxis.createAxisRange(
            xAxis.makeDataItem({
              above: true,
              value: value,
              endValue: 100
            })
          );
          
          axisRange1.get("axisFill").setAll({
            visible: true,
            fill: colorSet.getIndex(4)
          });
          
          axisRange1.get("label").setAll({
            forceHidden: true
          });
          
          // Make stuff animate on load
          chart.appear(1000, 100);
          
    
    
        return () => {
            rootSpeed.dispose();
        };
      }, [value]);

      return <div id="chartdivSpeed" style={{ width: "100%", height: "200px" }}></div>;
}

export default Speedometer