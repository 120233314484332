import React from 'react';
import ventasIconAzul from "../../image/ventas_icon.svg"
import chatIcon from "../../image/chat_icon.svg"
import fileIcon from "../../image/icons/archivo azul.svg"
import {Col, Row, Space} from "antd";
import ButtonLightHeader from "../common/elements/ButtonLightHeader/ButtonLightHeader";
import {FormattedMessage, injectIntl} from "react-intl";
import { useHistory } from "react-router-dom";

const ServiceButtonsHeader =({orderId, viewDetail=false})=>{
    const history = useHistory();
    return (
        <Space>
            <ButtonLightHeader onClick={()=>viewDetail?history.push('/sale-orders/tracking/'+orderId):history.push('/sale-orders')} icon={<img src={ventasIconAzul} width="20px" height="20px" />}  text={<FormattedMessage id={viewDetail?'layout.goBack':'services.sale'} />}/>
            <ButtonLightHeader onClick={() => history.push(`/service-chat/${orderId}`)} icon={<img src={chatIcon} width="20px" height="20px" />} text={'Chat'} />
            <ButtonLightHeader onClick={() => history.push(`/services-files/${orderId}`)}  icon={<img src={fileIcon} width="20px" height="20px" />} text={<FormattedMessage id="services.files.files" />}/>
        </Space>
    )
}

export default injectIntl(ServiceButtonsHeader)