import React, { useState, useEffect } from 'react'
import {Col, Form, Row, Spin, Alert, Space,Typography, Divider,DatePicker,List, message, Select} from "antd";
import KSelect from "../common/elements/KSelect/KSelect";
import locale from 'antd/es/date-picker/locale/es_ES'
import KButton from "../common/elements/KButton/KButton";
import {FormattedMessage, injectIntl} from "react-intl";
import { connect } from 'react-redux'
import {getServiceDetail,getClosingReasons} from "../../redux/serviceOrderDuck";
import {getWorkFlow, getUsersWorkFlow} from "../../redux/workflowDuck";
import _ from 'lodash'
import moment from 'moment'
import SelectLocations from "../Selects/SelectLocations";
import {ruleRequired} from "../../lib/utils/rules";
import {getCleanDateTime} from "../../lib/utils/utils";
import { axiosApi, API } from '../../lib/utils/axiosApi';
import { tenantSelected } from '../../lib/utils/axiosApi';

const FormProcessChange=({loading=false,
      serviceOrder,
      orderId,
      getClosingReasons,
      settings,
      intl,
      getServiceDetail,
      getWorkFlow,
      workflow,
      workflowCode,
      getUsersWorkFlow, 
      stepDefault = null, 
      orderAssigned_to = null, 
      current_location = null,
      assignmentRequired=false, 
      ...props})=>{

    let fieldRequiredLabel = intl.formatMessage({ id: "services.saleOrder.obligatory" })
    const [steps,setSteps]=useState([])
    const [users,setUsers]=useState([])
    const [reasons,setReasons] = useState(null)
    const [deliveryDate,setDeliveryDate] = useState(moment())
    const [reasonSelected,setReasonSelected] = useState(null)
    const [form] = Form.useForm();
    const stepRepair = Form.useWatch('step', form);
    const [locationSelected,setLocationSelected] = useState(null)
    const [partsModel, setPartsModel] = useState([])
    const [partsSelected, setPartsSelected] = useState([])

    const tenantJAC = 'jacmexico'


    useEffect(()=>{
      getClosingReasons();
    },[])

    useEffect(()=>{
      if(current_location){
        form.setFieldsValue({
          location: current_location?.id
        })
        setLocationSelected(current_location?.id)
      }
    },[current_location])


    const resetFormFields=()=>{
        setDeliveryDate(moment())
        setReasonSelected(null)
        form.resetFields()
        setPartsModel([])
    }

    const onChangePartsModel = (e) => {
      const partToAdd = partsModel.find(part => part.value === e);
      if (partToAdd) {
        setPartsSelected([...partsSelected, partToAdd]);
      }
    }

    useEffect(()=>{
      if(serviceOrder.serviceDetail?.WORKSHOP?.vehicle?.model?.id){
        getPartsByModel(serviceOrder.serviceDetail?.WORKSHOP?.vehicle?.model?.id)
      }
    },[serviceOrder.serviceDetail])

    const getPartsByModel = async (id) => {
      try{
        const response = await axiosApi.get(API.WORKSHOP.WORKSHOP_GET_PARTS_BY_MODEL.replace('{id}', id))
        processParts(response.data)
      }catch(error){
        console.log(error)
        setPartsModel([])
      }
    }

    const processParts=(parts)=>{
      const opts = parts.map((p)=>{
          return {value:p.id, label:`${p.name}${p.code?':'+p.code:''}`, name: p.name, code: p?.code}
      })
      setPartsModel(opts)
  } 


    useEffect(()=>{
      if(serviceOrder.reasons){
          let arr = serviceOrder.reasons.map((item)=> {
              return {label: item.name, value:item.id}
          })
          setReasons(arr)
      }
    },[serviceOrder.reasons])

    const onFinish = (values) => {
        console.log('onFinishsss',values)
      if(values.step===-2){
        if(!deliveryDate || !reasonSelected){
            message.error('Es requerido fecha y razón de entrega')
            return;
        }else{
          values.closing_datetime = getCleanDateTime(deliveryDate,true);
          values.closing_reason = reasonSelected;
          values.location = locationSelected;
        }
      }
      if(partsSelected.length>0 && tenantSelected === tenantJAC){
        values.spareparts = partsSelected.map(part => part.value);
      }
      props.onSubmit({...values, service_order:orderId })
    };

     useEffect(() => {
       form.setFieldsValue({
         step: stepDefault?.id,
         assigned_to: orderAssigned_to
       });
     }, [stepDefault]);
     

    useEffect(()=>{
        if(workflowCode && settings?.settingsData?.branchSelected?.id){
            getWorkFlow(workflowCode, settings?.settingsData?.branchSelected?.id)
                getUsersWorkFlow(settings.settingsData.branchSelected.id)
            
        }
    },[workflowCode])

    useEffect(()=>{
        if(workflow.workflow[0]){
            let stp = workflow.workflow[0].steps.map((s)=> {
               return {order: s.order, label:s.name, value:s.id}
            } );
            stp.push({order: orderId, label:'Finalizar reparación', value:-1}) // para el step de finalizar rep.
            stp.push({order: orderId, label:'Finalizar reparación y entregar vehículo', value:-2}) // para el step de finalizar rep.
            setSteps(_.sortBy(stp, ['order']))
        }
    },[workflow])


    useEffect(()=>{
        if(workflow?.usersWorkflow && workflow.usersWorkflow.length>0){
            let usrs = workflow.usersWorkflow.map((u)=> {
                return {label:`${u.group?`[${u.group.name}]`:''} ${u.first_name} ${u.last_name}`, value:u.id}
            });
            setUsers(usrs)
        }
    },[workflow.usersWorkflow])

    const removeFromList = (item) => {
      if(item.value){
        setPartsSelected(partsSelected.filter(part => part.value !== item.value));
      }
    }

    return (
      <Spin spinning={loading} delay={500}>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item
            label={intl.formatMessage({ id: "process" })}
            name="step"
            rules={[{ required: true, message: fieldRequiredLabel }]}
            /* value={stepDefault.id} */
          >
            <KSelect options={steps} />
          </Form.Item>

          {(assignmentRequired && (stepRepair!==-1 && stepRepair!==-2)) && (
            <Form.Item
              label={intl.formatMessage({ id: "repair.responsible" })}
              name="assigned_to"
              rules={[{ required: true, message: fieldRequiredLabel }]}
            >
              <Select 
                showSearch 
                options={users} 
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
              />
            </Form.Item>
          )}

          {
            (tenantSelected === tenantJAC && (stepRepair!==-1 && stepRepair!==-2)) &&
            <SelectLocations />
          }  

        {
            (stepRepair===-1 || stepRepair===-2) &&  <Alert style={{marginBottom:10}} showIcon message="Estas apunto de finalizar la reparación." type="warning" />
        }

        {
          stepRepair===-2 && <Row style={{marginBottom:20}}>
                  <Col span={24}>
                  <Space direction={'vertical'} style={{width:'100%'}}>
                  <Divider>Entregar Unidad</Divider>
                    <b>{intl.formatMessage({ id: "saleOrder.deliveryDateClose" })}</b>
                    <DatePicker
                        locale={locale}
                        className={'kinput'}
                        defaultValue={moment()}
                        format="DD-MM-YYYY"
                        style={{width:'100%'}}
                        showTime={true}
                        placeholder={intl.formatMessage({ id: "saleOrder.datedelivery" })}
                        onChange={(e)=> {
                            let datetime = getCleanDateTime(e)
                            console.log(datetime, e)
                            setDeliveryDate(datetime)
                        }} />
                      <Form.Item
                          label={intl.formatMessage({ id: "saleOrder.deliveryReasonClose" })}
                          name={'reason'}
                          rules={[ruleRequired]}
                          className="item-expediente-lg mb-0 label-margin"
                      >
                          <KSelect
                              options={reasons}
                              value={reasonSelected}
                              onChange={(e)=> setReasonSelected(e)} />
                      </Form.Item>


                    <SelectLocations required={true} label={'Mover unidad a'}  style={{width:'100%'}} onChange={(e)=>setLocationSelected(e)} />
                  </Space>
                  </Col>
                </Row>
        }


        {
          (tenantSelected === tenantJAC && (stepRepair!==-1 && stepRepair!==-2)) &&
            <>
            
                  <Select
                        showSearch
                        allowClear={true}
                        placeholder="Elige una refacción"
                        style={{width:'100%', marginTop:20}}
                        optionFilterProp="children"
                        onChange={onChangePartsModel}
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={partsModel}
                    />

                    <List
                      header={<div>Refacciones seleccionados  <a onClick={()=>setPartsSelected([])}>Limpiar todo</a></div>}
                      footer={<div>Partes seleccionadas: {partsSelected.length} </div>}
                      bordered
                      dataSource={partsSelected}
                      renderItem={item => (
                        <List.Item
                          actions={[<a key="list-loadmore-edit" onClick={()=>removeFromList(item)}>Eliminar</a>]}
                        >
                          <Typography.Text mark>[{item.code}]</Typography.Text> {item.name}
                        </List.Item>
                              )}
                    />
            </>
        }
            
        



          <Row justify={"center"} align={"center"} style={{marginTop:20}}>
            <Col span={24} flex={"center"}>
              <KButton
                loading={loading}
                onClick={()=>{
                  resetFormFields()
                  setPartsSelected([])
                  props.onCancel()
                }}
                type="secondary"
                text={<FormattedMessage id={"cancel"} />}
              />
              <KButton
                loading={loading}
                type="primary"
                htmlType="submit"
                text={<FormattedMessage id={"accept"} />}
              />
            </Col>
          </Row>
        </Form>
      </Spin>
    );

}


const mapStateToProps = state => ({
    settings: state.settings,
    serviceOrder: state.serviceOrder,
    workflow: state.workflow
})


export default injectIntl(connect(mapStateToProps,{ getServiceDetail, getWorkFlow, getUsersWorkFlow, getClosingReasons})(FormProcessChange))