import { axiosApi, API } from '../lib/utils/axiosApi';

// initial state
let initialData = {
    fetching: false,
    deliveryToday: null,
    lateDelivery:null,
    openOrders:null,
    openOrdersByColors:null,
    openOrdersByLocation:null,
    onTimeDelivery:null,
    ordersByWeekday:null,
    ordersByInsuranceType:null,
    ordersByInsurance:null,
    OPEN_ORDERS_BY_ASSIGNED_TO:null,
    ordersByAssignedTo:null,
    numOrders:null,
    avgClosingOrdersTime:null,
    ordersByBrandModel:null,
    ordersByBrand:null,
    ordersClosed: null,
    ordersClosedByAconditionerJac: null,
    ordersByRepairStatus:null,
    reopenOrders:null,
    reopenOrdersReason:null,
    ordersByMonth:{},
    jacPaintedParts:null
}

const DELIVERY_TODAY = "DELIVERY_TODAY";
const LATE_DELIVERY = "LATE_DELIVERY";
const OPEN_ORDERS = "OPEN_ORDERS";
const OPEN_ORDERS_BY_LOCATION = "OPEN_ORDERS_BY_LOCATION";
const ON_TIME_DELIVERY = "ON_TIME_DELIVERY";
const OPEN_ORDERS_BY_INSURANCE_TYPE = "OPEN_ORDERS_BY_INSURANCE_TYPE";
const OPEN_ORDERS_BY_INSURANCE = "OPEN_ORDERS_BY_INSURANCE";
const OPEN_ORDERS_BY_ASSIGNED_TO = "OPEN_ORDERS_BY_ASSIGNED_TO";
const OPEN_ORDERS_BY_COLOR = "OPEN_ORDERS_BY_COLOR";
const ORDERS_BY_COLOR = "ORDERS_BY_COLOR";
const ORDERS_BY_MONTH = "ORDERS_BY_MONTH";
const NUM_ORDERS = "NUM_ORDERS";
const ORDERS_BY_DAY = "ORDERS_BY_DAY";
const ORDERS_BY_WEEKDAY = "ORDERS_BY_WEEKDAY";
const ORDERS_BY_BRAND_MODEL = "ORDERS_BY_BRAND_MODEL";
const ORDERS_BY_BRAND = "ORDERS_BY_BRAND";
const OPEN_ORDERS_BY_BRAND = "OPEN_ORDERS_BY_BRAND";
const OPEN_ORDERS_BY_BRAND_MODEL = "OPEN_ORDERS_BY_BRAND_MODEL";
const ORDERS_BY_ASSIGNED_TO = "ORDERS_BY_ASSIGNED_TO";
const NUM_CLOSED_ORDERS = "NUM_CLOSED_ORDERS";
const ORDERS_BY_ACONDITIONERS = "ORDERS_BY_ACONDITIONERS";
const OPEN_ORDERS_BY_REPAIR_STATUS = "OPEN_ORDERS_BY_REPAIR_STATUS";
const AVG_CLOSING_ORDERS_TIME = "AVG_CLOSING_ORDERS_TIME";
const REOPEN_ORDERS = "REOPEN_ORDERS";
const REOPEN_ORDERS_BY_REASON = "REOPEN_ORDERS_BY_REASON";
const JAC_PAINTED_PARTS = "JAC_PAINTED_PARTS";


// Reducer
export default function reducer(state=initialData, action) {
    switch (action.type) {
        case DELIVERY_TODAY:
            return { ...state, fetching: action.payload.loading,deliveryToday:action.payload.data , ...action.payload }
        case LATE_DELIVERY:
            return { ...state, fetching: action.payload.loading,lateDelivery:action.payload.data , ...action.payload }
        case OPEN_ORDERS:
            return { ...state, fetching: action.payload.loading,openOrders:action.payload.data , ...action.payload }
        case OPEN_ORDERS_BY_LOCATION:
            return { ...state, fetching: action.payload.loading,openOrdersByLocation:action.payload.data , ...action.payload }
        case ON_TIME_DELIVERY:
            return { ...state, fetching: action.payload.loading,onTimeDelivery:action.payload.data , ...action.payload }
        case OPEN_ORDERS_BY_INSURANCE_TYPE:
            return { ...state, fetching: action.payload.loading,ordersByInsuranceType:action.payload.data , ...action.payload }
        case OPEN_ORDERS_BY_INSURANCE:
            return { ...state, fetching: action.payload.loading,ordersByInsurance:action.payload.data , ...action.payload }
        case OPEN_ORDERS_BY_ASSIGNED_TO:
            return { ...state, fetching: action.payload.loading,ordersByAssignedTo:action.payload.data , ...action.payload }
        case AVG_CLOSING_ORDERS_TIME:
            return { ...state, fetching: action.payload.loading,avgClosingOrdersTime:action.payload.data , ...action.payload }
        case ORDERS_BY_ASSIGNED_TO:
            return { ...state, fetching: action.payload.loading,ordersByAssignedTo:action.payload.data , ...action.payload }
        case OPEN_ORDERS_BY_COLOR:
            return { ...state, fetching: action.payload.loading,openOrdersByColors:action.payload.data , ...action.payload }
        case ORDERS_BY_COLOR:
            return { ...state, fetching: action.payload.loading,openOrdersByColors:action.payload.data , ...action.payload }
        case NUM_ORDERS:
            return { ...state, fetching: action.payload.loading,numOrders:action.payload.data , ...action.payload }
        case ORDERS_BY_DAY:
            return { ...state, fetching: action.payload.loading,orderByDay:action.payload.data , ...action.payload }
        case ORDERS_BY_WEEKDAY:
            return { ...state, fetching: action.payload.loading,ordersByWeekday:action.payload.data , ...action.payload }
        case OPEN_ORDERS_BY_BRAND:
            return { ...state, fetching: action.payload.loading,ordersByBrand:action.payload.data , ...action.payload }
        case ORDERS_BY_BRAND:
            debugger;
            return { ...state, fetching: action.payload.loading,ordersByBrand:action.payload.data , ...action.payload }
        case ORDERS_BY_BRAND_MODEL:
            return { ...state, fetching: action.payload.loading,ordersByBrandModel:action.payload.data , ...action.payload }
        case OPEN_ORDERS_BY_BRAND_MODEL:
            return { ...state, fetching: action.payload.loading,ordersByBrandModel:action.payload.data , ...action.payload }
        case NUM_CLOSED_ORDERS:
            return { ...state, fetching: action.payload.loading,ordersClosed:action.payload.data , ...action.payload }
        case ORDERS_BY_ACONDITIONERS:
            return { ...state, fetching: action.payload.loading,ordersClosedByAconditionerJac:action.payload.data , ...action.payload }
        case ORDERS_BY_MONTH:
            return { ...state, fetching: action.payload.loading,ordersByMonth:action.payload.data , ...action.payload }
        case OPEN_ORDERS_BY_REPAIR_STATUS:
            return { ...state, fetching: action.payload.loading,ordersByRepairStatus:action.payload.data , ...action.payload }
        case REOPEN_ORDERS:
            return { ...state, fetching: action.payload.loading,reopenOrders:action.payload.data , ...action.payload }
       case REOPEN_ORDERS_BY_REASON:
            return { ...state, fetching: action.payload.loading,reopenOrdersReason:action.payload.data , ...action.payload }
        case JAC_PAINTED_PARTS:
            return { ...state, fetching: action.payload.loading,jacPaintedParts:action.payload.data , ...action.payload }

        default:
            return state
    }
}


/**
 *
 * @param WIDGET_TYPE Tipo de widget
 * @param type // 'count' o 'array
 * @returns {(function(*, *): Promise<void>)|*}
 */
export let getWidgetDashboard= (WIDGET_TYPE, type='count',filters='') => async ( dispatch, getState ) => {
    dispatch({
        type: WIDGET_TYPE,
        payload: {loading: true, data: 0}
    })
    try {
        let response = await axiosApi.get(API.DASHBOARD.GET_WIDGET + '&widget_code='+WIDGET_TYPE+'&'+filters);
        if(WIDGET_TYPE==='OPEN_ORDERS_BY_BRAND');
        dispatch({
            type: WIDGET_TYPE,
            payload: {loading: true, data:type==='count'? response.data?.count : type==='object'? response : response.data}
        });
        return {
            data: response.data,
            success: true
        }
    } catch (e) {
        dispatch({
            type: WIDGET_TYPE,
            payload: {loading: false, data: 0}
        });
        return {
            data: e,
            status: false
        }
    }
}


/**
 * Para analitica
 * @param WIDGET_TYPE Tipo de widget
 * @param type // 'count' o 'array
 * @returns {(function(*, *): Promise<void>)|*}
 */
export let getWidgetAnalytic= (WIDGET_TYPE, type='count',filters='') => async ( dispatch, getState ) => {
    dispatch({
        type: WIDGET_TYPE,
        payload: {loading: true, data: 0}
    })
    try {
        let response = await axiosApi.get(API.ANALYTIC.GET_WIDGET + '&widget_code='+WIDGET_TYPE+'&'+filters);
        dispatch({
            type: WIDGET_TYPE,
            payload: {loading: true, data:type==='count'? response.data?.count : response.data}
        });
        return {
            data: response.data,
            success: true
        }
    } catch (e) {
        dispatch({
            type: WIDGET_TYPE,
            payload: {loading: false, data: 0}
        });

        return {
            data: e,
            status: false
        }

    }
}
