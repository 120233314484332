import React, {useEffect, useState} from 'react'
import {Layout, Button, Card, Form, Input, Modal, message, List, Spin, Row, Col, Typography} from "antd";
import VirtualList from 'rc-virtual-list';
import {useParams, useHistory} from "react-router-dom";
import DashboardLayout from '../../layouts/DashboardLayout';
import styled from "styled-components";
import {injectIntl, FormattedMessage} from "react-intl";
import {changeLangAction} from '../../redux/settingsDuck';
import {connect} from 'react-redux';
import axiosApi, {API} from "../../lib/utils/axiosApi";
import ServiceLayout from "../../layouts/ServiceLayout";
import {
    ArrowLeftOutlined, EditOutlined,
    FolderFilled,
    SettingOutlined,
    TableOutlined,
    UnorderedListOutlined
} from "@ant-design/icons";
import CreateFolderModal from "../../components/modal/CreateFolderModal";
import CreateFileModal from "../../components/modal/CreateFileModal";
import icon_img from "../../image/img.png";
import icon_pdf from "../../image/icon_pdf.png";
import icon_doc from "../../image/icon_doc.png";
import {css, Global} from '@emotion/react'
import DeleteLinkComponent from "../../components/DeleteLinkComponent";
import EditNameModal from "../../components/modal/EditNameModal";
import CardPurpleLight from "../../components/common/elements/CardPurpleLight/CardPurpleLight";
import _ from "lodash";
import ServiceButtonsHeader from "../../components/services/ServiceButtonsHeader";
import ServiceFileView from "../../components/services/ServiceFileView";
const { Title, Text } = Typography;
const {Meta} = Card;

const {Content} = Layout;

const NewCard = styled(Card)`
  .ant-card-body {
    padding: 0px 10px !important;
  }
`;

const ContainerHeight = 350;

const ServicesFilesPage = ({intl, settings, ...props}) => {
    const history = useHistory();
    const [form] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);

    const [loading, setLoading] = useState(false);
    const {idService, nameFolder, idFolder} = useParams();
    const [isGrid, setIsGrid] = useState(0);
    const [pageObj, setPageObj] = useState(null);
    const [generals, setGenerals] = useState([]);
    const [saleOrder, setSaleOrder] = useState(null);

    const [formFile] = Form.useForm();
    const [isFileModalVisible, setIsFileModalVisible] = useState(false);
    const [currentFile, setCurrentFile] = useState(null);

    const [formEdit] = Form.useForm();
    const [modalEditVisible, setModalEditVisible] = useState(false);
    const [objEdit, setObjEdit] = useState(null);


    const handleGrid = (isType) => {
        setIsGrid(isType)
        window.localStorage.setItem("grid", isType)

    };

    useEffect(() => {
        setIsGrid(window.localStorage.getItem("grid"))
        loadData(idService)
    }, [])

    const loadData= async (id)=>{
        try{
            const saleOrderResponse = await axiosApi.get(
                `${API.SALE.SALE_ORDER}${id}`
            );
            setSaleOrder(saleOrderResponse.data);
        }catch (e){

        }
    }


    const setFile = (e) => {
        //console.log('', e.target.files) //Muestra el archivo que vamos a cambiar
        if (e.target.files)
            console.log("file", e.target.files[0])
        setCurrentFile(e.target.files[0])
    }


    const showEditModal = (item) => {
        console.log(item)
        setObjEdit(item)
        setModalEditVisible(true);
    };
    const showModal = () => {
        setIsModalVisible(true);
    };

    const showFileModal = () => {
        setIsFileModalVisible(true);
    };


    const validateArray = (array) => {
        let foldersArray = []
        let filesArray = []
        array.map((item, index) => {
            if (item.is_folder) {
                foldersArray.push(item)
            } else {
                filesArray.push(item)
            }
        });

        return {foldersArray, filesArray}
    }

    const validateParent = (array) => {
        let treatArray = [];
        array.map((item) => {
            if (idFolder && item.parent) {
                treatArray.push(item)
            } else if (item.parent == null) {
                treatArray.push(item)
            }
        })

        return treatArray;
    }

    const getServicesOrderFile = () => {
        setLoading(true);
        let params = "";
        if (idFolder) {
            params = `/?order=${idService}&parent=${idFolder}&page_size=100000`
        } else {
            params = `/?order=${idService}&page_size=100000`
        }
        axiosApi.get(API.SERVICE.SERVICE_ORDER_FILE + `${params}`).then(response => {
            console.log("response-->", response.status);
            if (response.status === 200) {
                setPageObj(response.data)
                console.log(response.data)
                setGenerals(validateParent(response.data.results))
            }
            setLoading(false);
        }).catch(e => {
            console.log("error-->", e);
            setLoading(false);
        });
    }

    const onScroll = e => {
        if (e.target.scrollHeight - e.target.scrollTop === ContainerHeight) {
            if (pageObj.next) {

            }
        }
    };


    const getType = (file) => {
        let fileType = file.split(".");
        return fileType[fileType.length - 1]
    }

    const fileType = (type, size = '35px') => {

        switch (type) {
            case "png":
                return <img src={icon_img} style={{height: size, width: size}}/>
                break;
            case "jpg":
                return <img src={icon_img} style={{height: size, width: size}}/>
                return;
            case "jpeg":
                return <img src={icon_img} style={{height: size, width: size}}/>
                break;
            case "pdf":
                return <img src={icon_pdf} style={{height: size, width: size}}/>
                break;
            default :
                return <img src={icon_doc} style={{height: size, width: size}}/>
                break;

        }

    }


    const download = (link) => {
        var element = document.createElement('a');
        element.setAttribute('href', link);
        element.setAttribute('target', '_blank')
        element.setAttribute('download', 'download')
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }


    const goFolder = (item) => {
        if (item.is_folder) {
            history.push(`/services-files/${idService}/${item.name}/${item.id}`)
        } else {
            download(item.file)
        }
    }
    const goBack = (item) => {
        history.push(`/services-files/${idService}`)
    }

    const handleNameOk = () => {
        formEdit.submit();
        setModalEditVisible(false);
    };
    const handleNameCancel = () => {
        setModalEditVisible(false);
        setObjEdit(null)
    };

    const handleFileOk = () => {
        formFile.submit();
        setIsFileModalVisible(false);
    };
    const handleFileCancel = () => {
        setIsFileModalVisible(false);
        formFile.resetFields()
    };


    const handleOk = () => {
        form.submit();
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        form.resetFields()
    };

    const onDelete = async id => {
        try {
            const res = await axiosApi.delete(`${API.SERVICE.SERVICE_ORDER_FILE}/${id}`)
            console.log("eeee", res)
            if (res.status === 204) {
                message.success(intl.formatMessage({id: "item.deleteSuccess"}))
                await getServicesOrderFile()

            } else {
                message.error(intl.formatMessage({id: "item.brand.registerErr"}))
            }
        } catch (e) {

            message.error(intl.formatMessage({id: "item.brand.registerErr"}))
        }
    }


    const saveFile = async (values) => {
        if (!currentFile) {
            return
        }

        let req = new FormData()
        req.append('name', currentFile.name);
        req.append('is_folder', false);
        req.append('is_autogenerated', false);
        req.append('order', idService);
        req.append('content_type', currentFile.type);
        req.append('size', currentFile.size);
        req.append('file', currentFile);
        if (idFolder) {
            req.append('parent', parseInt(idFolder));
        } else {
            ///req.append('parent',null);
        }
        try {
            let res = await axiosApi.post(API.SERVICE.SERVICE_ORDER_FILE + "/", req);
            message.success(intl.formatMessage({id: "item.saveSuccess"}))
            getServicesOrderFile();
            console.log(res)
        } catch (e) {
            message.error(e)

        } finally {
            formFile.resetFields()
        }
    }

    const saveFolder = async (values) => {
        let req = {
            "name": values.name,
            "is_folder": true,
            "is_autogenerated": false,
            "content_type": null,
            "size": 0,
            "order": idService + "",
            "parent": null
        }
        try {
            let res = await axiosApi.post(API.SERVICE.SERVICE_ORDER_FILE + "/", req);
            message.success(intl.formatMessage({id: "item.saveSuccess"}))
            getServicesOrderFile();
        } catch (e) {
            message.error(e)
        } finally {
            form.resetFields()
        }

    }


    const editName = async (values) => {
        let req = {
            "name": values.name,
            "order": idService,
        }
        console.log(req)
        try {
            let res = await axiosApi.put(API.SERVICE.SERVICE_ORDER_FILE + "/" + objEdit.id + "/", req);
            message.success(intl.formatMessage({id: "item.editSuccess"}))
            getServicesOrderFile();
        } catch (e) {
            message.error(e)
        } finally {
            setObjEdit(null)
            /// formEdit.resetFields()
        }
    }

    const MessageEmpty = (isList = false) => (
        <div>
            {
                isList ?
                    generals && generals.length === 0 &&
                    <div style={{padding: '10%', textAlign: 'center'}}>
                        <a style={{color: 'black', fontWeight: '400', fontSize: '22px'}}><FormattedMessage
                            id="services.files.empty"/></a>
                    </div>
                    :
                    generals && generals.length === 0 ?
                        <div style={{padding: '10%', textAlign: 'center'}}>
                            <a style={{color: 'black', fontWeight: '400', fontSize: '22px'}}><FormattedMessage
                                id="services.files.empty"/></a>
                        </div>
                        :
                        validateArray(generals).foldersArray.length == 0 ?
                            <div style={{padding: '10%', textAlign: 'center'}}>
                                <a style={{color: 'black', fontWeight: '400', fontSize: '22px'}}><FormattedMessage
                                    id="services.files.empty"/></a>
                            </div>
                            :
                            validateArray(generals).foldersArray.length == 0 ?
                                <div style={{padding: '10%', textAlign: 'center'}}>
                                    <a style={{color: 'black', fontWeight: '400', fontSize: '22px'}}><FormattedMessage
                                        id="services.files.empty"/></a>
                                </div>
                                :
                                null
            }
        </div>
    )

    return (
        <ServiceLayout
            title={intl.formatMessage({ id: "servicesDetails.servicesDetails" })}
            index="expedientes"
        >
        {isModalVisible && (
          <CreateFolderModal
            form={form}
            isModalVisible={isModalVisible}
            saveFolder={saveFolder}
            handleOk={handleOk}
            handleCancel={handleCancel}
          />
        )}
        {isFileModalVisible && (
          <CreateFileModal
            isVisible={isFileModalVisible}
            onFinish={saveFile}
            form={formFile}
            onOk={handleFileOk}
            onCancel={handleFileCancel}
            setFile={setFile}
          />
        )}
        {modalEditVisible && (
          <EditNameModal
            visible={modalEditVisible}
            form={formEdit}
            obj={objEdit}
            onFinish={editName}
            onOk={handleNameOk}
            onCancel={handleNameCancel}
          />
        )}

        <ServiceFileView serviceId={idService} idFolder={idFolder} nameFolder={nameFolder}/>
      </ServiceLayout>
    );
}
const mapState = (state) => ({
    settings: state.settings
});

export default injectIntl(connect(mapState, {
    changeLangAction
})(ServicesFilesPage))
