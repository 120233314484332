import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux'
import {
  Layout,
  Menu,
  Row,
  Form,
  Col,
  Typography,
  Input,
  Space,
  Button,
  Card,
  Avatar,
  Dropdown,
  Alert,
  List,
  Badge,
  message,
  Drawer
} from 'antd';
import logo from '../image/black_icon_kikert.svg'
import turn from '../image/turn_icon_kikert.svg'
import _ from 'lodash'
import { injectIntl } from 'react-intl';
import { useHistory, Link, Redirect, useLocation } from "react-router-dom"
import { logOut } from "../redux/securitryDuck";
import MainSider from "./MainSider";
import { PoweroffOutlined, MenuOutlined, TransactionOutlined } from "@ant-design/icons";
import { toggleMenu } from "../redux/settingsDuck";
import { changeLangAction } from '../redux/settingsDuck';
import "../components/sideBarDerecha2/SiderBarRight.css"

import lenguaje from "../image/idiomas_icon.svg"
import CardMenuHeader from "../components/CardMenuHeader";
import KModal from "../components/common/elements/KModal/KModal";
import PendingPaymentAlert from "../components/PendingPaymentAlert";
import BadgeNotifications from "../components/BadgeNotifications";
import CloseButton from "../components/CloseButton";

const DashboardLayout = ({ HeaderComponent,changeLangAction, intl, title, subtitle, index, settings, logOut, toggleMenu, openKeys, ...props }) => {

  const { Header, Content, Footer, Sider } = Layout;
  const { Item } = List;
  const { SubMenu } = Menu;
  const { Search } = Input;
  const { Meta } = Card;
  const { Title, Text } = Typography;
  const history = useHistory()
  const [isLogout, setIsLogout] = useState(false)

  const [sidebar, setSidebar] = useState(false);

  const toggleSideBar = () => {
    setSidebar((prevState) => !prevState);
  }

  /* const [openKeys, setOpenkeys] = useState([]); */
  const [rootSubmenuKeys] = useState(["sub1"]);
  const location = useLocation();

  /* useEffect(() => {
    console.log(title)
    console.log(index)
    if (location) {
      console.log(location.pathname)
      const path = location.pathname;

      let selectedMenuArray = [(path === '/config/colour'
        || path === '/config/insurance'
        || path === '/config/brands'
        || path === '/config/types'
        || path === '/config/models') ? 'sub1' : '']
      setOpenkeys(selectedMenuArray)
    }
  }, []) */

  /* const onOpenChange = (openKeysVals) => {
    const latestOpenKey = openKeysVals.find(
      (key) => openKeys.indexOf(key) === -1
    );
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenkeys(openKeysVals);
    } else {
      setOpenkeys(latestOpenKey ? [latestOpenKey] : []);
    }
  }; */

  const logout = async () => {

    try {
      const success = await logOut()
      if (success) {
        message.success(intl.formatMessage({ id: "security.login.logoutSuccess" }))
        setIsLogout(true)

      } else {
        message.error(intl.formatMessage({ id: "security.login.logoutErr" }))
      }
    } catch (e) {

    }

  }


  const menu = (
    <Menu>
      <Menu.Item onClick={() => history.push('/company')}>
        <FormattedMessage id="layout.changeEmpresa" />
      </Menu.Item>
      <Menu.Item onClick={() => logout()}>
        <FormattedMessage id="layout.logOut" />
      </Menu.Item>
    </Menu>
  );

  const style = {
    contenidoInicial: {
      margin: '0 auto',
      width: '70%'
    },
    contenidoInicialTitle: {
      paddingTop: 10,
      color: 'white',
      fontSize: 52,
      fontWeight: 480,
      textAlign: 'left',
      fontFamily: 'Sharp Grotesk, Book 20'
    },
    textWhite: {
      color: 'white',
      fontFamily: 'Sharp Grotesk',
      fontSize: 18,
      fontWeight: 200
    },
    divlogo: {
      backgroundColor: '#fff',
      marginTop: 30
    },
    logoMenu: {
      height: 64,
      width: 28,
      marginLeft: 40
    },
    logo: {
      height: 64,
      width: 28,
      transform: 'scaleX(-1)'
    },
    profilepic: {
      marginLeft: 50,
    },
    mainContent: {
      paddingTop: 120
    },
    divCenterButton: {
      paddingTop: 30,
      paddingLeft: 40,
    },
    centerButton: {
      color: 'white',
      padding: '12px 40px',
      backgroundColor: '#090731',
      borderColor: '#090731',
      borderRadius: 18,
      fontSize: 14,
    },
    footerContent: {
      margin: '0 auto',
      width: '75%',
      fontSize: 19,
      fontFamily: 'Sharp Grotesk',
      textAlign: 'left',
      color: 'white',
      verticalAlign: 'end',
      paddingLeft: 100,
      paddingTop: 50,
      marginTop: 50,
    }
  }

  return (
    <Layout>
      {isLogout && <Redirect to="/security/login" />}
      <MainSider index={index} /* onOpenChange={onOpenChange} */ openKeys={openKeys} />
      <Layout style={{height: "100vh",  padding: "0 30px", overflowY: "scroll" }}>
        <PendingPaymentAlert/>
        <Header style={{ padding: 0, paddingTop: 10, margin: 0 }}>

          <Row justify="space-between">
            <Col>
              <Space direction="horizontal">
                {/*
                <MenuOutlined
                  onClick={() => toggleMenu(!settings.menu.collapsed)}
                />
                */}
                <Title
                  level={1}
                  className="mb-0"
                  onClick={() => toggleMenu(!settings.menu.collapsed)}
                >
                  <img src={turn} alt="logo" style={style.logo} />{" "}
                  {title ? title : "Mi Dashboard"}
                  <Text style={{ fontWeight: 400 }} >{subtitle ? ` - ${subtitle}` : ""}</Text>
                </Title>
                {/*<Form size="large">
                  <Form.Item className="m-0">
                    <Input
                      placeholder={intl.formatMessage({
                        id: "dashboard.search",
                      })}
                      className="site-header__input m-0"
                      prefix={<SearchOutlined className="site-header__icon" />}
                    />
                  </Form.Item>
                    </Form>*/}
              </Space>
            </Col>
            <Col>
              <Space align="baseline">    
                <BadgeNotifications/>
                <CloseButton/>
                <CardMenuHeader/>
              </Space>
            </Col>
          </Row>

        </Header>
        <Content style={{ paddingTop: 40 }}>
          {
            HeaderComponent
          }
          {props.children}
        </Content>
      </Layout>
    </Layout>
  );
}

const mapStateToProps = state => ({
  settings: state.settings,
  security: state.security,
})

export default injectIntl(
  connect(mapStateToProps, {
    logOut,
    changeLangAction,
    toggleMenu,
  })(DashboardLayout)
);
