import React, {useState, useEffect} from 'react'
import DashboardLayout from '../../../layouts/DashboardLayout';
import {message, Table,Row, Col, Layout, Form, Button, Modal, Input, Typography, Upload, Space, Image, Drawer, List} from 'antd'
import {UploadOutlined} from '@ant-design/icons';
import {axiosApi, API} from "../../../lib/utils/axiosApi"
import {connect} from 'react-redux'
import DeleteLinkComponent from "../../../components/DeleteLinkComponent";
import {injectIntl} from 'react-intl';
import {setConfigCompanyBranch, changeLangAction} from "../../../redux/settingsDuck";
import logoDefault from "../../../image/imagenDefault.jpg";
import {CustomTable} from "../../../components/KComponents";
import moment from 'moment'
import {useHistory,useParams} from "react-router-dom";
import UnitForm from "../../../components/units/UnitForm"

const {Header, Content, Footer} = Layout;

const UnitDetailPage = ({changeLangAction, intl, setConfigCompanyBranch, settings,permissions, ...props}) => {

    const [loading, setLoading] = useState(false)
    const [loadingOrders, setLoadingOrders] = useState(false)
    const [unit, setUnit] = useState(null)
    const [filterText, setFilterText] = useState(null)
    const [count, setCount] = useState(null)
    const [unitOwner, setUnitOwner] = useState(null)
    const [unitOrders, setUnitOrders] = useState([])
    const history = useHistory()

    let { id } = useParams();

    const columns = [
        {
            title: 'Folio',
            key: 'folio',
            render: (item) => <Button onClick={()=>history.push("/service-detail/"+item.id) } type="link">{item.folio}</Button>
        },
        {
            title: 'Número de orden',
            key: 'order_number',
            render: (item)=><p>{item?.order_number}</p>
        },
        {
            title: 'Cliente',
            key:'customer',
            render: (item)=><p>{item?.customer?.first_name} {item?.customer?.mlast_name} {item?.customer?.last_name}</p>
        },
        {
            title: 'Estatus',
            key:'status',
            render: (item)=><p>{item?.status}</p>
        },
        {
            title: 'Proceso actual',
            key:'current_step',
            render: (item)=><p>{item?.current_step?.status || "N/A"}</p>
        },
        {
            title: 'Asesor',
            key:'assigned_to',
            render: (item)=><p>{item?.assigned_to?.first_name} {item?.assigned_to?.last_name}</p>
        },
        {
            title: 'Fecha de creación',
            key:'timestamp',
            render: (item)=><p>{item?.timestamp?moment(item?.timestamp).format('DD/MM/YYYY hh:mm a'):''}</p>
        },

    ]

    useEffect(()=>{
        getUnitData()
        getOrdersUnitData()
    },[])

    const getUnitData = async (filter='', page=1)=>{
        setLoading(true)
        try{
            let res = await axiosApi.get(`${API.BUSINESS.UNITS}${id}`)
            console.log("Unit", res.data)
            setCount(res.data?.count)
            setUnit(res.data)
            setUnitOwner(res?.data?.owner)
        }catch (e){
            setUnit(null)
        }finally {
            setLoading(false)
        }
    }

    const getOrdersUnitData = async (filter='', page=1)=>{
        setLoadingOrders(true)
        try{
            let res = await axiosApi.get(`${API.WORKSHOP.WORKSHOP_ORDER_LIST}&vehicle=${id}`)
            console.log("Orders", res.data)
            setUnitOrders(res.data.results)
        }catch (e){
        }finally {
            setLoadingOrders(false)
        }
    }


    return (
        <DashboardLayout
            title={'Unidad'}
            index="units"
        >
            <Content
                style={{padding: "10px 10px",backgroundColor:'white', marginTop: 100}}
            >
                <Row gutter={[20]} justify="space-between">
                    <Col flex="auto" span={10}>
                        <h2>Detalle de unidad</h2>
                        <div>
                            {
                                unit && <UnitForm unitData={unit}/>
                            }
                        </div>
                    </Col>
                    {/*<Col flex="auto">*/}
                    {/*    <h2>Datos del propietario actual</h2>*/}
                    {/*    <div>*/}
                    {/*        {*/}
                    {/*            unitOwner?.id ? (*/}
                    {/*                <span>*/}
                    {/*                    <p>Nombre: <b>{unitOwner?.first_name} {unitOwner?.mlast_name} {unitOwner?.last_name}</b></p>*/}
                    {/*                    <p>Email: <b>{unitOwner?.email}</b></p>*/}
                    {/*                    <p>Dirección: <b>{unitOwner?.address}</b></p>*/}
                    {/*                    <p>Teléfono: <b>{unitOwner?.phone_number}</b></p>*/}
                    {/*                    <p>Empresa: <b>{unitOwner?.company_name}</b></p>*/}
                    {/*                </span>*/}
                    {/*            ) : (*/}
                    {/*                <span>*/}
                    {/*                    <b>Sin propietario actual</b>*/}
                    {/*                </span>*/}
                    {/*            )*/}
                    {/*        }*/}
                    {/*    </div>*/}
                    {/*</Col>*/}
                </Row>

                <Row gutter={[10]} justify="space-between">
                
                    <Col flex="auto">
                    <h2>Órdenes relacionados</h2>
                        <CustomTable
                            loading={loadingOrders}
                            dataSource={unitOrders}
                            columns={columns}
                        />
                    </Col>
                </Row>

            </Content>
        </DashboardLayout>
    );
}

const mapStateToProps = state => ({
    settings: state.settings,
    security: state.security,
    permissions:state.permissions

})

export default injectIntl(connect(mapStateToProps, {
    setConfigCompanyBranch,
    changeLangAction,
})(UnitDetailPage))
