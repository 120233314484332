import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {Badge, Space, Breadcrumb} from 'antd'

const StatusOrder = ({ type='badge',...props }) => {
    const serviceOrder = useSelector(state => state.serviceOrder);
    const StatusLabel=()=>{
        switch (serviceOrder?.serviceDetail?.status){
            case 'CLOSED':
                return <Badge status="success" text="Entregado" />
            case 'RE-OPENED':
                return <Badge status="success" text="Reaperturado" />
            default:
                return <Badge status="success" text="Abierto" />
        }
    }

    const StatusValuation=()=>{
        switch (serviceOrder?.serviceDetail?.WORKSHOP?.valuation?.status){
            case 'PROGRESS':
                return  <Breadcrumb.Item key="valuation"><Badge status="processing" text="Valuación iniciada" /></Breadcrumb.Item>
            case 'DONE':
                return  <Breadcrumb.Item key="valuation"><Badge status="success" text="Valuación finalizada" /></Breadcrumb.Item>
            default:
                return  null
        }
    }

    const StatusSecondary=()=>{
        let ele = null
        switch (serviceOrder?.serviceDetail?.process_status){
            case 'REPAIR-IN-PROCESS':
                return  <Breadcrumb.Item key="repair"><Badge status="processing" text="En reparación" /></Breadcrumb.Item>
            case 'REPAIR-COMPLETED':
                return <Breadcrumb.Item key="repair"> <Badge status="success" text="Reparación finalizada" /></Breadcrumb.Item>
            default:
                return null
        }
    }

    return (
        <Breadcrumb {...props}>
            <Breadcrumb.Item key="home">
            <StatusLabel/>
            </Breadcrumb.Item>
            <StatusValuation/>
            <StatusSecondary/>
        </Breadcrumb>

    )
}

export default StatusOrder;