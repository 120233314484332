import { axiosApi, API } from '../lib/utils/axiosApi';

// initial state
let initialData = {
    fetching: false,
    branches: null,
    branchesList: [],
    companies: null,
    companiesList: [],
    newAccount: null,
    categories:[]
}

const GET_BRANCHES = "GET_BRANCHES";
const GET_BRANCHES_SUCCESS = "GET_BRNACHES_SUCCESS";
const GET_BRANCHES_ERROR = "GET_BRANCHES_ERROR";

const GET_COMPANIES = "GET_COMPANIES";
const GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS";
const GET_COMPANIES_ERROR = "GET_COMPANIES_ERROR";

const GET_CUSTOMERS = "GET_CUSTOMERS";
const GET_CUSTOMERS_SUCCESS = "GET_CUSTOMERS_SUCCESS";
const GET_CUSTOMERS_ERROR = "GET_CUSTOMERS_ERROR";

const SAVE_CUSTOMER = "SAVE_CUSTOMER"
const SAVE_CUSTOMER_SUCCESS = "SAVE_CUSTOMER_SUCCESS"
const SAVE_CUSTOMER_ERROR = "SAVE_CUSTOMER_ERROR"
const GET_CATEGORY_SUPPLIERS = "GET_CATEGORY"
// Reducer
export default function reducer(state=initialData, action) {
    switch (action.type) {
        case GET_BRANCHES:
            return { ...state, fetching: true }
        case GET_CATEGORY_SUPPLIERS:
            return { ...state, categories: action.payload }
        case GET_BRANCHES_SUCCESS:
            return { ...state, fetching: false, ...action.payload }
        case GET_BRANCHES_ERROR:
            return { ...state, fetching: false, branches:[] }
        case GET_COMPANIES:
            return { ...state, fetching: true }
        case GET_COMPANIES_SUCCESS:
            return { ...state, fetching: false, ...action.payload }
        case GET_COMPANIES_ERROR:
            return { ...state, fetching: false, companies:[] }
        case GET_CUSTOMERS:
            return { ...state, fetching: true};
        case GET_CUSTOMERS_SUCCESS:
            return { ...state, fetching: false, ...action.payload }
        case GET_CUSTOMERS_ERROR:
            return { ...state, fetching: false, userProfiles: [] }
        case SAVE_CUSTOMER:
            return {...state, fetching: true}
        case SAVE_CUSTOMER_SUCCESS:
            return {...state, fetching: false, ...action.payload }
        case SAVE_CUSTOMER_ERROR:
            return {...state, fetching: false }
        default:
            return state
    }
}

// Actions

export let getBranchesAction = () => async ( dispatch, getState ) => {
    dispatch({
        type: GET_BRANCHES
    })
    await axiosApi.get(API.BUSINESS.BRANCH)
    .then((response) => {
        dispatch({
            type: GET_BRANCHES_SUCCESS,
            payload: { branches: response.data, branchesList: response.data.results }
        });
    })
    .catch((error) => {
        dispatch({
            type: GET_BRANCHES_ERROR
        });
    })
}

export let getCategoriesSupplier = () => async ( dispatch, getState ) => {
    try{
        const res = await axiosApi.get(API.BUSINESS.SUPPLIER_CATEGORY);
        console.log('rescat',res)
        dispatch({
            type: GET_CATEGORY_SUPPLIERS,
            payload: res.data.results
        });
    }catch (e){
        dispatch({
            type: GET_CATEGORY_SUPPLIERS,
            payload: []
        });
    }
}

export let getCompaniesAction = () => async (dispatch, getState ) => {
    dispatch({
        type: GET_COMPANIES
    })
    await axiosApi.get(API.BUSINESS.COMPANY)
    .then((response) => {
        dispatch({
            type: GET_COMPANIES_SUCCESS,
            payload: { companies: response.data, companiesList: response.data.results }
        });
    })
    .catch((error) => {
        dispatch({
            type: GET_COMPANIES_ERROR
        });
    })
}

export let postCustomerAction = (data) => async ( dispatch, getState ) => {
    dispatch({
        type: SAVE_CUSTOMER
    })

    let request = {
        ...data
    }

    await axiosApi.post(API.BUSINESS.CUSTOMER, request)
    .then((response) => {
        console.log(response);
        dispatch({
            type: SAVE_CUSTOMER_SUCCESS,
            payload: { newAccount: response.data }
        })
        return { success:true }
    })
    .catch((error) => {
        dispatch({
            type: SAVE_CUSTOMER_ERROR
        })
        return null;
    })
}