import React, { useState, useEffect } from 'react'
import {useSelector} from "react-redux";
import _ from 'lodash'
import { FormattedMessage, injectIntl } from "react-intl";



const tags = {
    giksa: {
        repair: <FormattedMessage id="servicesDetails.maintenance" />,
        tech_valuator: <FormattedMessage id="servicesDetails.valuation.quoting" />
    },
    default: {
        repair: <FormattedMessage id="servicesDetails.repair" />,
        tech_valuator: <FormattedMessage id="servicesDetails.valuation.technical" />
    }
}


const useCustomTags=()=>{
    const settingsKikert = useSelector(state => state.settings);

    const getMetaValue=(meta)=>{
        let metas = settingsKikert?.generalInfoMeta?.results;
        if(metas){
            return _.find(metas,{key:meta})?.value
        }else{
            return null
        }
    }

    const getCustomTag = (key) => {
        const clientCode = getMetaValue('TENANT_CODE')
        const tagsClientToSearch = clientCode && clientCode in tags ? clientCode : 'default'
        return tags[tagsClientToSearch][key]
    }

    return {
        getCustomTag
    }


}

export default useCustomTags;