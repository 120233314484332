import axiosApi, { API } from "../lib/utils/axiosApi";

export const getSurveys = async () => {
  const response = await axiosApi.get(API.SURVEYS.GET_SURVEYS+'?page_size=1000000');
  return response.data;
};


export const getSurveyByID = async (id) => {
  let URL = API.SURVEYS.SURVEY_DETAIL.replace('{id}', id)
  const response = await axiosApi.get(URL);
  return response.data;
};

export const submitSurvey = async (data) => {
  const response = await axiosApi.post(API.SURVEYS.SURVEY_SUBMIT, data);
  return response.data;
}


