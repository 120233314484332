import React, { useEffect, useState } from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import { FormattedMessage, injectIntl } from "react-intl";
import { Col, Row, Input, Tooltip, Button, Table, Card, Form, Typography, Space, Spin } from "antd";
import {
  SearchOutlined,
  FilterOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import MembersTable from "../../components/members/MembersTable";
import ModalMembers from "../../components/members/ModalMembers";
import styled from "styled-components";
import { connect } from 'react-redux';
import CreateGroup from "../../components/members/CreateGroup";
import ProfileForm from '../../components/members/forms/ProfileForm';
import { showMembers, getProfiles, getPermissionsProfile, savePermissions } from "../../redux/membersDuck";
import {
  BrowserRouter as Router,
  Route,
  Link,
  useRouteMatch,
  useHistory,
  useParams,
} from "react-router-dom";
import MainLayoutv2 from "../../layouts/MainLayoutv2";

const UpdProfile = ({ intl, getPermissionsProfile, savePermissions, membersState, ...props}) => {
  const {Text, Title } = Typography;
  const { id } = useParams();
  const history = useHistory();
  const [perms, setPerms] = useState([])
  const [nameProfile, setNameProfile] = useState(null)

  useEffect(() => {
    getPermissionsProfile(id);
  }, [])

  useEffect(() => {

      if(Object.keys(membersState.current).length > 0){
        if(membersState.current){
          console.log('map',membersState.current)
          setNameProfile(membersState.current.name)
          constructPermsArray(membersState.current.permissions_list);
        }
      }
  }, [membersState.current])


  const constructPermsArray = (permissions_list) => {
    let permsArray = [];
    permissions_list.map((item) => {
      permsArray.push(item.codename);
    })
    setPerms(permsArray)
  }


  const saveInfo = () => {
    let data = {
      name: nameProfile,
      perms: perms
    }
    savePermissions(id,data);
  }

  return (
    <MainLayoutv2
      title={<FormattedMessage id="members.title" />}
      index="members"
    >
        <Row justify="space-between" align="bottom">
          <Col md={8}>
            <Form layout="vertical">
              <Form.Item
                label={<Text style={{ paddingLeft: 10 }}>Nombre del perfil</Text>}
                style={{ marginBottom: 0 }}
              >
                <Input
                  placeholder={intl.formatMessage({
                    id: "members.search_name",
                  })}
                  className="br-5"
                  value={nameProfile}
                  onChange={(e) => setNameProfile(e.target.value)}
                />
              </Form.Item>
            </Form>
        </Col>
        <Col>
          <Space size={10}>
            <Button
                  disabled={membersState?.saving}
                  type="default"
                  htmlType="button"
                  onClick={() => history.push("/members_center_profiles")}
                  className="btn-string-blue"
                >
                  <FormattedMessage id="layout.goBack" />
                </Button>
                <Button
                loading={membersState?.saving}
                  type="primary"
                  className="btn-blue"
                  onClick={() => saveInfo()}
                >
                  <FormattedMessage id="services.filter.save" />
                </Button>
              </Space>
        </Col>
        <Col span={24} style={{paddingTop:25}}>
          <Spin spinning={membersState.saving}>
            <ProfileForm perms={perms} setPerms={setPerms} />
          </Spin>
        </Col>
        </Row>
    </MainLayoutv2>
  )
}
const mapState = (state) => ({
  membersState: state.members,
});

export default injectIntl(connect(mapState, {getPermissionsProfile, savePermissions})(UpdProfile))
