import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {injectIntl } from "react-intl";
import locale from 'antd/es/date-picker/locale/es_ES'
import {
    Layout,
    Card,
    Alert,
    Row,
    Col,
    DatePicker,
    Space,
    Spin,
    Form,
    Checkbox,
    Descriptions, Divider, message
} from 'antd'
import ServiceLayout from "../../layouts/ServiceLayout";
import MenuServiceDetail from "../../components/services/MenuServiceDetail";
import SectionTitle from "../../components/common/elements/SectionTitle/SectionTitle";
import KButton from "../../components/common/elements/KButton/KButton";
import {useHistory, useParams} from "react-router-dom";
import {getServiceDetail, getClosingReasons, closeOrder, reOpenOrder} from '../../redux/serviceOrderDuck'
import moment from 'moment'
import KSelect from "../../components/common/elements/KSelect/KSelect";
import _ from "lodash";
import {UnlockOutlined, DownloadOutlined} from "@ant-design/icons";
import ButtonLightHeader from '../../components/common/elements/ButtonLightHeader/ButtonLightHeader';
import styles from '../../components/common/elements/ButtonLightHeader/ButtonLightHeader.module.css'
import KModal from "../../components/common/elements/KModal/KModal";
import FormProcessChange from "../../components/services/FormProcessChange";
import axiosApi, {API, urlfull} from "../../lib/utils/axiosApi";
import SelectLocations from "../../components/Selects/SelectLocations";
import useSettingsKikert from "../../hooks/useSettingsKikert";
import { downloadFile } from '../../lib/utils/utils';
import MainLayoutv2 from '../../layouts/MainLayoutv2';

const ServiceClosePage=({intl,serviceOrder,getServiceDetail,getClosingReasons,closeOrder, reOpenOrder, ...props})=>{

    let { id } = useParams();
    let history = useHistory();
    const [deliveryDate,setDeliveryDate] = useState(moment())
    const [reasons,setReasons] = useState(null)
    const [reasonSelected,setReasonSelected] = useState(null)
    const [locationSelected,setLocationSelected] = useState(null)
    const [showReOpenOrder, setShowReOpenOrder] = useState(null)
    const [localLoading,setLocalLoading] = useState(false)
    const {settingsKikert, getMetaValue} = useSettingsKikert()
    const HIDE_LICENSE_PLATE_INPUT=getMetaValue('HIDE_LICENSE_PLATE_INPUT');


    const [openreasons,setOpenReasons] = useState(null)
    const [openreasonSelected,setOpenReasonSelected] = useState(null)


    useEffect(()=>{
        if (id) {
            getServiceDetail(id);
            getClosingReasons();
            getReopenReasons();
        }
    },[])

    useEffect(()=>{
        if(serviceOrder.reasons){
            let arr = serviceOrder.reasons.map((item)=> {
                return {label: item.name, value:item.id}
            })
            setReasons(arr)
        }
    },[serviceOrder.reasons])

    const getReopenReasons=async()=>{
        try{
            const res = await axiosApi.get(API.SERVICE.SERVICE_ORDER_REASONS);
            if(res?.data?.results){
                let arr = res.data.results.map((item)=> {
                    return {label: item.name, value:item.id}
                })
                setOpenReasons(arr)
            }
        }catch (e){

        }
    }

    const onReopen = (values) => {
        console.log('Success:', values);
        reopenOrderService(values)
    };




    const reopenOrderService=async (values)=>{
        try{
            const res = await reOpenOrder(id,values)
            if(res.success===false && res.error.response.status === 403){
                message.error('No cuenta con los permisos sufientes para realizar esta acción');
            }
            else if(res.success===false){
                
                if(res.message){
                    message.error(res.message)
                }else{
                    message.error('Hubo un error al abrir el expediente, por favor intenta nuevamente')
                }

            }else{
                console.log('res',res)
                if(res?.data?.order){
                    let configMessage = {
                        content: `Expediente generado con folio ${res?.data?.order?.folio}`,
                        duration: 5,
                        style:{cursor:'pointer'},
                        onClick: ()=>{
                            history.push(`/service-detail/${res?.data?.order?.id}`)
                        }
                    }
                    message.success(configMessage)
                }else{
                    message.success('Expediente abierto corectamente')
                }

                getServiceDetail(id)
                setShowReOpenOrder(false)
            }
        }catch (e) {

        }
    }

    const exitPassReport = async()=>{
        setLocalLoading(true)
          try{
           const res = await  axiosApi.get(`${urlfull}service/order/${id}/build_report/?type=EXIT_PASS`,{responseType: 'blob'})
            downloadFile(res.data,`pase_salida_${_.get(
                        serviceOrder,
                        "serviceDetail.folio",
                        ""
                      )}`,'application/pdf')
            console.log(res)
          }catch (e){
            console.log(e)
          }finally {
            setLocalLoading(false)
          }
      }


    const closeOrderService=async ()=>{
        try{
            if(serviceOrder.serviceDetail.process_status==='REPAIR-IN-PROCESS'){
                message.error('Porfavor finalice la reparación antes de entregar la unidad')
                return
            }

            if(!locationSelected){
                message.error('Porfavor elija una locación')
                return
            }

            const res = await closeOrder(id,{
                closing_reason: reasonSelected,
                closing_datetime: deliveryDate,
                location: locationSelected
            })
            if(res.success===false){
                if(res?.error?.response?.data?.message){
                    message.error(res?.error?.response?.data?.message)
                }else{
                    message.error('Hubo un error al entregar el expediente, por favor intenta nuevamente')
                }

            }else{
                message.success('Expediente cerrado corectamente')
                getServiceDetail(id)
            }
        }catch (e) {
            message.error('Hubo un error al entregar el expediente, por favor intenta nuevamente')
        }
    }

    return <>
        <MainLayoutv2 isServiceDetail={true} title={intl.formatMessage({ id: "servicesDetails.servicesDetails" })} index="expedientes">
            <Spin spinning={serviceOrder.loading}>
            <Layout style={{ marginTop: 10 }}>
                <Card className="card_details_service">
                    <MenuServiceDetail menuSelected={7} />
                    <SectionTitle title={intl.formatMessage({ id: "servicesDetails.deliverVehicle" })} />
                    <Row gutter={16} style={{marginBottom:20}}>
                        <Col xs={24} lg={16}>
                            <br/>
                            {
                                serviceOrder?.serviceDetail &&  <Descriptions
                                    bordered
                                    layout="vertical"
                                    title={intl.formatMessage({ id: "servicesDetails.orderDetail" })}
                                    size={'small'}
                                >
                                    <Descriptions.Item label="Folio">{serviceOrder?.serviceDetail?.folio}</Descriptions.Item>
                                    <Descriptions.Item label="Núm. Orden">{serviceOrder?.serviceDetail?.order_number}</Descriptions.Item>
                                    <Descriptions.Item label="Cliente">{serviceOrder?.serviceDetail?.customer?.first_name} {serviceOrder?.serviceDetail?.customer?.last_name}</Descriptions.Item>
                                    {
                                        serviceOrder.serviceDetail['WORKSHOP'] &&
                                        <>
                                            <Descriptions.Item label="Unidad">{serviceOrder.serviceDetail['WORKSHOP'].vehicle?.model?.brand?.name} {serviceOrder?.serviceDetail['WORKSHOP']?.vehicle?.model?.name} {serviceOrder.serviceDetail['WORKSHOP']?.vehicle?.year}</Descriptions.Item>
                                            {
                                                HIDE_LICENSE_PLATE_INPUT==='0' && <Descriptions.Item label="Placas">{serviceOrder.serviceDetail['WORKSHOP']?.vehicle?.license_plate}</Descriptions.Item>
                                            }
                                            
                                            <Descriptions.Item label="Color">{serviceOrder.serviceDetail['WORKSHOP']?.vehicle?.color?.name}</Descriptions.Item>
                                        </>
                                    }
                                    <Descriptions.Item label="Comentario">{serviceOrder.serviceDetail.comment}</Descriptions.Item>
                                    <Descriptions.Item label="Fecha de entrega">{_.get(serviceOrder, 'serviceDetail.closing_datetime','--') }</Descriptions.Item>
                                    <Descriptions.Item label="Razón de entrega">{ _.get(serviceOrder, 'serviceDetail.closing_reason.name','--') }</Descriptions.Item>
                                    <Descriptions.Item label="Locación actual">{ _.get(serviceOrder, 'serviceDetail.current_location.name','--') }</Descriptions.Item>
                                </Descriptions>
                            }

                            {
                                serviceOrder.serviceDetail?.status!=='CLOSED' &&
                                <Alert
                                    style={{marginTop:20, marginBottom:20}}
                                    message={intl.formatMessage({ id: "servicesDetails.deliveryOrderText" })}
                                    showIcon={false}
                                    description={
                                        <Space direction={'vertical'} style={{width:'100%'}}>
                                            <Divider/>
                                            <b>{intl.formatMessage({ id: "saleOrder.deliveryDateClose" })}</b>
                                            <DatePicker
                                                locale={locale}
                                                className={'kinput'}
                                                defaultValue={moment()}
                                                format="DD-MM-YYYY"
                                                style={{width:300}}
                                                showTime={true}
                                                placeholder={intl.formatMessage({ id: "saleOrder.datedelivery" })}
                                                onChange={(e)=> setDeliveryDate(e)} />
                                            <b>{intl.formatMessage({ id: "saleOrder.deliveryReasonClose" })}</b>
                                            <KSelect
                                                style={{width:300}}
                                                options={reasons}
                                                value={reasonSelected}
                                                onChange={(e)=> setReasonSelected(e)} />

                                            <Form layout={'vertical'}>
                                                <SelectLocations label={'Mover unidad a'}  style={{width:300}} onChange={(e)=>setLocationSelected(e)} />
                                            </Form>



                                            <Space direction={'horizontal'}>
                                                <KButton onClick={()=> history.push(`/service-detail/${id}`) } type={'secondary'} text={intl.formatMessage({ id: "cancel" })} />
                                                <KButton disabled={!deliveryDate || !reasonSelected} onClick={()=>closeOrderService()} text={intl.formatMessage({ id: "confirm" })} />
                                            </Space>
                                        </Space>
                                    }
                                />
                            }

                            {
                                serviceOrder?.serviceDetail?.status==='CLOSED' && 
                                <div style={{marginTop:20}}>
                                    <KButton onClick={()=>setShowReOpenOrder(true)} icon={<UnlockOutlined />} text={'Re aperturar orden'} />
                                    <ButtonLightHeader
                                        loading={localLoading}
                                        className={styles.button_header}
                                        size={"large"}
                                        icon={<DownloadOutlined />}
                                        text={ "Pase de salida"}
                                        onClick={exitPassReport}
                                    />
                                </div>
                            }



                            <KModal
                                title={'Re aperturar Orden'}
                                loading={serviceOrder.loading}
                                onCancel={() => setShowReOpenOrder(false)}
                                visible={showReOpenOrder}
                            >
                                <Form
                                    onFinish={onReopen}
                                    name={'formreopen'}
                                    layout="vertical"
                                    initialValues={{
                                        create_new_order: false,
                                    }}
                                >
                                    <Form.Item
                                        label="Razón de re apertura"
                                        name="open_reason"
                                        rules={[{ required: true }]}
                                    >
                                        <KSelect
                                            style={{width:300}}
                                            options={openreasons} />
                                    </Form.Item>

                                    <Form.Item
                                        label=""
                                        valuePropName="checked"
                                        name="create_new_order"
                                    >
                                        <Checkbox>Generar uno nuevo a partir de esta.</Checkbox>
                                    </Form.Item>
                                    <Form.Item>
                                        <KButton onClick={()=> setShowReOpenOrder(false)} type={'secondary'} text={'Cancelar'}/>
                                        <KButton htmlType="submit" text={'Aceptar'}/>
                                    </Form.Item>
                                </Form>

                            </KModal>

                        </Col>
                    </Row>
                </Card>
            </Layout>
            </Spin>
        </MainLayoutv2>
    </>
}


const mapStateToProps = state => ({
    settings: state.settings,
    serviceOrder: state.serviceOrder
})


export default injectIntl(connect(mapStateToProps,{getServiceDetail,getClosingReasons,closeOrder, reOpenOrder})(ServiceClosePage));