import React from "react";
import {
    Form
} from "antd";
import { injectIntl, FormattedMessage } from "react-intl";
import KSelect from "../common/elements/KSelect/KSelect";
import _ from "lodash";
import {ruleRequired} from "../../lib/utils/rules";

const SelectJacVersions=({name='order_version', label='Versión', required=false,...props})=>{




    const types = [
        {
            label: 'CBU',
            value: 'CBU'
        },
        {
            label: 'SKD',
            value: 'SKD'
        },
        {
            label: 'DKD',
            value: 'DKD'
        }
    ];

    return (
        <Form.Item
            label={label ? label : <FormattedMessage id="Color" />}
            name={name}
            rules={required? [ruleRequired]:[]}
            className="item-expediente-lg mb-0 label-margin"
        >
            <KSelect
                {...props}
                options={
                    types && _.map(types, (o)=>{
                        return {
                            value:o.value,
                            label:`${o.label}`
                        }
                    })
                }
            />
        </Form.Item>
    )
}


export default injectIntl(
    SelectJacVersions 
);
