import React, {useState, useEffect} from 'react'
import turn from '../../image/turn_icon_kikert.svg'
import DashboardLayout from '../../layouts/DashboardLayout';
import {message, Table, Layout, Form, Button, Modal, Input, Typography, Upload, Space, Image, Drawer, List, Row, Col} from 'antd'
import {UploadOutlined} from '@ant-design/icons';
import {axiosApi, API} from "../../lib/utils/axiosApi"
import {connect} from 'react-redux'
import DeleteLinkComponent from "../../components/DeleteLinkComponent";
import {injectIntl} from 'react-intl';
import {setConfigCompanyBranch, changeLangAction} from "../../redux/settingsDuck";
import {FormattedMessage} from "react-intl";
import {CustomTable} from "../../components/KComponents";
import KButton from "../../components/common/elements/KButton/KButton";
import { CustomModal } from '../../components/MyComponents'
import CloseIcon from '../../image/close-outline.svg';
import logoDefault from "../../image/imagenDefault.jpg"
import {permissionsExist} from "../../lib/utils/utils";
import EditIcon from "../../image/edit.svg";
import DeleteIcon from "../../image/delete.svg";
import DeleteModal from "../../components/modal/DeleteModal";
import MainLayoutv2 from "../../layouts/MainLayoutv2";
import TabsConfig from '../../components/common/elements/TabsMenuConfig/TabsMenuConfig';

const {Header, Content, Footer} = Layout;

const InsurancePage = ({changeLangAction, intl, setConfigCompanyBranch, settings,permissions, ...props}) => {

    const { Title } = Typography

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [loading, setLoading] = useState(false)
    const [insuranceList, setInsuranceList] = useState([])
    const [currentInsurance, setCurrentInsurance] = useState(null)
    const [currentLogo, setCurrentLogo] = useState(null)
    const [showDrawer, setShowDrawer] = useState(false)
    const [agents, setAgents] = useState(null)
    const [actionAddAgent, setActionAddAgent] = useState(false)
    const [form] = Form.useForm();
    const [formAgent] = Form.useForm();

    const [forDelete, setForDelete] = useState(null)
    const [modalDelete, setModalDelete] = useState(false)


    useEffect(() => {
        getInsurance()
    }, [])

    const showModal = () => {
        setIsModalVisible(true);
    };

    const onShowDrawer = (insurance) => {
        console.log('Datos aseguradora', insurance)
        setShowDrawer(true)

        if (insurance) {
            setCurrentInsurance(insurance)
            //
            if (insurance.agents) {
                setAgents(insurance.agents)
                getInsuranceAgent(insurance.id)
            }
        }
    }

    const closeDrawer = () => {
        setShowDrawer(false)
        setCurrentInsurance(null)
    }

    const handleOk = () => {
        form.submit();
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setCurrentInsurance(null)
        setCurrentLogo(null)
        form.resetFields()
    };


    const getInsuranceAgent = async (id_insurance) => {
        setLoading(true)
        try {
            let res = await axiosApi.get(`${API.WORKSHOP.WORKSHOP_INSURANCE_AGENTS}?insurance=${id_insurance}`)
            if (res.data.results) {
                setAgents(res.data.results)
            }
        } catch (e) {
            setAgents([])
        } finally {
            setLoading(false)
        }
    }

    const saveAgent = async (values) => {
        setLoading(true)
        console.log('currentInsurance', currentInsurance)
        if (currentInsurance) {
            let req = {
                ...values,
                insurance: currentInsurance.id
            }
            try {
                let res = await axiosApi.post(API.WORKSHOP.WORKSHOP_INSURANCE_AGENTS, req);
                message.success(intl.formatMessage({id: "item.saveSuccess"}))
                getInsurance()
                getInsuranceAgent(currentInsurance.id)
                setActionAddAgent(false)
            } catch (e) {
                message.error(intl.formatMessage({id: "item.issure.agentErr"}))
            } finally {
                setLoading(false)
                formAgent.resetFields()
            }
        }
    }

    const onEditAgent = async (e, agent) => {
        if (e.target.value.trim() !== agent.name.trim()) { // validamos si hubieron cambios para saber si editamos o no
            setLoading(true)
            try {
                let req = {
                    insurance: currentInsurance.id,
                    name: e.target.value
                }
                const res = await axiosApi.put(`${API.WORKSHOP.WORKSHOP_INSURANCE_AGENTS}${agent.id}/`, req)
                message.success(intl.formatMessage({id: "item.editSuccess"}))
            } catch (e) {

            } finally {
                setLoading(false)

            }
        }

    }

    const onDeleteAgent = async (id_agent) => {
        setLoading(true)
        try {
            let res = await axiosApi.delete(`${API.WORKSHOP.WORKSHOP_INSURANCE_AGENTS}${id_agent}`)
            getInsuranceAgent(currentInsurance.id)
            console.log(res)
            message.success(intl.formatMessage({id: "item.deleteSuccess"}))
        } catch (e) {

        } finally {
            setLoading(false)
        }
    }

    //Funciones para las aseguradoras

    const getInsurance = async (page = 1) => {
        setLoading(true)
        try {
            let res = await axiosApi.get(
                `${API.WORKSHOP.WORKSHOP_INSURANCE}?company=${settings.settingsData.companySelected.id}&page=${page}`
            );
            if (res.data.results) {
                setInsuranceList(res.data)
            }
        } catch (e) {
            setInsuranceList([])
        } finally {
            setLoading(false)
        }
    }

    const saveInsurance = async (values) => {
        setLoading(true)
        //const imagen123 = URL.createObjectURL(logoDF1)
        //setCurrentLogo(imagen123);
        let req = new FormData()
        req.append('name', values.name)
        req.append('company', settings.settingsData.companySelected.id)
        if (currentLogo)
            req.append('logo', currentLogo)
        //req.append('logo', currentLogo)
        try {
            let res = await axiosApi.post(`${API.WORKSHOP.WORKSHOP_INSURANCE}`, req)
            message.success(intl.formatMessage({id: "item.saveSuccess"}))
            getInsurance()
            console.log(res)
        } catch (e) {

        } finally {
            setLoading(false)
            form.resetFields()
        }
    }

    const editInsurance = async (values) => {
        console.log('esto son los values', values)
        let actualizacion = new FormData()
        actualizacion.append('name', values.name)
        actualizacion.append('company', settings.settingsData.companySelected.id)
        if (currentLogo) {
            actualizacion.append('logo', currentLogo)
        }
        setLoading(true)
        try {
            let res = await axiosApi.put(`${API.WORKSHOP.WORKSHOP_INSURANCE}${currentInsurance.id}/`, actualizacion)
            message.success(intl.formatMessage({id: "item.editSuccess"}))
            getInsurance()
        } catch (e) {

        } finally {
            setLoading(false)
        }
    }

    const onEditInsurance = async (item) => {
        setCurrentInsurance(item)
        console.log('edit', item)
        if (item) {
            form.setFieldsValue({
                name: item.name,
                logo: null

            })
            showModal(true)
        }
    }

    const setLogo = (e) => {
        console.log('imagen nueva', e.target.files) //Muestra la imagen que vamos a cambiar
        if (e.target.files)
            setCurrentLogo(e.target.files[0])
    }

    const closeModal = () =>{
        setModalDelete(false);
        setForDelete(null);
    }

    const deleteItem = (current) => {
        console.log(current);
        setModalDelete(true);
        setForDelete(current);
    }

    const onDelete = async () => {
        setLoading(true)
        try {
            let res = await axiosApi.delete(`${API.WORKSHOP.WORKSHOP_INSURANCE}${forDelete.id}`)
            message.success(intl.formatMessage({id: "item.deleteSuccess"}))
            getInsurance()
            closeModal();
        } catch (e) {

        } finally {
            setLoading(false)
            closeModal();
        }
    }

    const columns = [
        {
            title: 'Logo',
            dataIndex: 'logo',
            key: 'logo',
            render: (id, record) => {
                if (record.logo === null) {
                    return <Image style={{width: '100px'}} src={logoDefault}/>
                } else {
                    return <Image style={{width: '100px'}} src={record.logo}/>
                }
            }
        },
        {
            title: <FormattedMessage id="layout.name"/>,
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: <FormattedMessage id="layout.actions"/>,
            dataIndex: 'actions',
            key: 'actions',
            render: (id, record) => {

                return( <Space size={30}>
                    <KButton type={'link'} onClick={() => onShowDrawer(record)} text={<FormattedMessage
                        id="layout.agents"/>}/>
                    {
                        permissionsExist(permissions.permissionsList,"change_insurance") &&
                        <img key="EditIcon" src={EditIcon} style={{cursor:'pointer',  width:12}}
                             onClick={() => onEditInsurance(record)}/>
                    }
                    {
                        permissionsExist(permissions.permissionsList,"delete_insurance") &&
                        <img key="DeleteIcon" src={DeleteIcon} style={{cursor:'pointer', width:12}} onClick={() => deleteItem(record)}/>
                    }
                </Space>)
            }
        },

    ];

    const changePagination = (page) => {
        console.log('page', page)
        getInsurance(page);
    }

    return (
        <MainLayoutv2
            title={intl.formatMessage({id: "services.insur"})}
            index="5"
            openKeys={["sub1"]}
        >
            <Drawer
                title={
                    intl.formatMessage({id: "layout.agents"}) +
                    ` ${currentInsurance ? currentInsurance.name : null}`
                }
                width={400}
                onClose={closeDrawer}
                visible={showDrawer}
                bodyStyle={{paddingBottom: 80}}
            >
                {actionAddAgent || (
                    <KButton
                        type="primary"
                        text={<FormattedMessage id="layout.new"/>}
                        style={{marginBottom: 20}}
                        onClick={() => setActionAddAgent(true)}
                    />
                )}

                {!actionAddAgent || (
                    <Form layout={"vertical"} form={formAgent} onFinish={saveAgent}>
                        <Form.Item
                            name={"name"}
                            label={intl.formatMessage({id: "layout.nameAgente"})}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item>
                            <Button
                                style={{marginRight: 10}}
                                onClick={() => setActionAddAgent(false)}
                            >
                                <FormattedMessage id="layout.cancel"/>
                            </Button>
                            <Button type="primary" htmlType="submit">
                                <FormattedMessage id="layout.add"/>
                            </Button>
                        </Form.Item>
                    </Form>
                )}


                    <List
                        dataSource={agents}
                        loading={loading}
                        renderItem={(item) => (
                            <List.Item
                                actions={[
                                    <DeleteLinkComponent
                                        onDelete={() => onDeleteAgent(item.id)}
                                    />
                                ]}
                            >
                                <List.Item.Meta
                                    title={
                                        item.name
                                    }
                                />
                            </List.Item>
                        )}
                    />
            </Drawer>

            <CustomModal
                title=""
                footer=""
                visible={isModalVisible}
                onCancel={handleCancel}
                closeIcon={<img src={CloseIcon} style={{width:15}} />}

                /* title={intl.formatMessage({id: "services.insure"})}
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel} */
            >
                <Row>
                    <Col md={24} style={{ borderBottom: "solid 1px #341eff", marginBottom:10 }}>
                        <Title level={2} style={{ marginBottom: 0 }}>
                            <img
                            src={turn}
                            alt="logo"
                            style={{ height: 60, width: 30, transform: "scaleX(-1)", marginRight:10 }}
                            />
                            <FormattedMessage id="services.insure" />
                        </Title>
                    </Col>
                    <Col span={24}>
                        <Form
                        layout={"vertical"}
                        form={form}
                        onFinish={currentInsurance ? editInsurance : saveInsurance}
                        >
                            {currentInsurance ? (
                                currentInsurance.logo ? (
                                    <Image src={currentInsurance.logo}/>
                                ) : null
                            ) : null}

                            <Form.Item name={"logo"} label="Logo">
                                <Input type={"file"} onChange={setLogo} className='kinput'/>
                            </Form.Item>

                            <Form.Item
                                name={"name"}
                                label={intl.formatMessage({id: "layout.name"})}
                            >
                                <Input className='kinput'
                                    placeholder={intl.formatMessage({id: "services.insure"})}
                                />
                            </Form.Item>
                        </Form>
                    </Col>
                    <Col span={24} style={{ textAlign: "center" }}>
                        <Space>
                            <Button
                                size="large"
                                className="btn-string-blue btn-report-action"
                                onClick={() => handleCancel()}
                            >
                                <FormattedMessage id="layout.cancel" />
                            </Button>
                            <Button
                                className="btn-blue btn-report-action"
                                size="large"
                                onClick={() => handleOk()       }
                                >
                                <FormattedMessage id="services.members.create.save" />
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </CustomModal>

            <Content
                style={{padding: "10px 10px", background: "white"}}
            >
                 <TabsConfig keyActive={'3'}/>
                 
                {
                    permissionsExist(permissions.permissionsList,"add_insurance") &&
                    <KButton
                        type='primary'
                        size={"large"}
                        onClick={showModal}
                        style={{float: "right", marginBottom: 20}}
                        text={<FormattedMessage id="layout.createNew"/>}
                    />
                }

               
                <CustomTable
                    loading={loading}
                    fixed={true}
                    scroll={{ x: 1500 }}
                    dataSource={insuranceList.results}
                    columns={columns}
                    pagination={{total: insuranceList.count, pageSize: 25, onChange: changePagination}}
                />
            </Content>
            <DeleteModal actionDelete={() => onDelete()} isVisible={modalDelete}
                         closeModal={closeModal}
                         modalTitle={<FormattedMessage id="deleted.ask"/>}
                         description={intl.formatMessage({ id:'deleted.description'},{ obj: forDelete && forDelete.name })} />
        </MainLayoutv2>
    );
}

const mapStateToProps = state => ({
    settings: state.settings,
    security: state.security,
    permissions:state.permissions

})

export default injectIntl(connect(mapStateToProps, {
    setConfigCompanyBranch,
    changeLangAction,
})(InsurancePage))
