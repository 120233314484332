import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl';
import {connect} from   'react-redux'
import { Layout, Menu, Typography, Input, Form, Button, Card, Col, Upload  } from 'antd';
import { UploadOutlined, FolderOpenOutlined, TeamOutlined, MessageOutlined, SettingOutlined, UserOutlined, DownOutlined} from '@ant-design/icons';
import logo from '../../image/black_icon_kikert.svg'
import turn from '../../image/turn_icon_kikert.svg'
import { injectIntl } from 'react-intl';
import { useHistory } from "react-router-dom"
import DashboardLayout from '../../layouts/DashboardLayout';


const UserPage = () => {

    const { Header, Content, Footer, Sider } = Layout;
    const { SubMenu } = Menu;
    const { Search } = Input;
    const { Meta } = Card;
    const { Title } = Typography;
    const style={
        contenidoInicial:{
            margin:'0 auto',
            width:'70%'
        },
        contenidoInicialTitle:{
            paddingTop: 10,
            color:'white', 
            fontSize:52, 
            fontWeight:480, 
            textAlign:'left', 
            fontFamily:'Sharp Grotesk, Book 20'
        },
        textWhite:{
            color:'white',
            fontFamily:'Sharp Grotesk', 
            fontSize:18, 
            fontWeight:200
        },
        divlogo:{
            backgroundColor: '#fff',
            marginTop: 30
        },
        logo:{
            height: 64,
            width: 32,
            marginLeft: 40,
        },
        profilepic:{
            marginLeft: 50,
        },
        mainContent:{
            paddingTop:120
        },
        divCenterButton:{
            paddingTop: 30,
            paddingLeft:40,
        },
        centerButton:{
            color: 'white', 
            padding:'12px 40px',
            backgroundColor: '#090731', 
            borderColor: '#090731', 
            borderRadius: 18,
            fontSize:14,
        },
        footerContent:{
            margin:'0 auto',
            width:'75%',    
            fontSize: 19,
            fontFamily:'Sharp Grotesk',
            textAlign: 'left',
            color:'white',
            verticalAlign:'end',
            paddingLeft: 100,
            paddingTop: 50,
            marginTop: 50,
        }
    }

    const onFinish = (values) => {
        console.log(values)
    }
    
    useEffect(() => {

    });

    return (
        <DashboardLayout>
            <Card><Col span={20}>
                <Form
                layout='vertical'
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                style={{padding:60}}
                >
                    <Form.Item label="Nombre">
                        <Input />
                    </Form.Item>
                    <Form.Item label='Appellido'>
                        <Input />
                    </Form.Item>
                    <Form.Item label="Nombre de usuario">
                        <Input />
                    </Form.Item>
                    <Form.Item label='Correo electronico'>
                        <Input />
                    </Form.Item>
                    <Form.Item label='Foto de perfil'>
                        <Upload>
                            <Button icon={<UploadOutlined />}>Subir imagen</Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item label='Contraseña'>
                        <Input />
                    </Form.Item>
                </Form>
                <Form.Item>
                    <Button type="primary" htmlType="submit">Guardar</Button>
                </Form.Item>
            </Col></Card>
        </DashboardLayout>
    )
}

export default UserPage