import React from 'react';

import {  Layout, Button, Card, Form } from "antd";
import ReceptionSection from './components/ReceptionSection';
import UnitSection from './components/UnitSection';
import ClientSection from './components/ClientSection';
import InsuranceSection from './components/InsuranceSection';
import ObservationsSection from './components/ObservationsSection';
import MainLayoutv2 from '../../../layouts/MainLayoutv2';
import { FormattedMessage } from 'react-intl';
import styled from "styled-components";
import { useSelector } from 'react-redux';
import useNewServiceOrder from './hooks/useNewServiceOrder';


const CreateServicePage = () => {

 const NewCard = styled(Card)`
  .ant-card-body {
    padding: 0px 10px !important;
  }
`;

const { form, onFinish } = useNewServiceOrder();
const user = useSelector(state => state.user);

  return (
    <MainLayoutv2 title={<FormattedMessage id="services.createNew" />} index="expedientes">
      <Layout style={{ marginTop: 20 }}>
      <NewCard className="br-10" style={{ padding: "10px", marginTop: 20 }}>
      <Form 
          layout="vertical"
          form={form} 
          onFinish={onFinish}
        >
        <ReceptionSection user={user} form={form} />
        <UnitSection user={user} form={form} />
        <ClientSection user={user} form={form} />
        <InsuranceSection user={user} form={form} />
        <ObservationsSection user={user} form={form} />
      <div className="buttons">
        <Button type="default">Salir</Button>
        <Button type="primary" htmlType="submit">Guardar</Button>
      </div>
      </Form>
      
    </NewCard>
    </Layout>
    </MainLayoutv2>
  );
};

export default CreateServicePage;