import React, { useEffect } from "react";
import {
  Modal,
  Select,
  Form,
  Typography,
  Button,
  Row,
  Col,
  Space,
  Divider,
  Input,
  DatePicker,
  Checkbox,
} from "antd";
import turn from "../../image/turn.svg";
import { Global, css } from "@emotion/react";
import "moment/locale/es-mx";
import locale from "../../locale/es_mx.json";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";
import { changeLangAction } from "../../redux/settingsDuck";
import { setModalFilter } from "../../redux/servicesDuck";
import { setFilterData, clearFilterSales } from "../../redux/salesDuck";
import moment from "moment";

const FilterServicesModal = ({
  changeLangAction,
  intl,
  settings,
  sales,
  setModalFilter,
  setFilterData,
  getData,
  workflow,
  userProfile,
  clearFilterSales,
  ...props
}) => {
  const { Title, Text } = Typography;
  const { Option } = Select;
  const { RangePicker } = DatePicker;

  useEffect(() => {
    console.log("settings ===>", sales);
  }, [sales]);

  const changeBrand = (value) => {
    let dataToSend = { ...sales.filterData, brand: value };
    console.log("dataToSend", dataToSend);
    setFilterData(dataToSend);
  }

  const changeModel = (value) => {
    let dataToSend = { ...sales.filterData, model: value };
    console.log("dataToSend", dataToSend);
    setFilterData(dataToSend);
  };

  const changeStage = (value) => {
    let dataToSend = { ...sales.filterData, order__current_step: value };
    setFilterData(dataToSend);
  };

  const changeSaleType = (value) => {
    let dataToSend = { ...sales.filterData, sale_type: value };
    setFilterData(dataToSend);
  }

  const changeDateRange = (values, dateString) => {
    let dataToSend = {
        ...sales.filterData,
        start_date: dateString[0],
        end_date: dateString[1] 
      };
    setFilterData(dataToSend);
  };

  const changeUser = (value) => {
    let dataToSend = {
      ...sales.filterData,
      asigned_to: value
    };
    setFilterData(dataToSend);
  }

  const clearFilter = () => {
    console.log("clearFilter");
    clearFilterSales();
  };

  useEffect(() => {
    console.log("userProfile", userProfile);
  }, [userProfile]);
  


  return (
    <>
      <Global
        styles={css`
          .modal_filter_service .ant-modal-content {
            border-radius: 15px;
            padding: 0px 15px;
          }
          .label-margin .ant-form-item-label {
            margin-left: 15px;
          }

          .label-margin .ant-form-item-label label {
            font-weight: 500 !important;
          }
          .title_date {
            font-weight: normal !important;
            margin-left: 15px;
          }
          .text_dates {
            color: var(--secondaryColor);
            margin-left: 15px;
          }
          .ant-checkbox-inner {
            border-radius: 25%;
            border-color: #2d2cf5;
          }
          .ant-checkbox-checked .ant-checkbox-inner {
            background-color: #2d2cf5;
          }
          .ant-checkbox-inner::after {
            background-color: #2d2cf5;
            border-color: #2d2cf5;
          }
        `}
      />
      <Modal
        centered
        visible={sales.openModalFilter}
        title=""
        footer=""
        className="modal_filter_service"
        width={700}
        onCancel={() => setModalFilter(false)}
      >
        <Title level={3} className="title-modal-filte">
          <img src={turn} alt="logo" style={{ width: 25 }} />{" "}
          <FormattedMessage id="services.filter.filter" />
        </Title>
        <Divider className="divider-blue" />
        <Form layout="vertical">
          <Row style={{ paddingTop: 10 }} gutter={[20, 20]}>
            <Col xs={24} md={12}>
              <Form.Item
                label={intl.formatMessage({ id: "services.page.advisor" })}
                className="item-expediente-lg mb-0 label-margin"
              >
                <Select
                  size="large"
                  showSearch
                  className="selectFormCredit"
                  placeholder={intl.formatMessage({
                    id: "services.filter.everything",
                  })}
                  onChange={changeUser}
                  value={sales.filterData?.asigned_to}
                >
                  <Select.Option value="">
                    <FormattedMessage id="services.filter.everything" />
                  </Select.Option>
                  {userProfile.userProfilesList.map((item) => (
                    <Select.Option key={item.user}>
                      {item.user && item.user.first_name+item.user.last_name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label={intl.formatMessage({ id: "services.filter.stage" })}
                className="item-expediente-lg mb-0 label-margin"
              >
                <Select
                  size="large"
                  showSearch
                  className="selectFormCredit"
                  placeholder={intl.formatMessage({
                    id: "services.filter.everything",
                  })}
                  onChange={changeStage}
                  value={sales.filterData?.order__current_step}
                >
                  <Select.Option value="">
                    <FormattedMessage id="services.filter.everything" />
                  </Select.Option>
                  {workflow.all_steps.map((item) => (
                    <Select.Option key={item.id}>{item.name}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label={intl.formatMessage({ id: "services.filter.date" })}
                className="item-expediente-lg mb-0 label-margin"
              >
                <RangePicker
                  size="large"
                  format="DD/MM/YYYY"
                  locale={locale}
                  style={{ width: "100%" }}
                  onChange={changeDateRange}
                  value={[
                    sales.filterData && sales.filterData.start_date
                      ? moment(sales.filterData.start_date, "DD/MM/YYYY")
                      : undefined,
                    sales.filterData && sales.filterData.end_date
                      ? moment(sales.filterData.end_date, "DD/MM/YYYY")
                      : undefined,
                  ]}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label={intl.formatMessage({
                  id: "services.saleOrder.typeOfSale",
                })}
                className="item-expediente-lg mb-0 label-margin"
              >
                <Select
                  size="large"
                  showSearch
                  className="selectFormCredit"
                  placeholder={intl.formatMessage({
                    id: "services.filter.everything",
                  })}
                  onChange={changeSaleType}
                  value={sales.filterData?.sale_type}
                >
                  <Select.Option value="">
                    <FormattedMessage id="services.filter.everything" />
                  </Select.Option>
                  <Select.Option key={"CREDIT"}>
                    <FormattedMessage id="services.saleOrder.credit" />
                  </Select.Option>
                  <Select.Option key={"CASH"}>
                    <FormattedMessage id="services.saleOrder.cash" />
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                label={intl.formatMessage({ id: "layout.brand" })}
                className="item-expediente-lg mb-0 label-margin"
              >
                <Select
                  size="large"
                  showSearch
                  className="selectFormCredit"
                  placeholder={intl.formatMessage({
                    id: "services.filter.everything",
                  })}
                  onChange={changeBrand}
                  value={sales.filterData?.brand}
                >
                  <Select.Option value="">
                    <FormattedMessage id="services.filter.everything" />
                  </Select.Option>
                  {settings.brandsList.map((item) => (
                    <Select.Option key={item.id}>{item.name}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                label={intl.formatMessage({ id: "layout.model" })}
                className="item-expediente-lg mb-0 label-margin"
              >
                <Select
                  size="large"
                  showSearch
                  className="selectFormCredit"
                  placeholder={intl.formatMessage({
                    id: "services.filter.everything",
                  })}
                  onChange={changeModel}
                  value={sales.filterData?.model}
                >
                  <Select.Option value="">
                    <FormattedMessage id="services.filter.everything" />
                  </Select.Option>
                  {settings.modelsList.map((item) => (
                    <Select.Option key={item.id}>{item.name}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} style={{ paddingTop: 30, marginBottom: 20 }}>
              <Button type="link" onClick={() => clearFilter()}>
                <Text type="danger">
                  <FormattedMessage id="services.filter.clearFilter" />
                </Text>
              </Button>
            </Col>
          </Row>
          <Row justify="center">
            <Col>
              <Space size="middle">
                <Button
                  htmlType="button"
                  className="btn-string-blue btn-report-action"
                  onClick={() => setModalFilter(false)}
                >
                  <FormattedMessage id="layout.cancel" />
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="btn-string-blue-low btn-report-action"
                  onClick={() => getData(1, 10)}
                >
                  <FormattedMessage id="services.filter.save" />
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  sales: state.sales,
  settings: state.settings,
  workflow: state.workflow,
  userProfile: state.userProfile,
});

export default injectIntl(
  connect(mapStateToProps, {
    setModalFilter,
    changeLangAction,
    setFilterData,
    clearFilterSales,
  })(FilterServicesModal)
);
