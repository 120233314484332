import React, { useState, useEffect } from 'react'
import DashboardLayout from '../../layouts/DashboardLayout';
import { Table, Layout, Form, Button, Modal, Input, Upload, Space, Popconfirm, message, Row, Col, Typography } from 'antd'
import { UploadOutlined } from '@ant-design/icons';
import DeleteLinkComponent from "../../components/DeleteLinkComponent";
import { connect } from 'react-redux'
import { injectIntl } from "react-intl";
import { FormattedMessage } from "react-intl";
import moment from 'moment';
import {
    getMetas,
    saveMetaAction,
    deleteMetaAction,
    editMetaAction
} from "../../redux/settingsDuck"
import HeaderSection, { InputDate, ItemForm } from "../../components/MyComponents";
import {permissionsExist} from "../../lib/utils/utils";
import DeleteModal from "../../components/modal/DeleteModal";
import EditIcon from "../../image/edit.svg";
import DeleteIcon from "../../image/delete.svg";
import turn from '../../image/turn_icon_kikert.svg'
import {CustomTable} from "../../components/KComponents";
import KButton from "../../components/common/elements/KButton/KButton";
import KModal from '../../components/common/elements/KModal/KModal';
import KSelect from '../../components/common/elements/KSelect/KSelect'
import MainLayoutv2 from "../../layouts/MainLayoutv2";
import TabsConfig from '../../components/common/elements/TabsMenuConfig/TabsMenuConfig';

const { Header, Content, Footer } = Layout;


const MetasPage = ({intl, settings, permissions, getMetas, saveMetaAction, deleteMetaAction, editMetaAction}) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentMeta, setCurrentMeta] = useState(false);
    const [actionView, setActionView] = useState(true);
    const [forDelete, setForDelete] = useState(null)
    const [modalDelete, setModalDelete] = useState(false)
    const [form] = Form.useForm();

    const {Title} = Typography;

    useEffect(() => {
      getMetas()
    }, [])
    

    const columns = [
        {
            title: <FormattedMessage id='services.metas.type'/>,
            dataIndex: 'goal_type',
            key: 'goal_type',
        },
        {
            title: <FormattedMessage id='services.metas.value'/>,
            dataIndex: 'goal',
            key: 'goal',
        },
        {
            title: <FormattedMessage id='services.metas.date'/>,
            dataIndex: 'goal_date_apply',
            key: 'goal_date_apply',
            render: (record) => (
                <>{moment(record).format("DD-MM-YYYY")}</>
                )
        },
        {
            title: <FormattedMessage id="layout.actions"/>,
            dataIndex: 'id',
            key: 'actions',
            render: (id, record) => {
                return <Space size={30}>
                    {
                        <img key="EditIcon" src={EditIcon} style={{cursor:'pointer',  width:12}} onClick={() => editMeta(record)} />
                    }
                    {
                        <img key="DeleteIcon" src={DeleteIcon} style={{cursor:'pointer', width:12}} onClick={() => deleteItem(record)} />

                    }
                </Space>
            }

        }
    ];

    const showModal = (isEdit = false) => {
        if (!isEdit) {
            form.resetFields()
            setCurrentMeta(null)
        }
        setIsModalVisible(true);
    };

    const handleOk = () => {
        form.submit();
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setCurrentMeta(null)
        form.resetFields()
    };

    const closeModal = () =>{
        setModalDelete(false);
        setForDelete(null);
    }

    const deleteItem = (current) => {
        setModalDelete(true);
        setForDelete(current);
    }

    const onSaveMeta = async values => {
        if (values) {
            values['goal_date_apply']  = moment(values['goal_date_apply']).format("YYYY-MM-DD")
            let res = await saveMetaAction(values)
            if (res.success) {
                message.success(intl.formatMessage({ id: "item.saveSuccess" }))
                await getMetas()
            } else {
                message.error(intl.formatMessage({ id: "item.brand.element" }))
            }

            form.resetFields()
            setCurrentMeta(null)
        }
        console.log('Received values of form: ', values);
    };

    const onEditMeta = async values => {
        if (values) {
            values['goal_date_apply']  = moment(values['goal_date_apply']).format("YYYY-MM-DD")
            let req = {
                id: currentMeta.id,
                ...values
            }
            let res = await editMetaAction(req)
            if (res.success) {
                message.success(intl.formatMessage({ id: "item.editSuccess" }))
                await getMetas()
            } else {
                message.error(intl.formatMessage({ id: "item.brand.element" }))
            }

            form.resetFields()
            setCurrentMeta(null)
        }
        console.log('Received values of form: ', values);
    };

    const editMeta = (meta = null) => {
        setCurrentMeta(meta)
        if (meta) {
            form.setFieldsValue({
                goal: meta.goal,
                goal_date_apply: moment(meta.goal_date_apply),
                goal_type: meta.goal_type
            })
        }
        showModal(true)
    }

    const changePagination = (page) => {
        console.log("page", page);
        getMetas(page);
      };


      const onDeleteMeta = async () => {
        try {
            const res = await deleteMetaAction(forDelete.id)
            if (res.success) {
                message.success(intl.formatMessage({ id: "item.deleteSuccess" }))
                await getMetas()
                await closeModal()

            } else {
                message.error(intl.formatMessage({ id: "item.brand.registerErr" }))
                await closeModal()

            }
        } catch (e) {
            message.error(intl.formatMessage({ id: "item.brand.registerErr" }))
            await closeModal()
        }
    }

  return (
    <MainLayoutv2
        title={intl.formatMessage({ id: "services.metas" })}
        index="9.1"
        openKeys={["sub1"]}
      >
        <KModal
          title=""
          footer=""
          visible={isModalVisible}
          /* onOk={handleOk} */
          onCancel={handleCancel}
        >
            <Row>
                <Col span={24}>
                    <Title level={2} style={{ marginBottom: 0 }}>
                        <img
                        src={turn}
                        alt="logo"
                        style={{ height: 60, width: 30, transform: "scaleX(-1)", marginRight:10 }}
                        />
                        <FormattedMessage id={currentMeta ? "services.metas.upd": "services.metas.new" } />
                    </Title>
                </Col>
                <Col span={24}>
                    <Form
                        layout={"vertical"}
                        form={form}
                        onFinish={currentMeta ? onEditMeta : onSaveMeta}
                    >
                        <Form.Item
                            name={"goal_type"}
                            label={intl.formatMessage({ id: "services.metas.form.type" })}
                            rules={[{ required: true }]}
                        >
                            <KSelect
                                allowClear={true}
                                placeholder={'Elige'}
                                options={[
                                {value:'DAY',label:'Por día'},
                                {value:'MONTH',label:'Por mes'},
                                {value:'YEAR',label:'Por año'}
                                ]}
                            /> 
                        </Form.Item>
                        <Form.Item
                            name={"goal"}
                            label={intl.formatMessage({ id: "services.metas.form.value" })}
                            rules={[{ required: true }]}
                        >
                            <Input className='kinput' />
                        </Form.Item>
                        <Form.Item
                            name={"goal_date_apply"}
                            label={intl.formatMessage({ id: "services.metas.form.aplication_date    " })}
                            rules={[{ required: true }]}
                        >
                            <InputDate
                                format={"DD-MM-YYYY"}
                                style={{ width: "100%" }}
                                placeholder={intl.formatMessage({ id: "form.date" })}
                            />
                        </Form.Item>
                    </Form>
                </Col>
                <Col span={24} style={{ textAlign: "center" }}>
                    <Space>
                        <Button
                            size="large"
                            className="btn-string-blue btn-report-action"
                            onClick={() => handleCancel()}
                        >
                            <FormattedMessage id="layout.cancel" />
                        </Button>
                        <Button
                            className="btn-blue btn-report-action"
                            size="large"
                            onClick={() => handleOk()       }
                            >
                            <FormattedMessage id="services.members.create.save" />
                        </Button>
                    </Space>
                </Col>
            </Row>
        </KModal>

        <Content
          style={{ padding: "10px 10px", background: "white" }}
        >
            <KButton
                onClick={showModal}
                type="primary"
                style={{ float: "right", marginBottom: 20 }}
                size={"large"}
                text={<FormattedMessage id="layout.createNew" />}
            />
            <TabsConfig keyActive={'8'}/>
            <CustomTable
                loading={settings.fetching}
                dataSource={settings.metaList}
                columns={columns}
                pagination={{
                total: settings?.metaList?.count,
                pageSize: 25,
                onChange: changePagination,
                }}
            />
        </Content>
        <DeleteModal actionDelete={() => onDeleteMeta()} isVisible={modalDelete}
            loading={settings?.fetching}
            closeModal={closeModal}
            modalTitle={<FormattedMessage id="deleted.ask"/>}
            description={intl.formatMessage({ id:'deleted.description'},{ obj: forDelete && forDelete.goal_type })} />
    </MainLayoutv2>
  )
}

const mapStateToProps = state => ({
    settings: state.settings,
    permissions: state.permissions
})

export default injectIntl(connect(mapStateToProps,{getMetas, saveMetaAction, deleteMetaAction, editMetaAction})(MetasPage)) 