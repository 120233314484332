import {
  DislikeFilled,
  DislikeOutlined,
  LikeFilled,
  LikeOutlined,
  MailOutlined,
} from "@ant-design/icons";
import { Avatar, Comment, Tooltip, Typography } from "antd";
import moment from "moment";
import React, { createElement, useState } from "react";

const ItemChatService = ({ message, ...props }) => {
  const { Text } = Typography;

  const dateMessage = moment(message.timestamp)

  const MessageContent = () => (
    <div
      style={{
        background: "rgb(230, 230, 255)",
        padding: 20,
        borderRadius: 5,
      }}
    >
      <p>{message.comment}</p>
      {/* {send && (
        <span className="tangNotifyMail">
          <MailOutlined /> Enviado al cliente:{" "}
          <Text strong style={{ color: "white" }}>
            Alan Pacheco
          </Text>
        </span>
      )} */}
      {message && message.tagged_users.length > 0 && (
        <p style={{ paddingTop: 10 }}>
          Cc:{" "}
          {message.tagged_users.map((item) => (
            <span className="mentionInMessage">@{item.first_name}</span>
          ))}
        </p>
      )}
    </div>
  );

  const actions = [
    <Tooltip key="comment-basic-like" title="Like">
      <div
        style={{
          background: "var(--primaryColor)",
          padding: 5,
          borderRadius: 50,
          position: "relative",
          height: 30,
          width: 30,
          textAlign: "center",
          marginRight: 10,
        }}
      >
        <LikeFilled style={{ color: "white" }} />
        <div
          className="comment-action"
          style={{
            position: "absolute",
            bottom: "-5px",
            color: "black",
            backgroundColor: "white",
            right: 0,
            borderRadius: 50,
            fontSize: 10,
            padding: 1,
          }}
        >
          {1}
        </div>
      </div>
    </Tooltip>,
    <Tooltip key="comment-basic-dislike" title="Dislike">
      <div
        style={{
          background: "var(--secondaryColor)",
          padding: 5,
          borderRadius: 50,
          position: "relative",
          height: 30,
          width: 30,
          textAlign: "center",
        }}
      >
        <DislikeOutlined style={{ color: "white" }} />
        <div
          className="comment-action"
          style={{
            position: "absolute",
            bottom: "-5px",
            color: "black",
            backgroundColor: "white",
            right: 0,
            borderRadius: 50,
            fontSize: 10,
            padding: 1,
          }}
        >
          {0}
        </div>
      </div>
    </Tooltip>,
  ];

  return (
    <Comment
      className="itemMessage"
      /* actions={actions} */
      author={
        <Text strong style={{ fontSize: 15 }}>
          {message.created_by?.first_name} {message.created_by?.last_name}
        </Text>
      }
      avatar={
        <Avatar
          src={message.created_by?.userprofile?.image_profile}
          alt="User"
        />
      }
      content={<MessageContent />}
      datetime={
          <span>{dateMessage.format('DD/MM/YYYY hh:mm a')}</span>
      }
    />
  );
};

export default ItemChatService;
