import React, { useState, useEffect } from 'react'
import _ from "lodash"
import styles from '../bussiness.module.css'
import BussinessCompanyLayout from "../../../layouts/BussinessCompanyLayout";
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux'
import { Button, Space, Row, Col, Spin } from 'antd';
import { injectIntl } from 'react-intl';
import { axiosApi, API } from "../../../lib/utils/axiosApi"
import { setConfigCompanyBranch } from "../../../redux/settingsDuck";
import { getPermissionsConfig } from "../../../redux/permissionsDuck";
import useResponsive from "../../../hooks/useResponsive"
import { useHistory } from "react-router-dom"

const CompanyPage = ({ settings, security, intl, setConfigCompanyBranch, getPermissionsConfig }) => {

    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [companies, setCompanies] = useState(null)
    const {isMobile, isTablet} = useResponsive();

    const style = {
        CompanyButton: {
            Maxwidth: 400,
            height: 80,
            fontSize: 25,
            border: "solid 2px"
        }
    }

    const getCompany = async () => {
        setLoading(true)
        try {
            console.log('Aqui intentod jalar empresa')
            const res = await axiosApi.get(API.BUSINESS.BUSINESS_SELECT_COMPANY)
            console.log(res)
            if (res.data.results) {
                console.log('companies==>',res.data.results)
                setCompanies(res.data.results)
                if(res.data.results.length  === 1){
                    onClick(res.data.results[0], true);
                }
            }
        } catch (e) {

        } finally {
            setLoading(false)
        }
    }


    useEffect(()=>{
        const groupId = _.get(security, 'user.user.group.id')
        if (groupId){
            getPermissionsConfig(security.user.user.group.id)
        }
    },[])


    const onClick = async (company, unique=false) => {
        try {
            await setConfigCompanyBranch('company', company)
            history.push(`/branch${unique?'?unique':''}`);

        } catch (e) {

        }

    }

    useEffect(() => {
        getCompany()
    }, [])

    return (
        <BussinessCompanyLayout loading={loading} className={styles.container}>
            <div style={{ height: "100%", width: "100%" }}>
            <Row justify="center" align="bottom" style={{ height: "100%" }}>
                <Col >
                <Row justify="center">
                    <Col>
                    <h1 style={{ fontSize: 30, fontFamily: 'Sharp Grotesk' }}>
                    <FormattedMessage id="bussiness.branch.chooseBussines" />
                </h1>

                {
                    companies ? companies.map((item, i) => {
                        return (
                        
                        <Row 
                        key={i} 
                        align="middle" 
                        style={{ paddingBottom: 20 }}>
                            <Col xs={24} md={24}>
                                <Button 
                                onClick={() => onClick(item)} 
                                style={style.CompanyButton} 
                                block>
                                    {item.name}
                                    </Button>
                            </Col>
                        </Row>
                        
                        )
                    }) : null
                }

                    </Col>
                </Row>
                

                
                </Col>
            

            </Row>

                
            </div>
        </BussinessCompanyLayout>
    )
}

const mapStateToProps = state => ({
    settings: state.settings,
    security: state.security,
})

export default injectIntl(connect(mapStateToProps, { setConfigCompanyBranch, getPermissionsConfig })(CompanyPage))
