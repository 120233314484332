import React, { useState, useEffect } from 'react'
import {useSelector} from "react-redux";
import _ from 'lodash'



const useSettingsKikert=()=>{
    const settingsKikert = useSelector(state => state.settings);

    const getMetaValue=(meta, defaultValue=null)=>{
        let metas = settingsKikert?.generalInfoMeta?.results;
        if(metas){
            const foundValue = _.find(metas,{key:meta})?.value 
            return foundValue ? foundValue : defaultValue
        }else{
            return defaultValue
        }
    }


    return {
        settingsKikert,
        getMetaValue
    }


}

export default useSettingsKikert;