import React, { useState, useEffect } from 'react'
import {Space, Table, Tag, Tooltip, Button, Col, Input, Alert, Form, Popconfirm} from "antd";
import {
    FolderFilled,
    HomeFilled,
    DeleteOutlined,
    StarOutlined,
    FolderAddOutlined,
    FileOutlined,
    UploadOutlined,
    EditOutlined,
    StarFilled,
    MoreOutlined,
    InfoCircleOutlined
} from "@ant-design/icons";
import moment from 'moment'
import { useHistory } from "react-router-dom";
import { Menu, Breadcrumb, Drawer, List,Select, Avatar,Divider } from 'antd';
import DriveApi from "../api/DriveApi";
import KModal from "../components/common/elements/KModal/KModal";
import KButton from "../components/common/elements/KButton/KButton";
import DragAndDrop from "../components/DragAndDrop";
import {convertirBytes} from "../lib/utils/utils";
import { CustomTable } from '../components/KComponents';
const { TextArea } = Input;

const useDriveTable = (parentID, search=null) => {

    const [loadingFiles,setLoadingFiles] = useState(false)
    const [loading,setLoading] = useState(false)
    const [loadingInfo,setLoadingInfo] = useState(false)
    const [files,setFiles] = useState(false)
    let history = useHistory();
    const [openInfo, setOpenInfo] = useState(false);
    const [responseDataFiles,setResponseDataFiles] = useState(null)
    const [fileList,setFileList] = useState([])
    const [pathFile,setPathFile] = useState(null)
    const [currentPath,setCurrentPath] = useState(null)
    const [tags,setTags] = useState([])
    const [currentPage,setCurrentPage] = useState(1)
    const [currentFileSelected,setCurrentFileSelected] = useState(null)
    const [visibleModalCreateFolder,setVisibleModalCreateFolder] = useState(false)
    const [visibleModalEdit,setVisibleModalEdit] = useState(false)
    const [visibleModalCreateFile,setVisibleModalCreateFile] = useState(false)
    const [formEdit] = Form.useForm();
    //const [fileInfo, setFileInfo] = useState(null)


    const showDrawerInfo = (file,idFile) => {
        try{

        }catch (e){

        }
        setOpenInfo(true);
    };

    const onCloseInfo = () => {
        setOpenInfo(false);
    };

    const handleChangeTag = (value=[]) => {
        setTags(value);
    };


    const editFileInfoDetail=async (values)=>{
        values.description = values?.description || '';
        setLoadingInfo(true)
        try{
            const res = await DriveApi.updateFile(currentFileSelected.id, values)
        }catch (e){
            console.log('error',e)
        }finally {
            onCloseInfo()
            setLoadingFiles(false)
            setLoading(false)
            setLoadingInfo(false)
            getFileList(currentPage)
        }


    }

    const DrawerInfo=()=>{
        return <Drawer
            title={
                `${currentFileSelected?.name ? currentFileSelected?.name.toUpperCase() : ''}`
            }
            width={500}
            onClose={onCloseInfo}
            visible={openInfo}
            bodyStyle={{paddingBottom: 80}}
        >
            <p style={{margin:0,padding:0}}><b>Propietario</b></p>
            <span>{currentFileSelected?.created_by?.first_name} {currentFileSelected?.created_by?.last_name}</span>

            <p style={{margin:0,padding:0,marginTop:5}}><b>Creado</b></p>
            <span>{currentFileSelected?.timestamp ?  moment(currentFileSelected?.timestamp).format('LLL') : '--'}</span>

            <Divider />

            <Form
                onFinish={editFileInfoDetail}
                layout="vertical"
            >

                <Form.Item
                    name={'description'}
                    label={'Descripción'}
                >
                    <TextArea rows={4} style={{marginBottom:5}} />
                </Form.Item>

                <KButton type={'default'} loading={loadingInfo} onClick={onCloseInfo} text={'Cancelar'}/>
                <KButton type={'primary'} loading={loadingInfo}  htmlType={'submit'} text={'Guardar'}/>

            </Form>

        </Drawer>
    }


    const onFinishCreateFolder=async (values)=>{
        setLoading(true)
        try{
            const res = await DriveApi.createFolder(values.name,values?.description?values?.description:'',parentID)
            setVisibleModalCreateFolder(false)
            getFileList()
        }catch (e){
            console.log('folder',e)
        }finally {
            setLoading(false)
        }
    }

    const onFinishEdit=async (values)=>{
        setLoadingFiles(true)
        setLoading(true)
        try{
            const res = await DriveApi.editNameDescription(currentFileSelected.id, values.name, values.description)
            getFileList()
            setVisibleModalEdit(false)
        }catch (e){
            console.log('error',e)
        }finally {
            setLoadingFiles(false)
            setLoading(false)
        }
    }



    const uploadFile=async ()=>{
        setLoading(true)
        try{
            let f = fileList[0]
            const res = await DriveApi.createFile(f.name,f,parentID)
            setVisibleModalCreateFile(false)
            getFileList()
        }catch (e){
        }finally {
            setLoading(false)
        }
    }

    const getFileList=async (page=1,search)=>{
        setLoadingFiles(true)
        try{
            let filters = `${parentID?`?parent=${parentID}`:'?depth=1'}&page=${page}`;
            const res = await DriveApi.getFileList(search?`?search=${search}`:filters)
            if(res?.data?.results){
                setFiles(res?.data?.results)
                setPathFile(res?.data?.path_to_file)
                setResponseDataFiles(res?.data)
                setCurrentPage(res?.data?.page)
            }
        }catch (e){
            console.log('error',e)
        }finally {
            setLoadingFiles(false)
        }
    }

    const IconFile=({file})=>{
        if(file.is_folder){
            return <FolderFilled />
        }else{
            return <FileOutlined />
        }
    }


    const clickFile=(file)=>{
        if(file.is_folder){
            setCurrentPath(`/drive?parent=${file.id}`)
            history.push(`/drive?parent=${file.id}`)
        }else{
            window.open(file.file, '_blank');
        }
    }

    const deleteFile=async (idElement)=>{
        setLoadingFiles(true)
        try{
            const res = await DriveApi.deleteElement(idElement)
            getFileList(currentPage)
        }catch (e){
            console.log('error',e)
        }finally {
            setLoadingFiles(false)
        }
    }

    const addRemoveFavorite=async (idElement, isFavorite)=>{
        setLoadingFiles(true)
        try{
            const res = await DriveApi.addRemoveFavorite(idElement, isFavorite)
            getFileList(currentPage)
        }catch (e){
            console.log('error',e)
        }finally {
            setLoadingFiles(false)
        }
    }



    const columns = [
        {
            title: 'Nombre',
            key: 'name',
            render: (item) =>   <a style={{fontSize:'15px',color:item.is_folder?'black':'black' }} 
            onClick={()=>clickFile(item)}> 
                <IconFile file={item}/> {item.name}
            </a>,
        },
        {
            title: 'Descripción',
            dataIndex: 'description',
            key: 'description',
            render: (text) => <span style={{fontSize:'15',color:'gray'}}>{text}</span>,
        },
        {
            title: 'Tamaño',
            key: 'size',
            render: (item) => <span> {item.is_folder?'':convertirBytes(item.size)} </span>,
        },
        {
            title: 'Creado',
            key: 'created',
            render: (item) => <span> {item.timestamp?moment(item.timestamp).format('lll'):'--'}</span>,
        },
        {
            title: 'Propietario',
            key: 'created_by.first_name',
            render: (item) => <span> {item?.created_by?.first_name} {item?.created_by?.last_name}</span>,
        },
        {
            title: 'Acciones',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Tooltip placement="bottom" title={'Editar nombre'}>
                    <Button onClick={()=>{
                        setVisibleModalEdit(true)
                        setCurrentFileSelected(record)
                        formEdit.setFieldsValue({
                            name: record.name,
                            description: record.description
                        })
                        
                        }} size={'small'} type={'text'} >
                        <EditOutlined />
                    </Button>
                    </Tooltip>
                    <Tooltip placement="bottom" title={'Eliminar'}>
                    <Popconfirm onConfirm={()=>deleteFile(record.id) } okText="Confirmar" cancelText="Cancelar" title={'¿Deseas eliminar este elemento?'}>
                        <Button size={'small'} type={'text'} >
                            <DeleteOutlined />
                        </Button>
                    </Popconfirm>
                    </Tooltip>

                    <Tooltip placement="bottom" title={'Detalle'}>
                    <Button size={'small'} onClick={()=> {
                        setCurrentFileSelected(record)
                        showDrawerInfo(record.id)
                    }} type={'text'}>
                        <InfoCircleOutlined />
                    </Button>
                    </Tooltip>
                <Tooltip placement="bottom" title={record?.is_favorite ? 'Quitar de destacados': 'Agregar a destacados'}>
                        <Button size={'small'} onClick={()=>addRemoveFavorite(record?.id, !record?.is_favorite)} type={'text'}>
                            {
                                record.is_favorite ? <StarFilled style={{color:'#f68f49'}} /> : <StarOutlined />
                            }
                        </Button>
                </Tooltip>
                </Space>


            ),
        },
    ];


    const changePagination = (page) => {
        getFileList(page);
    }

    const showTotal = (total) => `Total ${total} elementos`;

    const TableDrive=()=>{
        return <CustomTable 
        loading={loadingFiles}
        dataSource={files}
        fixed={true}
        scroll={{ x: 1500 }}
        columns={columns} 
        pagination={{
            total: responseDataFiles?.count,
            pageSize: 25,
            showSizeChanger:false,
            pageSizeOptions:[],
            showTotal:showTotal,
            showQuickJumper:true, 
            current:responseDataFiles?.page,
            onChange: changePagination
          }} 
         />
    }

    const PathFiles=()=>{
        return <Breadcrumb>
            <Breadcrumb.Item><a onClick={()=>history.push(`/drive`)}><HomeFilled /> Home {search && `/ Búsqueda:${search} `}</a> </Breadcrumb.Item>
            {
                pathFile && pathFile.length>0 ? pathFile.map((ele)=>{
                    return <Breadcrumb.Item>
                        <a onClick={()=>history.push(`/drive?parent=${ele.id}`)}>{ele?.name}</a>
                    </Breadcrumb.Item>
                }) : null
            }
        </Breadcrumb>
    }


    const MenuDrive=()=>{
        return <>
            <KModal
                title={"Subir archivo"}
                onCancel={()=> {
                    setVisibleModalCreateFile(false)
                    setFileList([])
                }}
                loading={loading}
                visible={visibleModalCreateFile}>
                <Col span={24} style={{ textAlign: "center"}}>
                <Alert message="Puedes subir un sólo archivo a la vez, próximamente habilitaremos la funcionalidad múltiple." type="info" />
                    <DragAndDrop isMultiple={false} fileList={fileList} setFileList={setFileList} />
                    <br/>
                    <KButton type={'secondary'} text={'Cancelar'} onClick={()=> {
                        setFileList([])
                        setVisibleModalCreateFile(false)
                    }}/>
                    <KButton type={'primary'} text={'Subir'} onClick={()=>uploadFile()}/>
                </Col>

            </KModal>
            <KModal
                title={"Carpeta nueva "}
                onCancel={()=> setVisibleModalCreateFolder(false)}
                loading={loading}
                visible={visibleModalCreateFolder}>
                <Col span={24} style={{ textAlign: "center"}}>
                    <Form
                    onFinish={onFinishCreateFolder}
                    layout="vertical"
                    >
                        <Form.Item
                            label="Nombre"
                            name="name"
                            rules={[{ required: true, message: 'Agrega un nombre' }]}
                        >
                            <Input className={'kinput'}/>
                        </Form.Item>
                        {/*<Form.Item name={'description'} label="Descripción">*/}
                        {/*    <Input.TextArea  className={'kinput'}/>*/}
                        {/*</Form.Item>*/}
                        <KButton type={'secondary'} text={'Cancelar'} onClick={()=>setVisibleModalCreateFolder(false)}/>
                        <KButton type={'primary'} text={'Crear carpeta'} htmlType={'submit'}/>
                    </Form>
                </Col>
            </KModal>
            <KModal
                title={"Editar"}
                onCancel={()=> setVisibleModalEdit(false)}
                loading={loading}
                visible={visibleModalEdit}>
                <Col span={24} style={{ textAlign: "center"}}>
                    <Form
                    onFinish={onFinishEdit}
                    layout="vertical"
                    form={formEdit}
                    >
                        <Form.Item
                            label="Nombre"
                            name="name"
                            rules={[{ required: true, message: 'Agrega un nombre' }]}
                        >
                            <Input className={'kinput'}/>
                        </Form.Item>
                        <KButton type={'secondary'} text={'Cancelar'} onClick={()=>setVisibleModalEdit(false)}/>
                        <KButton type={'primary'} text={'Editar'} htmlType={'submit'}/>
                    </Form>
                </Col>
            </KModal>
            <Menu mode="horizontal" selectable={false} style={{backgroundColor:'white',lineHeight:'41px'}} >
                <Menu.SubMenu key="SubMenu" title="Acciones" icon={<MoreOutlined />}>
                    <Menu.Item key="addfolder" onClick={()=>setVisibleModalCreateFolder(true)} icon={<FolderAddOutlined />}>
                        Crear Carpeta
                    </Menu.Item>
                    <Menu.Item key="addfile" onClick={()=> {
                        setVisibleModalCreateFile(true);
                        setFileList([])
                    }} icon={<UploadOutlined />}>
                        Subir archivo
                    </Menu.Item>
                </Menu.SubMenu>
            </Menu>
        </>
    }

    return {
        TableDrive,
        MenuDrive,
        DrawerInfo,
        getFileList,
        files,
        loadingFiles,
        PathFiles
    }

}

export default useDriveTable;