import { Avatar, Table, Typography } from 'antd'
import React from 'react'
import {UserOutlined} from '@ant-design/icons';
import styled from 'styled-components';
import { injectIntl, FormattedMessage } from 'react-intl';

const CustomTable = styled(Table)`

    .ant-table-thead{
        border: solid 2px var(--secondaryColor);
    }
    .ant-table-thead th:first-child{
        border-radius:10px 0 0 10px;
    }

    .ant-table-thead > tr > th {
        color: #341eff;
        white-space: nowrap;
    }
    .ant-table-cell{
        background: none;
    }
    
    table th:nth-child(2){
        border-top: solid 2px var(--secondaryColor);
        border-bottom: solid 2px var(--secondaryColor);
    }

    table th:first-child{
        border-radius: 10px 0 0 10px !important;
        border-top: solid 2px var(--secondaryColor);
        border-bottom: solid 2px var(--secondaryColor);
        border-left: solid 2px var(--secondaryColor);
    }

    table th:last-child{
        border-radius: 0 10px 10px 0 !important;
        border-top: solid 2px var(--secondaryColor);
        border-bottom: solid 2px var(--secondaryColor);
        border-right: solid 2px var(--secondaryColor);
    }
    
`


const MembersList = ({members, intl, ...props}) => {
    console.log('members',members);

    const {Text} = Typography

    const columns = [
        {
            title: '',
            key:'name',
            width: 60,
            render: (row) => (
                <Avatar icon={<UserOutlined />} />
            )
        },
        {
            title: 'Nombre',
            key:'name',
            render: (row) => <Text>
                {row.first_name+" "+row.last_name}
            </Text>
        },
        {
            title: 'Email',
            key: 'email',
            dataIndex: 'email',
            align: 'center'
        }
    ]
  return (
    <CustomTable 
        pagination={false} 
        columns={columns} 
        dataSource={members} 
        locale={{
            emptyText: intl.formatMessage({ id: "no_results_found" }),
        }}
    />
  )
}


export default injectIntl(MembersList)