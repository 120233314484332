import React, { useState, useEffect } from 'react'
import {FormattedMessage, injectIntl} from "react-intl";
import {
    Layout,
    Card,
    Row,
    Col,
    Input,
    Radio,
    Form,
    message,
    Space, Tag, Typography, DatePicker, Divider
} from 'antd'
import { connect } from 'react-redux'
import MainLayoutv2 from "../../layouts/MainLayoutv2";
import KButton from "../../components/common/elements/KButton/KButton";
import {FileExcelOutlined, UpOutlined,
    DownOutlined, PauseOutlined} from "@ant-design/icons";
import { getSupplierAction } from "../../redux/settingsDuck";
import {axiosApi, API} from "../../lib/utils/axiosApi";
import {CustomTable} from "../../components/KComponents";
import NumberFormat from "../../components/common/NumberFormat";
import moment from "moment/moment";
import {twoDecimal} from "../../lib/utils/rules";
import SelectSuppliers from "../../components/Selects/SelectSuppliers";
import locale from "../../locale/es_mx.json";
import SelectBudgetReceivers from "../../components/Selects/SelectBudgetReceivers";
import KModal from "../../components/common/elements/KModal/KModal";
import {useHistory} from "react-router-dom";
import {downloadFile} from "../../lib/utils/utils";

const { Search } = Input;

const { RangePicker } = DatePicker;

const ServicePartsPage=({intl,getSupplierAction, ...props})=>{
    const [count, setCount] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingReport, setLoadingReport] = useState(false);
    const [parts, setParts] = useState(null);
    const [searchText, setSearchText] = useState('');
    const [editObj, setEditObj] = useState(false)
    let history = useHistory();
    const [form] = Form.useForm();
    const [pagination, setPagination] = useState({
        showSizeChanger: true,
        current: 1,
        pageSize: 10,
    });
    const [showModal, setShowModal] = useState(false)
    const [statusFilter, setStatusFilter] = useState('');
    const [statusPartFilter, setStatusPartFilter] = useState('');
    const [statusValuationFilter, setStatusValuationFilter] = useState('');
    const [startDateFilter, setStartDateFilter] = useState();
    const [endDateFilter, setEndDateFilter] = useState();

    const priorityList = [
        { value:1, label:  ` Alta`},
        { value:2, label:' Media'},
        { value:3, label:' Baja'}
    ]

    const options = [
        { label: 'Todos', value: '' },
        { label: 'Abiertos', value: 'OPEN' },
        { label: 'Cerrados', value: 'CLOSED' },
      ];

      const statusParts = [
        { label: 'Todos', value: '' },
        { label: 'Pendientes', value: 'pending' },
        { label: 'Entregados', value: 'delivered' },
      ];
    
      const statusValuation = [
        { label: 'Todos', value: '' },
        { label: 'Pendientes', value: 'pending' },
        { label: 'Autorizados', value: 'auth' },
        { label: 'Rechazados', value: 'rejected' },
      ];
    
    const onChangeFilterRadio = ({ target: { value } }) => {
        //console.log('radio3 checked', value);
        setStatusFilter(value);
    };

    const onChangeFilterPartStatusRadio = ({ target: { value } }) => {
        //console.log('radio3 checked', value);
        setStatusPartFilter(value);
    };

    const onChangeFilterValuationStatusRadio = ({ target: { value } }) => {
        //console.log('radio3 checked', value);
        setStatusValuationFilter(value);
    };

    const onChangeFilterDates = (event) => {
        //console.log('radio3 checked', value);
        //setStatusFilter(value);
        if(!event){
            setStartDateFilter(null)
            setEndDateFilter(null)
        }else{
            setStartDateFilter(event[0])
            setEndDateFilter(event[1])
        }
        
    };


    useEffect(()=>{
        getGlobalParts()
    }, [startDateFilter, endDateFilter, statusFilter, statusPartFilter, searchText, statusValuationFilter])


    const columns =  [
        {
            title: 'Servicio',
            key: 'service',
            render:(item)=>{
                return <Typography.Link onClick={()=>history.push(`/service-parts/${item?.order?.id}`)}>
                    {item?.order?.folio}
                </Typography.Link>
            }
        },
        {
            title: "Prioridad ",
            dataIndex: "priority",
            key: "priority",
            render: (item) => {
                let priority = priorityList.find((element) => element.value === item);
                return <span>
                     {
                         item === 1 ? <UpOutlined style={{color: 'red'}}/> :
                             item === 3 ? <DownOutlined style={{color: 'blue'}}/> :
                                 <PauseOutlined rotate={90} style={{color: 'orange'}}/>
                     }
                    {
                        priority.label
                    }
                </span>
            }
        },
        {
            title: 'Estatus valuación',
            key: 'status',
            render:(item)=>{
                if(item.status==="PENDING"){
                    return <p><Tag color="gold">Pendiente</Tag></p>
                }else if(item.status==="AUTH"){
                    return <p><Tag color="green">Autorizado</Tag></p>
                }else{
                    return <p><Tag color="red">Rechazado</Tag></p>
                }
                    
            }
        },
        {
            title: 'Estatus entrega',
            key: 'status',
            render:(item)=>{
                return <p>{item.delivered_to ? <Tag color="green">Entregado</Tag> : <Tag color="gold">Pendiente</Tag>}</p>
            }
        },
        {
            title: 'Refacción',
            dataIndex: 'concept',
            key: 'concept',
        },
        {
            title: 'Cantidad',
            dataIndex: 'qty',
            key: 'qty'
        },
        {
            title: 'Costo unitario',
            dataIndex: 'cost',
            key: 'cost',
            render:(data)=>{
                return <NumberFormat number={data} prefix={'$'}/>
            }
        },
        {
            title: 'Precio',
            dataIndex: 'price',
            key: 'price',
            render:(data)=>{
                return <NumberFormat number={data} prefix={'$'}/>
            }
        },
        {
            title: 'Proveedor',
            key: 'supplier',
            render:(record)=>{
                return <span>{record?.supplier?.name}</span>
            }
        },
        {
            title: 'Fecha promesa',
            dataIndex: 'delivery_promise_date',
            key: 'delivery_promise_date',
            render:(data)=>{
                return <span>{data && moment(data, 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>
            }
        },
        {
            title: 'Fecha recepción',
            dataIndex: 'reception_date',
            key: 'reception_date',
            render:(data)=>{
                return <span>{data && moment(data, 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>
            }
        },
        {
            title: 'Fecha real de entrega',
            dataIndex: 'delivery_real_date',
            key: 'delivery_real_date',
            render:(data)=>{
                return <span>{data && moment(data, 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>
            }
        },
        {
            title: 'Entregado a',
            key: 'delivered_to',
            render:(record)=>{
                return <span>{record?.delivered_to?.first_name} {record?.delivered_to?.last_name}</span>
            }
        },
        {
            title: 'Acciones',
            dataIndex: 'operation',
            render: (item,record) => {
                return <Typography.Link onClick={()=>editData(record)}>
                    Editar
                </Typography.Link>
            },
        }
    ]

    const changePagination = (data) => {
        getGlobalParts(searchText,data.current)
    }

    useEffect(()=>{
        getGlobalParts()
        getSupplierAction(1, null, 1000)
    },[])

    const editData=(item)=>{
        setShowModal(true)
        setEditObj(item)
        form.resetFields()

        if(item.supplier){
            form.setFieldsValue({
                supplier:item.supplier.id
            })
        }

        if(item.cost){
            form.setFieldsValue({
                cost:parseFloat(item.cost)
            })
        }

        if(item.delivery_real_date){
            form.setFieldsValue({
                delivery_real_date:moment(item.delivery_real_date)
            })
        }

        if(item.reception_date){
            form.setFieldsValue({
                reception_date: moment(item.reception_date)
            })
        }


        if(item.delivery_promise_date){
            form.setFieldsValue({
                delivery_promise_date: moment(item.delivery_promise_date)
            })
        }

        if(item.delivered_to){
            form.setFieldsValue({
                delivered_to: item?.delivered_to?.id
            })
        }

    }

    const getRangeDatesFilter = () => {
        const formatDate = 'YYYY-MM-DD'
        const date_range = startDateFilter && endDateFilter ? startDateFilter.format(formatDate) + ',' + endDateFilter.format(formatDate) : ''
        return date_range
    }

    const getGlobalParts=async(search='',page=1)=>{
        setLoading(true)
        try{
            const res = await axiosApi.get(
                `${API.WORKSHOP.WORKSHOP_GLOBAL_PARTS}&search=${searchText}&status=${statusFilter}&delivery_status=${statusPartFilter}&val_status=${statusValuationFilter}&date_range=${getRangeDatesFilter()}&page=${page}&page_size=10`
            )
            setParts(res?.data?.results)
            setPagination({
                ...pagination,
                total: res.data.count,
                current: page,
                pageSize: 10,
            });
            console.log('respuesta',res?.data?.results)
        }catch (e){
            console.log(e)
        }finally {
            setLoading(false)
        }
    }

    const editPart=async (idPart, data)=>{
        setLoading(true)
        try{
            const resp = await axiosApi.patch(
                `${API.WORKSHOP.WORKSHOP_BUDGET }${idPart}/`,
                data
            );
            message.success('Actualizado correctamente')
            getGlobalParts(searchText);
        }catch (e){
            message.error('Ha ocurrido un error, porfavor intenta nuevamente')
        }finally {
            setShowModal(false)
            setLoading(false)
        }

    }

    const onFinish = (values) => {
        let data = {...values}
        if(data.delivery_promise_date){
            data.delivery_promise_date = moment(data.delivery_promise_date).format('YYYY-MM-DD')
        }

        if(data.reception_date){
            data.reception_date = moment(data.reception_date).format('YYYY-MM-DD')
        }

        if(data.delivery_real_date){
            data.delivery_real_date = moment(data.delivery_real_date).format('YYYY-MM-DD')
        }
        if(!data.delivered_to){
            data.delivered_to = null
        }

        if(!data.supplier){
            data.supplier = null
        }

        editPart(editObj.id, data)
        console.log('Success:', data);
    };

    const getReport = async()=>{
        setLoadingReport(true)
        try{
            let url =`/workshop/budget/download_excel/?job_type__code=PARTS&search=${searchText}&status=${statusFilter}&val_status=${statusValuationFilter}&delivery_status=${statusPartFilter}&date_range=${getRangeDatesFilter()}`;
            console.log('URL', url)
            const res = await  axiosApi.get(`${url}`,{responseType: 'blob'})
            downloadFile(res.data,`report_refacciones`,'application/vnd.ms-excel')
            console.log(res)
        }catch (e){
            console.log(e)
        }finally {
            setLoadingReport(false)
        }
    }



    return(
        <MainLayoutv2 
        title={intl.formatMessage({ id: "servicesDetails.parts.request" })} 
        index="parts">
            <Layout style={{ marginTop: 10 }}>
                <Row gutter={[10]} justify="space-between" style={{ marginTop: 20 }}>
                    <Col flex="auto">
                        <Form size="large">
                            <Form.Item extra={` ${'    '}Total elementos de página actual: ${parts ?parts.length:0}`} className="pt-0">
                                <Search
                                    size="large"
                                    onSearch={(value)=>{
                                        setSearchText(value)    
                                    }}
                                    allowClear
                                    placeholder={intl.formatMessage({ id: 'servicespart.searchtext' })}
                                    className="kinput-search"
                                    style={{ height: 50 }}
                                />

                            </Form.Item>
                            <Form.Item
                                label={'Rango de fechas (Fecha creación de orden)'}
                                name={'created_at'}
                                className="item-expediente-lg mb-0 label-margin"
                            >
                                <RangePicker size="small" locale={locale} onChange={onChangeFilterDates} />
                            </Form.Item>
                            <Form.Item
                                label={'Estatus de la orden'}
                                name={'status'}
                                className="item-expediente-lg mb-0 label-margin"
                            >
                                <Radio.Group options={options} defaultValue='' onChange={onChangeFilterRadio} size="small" value={statusFilter} optionType="button" />
                            </Form.Item>
                            <Form.Item
                                label={'Estatus de refacciones'}
                                name={'status_part'}
                                className="item-expediente-lg mb-0 label-margin"
                            >
                                <Radio.Group options={statusParts} defaultValue='' onChange={onChangeFilterPartStatusRadio} size="small" value={statusPartFilter} optionType="button" />
                            </Form.Item>
                            <Form.Item
                                label={'Estatus de valuación'}
                                name={'status_valuation'}
                                className="item-expediente-lg mb-0 label-margin"
                            >
                                <Radio.Group options={statusValuation} defaultValue='' onChange={onChangeFilterValuationStatusRadio} size="small" value={statusValuationFilter} optionType="button" />
                            </Form.Item>

                        </Form>
                    </Col>
                    <Col>
                        <Space direction="horizontal">
                            {/*<KButton size="large" icon={<ControlOutlined />} text={<FormattedMessage id="services.page.filter" />} type={'outline'} />*/}
                            <KButton size="large" loading={loadingReport} onClick={()=>getReport()} icon={<FileExcelOutlined />} text={<FormattedMessage id="download" />}  />
                        </Space>
                    </Col>
                </Row>

                <Card className="card_details_service">
                    <CustomTable
                        fixed={true}
                        scroll={{ x: 1500 }}
                        columns={columns}
                        dataSource={parts ? parts : []}
                        loading={loading}
                        pagination={pagination}
                        onChange={changePagination}
                    />
                </Card>

                <KModal title={`${editObj.concept}`} onCancel={()=>setShowModal(false)} visible={showModal}>
                    <Row>
                        <Col span={24}>
                            <Form
                                name="basic"
                                onFinish={onFinish}
                                autoComplete="off"
                                form={form}
                                layout={'vertical'}
                            >
                                <Form.Item name={'cost'} label="Costo" rules={[twoDecimal]}>
                                    <Input  className={'kinput'} prefix={'$'}/>
                                </Form.Item>

                                <SelectSuppliers style={{marginBottom:20}} allowClear={true} name={'supplier'} label="Proveedor" />



                                <Form.Item name={'delivery_promise_date'} label="Fecha promesa de entrega">
                                    <DatePicker
                                        locale={locale}
                                        className={'kinput'}
                                        style={{width:'100%'}}
                                        format={'DD/MM/YYYY'}
                                    />
                                </Form.Item>
                                <Form.Item name={'reception_date'} label="Fecha de recepción en taller">
                                    <DatePicker
                                        locale={locale}
                                        className={'kinput'}
                                        style={{width:'100%'}}
                                        format={'DD/MM/YYYY'}
                                    />
                                </Form.Item>
                                <Divider>Entrega de parte</Divider>
                                <Form.Item name={'delivery_real_date'} label="Fecha real de entrega">
                                    <DatePicker
                                        locale={locale}
                                        className={'kinput'}
                                        style={{width:'100%'}}
                                        format={'DD/MM/YYYY'}
                                    />
                                </Form.Item>
                                <SelectBudgetReceivers extra={'Para marcar la pieza como entregada tendrá que elegir un usuario de esta lista.'} allowClear={true} name={'delivered_to'} label="Entregado a" />


                                <Form.Item style={{marginTop:20}}>
                                    <KButton loading={loading} text={'Cancelar'} onClick={()=>setShowModal(false)} type={'secondary'} />
                                    <KButton loading={loading} text={'Aceptar'} htmlType={'submit'} type={'Primary'} />
                                </Form.Item>

                            </Form>

                        </Col>
                    </Row>

                </KModal>


            </Layout>
        </MainLayoutv2>
    )
}

const mapStateToProps = state => ({
    permissions:state.permissions
})

export default injectIntl(connect(mapStateToProps, {
    getSupplierAction
})(ServicePartsPage));