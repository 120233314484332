import React, { useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";
import DashboardLayout from "../../../layouts/DashboardLayout";
import DeleteModal from "../../../components/modal/DeleteModal";
import {
  Button,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Space,
  Layout,
  Checkbox,
  DatePicker,
  message,
  Steps,
  Typography,
  Skeleton,
  Select,
  Card,
  Spin,
  Collapse,
  Table,
  Switch,
} from "antd";
import {
  SearchOutlined,
  SaveOutlined,
  RightOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { FormattedMessage, injectIntl } from "react-intl";
import { changeLangAction } from "../../../redux/settingsDuck";
import { connect } from "react-redux";
import {
  getFinancialPlans,
  getCarAvailabilityList,
} from "../../../redux/salesDuck";
import {
  getWorkFlow,
  getHistory,
  changeVisible,
} from "../../../redux/workflowDuck";
import axiosApi, { API } from "../../../lib/utils/axiosApi";
import { Global, css } from "@emotion/react";
import StatusServiceModal from "../../../components/modal/StatusServiceModal";
import CardPurpleLight from "../../../components/common/elements/CardPurpleLight/CardPurpleLight";
import ServiceButtonsHeader from "../../../components/services/ServiceButtonsHeader";

const { Title, Text } = Typography;
const { Step } = Steps;
const { Panel } = Collapse;

const TrackingServiceOrderPage = ({
  sales,
  getFinancialPlans,
  getCarAvailabilityList,
  getWorkFlow,
  getHistory,
  workflow,
  changeLangAction,
  intl,
  settings,
  changeVisible,
}) => {
  const { Meta } = Card;

  const rulesRequired = [
    {
      required: true,
    },
  ];

  const validateMessages = {
    required:
      "${label}" + intl.formatMessage({ id: "services.saleOrder.obligatory" }),
    types: {
      email: "${label} no es un email válido",
      number: "${label} no es un número válido",
    },
    number: {
      range: "${label} debe ser entre ${min} y ${max}",
    },
  };

  // Constante form method
  const [form] = Form.useForm();
  const { Item } = Form;
  const history = useHistory();

  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [saleOrder, setSaleOrder] = useState(null);
  const [initialValues, setInitialValues] = useState();
  const [isCreditPay, setIsCreditPay] = useState(false);
  const [workflowSteps, setWorkFlowSteps] = useState([]);
  const [workflowLogs, setWorkFlowLogs] = useState([]);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [saleType, setSaleType] = useState(null);
  const [openModalStatus, setOpenModalStatus] = useState(false);
  const [showHisory, setShowHistory] = useState([]);
  const [spinStatus, setSpinStatus] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false)
  const [deleting, setDeleting] = useState(false);
  const [forDelete, setForDelete] = useState(null);

  const getHistoryCurrent = () => {
    if (saleType === "CREDIT") {
      getHistory("SALE", saleOrder?.order?.id);
    }else{
      getHistory("SALE-CASH", saleOrder?.order?.id);
    }
  }

  const chekedTypeSale = (e) => {
    console.log("object", e);
    if (e.target.checked) {
      setSaleType(e.target.value);
      if (e.target.value === "CREDIT") {
        getWorkFlow("SALE");
        /* getHistoryCurrent("SALE"); */
        /* getHistory("SALE", saleOrder?.order?.id); */
      } else if (e.target.value === "CASH") {
        /* getHistory("SALE-CASH", saleOrder?.order?.id); */
        getWorkFlow("SALE-CASH");
      }
      getHistoryCurrent();
    }
  };

  const styles = {
    radioGroup: {
      right: "0px",
      justifyContent: "end",
      alignItems: "flex-end",
      float: "right",
      marginLeft: "auto",
      display: "flex",
    },
    spaceCol: {
      marginBottom: "20px",
      marginTop: "20px",
    },
    spaceForm: {
      borderBottom: "1px solid blue",
      width: "100%",
    },
    FormLayout: {
      backgroundColor: "#fff",
      borderRadius: "20px",
      marginTop: 20,
      padding: 10
    },
  };

  const HeaderRow = styled(Row)`
    border-bottom: solid 1px;
    border-color: #341eff;
    margin-bottom: 10px;
    --antd-wave-shadow-color: #2d2cf5;
    /* .ant-form-item{
        height: 100% !important;
    } */
    /* .ant-radio-inner{
        border-radius: 25%;
        border-color: #2D2CF5;
    } */
    /* .ant-radio-checked .ant-radio-inner{
        background-color: #2D2CF5;
    }
    .ant-radio-inner::after{
        background-color: #2D2CF5;
        border-color: #2D2CF5;
    } */
  `;

  const StyledItem = styled(Item)`
    .ant-select {
      color: #341eff;
    }
    .ant-input {
      color: #341eff;
    }
    .ant-input:placeholder-shown {
      color: #341eff;
    }
  `;

  const loadPaymentPlans = () => {
    getFinancialPlans();
  };

  const onFormChange = ({ financialPlan, plan_months, payment_type }) => {
    const found = _.find(sales.financialPlanList, {
      id: parseInt(financialPlan),
    });
    if (found) {
      form.setFieldsValue({
        plan_months: _.get(found, "month_duration"),
      });
    }
    if (payment_type) setIsCreditPay(payment_type === "CREDIT");
  };

  const loadData = async () => {
    try {
      setLoading(true);
      const saleOrderResponse = await axiosApi.get(
        `${API.SALE.SALE_ORDER}${id}`
      );

      const current_step_index = _.get(
        saleOrderResponse,
        "data.order.current_step.step",
        null
      );
      setCurrentStepIndex(current_step_index);
      setSaleOrder(saleOrderResponse.data);
      const payment_type = _.get(saleOrderResponse, "data.sale_type");
      const financialPlan = _.get(saleOrderResponse, "data.financial_plan");
      const plan_months = _.get(saleOrderResponse, "data.month_duration");
      const has_hitch = _.get(saleOrderResponse, "data.hitch_paid");
      const has_documentation = _.get(saleOrderResponse, "data.requirements");
      const hasAuthCredit = _.get(saleOrderResponse, "data.auth_credit");
      const carAvailability = _.get(saleOrderResponse, "data.car_availability");
      const has_close = _.get(saleOrderResponse, "data.contract_close");
      const totalPaid = _.get(saleOrderResponse, "data.total_paid");
      const deliveryStatus = _.get(saleOrderResponse, "data.delivery_status");
      const deliveryDate = _.get(saleOrderResponse, "data.delivery_date");
      const deliveryDateObj = deliveryDate ? moment(deliveryDate) : null;

      const iniData = {
        deliveryDate: deliveryDateObj,
        payment_type: payment_type,
        financialPlan: financialPlan ? financialPlan.toString() : "",
        plan_months: plan_months,
        has_hitch: has_hitch,
        has_documentation: has_documentation,
        hasAuthCredit: hasAuthCredit,
        has_close: has_close,
        carAvailability: carAvailability ? carAvailability.toString() : "",
        totalPaid,
        deliveryStatus,
      };
      setIsCreditPay(payment_type === "CREDIT");
      setSaleType(payment_type);
      setInitialValues(iniData);
    } catch (error) {
      console.log("Error al cargar orden de venta", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      loadData();
    }
    loadPaymentPlans();
    getCarAvailabilityList();
  }, [id]);

  useEffect(() => {
    if (saleOrder) {
      if (saleOrder.sale_type === "CREDIT") {
        getWorkFlow("SALE");
        getHistory("SALE", saleOrder?.order?.id);
      } else if (saleOrder.sale_type === "CASH") {
        getHistory("SALE-CASH", saleOrder?.order?.id);
        getWorkFlow("SALE-CASH");
      }
    }
  }, [saleOrder]);

  useEffect(() => {
    if (workflow && workflow.workflow && workflow.workflow.length > 0) {
      setWorkFlowSteps(_.get(workflow.workflow[0], "steps", []));
    }

    if (
      workflow &&
      workflow.workflow_logs &&
      workflow.workflow_logs.length > 0
    ) {
      setWorkFlowLogs(workflow.workflow_logs);
    }
  }, [workflow]);

  useEffect(() => {
  }, [workflowSteps]);

  const getRequest = (values) => {
    const delivery_date = _.get(values, "deliveryDate");

    let request = {
      //customer_id: _.get(initialValues, 'order.customer.id'),
      //car_brand: _.get(values, 'carBrand'),
      //car_year: _.get(values, 'carYear'),
      //car_model: _.get(values, 'carModel'),
      //car_color: _.get(values, 'carColor'),
      //comments: _.get(values, 'details'),
      order_id: id,
      sale_type: _.get(values, "payment_type"),
      financial_plan: _.get(values, "financialPlan"),
      month_duration: _.get(values, "plan_months"),
      hitch_paid: _.get(values, "has_hitch"),
      auth_credit: _.get(values, "hasAuthCredit"),
      requirements: _.get(values, "has_documentation"),
      contract_close: _.get(values, "has_close"),
      car_availability: _.get(values, "carAvailability"),
      total_paid: _.get(values, "totalPaid"),
      delivery_status: _.get(values, "deliveryStatus"),
      delivery_date: delivery_date.format("YYYY-MM-DD"),
    };

    return request;
  };

  // Función para guardar
  const onFinish = (values) => {
    const request = getRequest(values);
    save(request);
  };

  const onFinishFailed = (errorInfo) => {
    message.warning({
      content: intl.formatMessage({ id: "services.saleOrder.validateData" }),
    });
  };

  const save = async (request) => {
    try {
      setLoading(true);
      const response = await axiosApi.post(API.SALE.SALE_ORDER, request);
      message.success({
        content: intl.formatMessage({ id: "saleOrder.saved" }),
      });
      //history.push(`/sale-orders/tracking/${response.data.sale_order_id}`)
    } catch (error) {
      console.log("Error al guardar orden de servicio", error);
    } finally {
      setLoading(false);
    }
  };

  const changeStep = async (step) => {
    try {
      setSpinStatus(step.id);
      const request = {
        service_order: id,
        step: step.id,
        status: "PLAY",
      };

      const response = await axiosApi.post(API.WORKFLOW.LOG, request);
      setCurrentStepIndex(step);
      message.success({
        content: intl.formatMessage({ id: "saleOrder.saved" }),
      });

      if (saleOrder.sale_type === "CREDIT") {
        getHistory("SALE", saleOrder?.order?.id);
      } else if (saleOrder.sale_type === "CASH") {
        getHistory("SALE-CASH", saleOrder?.order?.id);
      }
      setSpinStatus(false);
      setDeleting(false);
      setShowModalDelete(false);
    } catch (error) { }
  };



  const onChange = (current) => {
    console.log("onChange:", current);
    setForDelete(current);
    setShowModalDelete(true);
    
  };

  const confirmChanges = () => {
    setDeleting(true);
    changeStep(forDelete);
  }

  const changeVisibleSwitch = (value, id) => {
    /* console.log("values", value); */
    changeVisible(id, value, getHistoryCurrent);
  }

  const columnsLogs = [
    {
      key: "arrow",
      title: "",
      render: (row) => (
        <>
          <img src={row.step.icon} style={{ width: 30 }} />
        </>
      ),
    },
    {
      key: "date",
      title: intl.formatMessage({ id: "tracking.date" }),
      dataIndex: "timestamp",
      render: (date) => <>{moment(date).format("DD/MM/YYYY")}</>,
    },
    {
      key: "time",
      title: intl.formatMessage({ id: "tracking.hour" }),
      dataIndex: "timestamp",
      render: (date) => <>{moment(date).format("h:mm:ss")}</>,
    },
    {
      key: "status",
      title: intl.formatMessage({ id: "services.page.status" }),
      dataIndex: ["step", "name"],
    },
    {
      key: "hide_show",
      title: intl.formatMessage({ id: "services.page.hide_status" }),
      render: (row) => (
        <Switch
          loading={row.id === workflow.updating_status}
          checkedChildren={intl.formatMessage({
            id: "services.switch.visible",
          })}
          unCheckedChildren={intl.formatMessage({ id: "services.switch.hide" })}
          checked={row.visible_for_client}
          onChange={(e) => changeVisibleSwitch(e, row.id)}
        />
      ),
    },
  ];

  const closeHistory = (e) => {
    e.preventDefault();
    setShowHistory([]);
  };

  return (
    <DashboardLayout
      title={intl.formatMessage({ id: "services.sale" })}
      index="ordenes_de_venta"
      align="center"
      HeaderComponent={<>
        <Row>
          <Col>
            <CardPurpleLight
                title={`FOLIO ${_.get(saleOrder, "order.folio", "")}`}
                description={`${_.get(saleOrder, "car_brand.name", "")} 
                      ${_.get(saleOrder, "car_model.name", "")} ${_.get(
                    saleOrder,
                    "car_year",
                    ""
                )}`}/>
          </Col>
          <Col style={{ display: "flex" }}>
            <Card
                style={{
                  background: "transparent",
                  margin: "auto",
                }}
            >
              <Title level={3} style={{ marginBottom: 0, color: "#341EFF" }}>
                {`${_.get(saleOrder, "order.customer.first_name", "")} 
                        ${_.get(
                    saleOrder,
                    "order.customer.mlast_name",
                    ""
                )} ${_.get(saleOrder, "order.customer.last_name", "")}`}
              </Title>
            </Card>
          </Col>
        </Row>
        <Row style={{ paddingTop: 40 }}>
          <Col span={24}>
            <ServiceButtonsHeader orderId={_.get(saleOrder, "order.id", "")}/>
          </Col>
        </Row>
      </>}
    >
      <Global
        styles={css`
          .btn-status {
            color: #ff6966;
            background: #ff69661a;
          }
          .btn-status:hover {
            color: #ff6966;
            background: #ff69661a;
            border: solid 1px #ff6966;
          }
          .btn-status:focus:not(:focus-visible) {
            color: #ff6966;
            background: #ff69661a;
            border: solid 1px #ff6966;
          }
          .buttons_status > .ant-space-item {
            margin-top: auto;
          }

          .btn-change-status:focus:not(:focus-visible) {
            background-color: #090731;
            color: white;
          }

          .rowSteeps {
            background: #341eff;
            border-radius: 20px;
            margin-top: 30px;
          }
          .item_step::hover {
            backgroud: gray;
          }

          .item_step div,
          .item_step_selected div {
            margin: auto;
          }
          .item_step,
          .item_step div .ant-typography {
            color: #ffffff7a;
          }
          .item_step_selected,
          .item_step_selected div .ant-typography {
            color: white;
          }
          .item_step_selected div img {
            filter: invert(1) !important;
          }

          .toggle_history .ant-collapse-header {
            border-radius: 10px !important;
            background: #d7d4d4;
          }

          .toggle_history .ant-collapse-content .ant-collapse-content-box {
            padding-left: 0;
            padding-right: 0;
          }
          .ant-table-thead tr th {
            background: none;
            border: none;
          }
          .table-logs tbody tr td {
            border: 0px;
          }
          .table-logs thead tr th {
            border-bottom: solid 1px #341eff;
          }
          .border-top-color td {
            border-top: solid 1px #d7d4d4 !important;
          }
          .card-folio .ant-card-meta-title {
            color: #341eff !important;
            font-weight: 600;
            font-size: 24px;
          }
          .card-folio .ant-card-meta-detail .ant-card-meta-title {
            height: 30px;
          }
          .card-folio .ant-card-meta-description {
            color: #341eff !important;
          }
        `}
      />
      {!loading && (
        <>
          <Row className="rowSteeps" justify="center">
            <Col span={23}>
              <Spin spinning={workflow.fetching}>
                <Row style={{ paddingTop: 10 }}>
                  {workflowSteps.map((step) => (
                    <Col
                      style={{
                        textAlign: "center",
                        width: `${100 / workflowSteps.length}%`,
                        cursor: "pointer",
                      }}
                      className={
                        currentStepIndex.id === step.id
                          ? "item_step_selected"
                          : "item_step"
                      }
                      onClick={() => onChange(step)}
                    >
                      <Spin spinning={step.id === spinStatus}>
                        <div>
                          <div
                            style={{
                              display: "flex",
                              height: 40,
                              marginBottom: 10,
                            }}
                          >
                            {step.icon ? (
                              <img
                                src={step.icon}
                                style={{
                                  width: 30,
                                  height: 30,
                                  marginTop: "auto",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                }}
                              />
                            ) : (
                              <CheckCircleOutlined
                                style={{
                                  fontSize: 30,
                                  height: 30,
                                  marginTop: "auto",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                }}
                              />
                            )}
                          </div>
                          <Text style={{ marginTop: 12, fontWeight: "bold" }}>
                            {step.name}
                          </Text>
                        </div>
                      </Spin>
                    </Col>
                  ))}
                </Row>
              </Spin>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Collapse
                ghost
                className="toggle_history"
                style={{ marginTop: 10 }}
                onChange={(e) => {
                  setShowHistory((prev) => e);
                }}
                activeKey={showHisory}
              >
                <Panel
                  header={intl.formatMessage({ id: "tracking.viewHistory" })}
                  key="1"
                  onChange={() => setShowHistory((prev) => [1])}
                >
                  <Card style={{ borderRadius: 10, paddingTop: 20 }}>
                    <Row justify="end">
                      <Col>
                        <Text style={{ color: "#341eff" }}>
                          <FormattedMessage id="tracking.viewFullHistory" />
                        </Text>
                      </Col>
                    </Row>
                    <Table
                      loading={{
                        tip: "Obteniendo datos",
                        spinning: workflow.fetching_history,
                      }}
                      pagination={false}
                      className="table-logs"
                      columns={columnsLogs}
                      dataSource={workflowLogs}
                      rowClassName={(record, index) =>
                        !workflowLogs[index - 1]
                          ? "no-border"
                          : moment(workflowLogs[index - 1].timestamp).format(
                              "DD/MM/YYYY"
                            ) !== moment(record.timestamp).format("DD/MM/YYYY")
                          ? "border-top-color"
                          : "no-border"
                      }
                    />
                    <div
                      style={{
                        textAlign: "center",
                        marginTop: 30,
                        marginBottom: 30,
                      }}
                    >
                      <Space>
                        <Button
                          className="btn-string-blue btn-report-action"
                          onClick={closeHistory}
                        >
                          <FormattedMessage id="layout.close" />
                        </Button>
                        <Button
                          size="small"
                          type="primary"
                          icon={<SaveOutlined />}
                          loading={loading}
                          htmlType="submit"
                          className="btn-string-blue-low btn-report-action"
                        >
                          <FormattedMessage id="layout.print" />
                        </Button>
                      </Space>
                    </div>
                  </Card>
                </Panel>
              </Collapse>
            </Col>
          </Row>
        </>
      )}

      <Layout style={styles.FormLayout}>
        {loading ? (
          <Skeleton active />
        ) : (
          <Form
            size="large"
            onValuesChange={onFormChange}
            form={form}
            onFinish={onFinish}
            initialValues={initialValues}
            layout="vertical"
            style={{ backgroundColor: "#FFFFFF", margin: "20px 40px 40px" }}
            validateMessages={validateMessages}
          >
            <Row gutter={[20]}>
              <Col span={24}>
                <Row justify="end">
                  <Col span={4} className="d-flex">
                    <Text strong style={{ margin: "auto" }}>
                      <FormattedMessage id="services.saleOrder.typeOfSale" />
                    </Text>
                  </Col>
                  <Col span={4}>
                    <Form.Item name="payment_type" className="mb-0" labe>
                      <Space direction="horizontal" align="end">
                        <Checkbox
                          value="CREDIT"
                          checked={saleType === "CREDIT"}
                          onChange={chekedTypeSale}
                        >
                          <FormattedMessage id="services.saleOrder.credit" />
                        </Checkbox>
                        <Checkbox
                          value="CASH"
                          checked={saleType === "CASH"}
                          onChange={chekedTypeSale}
                        >
                          <FormattedMessage id="services.saleOrder.cash" />
                        </Checkbox>
                      </Space>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              {isCreditPay && (
                <>
                  <Col span={24}>
                    <HeaderRow>
                      <Col>
                        <Title level={5}>
                          <FormattedMessage id="saleOrder.financing" />
                        </Title>
                      </Col>
                    </HeaderRow>
                  </Col>
                  <Col md={6}>
                    <StyledItem
                      label="Plan"
                      rules={rulesRequired}
                      name="financialPlan"
                      tooltip="Campo requerido"
                      className="item-expediente-lg mb-0"
                    >
                      <Select
                        loading={loading}
                        style={{ width: "100%" }}
                        onChange={() => {}}
                        className="selectFormCredit"
                      >
                        {sales.financialPlanList.map((item) => (
                          <Select.Option key={item.id}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </StyledItem>
                  </Col>
                  <Col span={6}>
                    <StyledItem
                      label={intl.formatMessage({ id: "saleOrder.timeLimit" })}
                      name="plan_months"
                      className="item-expediente-lg mb-0"
                    >
                      <Input style={{ textAlign: "center" }} />
                    </StyledItem>
                  </Col>
                  <Col md={12} style={{ marginTop: "auto" }}>
                    <Row gutter={25} justify="end">
                      <Col>
                        <StyledItem
                          name="has_hitch"
                          valuePropName="checked"
                          style={{ marginBottom: 10 }}
                        >
                          <Checkbox>
                            <FormattedMessage id="saleOrder.hitch" />
                          </Checkbox>
                        </StyledItem>
                      </Col>
                      <Col>
                        <StyledItem
                          name="has_documentation"
                          valuePropName="checked"
                          style={{ marginBottom: 10 }}
                        >
                          <Checkbox>
                            <FormattedMessage id="saleOrder.fullDocumentation" />
                          </Checkbox>
                        </StyledItem>
                      </Col>
                    </Row>
                  </Col>
                </>
              )}
              {/* <Col span={11} offset={1}>
                  <StyledItem name="hasAuthCredit" valuePropName="checked">
                    <Checkbox>Crédito aprobado</Checkbox>
                  </StyledItem>
                </Col> */}
              <Col span={24} style={{ marginTop: isCreditPay ? 30 : "auto" }}>
                <HeaderRow>
                  <Col>
                    <Title level={5}>
                      <FormattedMessage id="saleOrder.contractAndAvailability" />
                    </Title>
                  </Col>
                </HeaderRow>
              </Col>
              <Col md={6}>
                <StyledItem
                  label={intl.formatMessage({ id: "saleOrder.availability" })}
                  rules={rulesRequired}
                  name="carAvailability"
                  tooltip="Campo requerido"
                  className="item-expediente-lg mb-0"
                >
                  <Select
                    loading={loading}
                    style={{ width: "100%" }}
                    onChange={() => {}}
                    className="selectFormCredit"
                  >
                    {sales.carAvailabiltyList.map((item) => (
                      <Select.Option key={item.id}>{item.name}</Select.Option>
                    ))}
                  </Select>
                </StyledItem>
              </Col>
              <Col md={{ span: 5, offset: 1 }} style={{ marginTop: "auto" }}>
                <StyledItem
                  name="has_close"
                  valuePropName="checked"
                  style={{ marginBottom: 10 }}
                >
                  <Checkbox>
                    <FormattedMessage id="saleOrder.closeContract" />
                  </Checkbox>
                </StyledItem>
              </Col>
              <Col span={24} style={{ marginTop: 30 }}>
                <HeaderRow>
                  <Col>
                    <Title level={5}>
                      <FormattedMessage id="saleOrder.delivery" />
                    </Title>
                  </Col>
                </HeaderRow>
              </Col>
              <Col span={6}>
                <StyledItem
                  label={intl.formatMessage({ id: "saleOrder.paymentStatus" })}
                  rules={rulesRequired}
                  name="totalPaid"
                  tooltip="Campo requerido"
                  className="item-expediente-lg mb-0"
                >
                  <Select
                    loading={loading}
                    style={{ width: "100%" }}
                    onChange={() => {}}
                    className="selectFormCredit"
                  >
                    <Select.Option key={"PENDING"}>
                      <FormattedMessage id="saleOrder.toBeConfirmd" />
                    </Select.Option>
                    <Select.Option key={"CONFIRM"}>
                      <FormattedMessage id="saleOrder.confirmed" />
                    </Select.Option>
                  </Select>
                </StyledItem>
              </Col>
              <Col span={6} offset={1}>
                <StyledItem
                  label={intl.formatMessage({
                    id: "saleOrder.deliverySchedule",
                  })}
                  rules={rulesRequired}
                  name="deliveryStatus"
                  tooltip="Campo requerido"
                  className="item-expediente-lg "
                >
                  <Select
                    loading={loading}
                    style={{ width: "100%" }}
                    onChange={() => {}}
                    className="selectFormCredit"
                  >
                    <Select.Option key={"AUTO_CLEAN"}>
                      <FormattedMessage id="saleOrder.conditioningCar" />
                    </Select.Option>
                    <Select.Option key={"PLATES"}>
                      <FormattedMessage id="saleOrder.processingWithPlates" />
                    </Select.Option>
                    <Select.Option key={"READY"}>
                      <FormattedMessage id="saleOrder.deliveryRoom" />
                    </Select.Option>
                  </Select>
                </StyledItem>
              </Col>
              <Col span={6} offset={1}>
                <StyledItem
                  label={intl.formatMessage({
                    id: "saleOrder.approximateDeliveryDate",
                  })}
                  rules={rulesRequired}
                  name="deliveryDate"
                  tooltip="Campo requerido"
                  className="item-expediente-lg"
                >
                  <DatePicker
                    size="large"
                    style={{ width: "100%" }}
                    format={"DD MMMM YYYY"}
                  />
                </StyledItem>
              </Col>
              <Col span={23} offset={1} style={{ textAlign: "center" }}>
                <Space size="middle">
                  <Button
                    onClick={() => history.push("/sale-orders")}
                    className="btn-string-blue btn-report-action"
                  >
                    <FormattedMessage id="layout.cancel" />
                  </Button>
                  <Button
                    type="primary"
                    icon={<SaveOutlined />}
                    loading={loading}
                    htmlType="submit"
                    className="btn-string-blue-low btn-report-action"
                  >
                    <FormattedMessage id="services.filter.save" />
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form>
        )}
        <DeleteModal
          isVisible={showModalDelete}
          closeModal={() => setShowModalDelete(false)}
          modalTitle={
            <FormattedMessage id="services.saleOrder.deleteQuestion" />
          }
          description={
            <FormattedMessage id="services.saleOrder.deleteDescription" />
          }
          actionDelete={() => confirmChanges()}
          loading={deleting}
          txtConfirm={
            <FormattedMessage id="ok" />
          }
        />
        {/* <StatusServiceModal open={deleting} setOpen={setOpenModalStatus} /> */}
      </Layout>
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => ({
  sales: state.sales,
  workflow: state.workflow,
  settings: state.settings,
});

export default injectIntl(
  connect(mapStateToProps, {
    getFinancialPlans,
    getCarAvailabilityList,
    changeLangAction,
    getWorkFlow,
    getHistory,
    changeVisible,
  })(TrackingServiceOrderPage)
);
