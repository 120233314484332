import React from 'react';
import { Form, Input, Select, Checkbox } from 'antd';

const { Option } = Select;

const ClientSection = () => {
  return (
    <div className="section">
      <h2>Cliente</h2>
      <Form layout="vertical">
        <Form.Item label="ID DE CLIENTE">
          <Input value="NUEVO" readOnly />
        </Form.Item>
        <Form.Item label="Tipo de cliente">
          <Select defaultValue="Persona física">
            <Option value="Persona física">Persona física</Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Checkbox checked readOnly>ES EL DUEÑO DE LA UNIDAD</Checkbox>
        </Form.Item>
        <Form.Item label="Nombre(s)">
          <Input value="José Alejandrino" readOnly />
        </Form.Item>
        <Form.Item label="Apellido(s)">
          <Input value="Dzul Cetina" readOnly />
        </Form.Item>
        <Form.Item label="CURP">
          <Input value="DUCA982728HYTNKUI" readOnly />
        </Form.Item>
        <Form.Item label="RFC">
          <Input value="DUCA8801DTJ8" readOnly />
        </Form.Item>
        <Form.Item label="Correo electrónico">
          <Input value="alexdzul@me.com" readOnly />
        </Form.Item>
        <Form.Item label="Núm Teléfono / Celular">
          <Input value="9992191253" readOnly />
        </Form.Item>
        <Form.Item label="Dirección">
          <Input value="Calle 11 x 2 y 4 Sitpach Yucatán, CP 97306" readOnly />
        </Form.Item>
      </Form>
    </div>
  );
};

export default ClientSection;