import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Checkbox, Typography } from 'antd';
import { FormattedMessage, injectIntl } from "react-intl";
import { changeLangAction } from '../../../redux/settingsDuck'
import { getAuthAction, sendLogin } from "../../../redux/securitryDuck";
import { connect } from 'react-redux';
import { useHistory , Redirect} from 'react-router-dom';
import { Link } from 'react-router-dom';
import styles from './login.module.css';
import KButton from "../../../components/common/elements/KButton/KButton";
import { Global, css } from "@emotion/react";

const { Title } = Typography;

const LoginPageV2 = ({ changeLangAction, getAuthAction, settings, intl, isLoggedIn, ...props }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = values => {
    // Obtenemos el input de password.
    const password = values.password;
    // Obtenemos el input de username
    const username = values.username;
    // Armamos el json
    let data = {};
    data.username = username;
    data.password = password;
    /* setLoading(true); */
    // Usamos redux
    getAuthAction(data)
  }


  useEffect(() => {
    if (settings?.generalInfo?.image_background) {
      localStorage.setItem('image_background', settings?.generalInfo?.image_background);
    }
  }, [settings])


  return (
    <div className={styles.container}>
      {isLoggedIn && <Redirect to="/company" />}
      <Global
        styles={css`

          * {
              font-family: "Sharp Grotesk",serif !important;
              letter-spacing: 0.2px;
            }

          .imageSection {
            flex: 1;
            position: relative;
            background-image: url(${settings?.generalInfo?.image_background ? settings?.generalInfo?.image_background : localStorage.getItem('image_background') ? localStorage.getItem('image_background') : '/login_kikert03.png'});
            background-size: cover;
            background-position: center;
          }
          
          .imageSection::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.5); // Ajusta el último valor (0.5) para cambiar la opacidad
          }
        `}
      />
      <div className={styles.loginBox}>
        <div className={styles.formSection}>
          <Title level={2} className={styles.title}>
            <FormattedMessage id="security.login.title" defaultMessage="Iniciar Sesión" />
          </Title>
          <Form form={form} layout="vertical" onFinish={onFinish}>
            <Form.Item
              name="username"
              rules={[{ required: true, message: intl.formatMessage({ id: "security.login.messageUser" }) }]}
            >
              <Input
                size="large"
                placeholder={intl.formatMessage({ id: "security.login.email", defaultMessage: "Email" })}
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: intl.formatMessage({ id: "security.login.messagePass" }) }]}
            >
              <Input.Password
                size="large"
                placeholder={intl.formatMessage({ id: "security.login.password", defaultMessage: "Contraseña" })}
              />
            </Form.Item>
            {/* <Form.Item>
              <Checkbox className={styles.termsCheckbox}>
                <FormattedMessage id="security.login.accepTerms" defaultMessage="Acepto los términos y condiciones" />
              </Checkbox>
            </Form.Item> */}
            <Form.Item>
              <KButton
                size="large"
                block
                type="secondary"
                htmlType="submit"
                text={<FormattedMessage id="security.login.signin" defaultMessage="Ingresar" />}
                className={styles.loginButton}
              />
            </Form.Item>
          </Form>
          <div className={styles.forgotPassword}>
            <a  href='https://kikert.com/' target="_blank">
              Powered by Kikert ®
            </a>
          </div>
        </div>
        
        <div className={'imageSection'}>
        <img src="/logo_white.svg" alt="Imagen de fondo"
            className={styles.logoKikert}
          />
          {/* La imagen del auto se manejará con CSS */}
          
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  settings: state.settings,
  isLoggedIn: state.security.isLoggedIn,
  loadingLogin: state.security.loadingLogin,
  errorLogin: state.security.errorLogin,
});

export default injectIntl(connect(mapStateToProps, {
  changeLangAction,
  getAuthAction,
})(LoginPageV2))
