import React, { useEffect } from "react";
import {
    Form,
} from "antd";
import { connect } from 'react-redux'
import { injectIntl, FormattedMessage } from "react-intl";
import KSelect from "../common/elements/KSelect/KSelect";
import _ from "lodash";
import {getUsersAdvisors} from "../../redux/membersDuck";

const SelectAsesor=({name='asesor',placeholder='Selecciona un asesor',members,getUsersAdvisors,style={}, label=null, ...props})=>{

    useEffect(()=>{
        getUsersAdvisors()
    },[])


    return (
        <Form.Item
            label={label ? label : <FormattedMessage id="services.page.advisor" />}
            name={name}
            className="item-expediente-lg mb-0 label-margin"
            style={style}
        >
            <KSelect
                allowClear={true}
                showSearch
                placeholder={placeholder}
                options={
                    members?.usersAdvisorsList && _.map(members?.usersAdvisorsList, (o)=>{
                        return {
                            value:o.id,
                            label:`${o.first_name} ${o.last_name}`
                        }
                    })
                }
            />
        </Form.Item>
    )
}

const mapStateToProps = (state) => ({
    members: state.members
});

export default injectIntl(
    connect(mapStateToProps, { getUsersAdvisors })(SelectAsesor)
);
