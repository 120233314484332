import { Col, Row, Typography } from 'antd'
import React from 'react'
import { FormattedMessage } from 'react-intl';
import HeaderSection from '../../MyComponents'
import TableFunctionality from '../TableFunctionality';
import TableCatalogs from '../TableCatalogs';


import CatalogsIcon from '../../../image/admin.svg';
import FunctionsIcon from '../../../image/procesos.svg';

const ConfigPermissions = ({perms, setPerms, ...props}) => {
    const {Text,Title} = Typography


    const functionalities = [
        {
            title: 'members.can_show_membership',
            key: "view_paymentorder"
        }
    ]

    const catalogs = [
      {
        title: 'services.insur',
        show: "view_insurance",
        add: "add_insurance",
        edit: "change_insurance",
        delete: "delete_insurance"
      },
      {
          title: 'services.brand',
          show: "view_vehiclebrand",
          add: "add_vehiclebrand",
          edit: "change_vehiclebrand",
          delete: "delete_vehiclebrand"
      },
      {
          title: 'services.models',
          show: "view_vehiclemodel",
          add: "add_vehiclemodel",
          edit: "change_vehiclemodel",
          delete: "delete_vehiclemodel"
      },
      {
          title: 'vehicleColor.title',
          show: "view_vehiclecolor",
          add: "add_vehiclecolor",
          edit: "change_vehiclecolor",
          delete: "delete_vehiclecolor"
      },
      {
          title: 'vehicleTypeUnid.title',
          show: "view_vehicletype",
          add: "add_vehicletype",
          edit: "change_vehicletype",
          delete: "delete_vehicletype"
      },
    ]



  return (
    <Row justify={'space-between'}>
        <Col span={11}>
            <Title level={5} style={{color:'#3f4bfb'}} >
              <img src={CatalogsIcon} alt="" style={{ width:12 }} />
              &nbsp;
              <FormattedMessage id="layout.functions" />
            </Title>
          <div style={{border: 'solid 1px #cecece', borderRadius:20, padding:'0px 20px'}}>
            <TableFunctionality data={functionalities} perms={perms} setPerms={setPerms} />
          </div>
        </Col>
        <Col span={11}>
          <Title level={5} style={{color:'#3f4bfb'}} >
            <img src={FunctionsIcon} alt="" style={{ width:15 }} />
              &nbsp;
              <FormattedMessage id="layout.catalogs" />
          </Title>
          <div style={{border: 'solid 1px #cecece', borderRadius:20, padding:'0px 20px'}}>
            <TableCatalogs data={catalogs} perms={perms} setPerms={setPerms} />
          </div>
        </Col>
    </Row>
  )
}

export default ConfigPermissions