import React, { useState, useEffect } from 'react'
import DashboardLayout from '../../layouts/DashboardLayout';


const ConfigPage =()=>{
    return(
        <DashboardLayout>
            <p>Hola config</p>
        </DashboardLayout>
    )
}

export default ConfigPage
