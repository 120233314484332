import React, {useState, useEffect} from 'react'
import DashboardLayout from '../../../layouts/DashboardLayout';
import {message, Table,Row, Col, Layout, Form, Button, Modal, Input, Typography, Upload, Space, Image, Drawer, List} from 'antd'
import {UploadOutlined} from '@ant-design/icons';
import {axiosApi, API} from "../../../lib/utils/axiosApi"
import {connect} from 'react-redux'
import DeleteLinkComponent from "../../../components/DeleteLinkComponent";
import {injectIntl} from 'react-intl';
import {setConfigCompanyBranch, changeLangAction} from "../../../redux/settingsDuck";
import logoDefault from "../../../image/imagenDefault.jpg";
import {CustomTable} from "../../../components/KComponents";
import moment from 'moment'
import {useHistory} from "react-router-dom";
import MainLayoutv2 from "../../../layouts/MainLayoutv2";
import useSettingsKikert from "../../../hooks/useSettingsKikert";
const {Header, Content, Footer} = Layout;
const { Search } = Input;

const UnitsPage = ({changeLangAction, intl, setConfigCompanyBranch, settings,permissions, ...props}) => {

    const [loading, setLoading] = useState(false)
    const [units, setUnits] = useState(null)
    const [filterText, setFilterText] = useState(null)
    const [count, setCount] = useState(null)
    const history = useHistory()
    const {settingsKikert, getMetaValue} = useSettingsKikert()
    const HIDE_LICENSE_PLATE_INPUT=getMetaValue('HIDE_LICENSE_PLATE_INPUT');

    useEffect(()=>{
        getUnits()
    },[])


    const getUnits = async (filter='', page=1)=>{
        setLoading(true)
        try{
            let res = await axiosApi.get(`${API.BUSINESS.UNITS}?search=${filter?filter:''}&page=${page}`)
            console.log(res.data.results)
            setCount(res.data?.count)
            setUnits(res.data)
        }catch (e){
            setUnits(null)
        }finally {
            setLoading(false)
        }
    }

    const changePagination = (page) => {
        console.log('page', page)
        getUnits(filterText, page);
    }

    const columns = [
        {
            title: 'VIN',
            dataIndex: 'vin',
            key: 'vin'
        },
        {
            title: 'Placa',
            key:'license_plate',
            dataIndex:'license_plate'
        },
        {
            title: 'Marca',
            key:'brand',
            render: (item)=><p>{item?.model?.brand?.name}</p>
        },
        {
            title: 'Núm de ordenes.',
            key:'numorders',
            render: (item)=><p>{item?.num_workshop_orders}</p>
        },
        {
            title: 'Modelo',
            key:'model',
            render: (item)=><p>{item?.model?.name}</p>
        },
        {
            title: 'Color',
            key:'color',
            render: (item)=><p>{item?.color?.name}</p>
        },
        {
            title: 'Fecha de creación',
            key:'timestamp',
            render: (item)=><p>{item?.timestamp?moment(item?.timestamp).format('DD/MM/YYYY hh:mm a'):''}</p>
        },
        {
            title: 'Acciones',
            key:'actions',
            render: (item)=><Button onClick={()=>history.push("/unit/"+item.id) } type="link">Editar</Button>
        },

    ]

    if(HIDE_LICENSE_PLATE_INPUT ==='1'){
        columns.splice(1, 1)
    }

    return (
        <MainLayoutv2
            title={'Unidades'}
            index="units"
        >
            <Content
                style={{padding: "10px 10px", marginTop: 10}}
            >

                <Row gutter={[10]} justify="space-between">
                    <Col flex="auto">
                        <Form size="large">
                            <Form.Item extra={` ${'    '}Total: ${count ?count:0}`} className="pt-0">
                                <Search
                                    size="large"
                                    onSearch={(value)=>{
                                        getUnits(value)
                                    }}
                                    allowClear
                                    placeholder={'Buscar por Núm. Serie./Marca/Modelo/Color'}
                                    className="kinput-search"
                                    style={{ height: 50 }}
                                />

                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
                <CustomTable
                    loading={loading}
                    dataSource={units?.results}
                    columns={columns}
                    pagination={{total: units?.count, pageSize: 20, onChange: changePagination}}
                />

            </Content>
        </MainLayoutv2>
    );
}

const mapStateToProps = state => ({
    settings: state.settings,
    security: state.security,
    permissions:state.permissions

})

export default injectIntl(connect(mapStateToProps, {
    setConfigCompanyBranch,
    changeLangAction,
})(UnitsPage))
