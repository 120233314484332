import React, { useState, useEffect } from 'react';
import {
  Layout,
  Typography
} from 'antd';
import PendingPaymentAlert from '../components/PendingPaymentAlert';
import { injectIntl, FormattedMessage } from 'react-intl';
import _ from 'lodash'
import turn from '../image/turn_icon_kikert.svg'
import { Global, css } from '@emotion/react';
import { logOut } from "../redux/securitryDuck";
import { changeLangAction } from '../redux/settingsDuck';
import { toggleMenu } from "../redux/settingsDuck";
import MainSider from "./MainSider";
import "../components/sideBarDerecha2/SiderBarRight.css"
import { connect } from 'react-redux'
import HeaderLayout from './HeaderLayout';
import { useLocation } from 'react-router-dom';
import HeaderActionsServiceOrder from './HeaderActionsServiceOrder';
import { useMediaQuery } from 'react-responsive';
import MovilHeaderLayout from './MovilHeaderLayout';

const { Header, Content, Footer, Sider } = Layout;
const { Title, Text } = Typography;
const MainLayoutv2 = ({ 
  children, 
  HeaderComponent,
  changeLangAction,
  intl, 
  title, 
  subtitle, 
  index, 
  settings,
  openKeys,
  serviceOrder,
  isServiceDetail=false,
  ...props 
}) => {

  const isMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  const location = useLocation();


  return (
    <Layout style={{ minHeight: '100vh' }}>
      
        <Global
        styles={css`

                .fixedMenu {
                    position: fixed;
                    width: 100%; /* Asegúrate de que el menú ocupe todo el ancho */
                    top: 0; /* Posición fija en la parte superior */
                    z-index: 1000; /* Asegúrate de que el menú esté sobre otros elementos */
                }

                .divlogo{
                    background-color: #fff;
                    margin-top: 25px;
                    display: flex;
                    
                }
                
                .ant-layout-sider-collapsed .divlogo{
                    margin: auto;
                }

                .ant-layout-sider-collapsed .logo{
                  width:50px;
                  height: 50px;
                }
                .logo {
                    width:100px;
                    height: 100px;
                }

                .ant-layout-sider-collapsed .logoMenu{
                    margin: auto;
                }
                .logoMenu{
                    height: 64px;
                    width: 28px;
                    margin-left: 40px;
                }
                
                .siderColor{
                background-color: var(--secondaryColor) !important;
                }
                
                .ant-menu-item-selected{
                  background-color: var(--primaryColor) !important;
                }
            `}
      />

      {
        !isMobile ? 
        <MainSider 
        index={index} /* onOpenChange={onOpenChange} */ openKeys={openKeys} /> :
        <MovilHeaderLayout/>
      }
      
        
      <Layout className="site-layout">
        
          {location.pathname !== '/membership' && <PendingPaymentAlert />}
          <HeaderLayout title={title} subtitle={subtitle}/>
          
          
        
        <Content
          style={{
            margin: isMobile ? '12px': '24px 16px',
            padding: isMobile ?0:24,
            paddingTop: 0,
            maxHeight: '100vh',
            overflowY: 'scroll'
          }}
        >
          <Title
                  level={3}
                  className="mb-0 title-layout"
                >
                  <img src={turn} alt="logo" style={{...style.logo,transform: 'rotate(180deg)'}} />{" "}
                  {title ? title : "Mi Dashboard"}
                  <Text style={{ fontWeight: 400 }} >{subtitle ? ` - ${subtitle}` : ""}</Text>
                </Title>

          {
            isServiceDetail && 
            <HeaderActionsServiceOrder/>
          }

          {
            HeaderComponent
          }
            {children}
        </Content>
      </Layout>
    </Layout>
  );
};

const style = {
  logo: {
    width: 20,
    height: 20,
    marginRight: 2
  },
  avatar: {
    width: 30,
    height: 30,
    marginRight: 2
  },
};

const mapStateToProps = state => ({
  settings: state.settings,
  security: state.security,
  serviceOrder: state.serviceOrder
})
  
  export default injectIntl(
    connect(mapStateToProps, {
      logOut,
      changeLangAction,
      toggleMenu,
    })(MainLayoutv2)
  );

