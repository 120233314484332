import React from "react";
import {Button, Form, Input, Modal, Space, Radio} from "antd";
import {FormattedMessage} from "react-intl";
import KModal from "../common/elements/KModal/KModal";
import KInput from "../common/elements/KInput/KInput";
import KButton from "../common/elements/KButton/KButton";

const CreateFilterViewModal =({isModalVisible,handleOk,handleCancel,filterStr,viewCode='',onSave,...props})=>{

    const [form] = Form.useForm();

    const saveView=(values)=>{
        values.filter = filterStr;
        values.view_code = viewCode;
        onSave(values)
        handleCancel()
        form.resetFields()
        console.log(values, filterStr)
    }

    return(
        <KModal title={<FormattedMessage id="save.view"/>} visible={isModalVisible} onOk={handleOk}
               onCancel={handleCancel}>
            <Form
                layout={'vertical'}
                form={form}
                onFinish={saveView}
            >
                <Form.Item name={'name'} label={<FormattedMessage id="layout.name"/>}>
                    <Input/>
                </Form.Item>

                <p>¿Quien podrá usar esta vista?</p>

                <Form.Item name={'is_private'}>
                    <Radio.Group>
                        <Radio value="true">Todos</Radio>
                        <Radio value="false">Sólo yo</Radio>
                    </Radio.Group>
                </Form.Item>


                <Space>
                    <KButton
                        size="large"
                        text={<FormattedMessage id="layout.cancel" />}
                        type={'secondary'}
                        onClick={() => handleCancel() }
                    >

                    </KButton>
                    <KButton
                        className="btn-blue btn-report-action"
                        size="large"
                        text={<FormattedMessage id="services.members.create.save" />}
                        onClick={() => form.submit()}
                    >

                    </KButton>
                </Space>
            </Form>
        </KModal>
    )
}
export default CreateFilterViewModal
