import React, { useState, useEffect } from 'react'
import DashboardLayout from '../../layouts/DashboardLayout';
import { Table, Layout, Form, Button, Modal, Input, Upload, Space, Col, Typography, Popconfirm, Select, message, Row } from 'antd'
import { UploadOutlined } from '@ant-design/icons';
import {
    changeLangAction,
    deleteModelAction,
    getBrandsAction,
    editModelAction,
    getModelsAction,
    saveModelAction
} from '../../redux/settingsDuck'
import turn from '../../image/turn_icon_kikert.svg'
import KSelect from "../../components/common/elements/KSelect/KSelect";
import { connect } from 'react-redux'
import { injectIntl } from "react-intl";
import { axiosApi, API, tenantSelected } from "../../lib/utils/axiosApi"
import DeleteLinkComponent from "../../components/DeleteLinkComponent";
import { FormattedMessage } from "react-intl";
import {permissionsExist} from "../../lib/utils/utils";
import DeleteModal from "../../components/modal/DeleteModal";
import DeleteIcon from "../../image/delete.svg";
import EditIcon from "../../image/edit.svg";
import {CustomTable} from "../../components/KComponents";
import KButton from "../../components/common/elements/KButton/KButton";
import { CustomModal } from '../../components/MyComponents'
import MainLayoutv2 from "../../layouts/MainLayoutv2";
import TabsConfig from '../../components/common/elements/TabsMenuConfig/TabsMenuConfig';
import SelectVehicleType from '../../components/Selects/SelectVehicleType';

const { Option } = Select;
const { Header, Content, Footer } = Layout;
const TENANT_JAC='jacmexico'
const ModelsPage = ({ changeLangAction, intl, getModelsAction, settings, saveModelAction, deleteModelAction, editModelAction,permissions }) => {

    const {Title} = Typography;

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentModel, setCurrendModel] = useState(false);
    const [carbrands, setCarBrandas] = useState([])
    const [brandSelected, setBrandSelected] = useState('')

    const [actionView, setActionView] = useState(true);
    const [forDelete, setForDelete] = useState(null)
    const [modalDelete, setModalDelete] = useState(false)
    const [listOptions, setListOptions] = useState([])
    const [vehicleTypes, setVehicleTypes] = useState([])

    const [form] = Form.useForm();


    const getVehicleTypes=async()=>{
        try{
            const response=await axiosApi.get(`${API.WORKSHOP.WORKSHOP_CAR_TYPES}`)
            setVehicleTypes(response.data?.results)
        }catch(error){
            console.log(error)
            setVehicleTypes([])
        }
    }

    useEffect(() => {
        getModelsAction(1, `&brand__id=${brandSelected}`)
        getBrands()
        valActions()
        getVehicleTypes()
    }, [])

    const showModal = (isEdit = false) => {
        console.log("Va", isEdit)
        if (!isEdit) {
            form.resetFields()
            if(brandSelected){
                form.setFieldsValue({
                    brand: brandSelected,
                    vehicle_type: brandSelected.vehicle_type
                })
            }
            setCurrendModel(null)
        }
        setIsModalVisible(true);
    };

    const getBrands = async () => {
        try {
            const response = await axiosApi.get(`${API.WORKSHOP.WORKSHOP_CARBRAND}?page_size=100000`)
            setCarBrandas(response.data.results)
        } catch (errr) {

        }
    }

    const handleOk = () => {
        form.submit();
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setCurrendModel(null)
        form.resetFields()
    };

    const closeModal = () =>{
        setModalDelete(false);
        setForDelete(null);
    }

    const deleteItem = (current) => {
        console.log(current);
        setModalDelete(true);
        setForDelete(current);
    }

    const onDeleteModel = async () => {
        try {
            const res = await deleteModelAction(forDelete.id)
            if (res.success) {
                message.success(intl.formatMessage({ id: "item.deleteSuccess" }))
                await getModelsAction(1, `&brand__id=${brandSelected}`)
                await closeModal()

            } else {
                message.error(intl.formatMessage({ id: "item.brand.registerErr" }))
                await closeModal()

            }
        } catch (e) {
            message.error(intl.formatMessage({ id: "item.brand.registerErr" }))
            await closeModal()

        }
    }


    const onSaveModel = async values => {
        if (values) {
            let res = await saveModelAction(values)
            if (res.success) {
                message.success(intl.formatMessage({ id: "item.saveSuccess" }))
                await getModelsAction(1, `&brand__id=${brandSelected}`)
            } else {
                message.error(intl.formatMessage({ id: "item.brand.element" }))
            }

            form.resetFields()
            setCurrendModel(null)
        }
        console.log('Received values of form: ', values);
    };

    const onEditModel = async values => {
        if (values) {
            let req = {
                id: currentModel.id,
                ...values
            }
            let res = await editModelAction(req)
            if (res.success) {
                message.success(intl.formatMessage({ id: "item.saveSuccess" }))
                await getModelsAction(1, `&brand__id=${brandSelected}`)
            } else {
                message.error(intl.formatMessage({ id: "item.brand.element" }))
            }

            form.resetFields()
            setCurrendModel(null)
        }
    };


    const editModel = (model = null) => {
        if (model) {
            setCurrendModel(model)
            form.setFieldsValue({
                name: model.name,
                brand: model.brand.id,
                vehicle_type: model.vehicle_type
            })
        }
        showModal(true)
    }

    const valActions=()=>{
        if (!permissionsExist(permissions.permissionsList,"change_vehiclemodel") &&
            !permissionsExist(permissions.permissionsList,"delete_vehiclemodel")){
            setActionView(false)
        }else {
            setActionView(true)
        }
    }

    useEffect(()=>{
            getModelsAction(1, `&brand__id=${brandSelected}`)
    },[brandSelected])

    const columns = [
        {
            title: <FormattedMessage id="layout.model"/>,
            dataIndex: 'name',
            key: 'name',
            width: '30%',
        },
        {
            title: <FormattedMessage id="layout.brand"/>,
            key: 'brand',
            render: ((record) => {
                return <p>{record.brand.name}</p>
            })
        },
        ...(actionView ?
                [
                    {
                        title: <FormattedMessage id="layout.actions"/>,
                        dataIndex: 'id',
                        key: 'actions',
                        render: (id, record) => {
                            return <Space size={30}>
                                {
                                    permissionsExist(permissions.permissionsList, "change_vehiclemodel") &&
                                    <img key="EditIcon" src={EditIcon} style={{cursor:'pointer',  width:12}}
                                    onClick={() => editModel(record)}/>
                                }
                                {
                                    permissionsExist(permissions.permissionsList, "delete_vehiclemodel") &&
                                    <img key="DeleteIcon" src={DeleteIcon} style={{cursor:'pointer', width:12}} onClick={() => deleteItem(record)}/>
                                }
                            </Space>
                        }

                    }
                ]
                :
                []
        ),
    ];

    //Agregar columna de tipo de vehiculo si es del tenant jac
    if(tenantSelected === TENANT_JAC){
        columns.splice(2, 0, {
            title: "Tipo de vehiculo",
            key: 'vehicle_type',
            render: ((record) => {
                return <p>{vehicleTypes.find(type => type.id === record.vehicle_type)?.name}</p>
            }),
        })
    }

    let columns_remove_actions = [
        {
            title: <FormattedMessage id="layout.model" />,
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: <FormattedMessage id="layout.brand" />,
            key: 'brand',
            render: ((record) => {
                return <p>{record.brand.name}</p>
            }),
            filters: carbrands.map((brand) => {
                return {
                    text: brand.name,
                    value: brand.name
                }
            }),
            onFilter: (value, record) => record.brand.name.indexOf(value) === 0,
        },

    ];



    const handleChangeBrand = (value) => {
        if (value) {
            form.setFieldsValue({
                brand: value,
            });
        }
    }

    const changePagination = (page) => {
      getModelsAction(page, `&brand__id=${brandSelected}`);
    };


    useEffect(() => {
        if(carbrands){
            let list =[]
            carbrands.map(item => {
                list.push({value:item.id, label:item.name})
            })
            setListOptions(list)
        }
    }, [carbrands])
    

    return (
      <MainLayoutv2
        title={intl.formatMessage({ id: "services.models" })}
        index="7"
        openKeys={["sub1"]}
      >
        <CustomModal
          title=""
          footer=""
          visible={isModalVisible}
          onCancel={handleCancel}
        >
            <Row>
                <Col span={24}>
                    <Title level={2} style={{ marginBottom: 0 }}>
                        <img
                        src={turn}
                        alt="logo"
                        style={{ height: 60, width: 30, transform: "scaleX(-1)", marginRight:10 }}
                        />
                        <FormattedMessage id={currentModel ? "item.model.editModel": "item.model.addModel" } />
                    </Title>
                </Col>
                <Col span={24}>
                <Form
                    layout={"vertical"}
                    form={form}
                    onFinish={currentModel ? onEditModel : onSaveModel}
                >
                    <Form.Item
                    name={"brand"}
                    label={intl.formatMessage({ id: "layout.brand" })}
                    rules={[{ required: true }]}
                    >
                    {carbrands ? (
                        <Select style={{ width: "100%" }} onChange={handleChangeBrand}>
                        {carbrands.map((item) => {
                            return <Option value={item.id}>{item.name}</Option>;
                        })}
                        </Select>
                    ) : null}
                    </Form.Item>
                    <Form.Item
                    name={"name"}
                    label={intl.formatMessage({ id: "layout.name" })}
                    rules={[{ required: true }]}
                    >
                    <Input className='kinput'
                        placeholder={intl.formatMessage({ id: "item.model.nameModel" })}
                    />
                    </Form.Item>


                    {tenantSelected === TENANT_JAC && <SelectVehicleType style={{ width: "100%", marginTop: 10 }} name="vehicle_type"/>}
                </Form>
                </Col>
                <Col span={24} style={{ textAlign: "center" }}>
                    <Space>
                        <Button
                            size="large"
                            className="btn-string-blue btn-report-action"
                            onClick={() => handleCancel()}
                        >
                            <FormattedMessage id="layout.cancel" />
                        </Button>
                        <Button
                            className="btn-blue btn-report-action"
                            size="large"
                            onClick={() => handleOk()       }
                            >
                            <FormattedMessage id="services.members.create.save" />
                        </Button>
                    </Space>
                </Col>
            </Row>
          
        </CustomModal>

        <Content
          style={{ padding: "10px 10px", background: "white" }}
        >   
        <TabsConfig keyActive={'5'}/>
            <b>Filtrar por marca: </b>
            <KSelect style={{ width: "20%" }} options={listOptions} defaultValue='' onChange={(i) => setBrandSelected(i)}/>
            {

                permissionsExist(permissions.permissionsList,"add_vehiclemodel") &&
                <KButton
                    onClick={() => showModal(false)}
                    type="primary"
                    style={{ float: "right", marginBottom: 20 }}
                    size={"large"}
                    text={<FormattedMessage id="layout.createNew" />}
                />
            }

            
          <CustomTable
            loading={settings.fetching}
            dataSource={settings.modelsList}
            columns={ columns }
            fixed={true}
            scroll={{ x: 1200 }}
            pagination={{
              total: settings?.models?.count,
              pageSize:25,
              onChange: changePagination,
            }}
            /* models */
          />
        </Content>
          <DeleteModal actionDelete={() => onDeleteModel()} isVisible={modalDelete}
                       closeModal={closeModal}
                       modalTitle={<FormattedMessage id="deleted.ask"/>}
                       description={intl.formatMessage({ id:'deleted.description'},{ obj: forDelete && forDelete.name })} />
      </MainLayoutv2>
    );
}


const mapStateToProps = state => ({
    settings: state.settings,
    permissions:state.permissions
})


export default injectIntl(connect(mapStateToProps, {
    getModelsAction,
    changeLangAction,
    saveModelAction,
    deleteModelAction,
    editModelAction
})(ModelsPage))

