import React from 'react'
import { Tabs } from 'antd';
import { FormattedMessage } from 'react-intl';
import DashboardForm from './DashboardForm';
import SalesPermissionsForm from './SalesPermissionsForm';
import ServicesPermissionsForm from './ServicesPermissionsForm';
import MembersPermissions from './MembersPermissions';
import ConfigPermissions from './ConfigPermissions';
import styled from 'styled-components';

const CustomTabs = styled(Tabs)`
                    .ant-tabs-nav{
                      margin-bottom: 0px;
                    }
                    .ant-tabs-content-holder{
                      background-color: white;
                      padding: 20px;
                    }
                    .ant-tabs-tab{
                      background: none !important;
                      font-weight: 600;
                      color: black;
                      padding: 10px 40px !important;
                    }
                    .ant-tabs-tab-active{
                      background-color: white !important;
                      border-top-left-radius: 10px !important;
                      border-top-right-radius: 10px !important;
                      color: black;
                    }
                  `

const ProfileForm = ({perms, setPerms, ...props}) => {
 const { TabPane } = Tabs;
  return (
    <CustomTabs defaultActiveKey="1" type="card" style={{marginBottom:0}}>
    <TabPane tab={<FormattedMessage id="services.dash" />} key="1">
      <DashboardForm perms={perms} setPerms={setPerms} />
    </TabPane>
    <TabPane tab={<FormattedMessage id="services.sale" />} key="2">
      <SalesPermissionsForm perms={perms} setPerms={setPerms} />
    </TabPane>
    <TabPane tab={<FormattedMessage id="services.service" />} key="3">
      <ServicesPermissionsForm perms={perms} setPerms={setPerms} />
    </TabPane>
    <TabPane tab={<FormattedMessage id="services.members_center" />} key="4">
      <MembersPermissions perms={perms} setPerms={setPerms} />
    </TabPane>
    <TabPane tab={<FormattedMessage id="services.setts" />} key="5">
      <ConfigPermissions  perms={perms} setPerms={setPerms} />
    </TabPane>
  </CustomTabs>
  )
}

export default ProfileForm