import React, { useState, useEffect } from 'react'
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import {
    Row,
    Col,
    Card,
    Typography,
    Layout,
    Form,
    Divider,
    Collapse,
    Input,
    Table,
    Spin,
    message,
    Space
} from 'antd';
import ServiceLayout from '../../layouts/ServiceLayout';
import {getServiceDetail, getEntryConfigurations, setEntryService, changePromiseDateOrder} from '../../redux/serviceOrderDuck'
import MenuServiceDetail from "../../components/services/MenuServiceDetail";
import SectionTitle from "../../components/common/elements/SectionTitle/SectionTitle";
import KButton from "../../components/common/elements/KButton/KButton";
import { axiosApi, urlfull } from "../../lib/utils/axiosApi";
import ServiceDetailTable from "../../components/services/ServiceDetailTable";
import KModal from '../../components/common/elements/KModal/KModal';
import KSelect from '../../components/common/elements/KSelect/KSelect';
import {
    EditOutlined
} from '@ant-design/icons';
import QRReaderComponent from '../../components/qrCode/qrCode';
import { onlyNumeric } from '../../lib/utils/rules';
import MainLayoutv2 from '../../layouts/MainLayoutv2';
import _ from 'lodash'

const { Title } = Typography;
const {Panel} = Collapse;
const WorkshopEntrancePageJAC = ({user,getServiceDetail,getEntryConfigurations,serviceOrder,changePromiseDateOrder, setEntryService,intl, settings, ...props})=>{
    //const [messages, setMessages] = useState(null)
    const { id } = useParams();
    let history = useHistory();
    const [form] = Form.useForm();
    const [generalLoading,setGeneralLoading] = useState(false)
    const [readyToReadQr, setReadyToReadQr] = useState(false);
    const [maxLevel, setMaxLevel] = useState(1)
    const [levelSelect, setLevelSelect] = useState(1)
    const [openSelectLevel, setOpenSelectLevel] = useState(false)
    const [levelList, setLevelList] = useState([])


    const data_init = [
        {
            name:'Ox',
            description:'Oxido',
            level: 1
        },
        {
            name:'Sc',
            description: 'Suciedad',
            level: 1
        },
        {
            name:'Gl',
            description: 'Golpe',
            level: 2
        },
        {
            name:'Ry',
            description: 'Rayón',
            level: 2
        },
        {
            name:'Br',
            description: 'Brisado',
            level: 3
        },
        {
            name:'Ba',
            description: 'Basura',
            level: 3
        },
        {
            name:'Po',
            description: 'Poro',
            level:1
        },
        {
            name:'Esc',
            description: 'Escurrida',
            level: 1
        },
        {
            name:'Rb',
            description: 'Rebaba',
            level: 2
        },
        {
            name:'Mu',
            description: 'Mutilación',
            level: 2
        },
        {
            name:'Mr',
            description: 'Mala reparación',
            level: 3
        },
        {
            name:'Lf',
            description: 'Liquido de frenos',
            level: 3
        },
        {
            name:'T',
            description: 'Transparencia',
            level: 1
        },
        {
            name:'Pe',
            description: 'Pintura estrellada',
            level: 1
        },
        {
            name:'',
            description: '',
            level: 4
        }

    ]

    const columns = [
        {
            title: 'Incidencia',
            dataIndex: 'description',
            render: (text, item) => item.name!==''?`(${item.name})-${text}`:null
        },
        {
            title: 'Valor',
            dataIndex: 'name',
            render: (text) => text!==''?<Form.Item
                name={text}
                label={''}
                rules={[onlyNumeric]}
            >
                <Input className={'kinput'} />
            </Form.Item>:null
        },
    ]

    const [data, setData] = useState(data_init)

    useEffect(() => {
        refillDataForm()
    }, [serviceOrder])

    useEffect(() => {
        if (id) {
          getServiceDetail(id);
        }
      }, [])

    const refillDataForm = async()=>{
        const metadataService = serviceOrder?.serviceDetail?.order_dynamic_data?.metadata
        let data_new = {}
        let elementListLevelCheck = []
        let elementListLevel = [
            {
                label: 1,
                value: 1
            },
            {
                label: 2,
                value: 2
            },
            {
                label: 3,
                value: 3
            },
            {
                label: 4,
                value: 4
            }
        ]
        for (let element in data_init){
            if (metadataService?.incidences){
                if (metadataService.incidences[data_init[element].name]){
                    data_new[data_init[element].name] = metadataService.incidences[data_init[element].name]
                }else{
                    data_new[data_init[element].name] = 0
                }
                if (!elementListLevelCheck.includes(data_init[element].level)){
                    elementListLevelCheck.push(data_init[element].level)
                    elementListLevel.push({
                        'label': data_init[element].level,
                        'value': data_init[element].level
                    })
                }
            }else{
                data_new[data_init[element].name] = 0
            }
        }
        if(elementListLevel.length > 0){

            let arrayLevel =  elementListLevel.filter((item, index, self) => self.findIndex(t => t.value === item.value) === index)
            setLevelList(arrayLevel)
        }
        form.setFieldsValue(data_new)
        if (metadataService){
            let level_cost = metadataService.metaLevel?.split("_")
            if (level_cost){
                setMaxLevel(level_cost[0])
            }
        }
    }

    const getResultQr = (data)=>{
        let dataElement = data_init.filter(element=> element.name == data)
        if (dataElement.length > 0){
            let dataOld = form.getFieldValue(data)
            let dataSet = {}
            dataSet[data] = dataOld + 1
            form.setFieldsValue(dataSet)
            checkMaxLevel(dataElement[0].level);
        }
    }

    const handleFieldChange = (fieldName, fieldValue) => {
        let dataElement = data_init.find(element => element.name === fieldName);
        if (dataElement) {
            if (fieldValue === undefined) {
                let dataOld = form.getFieldValue(fieldName);
                let dataSet = {};
                dataSet[fieldName] = dataOld + 1;
                form.setFieldsValue(dataSet);
            }

            /*
            se omite el calculo automático del nivel
            if (fieldValue == 0) {
                const currentFieldValues = form.getFieldsValue();
                let maxFieldValue = 1;
    
                for (let name in currentFieldValues) {
                    if (currentFieldValues.hasOwnProperty(name) && currentFieldValues[name] > 0) {
                        const value = data_init.find(item => item.name === name);
                        if (value.level > maxFieldValue) {
                            maxFieldValue = value.level;
                        }
                    }
                }

                setMaxLevel(maxFieldValue);
            } else {
                checkMaxLevel(dataElement.level);
            }*/
        }
    };
    
    const checkMaxLevel = (level) => {
        if (level > maxLevel) {
            setMaxLevel(level);
        }
    };
    
    const handleFormChange = (changedFields) => {
        if (changedFields.length !== data_init.length){
            changedFields.forEach(changedField => {
                const fieldName = changedField.name[0];
                const fieldValue = changedField.value;
                handleFieldChange(fieldName, fieldValue);
            });
        }
    };

    const sendData = async(values)=>{
        let jsonData = {
            metaLevel: maxLevel+"_0",
            incidences: values
        }
        try{
            await axiosApi.post('/workshop/workshop-order-jac/'+id+'/save_incidences/',jsonData);
            message.success('Cambiado exitosamente')
        }catch (e){
            message.error('Hubo un error, porfavor intenta nuevamente')
        }
        await getServiceDetail(id);
    }

    const actionOpenModal = ()=>{
        setLevelSelect(maxLevel)
        setOpenSelectLevel(true)
    }

    return (
        <MainLayoutv2
            isServiceDetail={true}
            index={'expedientes'}
            title={'Registro de entrada'}>


            <Spin spinning={generalLoading || serviceOrder.loading}>
                <Layout style={{ marginTop: 10 }}>

                    <Card
                        className="card_details_service"
                    >
                        <MenuServiceDetail menuSelected={1} />
                        <br/>
                        <br/>

                        <Row>
                            <Col span={24}>
                                <Form layout={'vertical'}
                                      form={form}
                                      name="control-hooks"
                                      onFinish={sendData}
                                      onFieldsChange={handleFormChange}
                                >

                                    <SectionTitle title={
                                        `Recepción de vehículo`
                                    }/>

                                    <Row>
                                        <Col span={24} style={styles.description}>
                                            <Collapse defaultActiveKey={['1']}>
                                                <Panel header="Detalle de Orden" key="1">
                                                    <ServiceDetailTable serviceOrder={serviceOrder}/>
                                                </Panel>
                                            </Collapse>


                                        </Col>
                                        <Col span={24}>
                                            <Divider plain>Captura</Divider>
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <p>Maxlevel {maxLevel}</p>
                                            <KButton block disabled={readyToReadQr} style={{marginBottom: '10px'}} type={'large'} text={'Leer QR'} onClick={()=>setReadyToReadQr(!readyToReadQr)}/>
                                            <KButton block htmlType={'submit'} type={'secondary'} text={'Guardar Recepción'}/>
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <Row>
                                                <Col span={24} style={styles.levelHeader}>
                                                    <Title style={{color:'white'}}>Nivel: {maxLevel} <EditOutlined onClick={()=>actionOpenModal()}/></Title>
                                                </Col>
                                            </Row>
                                            <Table
                                                columns={columns}
                                                dataSource={data}
                                                pagination={false}
                                                scroll={{ y: `calc(100vh - 250px)` }}
                                            />
                                        </Col>
                                    </Row>

                                </Form>
                            </Col>
                        </Row>
                    </Card>
                    {readyToReadQr && <KModal
                        title={"Lector de código QR"}
                        onCancel={()=> setReadyToReadQr(false)}
                        loading={false}
                        visible={readyToReadQr}>
                        <Col span={24} style={{ textAlign: "center"}}>
                            <QRReaderComponent
                                readyToReadQr={readyToReadQr}
                                setReadyToReadQr={setReadyToReadQr}
                                getResultQr = {getResultQr}
                            />
                        </Col>
                    </KModal>}
                    <KModal
                        title={"Selecciona un nivel"}
                        visible={openSelectLevel}
                        onCancel={()=> setOpenSelectLevel(false)}
                    >
                        <Row justify="center">
                            <KSelect options={levelList} value={levelSelect}  onChange={(e)=> setLevelSelect(e)} />
                            <Space style={{marginTop: 20}}>
                                <KButton
                                    size="large"
                                    text={"Cancelar"}
                                    type={'secondary'}
                                    onClick={() => setOpenSelectLevel(false) }
                                >

                                </KButton>
                                <KButton
                                    className="btn-blue btn-report-action"
                                    size="large"
                                    text={"Guardar"}
                                    onClick={() => {
                                        setMaxLevel(levelSelect)
                                        setOpenSelectLevel(false)
                                    }}
                                >

                                </KButton>
                            </Space>
                        </Row>
                    </KModal>

                </Layout>
            </Spin>

        </MainLayoutv2>
    )
}


const styles = {
    description:{borderRadius:20,marginBottom:10,marginTop:10,padding:20},
    levelHeader:{borderRadius:20,marginBottom:10,background:'#090731',marginTop:10,minHeight:50,padding:20}
}

const mapStateToProps = state => ({
    settings: state.settings,
    user: state.user,
    serviceOrder: state.serviceOrder
})

export default injectIntl(connect(mapStateToProps,{getServiceDetail,changePromiseDateOrder, getEntryConfigurations, setEntryService})(WorkshopEntrancePageJAC))