import { axiosApi, config, API } from "../lib/utils/axiosApi"
import cookie from "js-cookie";
import { message } from 'antd'

// Datos iniciales
const initialData = {
    loadingLogin: false,
    errorLogin: '',
    token: '',
    user: null,
    isLoggedIn: null,
    isEmailSent: null,
    IsPasswordConfirmed: null,
    accounts: null,
    accountsList: [],
    latePayments:0,
    teams:[]
}


const EXTRA_ACTION = "EXTRA_ACTION";
const GET_AUTH = "GET_AUTH"
const LOGOUT = "LOGOUT"
const GET_AUTH_SUCCESS = "GET_AUTH_SUCCESS"
const GET_AUTH_ERROR = "GET_AUTH_ERROR"

const GET_RESET = "GET_RESET"
const GET_RESET_SUCCESS = "GET_RESET_SUCCESS"
const GET_RESET_ERROR = "GET_RESET_ERROR"

const GET_CONFIRM = "GET_CONFIRM"
const GET_CONFIRM_SUCCESS = "GET_CONFIRM_SUCCESS"
const GET_CONFIRM_ERROR = "GET_CONFIRM_ERROR"

const GET_ACCOUNTS = "GET_ACCOUNTS"
const GET_ACCOUNTS_SUCCESS = "GET_ACCOUNTS_SUCCESS"
const GET_ACCOUNTS_ERROR = "GET_ACCOUNTS_ERROR"
const GET_LICENSE_STATUS = "GET_LICENSE_STATUS"
const TEAMS = "TEAMS"

export default function reducer(state = initialData, action) {
    switch (action.type) {
        case LOGOUT:
            return { ...state, user: null, token: '', isLoggedIn: false }
        case GET_AUTH:
            return { ...state, fetching: true }
        case GET_AUTH_SUCCESS:
            return {
                ...state,
                user: action.payload,
                isLoggedIn: true
            };
        case GET_AUTH_ERROR:
            return { ...state, user: null, isLoggedIn: false }
        case GET_RESET:
            return { ...state, fetching: true }
        case GET_RESET_SUCCESS:
            return {
                ...state,
                user: null,
                isEmailSent: true
            };
        case GET_CONFIRM:
            return { ...state, fetching: true }
        case GET_CONFIRM_SUCCESS:
            return {
                ...state,
                fetching: false,
                isPasswordConfirmed: true
            };
        case GET_RESET_ERROR:
            return {
                ...state,
                user: null,
                isEmailSent: false
            };
        case GET_CONFIRM_ERROR:
            return {
                ...state,
                fetching: false,
                error: action.payload,
                isPasswordConfirmed: false
            }
        case GET_ACCOUNTS:
            return { ...state, fetching: true }
        case TEAMS:
            return { ...state, teams: action.payload }
        case GET_ACCOUNTS_SUCCESS:
            return { ...state, fetching: false, ...action.payload }
        case GET_ACCOUNTS_ERROR:
            return { ...state, fetching: false, accounts: [] }
        case GET_LICENSE_STATUS:
            return { ...state, fetching: false, latePayments: action.payload }
        case EXTRA_ACTION:
            return { ...state, ...action.payload };
        default:
            return state
    }
}

/* export const sendLogin = (flag) => async(dispatch) => {
const sendLogin = (flag) =>{
    dispatch({
      type: SEND_LOGIN,
      payload: flag,
    });
} */

//almacenams la sesion en el storage
function saveStorage(storage) {
    console.log('RESPONSE', storage)
    localStorage.setItem('storage', JSON.stringify(storage))
    cookie.set('token', storage.token)

}

export const logOut = () => async (dispatch) => {
    try {
        localStorage.removeItem('storage')
        localStorage.removeItem('settings_kk')
        cookie.remove('storage')
        dispatch({
            type: LOGOUT
        })
        console.log('eliminado')
        return true
    } catch (e) {
        return false
    } finally {

    }
}


export const getAuthAction = (data) => async (dispatch) => {
    dispatch({
        type: EXTRA_ACTION,
        payload: {
            loadingLogin: true,
            errorLogin: "",
        },
    });
    try {
        const response = await axiosApi.post(API.SECURITY.LOGIN, data)
        const data_token = {
            ...response.data,
            token: response.data.access,
            user: response.data.user
            /*user: {
                pk: 1,
                email: 'danielwongfarfan@gmail.com',
                first_name: "Daniel",
                last_name: "Wong",
                username: "root"
            }*/
        }
        saveStorage(data_token)
        dispatch({
            type: GET_AUTH_SUCCESS,
            payload: data_token
        });
    } catch (err) {
        /* console.log(err.response.data.detail); */
        dispatch({
            type: GET_AUTH_ERROR,
        })
        message.error('Accesos incorrectos');
        dispatch({
            type: EXTRA_ACTION,
            payload: {
                loadingLogin: false,
                errorLogin: err.response.data.detail,
            },
        });


    } finally {
        dispatch({
            type: EXTRA_ACTION,
            payload: {
                loadingLogin: false,
            },
        });
    }
}

export let getResetAction = (data) => async (dispatch, getState) => {
    dispatch({
        type: GET_RESET
    })
    return await axiosApi.post(`/rest-auth/password/reset/`, data)
        .then(res => {
            dispatch({
                type: GET_RESET_SUCCESS,
                payload: res.data
            })
        }).catch(error => {
            console.log(error)
            dispatch({
                type: GET_RESET_ERROR,
                payload: error.response.message
            })
        })
}

export let getConfirmAction = (data) => async (dispatch, getState) => {
    dispatch({
        type: GET_CONFIRM
    })
    return await axiosApi.post(`/rest-auth/password/reset/confirm/`, data)
        .then(res => {
            dispatch({
                type: GET_CONFIRM_SUCCESS,
                payload: res.data
            })
        }).catch(error => {
            console.log(error)
            dispatch({
                type: GET_CONFIRM_ERROR,
                payload: error.response.message
            })
        })
}

export let getAccountsAction = () => async (dispatch, getState) => {
    dispatch({
        type: GET_ACCOUNTS
    })
    await axiosApi.get(API.SECURITY.USER)
        .then((response) => {
            dispatch({
                type: GET_ACCOUNTS_SUCCESS,
                payload: { accounts: response.data, accountsList: response.data.results }
            });
        })
        .catch((error) => {
            dispatch({
                type: GET_ACCOUNTS_ERROR
            })
        })
}

export let getTeams= () => async (dispatch, getState) =>{
    try{
        const res = await axiosApi.get(API.SECURITY.TEAMS);
        console.log('getTeams',res)
        dispatch({
            type: TEAMS,
            payload: res?.data?.results ?? []
        });

    }catch (e) {

    }
}

export let getLicenseStatus = () => async (dispatch, getState) => {

    try{
        //onbtenemos el company seleccionado
        let localstore = localStorage.getItem('settings_kk');
        let companySelected = null;
        if(localstore){
            localstore = JSON.parse(localstore)
            companySelected = localstore?.branchSelected?.id
        }

        const res = await axiosApi.get(API.BUSINESS.LICENSE_STATUS.replace('{companyId}',companySelected));
        console.log(res)
        dispatch({
            type: GET_LICENSE_STATUS,
            payload: res?.data
        })
        console.log('llega aqui',  res?.data)
    }catch (e){
        console.log(e)
    }
}