import { Table, Checkbox } from 'antd'
import React from 'react'
import styled from 'styled-components';
import UnCheked from '../../image/check_vacio.svg';
import Cheked from '../../image/check_blue.svg';
import {TableFormProfile} from '../MyComponents';
import { FormattedMessage } from 'react-intl';




const TableCatalogs = ({ data=[], perms, setPerms, ...props}) => {

    const validateExist = (key) =>{
        return perms.includes(key)
    }

    const changePerms = (key) => {
        console.log('perms',perms);
        if(validateExist(key)){
            let newPerms = perms.filter(item => item !== key);
            setPerms(newPerms);
        }else{
            setPerms([...perms, key])
        }
    }

    const columns = [
        {
            title: '',
            key: 'title',
            render: (record) => (
                <FormattedMessage id={record.title} />
            )
        },
        {    
            title: 'Ver',
            key:'view',
            width: 50,
            align: 'center',
            render: (record) => (
                <CustomCheckBox cheked={validateExist(record.show)} click={changePerms} recordKey={record.show}  />
            )
        },
        {    
            title: 'Crear',
            key:'create',
            width: 50,
            align: 'center',
            render: (record) => (
                <CustomCheckBox cheked={validateExist(record.add)} click={changePerms} recordKey={record.add}  />
            )
        },
        {    
            title: 'Editar',
            key:'edit',
            width: 50,
            align: 'center',
            render: (record) => (
                <CustomCheckBox cheked={validateExist(record.edit)} click={changePerms} recordKey={record.edit}  />
            )
        },
        {    
            title: 'Eliminar',
            key:'delete',
            width: 50,
            align: 'center',
            render: (record) => (
                <CustomCheckBox cheked={validateExist(record.delete)} click={changePerms} recordKey={record.delete}  />
            )
        }
    ]

    const CustomCheckBox = ({ cheked = false, click, recordKey, ...props}) => (
        <img src={cheked?Cheked:UnCheked} alt="" style={{width:16, cursor:'pointer'}} onClick={() => click(recordKey)} />
    )


  return (
    <TableFormProfile columns={columns} dataSource={data} pagination={false} />
  )
}

export default TableCatalogs