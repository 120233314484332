import React, { useState, useEffect } from 'react'
import DashboardLayout from '../../layouts/DashboardLayout';
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import useChatList, { descargarCsv } from "../../hooks/useChatList";
import axios from 'axios'
import AxiosApi , {API} from "../../lib/utils/axiosApi";
import {useParams} from "react-router-dom";
import {Row, Col, Card, Typography} from 'antd';
import CardPurpleLight from "../../components/common/elements/CardPurpleLight/CardPurpleLight";
import _ from "lodash";
import ServiceButtonsHeader from "../../components/services/ServiceButtonsHeader";
import axiosApi from "../../lib/utils/axiosApi";
const { Title, Text } = Typography;

const ChatPage = ({user,sales})=>{
    //const [messages, setMessages] = useState(null)
    const { id } = useParams();
    const [saleOrder, setSaleOrder] = useState(null);
    //const [orderId, setOrderId] = useState('12345667')
    const {messages, ChatList, AreaMessage} = useChatList(id,user.id)

    useEffect(()=>{
        window.scrollTo(0,document.body.scrollHeight);
        loadData(id)
    },[])


    const loadData= async (id)=>{
        try{
            const saleOrderResponse = await axiosApi.get(
                `${API.SALE.SALE_ORDER}${id}`
            );
            setSaleOrder(saleOrderResponse.data);
        }catch (e){

        }
    }



    return (
        <DashboardLayout
            index={'ordenes_de_venta'}
            HeaderComponent={<>
                <Row>
                    <Col>
                        <CardPurpleLight
                            title={`FOLIO ${_.get(saleOrder, "order.folio", "")}`}
                            description={`${_.get(saleOrder, "car_brand.name", "")} 
                      ${_.get(saleOrder, "car_model.name", "")} ${_.get(
                                saleOrder,
                                "car_year",
                                ""
                            )}`}/>
                    </Col>
                    <Col style={{ display: "flex" }}>
                        <Card
                            style={{
                                background: "transparent",
                                margin: "auto",
                            }}
                        >
                            <Title level={3} style={{ marginBottom: 0, color: "#341EFF" }}>
                                {`${_.get(saleOrder, "order.customer.first_name", "")} 
                        ${_.get(
                                    saleOrder,
                                    "order.customer.mlast_name",
                                    ""
                                )} ${_.get(saleOrder, "order.customer.last_name", "")}`}
                            </Title>
                        </Card>
                    </Col>
                </Row>
                <Row style={{ paddingTop: 40, marginBottom:20 }}>
                    <Col span={24} >
                        <ServiceButtonsHeader viewDetail={true} orderId={_.get(saleOrder, "order.id", "")}/>
                    </Col>
                </Row>
            </>}
            title={'Chat '}>
                    <Card 
                        title={`Orden`}
                        bordered={false} 
                        className="cardChat"
                        >
                        <ChatList/>
                        <hr/>
                        <AreaMessage/>
                    </Card>
        </DashboardLayout>
    )
}

const mapStateToProps = state => ({
    settings: state.settings,
    user: state.user,
    sales: state.sales
})

export default injectIntl(connect(mapStateToProps)(ChatPage))