

//constants
let initialData = {
    loggedIn:false,
    fetching:false,
    userData:{}

}

const LOGIN = "LOGIN"
const LOGIN_SUCCESS = "LOGIN_SUCCESS"
const LOGIN_ERROR = "LOGIN_ERROR"
const LOG_OUT = "LOG_OUT";

// reducer

export default function reducer(state=initialData,action){
    switch (action.type){
        case LOGIN:
            return {...state, fetching: true}
        case LOGIN_SUCCESS:
            return {...state, fetching: false,...action.payload}
        case LOGIN_ERROR:
            return {...state, fetching: false, error:action.payload}
        case LOG_OUT:
            return {...initialData}
        default:
            return state
    }
}

//almacenams la sesion en el storage
function saveStorage(storage){
    localStorage.storage = JSON.stringify(storage)
}

//action (action creator)

export let logOutAction=()=>(dispatch, getState)=>{
    dispatch({
        type:LOG_OUT
    })

    localStorage.removeItem('storage')
}

export let restoreSessionAction=()=>(dispatch,getState)=>{
    let storage =  localStorage.getItem('storage')
    if(storage){
        storage = JSON.parse(storage)
        dispatch({
            type:LOGIN_SUCCESS,
            payload: storage.user
        })
    }

}

export let doLoginAction=()=>(dispatch, getState)=>{
    dispatch({
        type:LOGIN
    })

    // aqui se hace el promise de hacerlogin


    dispatch({
        type:LOGIN_SUCCESS,
        payload: {user:{}}
    })
    saveStorage(getState)

}
