import React, { useState, useEffect } from 'react'
import {
    Layout,
    Menu,
    Row,
    Form,
    Col,
    Typography,
    Input,
    Space,
    Button,
    Card,
    Avatar,
    Dropdown,
    List,
    Badge,
    message,
    Alert
  } from 'antd';
  import {
    DownOutlined,
    PaperClipOutlined,
    MailOutlined,
    HomeOutlined,
    DownloadOutlined,
    PoweroffOutlined,
    MenuOutlined,
    BellTwoTone
  } from '@ant-design/icons';
  import StatusOrder from "../components/services/StatusOrder";
  import { injectIntl, FormattedMessage } from 'react-intl';
  import styles from '../components/common/elements/ButtonLightHeader/ButtonLightHeader.module.css'
  import _ from 'lodash'
  import { useHistory, Redirect, useLocation, useParams } from "react-router-dom"
  import ButtonLightHeader from '../components/common/elements/ButtonLightHeader/ButtonLightHeader';
  import CardPurpleLight from '../components/common/elements/CardPurpleLight/CardPurpleLight';  import { useMediaQuery } from 'react-responsive';
  import { useSelector } from 'react-redux';
  import { downloadFile } from '../lib/utils/utils'
  import axiosApi, { urlfull } from "../lib/utils/axiosApi";
  import useSettingsKikert from '../hooks/useSettingsKikert'

const HeaderActionsServiceOrder = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    let { id } = useParams();
    const history = useHistory()
    const serviceOrder = useSelector((state) => state.serviceOrder)
    const [loadingReport, setLoadingReport] = useState(false)
    const [loadingReportInventory, setLoadingReportInventory] = useState(false)
    const {getMetaValue} = useSettingsKikert()


    const exitPassReport = async()=>{
      setLoadingReport(true)
        try{
         const res = await  axiosApi.get(`${urlfull}service/order/${id}/build_report/?type=EXIT_PASS`,{responseType: 'blob'})
          downloadFile(res.data,`pase_salida_${_.get(
                      serviceOrder,
                      "serviceDetail.folio",
                      ""
                    )}`,'application/pdf')
          console.log(res)
        }catch (e){
          console.log(e)
        }finally {
          setLoadingReport(false)
        }
    }

    const downloadInventoryReport = async()=>{
      setLoadingReportInventory(true)
      try{
        const res = await  axiosApi.get(`${urlfull}service/order/${id}/build_report/?type=ENTRY_INVENTORY`,{responseType: 'blob'})
        downloadFile(res.data,`inventario_${_.get(
            serviceOrder,
            "serviceDetail.folio",
            ""
        )}`,'application/pdf')
        console.log(res)
      }catch (e){
        console.log(e)
      }finally {
        setLoadingReportInventory(false)
      }
    }

    const reportAdhesion = async()=>{
      setLoadingReport(true)
        try{
         const res = await  axiosApi.get(`${urlfull}service/order/${id}/build_report/?type=ADHESION`,{responseType: 'blob'})
          downloadFile(res.data,`carta_adhesion${_.get(
                      serviceOrder,
                      "serviceDetail.folio",
                      ""
                    )}`,'application/pdf')
          console.log(res)
        }catch (e){
          console.log(e)
        }finally {
          setLoadingReport(false)
        }
    }

    const report1 = async()=>{
      setLoadingReport(true)
        try{
         const res = await  axiosApi.get(`${urlfull}service/order/${id}/build_report/?type=COVER`,{responseType: 'blob'})
          downloadFile(res.data,`caratula_${_.get(
                      serviceOrder,
                      "serviceDetail.folio",
                      ""
                    )}`,'application/pdf')
        }catch (e){
          console.log(e)
        }finally {
          setLoadingReport(false)
        }
    }

  return (
    <div>
      <Row>
        <Col span={24}>
          {
            !isMobile ? (
              <Space>
                {_.has(serviceOrder, "serviceDetail.folio") && (
                  <CardPurpleLight
                    title={`Folio ${_.get(
                      serviceOrder,
                      "serviceDetail.folio",
                      ""
                    )}`}
                  />
                )}
                <Button
                  className={styles.button_header}
                  size={"large"}
                  icon={<HomeOutlined />}
                  onClick={() => history.push(`/service-detail/${id}/`)}
                >
                  <FormattedMessage id="servicesDetails.home" />
                </Button>
                <ButtonLightHeader
                  loading={loadingReport}
                  className={styles.button_header}
                  size={"large"}
                  icon={<DownloadOutlined />}
                  text={ <FormattedMessage id="cover" />}
                  onClick={report1}
                />
                {
                  getMetaValue('HIDE_ADHESION_REPORT','0') === '0' && (
                    <ButtonLightHeader
                      loading={loadingReport}
                      className={styles.button_header}
                      size={"large"}
                      icon={<DownloadOutlined />}
                      text={ "Carta de adhesión"}
                      onClick={reportAdhesion}
                    />
                  )
                }
                

                <ButtonLightHeader
                  loading={loadingReportInventory}
                  icon={<DownloadOutlined />}
                  size={"large"}
                  className={styles.button_header}
                  onClick={downloadInventoryReport}
                  text={"Descargar inventario"}
                />

                {
                  serviceOrder?.serviceDetail?.status === 'CLOSED' && (
                    <ButtonLightHeader
                      loading={loadingReport}
                      className={styles.button_header}
                      size={"large"}
                      icon={<DownloadOutlined />}
                      text={ "Pase de salida"}
                      onClick={exitPassReport}
                    />
                  )
                }
                <ButtonLightHeader
                  size={"large"}
                  onClick={() => history.push(`/service-messages/${id}/`)}
                  icon={<MailOutlined />}
                  text={<FormattedMessage id="services.mess" />}
                />
                <ButtonLightHeader
                  size={"large"}
                  icon={<PaperClipOutlined />}
                  onClick={() => history.push(`/service-files/${id}/`)}
                  text={<FormattedMessage id="services.files.files" />}
                />
              </Space>
            ) : (
              <ul style={{listStyle:"none",margin:0,padding:0}}>
                {
                  _.has(serviceOrder, "serviceDetail.folio") && (
                    <li>
                      <CardPurpleLight
                        title={`Folio ${_.get(
                          serviceOrder,
                          "serviceDetail.folio",
                          ""
                        )}`}
                      />
                    </li>
                  )
                }
                <li>
                  <Button
                    className={styles.button_header}
                    size={"large"}
                    icon={<HomeOutlined />}
                    onClick={() => history.push(`/service-detail/${id}/`)}
                  >
                    <FormattedMessage id="servicesDetails.home" />
                  </Button>
                </li>
                <li>
                  <ButtonLightHeader
                    loading={loadingReport}
                    className={styles.button_header}
                    size={"large"}
                    icon={<DownloadOutlined />}
                    text={ <FormattedMessage id="cover" />}
                    onClick={report1}
                  />
                </li>
                {
                  getMetaValue('HIDE_ADHESION_REPORT','0') === '0' && (
                    <li>
                      <ButtonLightHeader
                        loading={loadingReport}
                        className={styles.button_header}
                        size={"large"}
                        icon={<DownloadOutlined />}
                        text={ "Carta de adhesión"}
                        onClick={reportAdhesion}
                      />
                    </li>
                  )
                }
                
                {
                  serviceOrder?.serviceDetail?.status === 'CLOSED' && (
                    <li>
                      <ButtonLightHeader
                        loading={loadingReport}
                        className={styles.button_header}
                        size={"large"}
                        icon={<DownloadOutlined />}
                        text={ "Pase de salida"}
                        onClick={exitPassReport}
                      />
                    </li>
                  )
                }
                <li>
                  <ButtonLightHeader
                    size={"large"}
                    onClick={() => history.push(`/service-messages/${id}/`)}
                    icon={<MailOutlined />}
                    text={<FormattedMessage id="services.mess" />}
                  />
                </li>
                <li>
                  <ButtonLightHeader
                    size={"large"}
                    icon={<PaperClipOutlined />}
                    onClick={() => history.push(`/service-files/${id}/`)}
                    text={<FormattedMessage id="services.files.files" />}
                  />
                </li>
              </ul>
            )
          }
          <StatusOrder style={{marginTop:10}}/>
        </Col>
      </Row>
    </div>
  )
}

export default HeaderActionsServiceOrder

