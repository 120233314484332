import React from 'react'
import AuthInitializer from "./components/common/AuthInitializer";
import AuthRequired from "./components/common/AuthRequired"

const AppAuthRequiredContainer = ({children}) => {
    return (
        <AuthInitializer>
            <AuthRequired>{children}</AuthRequired>
        </AuthInitializer>
    )
}

export default AppAuthRequiredContainer;