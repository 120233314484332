import React, { useState, useEffect } from 'react'
import { MessageBox, SystemMessage } from 'react-chat-elements'
import 'react-chat-elements/dist/main.css'; // https://github.com/detaysoft/react-chat-elements#messagelist-component
import _ from 'lodash'
import {
  Row,
  Col,
  Form,
  Input,
  Upload,
  Button,
  Spin,
  Select,
  Checkbox,
  Empty,
  Tooltip,
  Typography,
  Divider,
} from "antd";
import axios from "axios";
import AxiosApi, {tenantSelected} from "../lib/utils/axiosApi";
import {
  SendOutlined,
  DownloadOutlined,
  PaperClipOutlined,
} from "@ant-design/icons";
import { API } from "../lib/utils/axiosApi";
import moment from 'moment'
import nodataimg from '../image/nodata.svg';
import imgUser from "../image/UserSinBarba.png";

import { connect } from 'react-redux'
import { injectIntl } from "react-intl";
import { FormattedMessage } from "react-intl";
import { changeLangAction } from "../redux/settingsDuck"

import { ref, onValue, set } from "firebase/database";
import fireDB from '../firebase/firebaseConfig';

import { CSVLink } from "react-csv";
import chat from "../pages/chatKikkert/chat";

const format1 = "DD/MM/YY  HH:mm:ss"
const useChatList = (orderId, userId) => {

    const [messages, setMessages] = useState(null)
    const [loading, setLoading] = useState(false)
    const [fileSelected, setFileSelected] = useState(null)
    const [noData, setNoData] = useState(null)
    const [typeCustomer, setTypeCustomer] = useState(false)
    const [form] = Form.useForm();
    const { Title } = Typography

    const headers = [
        { label: "Mensaje", key: "mensaje" },
        { label: "Tipo", key: "tipo" },
        {label:"Link documento", key:'link'},
        { label: "Fecha", key: "fecha" },
        { label: "De usuario", key: "desde" },
    ]


    useEffect(() => {

        if (orderId)
            obtenerData()
    }, [orderId])

    const onFinish = (values) => {
        console.log('Finish:', values);
        sendMessage(values.message)
    };

    const configUpload = {
        onRemove: file => {

        },
        beforeUpload: file => {
            setFileSelected(file)
            setLoading(true)
            console.log(file)
            sendFile(file)
            return false;
        }
    };

    const sendFile = async (file) => {
        try {
            let url = `/service/order/${orderId}/save_file_from_chat/`
            let formData = new FormData();
            formData.set('file', file)
            const response = await AxiosApi.post(url, formData)
            if (response.status === 200) {
                let filedata = response.data;
                delete filedata.message;
                sendMessage(filedata.file_name, filedata)
                console.log(filedata)
            } else {
                alert('error al subir el archivo')
            }
            console.log(response)
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    }

    const sendMessage = async (message, fileData) => {
        setLoading(true)
        const req = {
            roomId: orderId,
            idMessenger: userId,
            message,
            type: typeCustomer ? 'customer' : 'asesor',
            messageType: fileData ? 'file' : 'text',
            title: '',
            fileData: fileData ? fileData : null,
            tenant:tenantSelected
        }
        try {
            const res = await axios.post(API.CHAT.MESSAGE, req)
            form.resetFields()
            obtenerData()
        } catch (e) {
            alert('No se pudo enviar el mensaje')
        } finally {
            setLoading(false)
        }
    }


    const obtenerData = () => {
        const variable = ref(fireDB,`${tenantSelected}/chat/${orderId}`);
        setLoading(true)
        onValue(variable, (snapshot) => {
            const data = snapshot.val();
            console.log('mensajesss', data);
            if (data) {
                setMessages(data)
                setNoData(false)
            } else {
                setNoData(true)
            }

            window.scrollTo(0, document.body.scrollHeight);
            /*console.log('Estos son los mensajes Mauricio', data);*/
            setLoading(false)
        });
    }

    const descargarCsv = () => {
        const myArray = [];
        messages && Object.keys(messages).map((ele, i) => {
            const chatItem = messages[ele]
            //const FechaPrueba = new Date(_.get(chatItem, 'date', new Date()))
            //moment(FechaPrueba).format(format1)

            const PruebaDeFecha = new Date(chatItem.date);
            const FechaPrueba = PruebaDeFecha.toLocaleString();


            myArray.push({
                mensaje: chatItem.message,
                tipo:chatItem.messageType,
                link:chatItem?.fileData?chatItem?.fileData?.url_file:'---',
                fecha: FechaPrueba,
                desde: chatItem.type });
        })
        return myArray;
    }

    const download = (link) => {
        var element = document.createElement('a');
        element.setAttribute('href', link);
        element.setAttribute('target', '_blank')
        element.setAttribute('download', 'download')
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    const validateMessageType = (chatItem) => {
        const { messageType, fileData } = chatItem
        if (messageType === 'file') {
            if (fileData.content_type.includes('image')) {
                return 'file'
            }
        }

        return messageType === 'text' ? 'text' : 'file'
    }

    const ChatList = () => {
        return (
          <Spin spinning={loading}>
            {noData === true && (
              <Row justify={"center"}>
                <Col xs={24}>
                  <div style={{ textAlign: "center" }}>
                    <img
                      src={nodataimg}
                      alt="Logo"
                      style={{ width: "20%", marginTop: 20 }}
                    />
                      <div style={{display: 'block'}}>
                           <p style={{fontWeight: "bolder"}}>
                              <FormattedMessage id="chat.notMessagesYet"/>{" "}
                            </p>
                            <p style={{color: "gray"}}>
                              <FormattedMessage id="chat.sendTextOrFile"/>
                            </p>
                      </div>

                  </div>
                </Col>
              </Row>
            )}
            {
              messages && <div className="chatMessagesArea" style={{ height:'calc(100vh - 500px)', overflowY:'scroll' }}>
              {Object.keys(messages).map((ele, i) => {
                  let chatItem = messages[ele];
                  let type = _.get(chatItem, "type", "asesor");
                  let date = new Date(_.get(chatItem, "date", new Date()));
                  let title = _.get(chatItem, "title", type);
                  return (
                    <MessageBox
                      className={
                        type === "customer" ? "messageLeft" : "messageRight"
                      }
                      avatar={imgUser}
                      title={
                        <span>
                          <Title
                            level={5}
                            style={{
                              marginBottom: 0,
                              lineHeight: 1,
                              textAlign: "right",
                            }}
                          >
                            {title ? title : type}
                          </Title>
                          <small style={{ color: "#000" }}>
                            {moment(date).format(format1)}
                          </small>
                        </span>
                      }
                      position={type === "customer" ? "left" : "right"}
                      key={i}
                      titleColor={type === "customer" ? "red" : "blue"}
                      type={validateMessageType(chatItem)}
                      date={date}
                      onClick={() => {
                        if (chatItem.messageType === "file") {
                          download(chatItem.fileData.url_file);
                        }
                      }}
                      data={
                        chatItem.messageType === "file" && {
                          uri: chatItem.fileData.url_file,
                        }
                      }
                      text={chatItem.message}
                    />
                  );
                })}
            </div>
            }
            {messages && messages.length <= 0 && <Empty />}
          </Spin>
        );
    }

    const DownloadMessagesButton=()=>{
       return <Tooltip
            placement="top"
            title={<FormattedMessage id="chat.downloadMessages" />}
        >
            <Button
                type="primary"
                style={{
                    backgroundColor: "green",
                    color: "white",
                }}
                size="large"
            >
                <CSVLink
                    headers={headers}
                    data={descargarCsv()}
                    filename="chatKikert.csv"
                >
                    <DownloadOutlined />{" "}
                </CSVLink>
            </Button>
        </Tooltip>
    }

    const AreaMessage = () => {
        return (
          <Spin spinning={loading}>
            <div style={{ padding: 20, width:'100%' }}>
              <span style={{ color: "transparent", fontSize: 0 }}>
                {JSON.stringify(typeCustomer)}
              </span>
              <Form
                form={form}
                name="horizontal_login"
                layout="inline"
                onFinish={onFinish}
                style={{ justifyContent: "right", width:'100%' }}
              >

                <Form.Item
                  name="message"
                  rules={[
                    {
                      required: true,
                      message: <FormattedMessage id="chat.needed" />,
                    },
                  ]}
                >
                  <Input
                    placeholder="Mensaje"
                    style={{
                      maxWidth: "400px",
                      background: "var(--colorInputGray)",
                      borderRadius: 10,
                      padding: 10,
                    }}
                  />
                </Form.Item>

                  <Form.Item style={{ marginRight: 10 }}>
                      <Tooltip
                          placement="top"
                          title={<FormattedMessage id="chat.sendMessage" />}
                      >
                          <Button
                              type="primary"
                              loading={loading}
                              htmlType="submit"
                              size="large"
                              className="bg-secondary"
                          >
                              <SendOutlined />
                          </Button>
                      </Tooltip>
                  </Form.Item>

                  <Form.Item>
                      <Tooltip
                          placement="top"
                          title={<FormattedMessage id="chat.shareFile"/>}
                      >
                          {/* <Upload {...configUpload} style={{ display: "block" }}>
                      <PaperClipOutlined />
                    </Upload> */}

                          <Upload {...configUpload} style={{display: "block"}}>
                              <Button
                                  type="dashed"
                                  icon={<PaperClipOutlined/>}
                                  size="large"
                              />
                          </Upload>
                      </Tooltip>
                  </Form.Item>


                <Divider type="vertical" style={{ height: "auto" }} />
                <Form.Item>
                  <Tooltip
                    placement="top"
                    title={<FormattedMessage id="chat.downloadMessages" />}
                  >
                    <Button
                      type="primary"
                      style={{
                        backgroundColor: "green",
                        color: "white",
                      }}
                      size="large"
                    >
                      <CSVLink
                        headers={headers}
                        data={descargarCsv()}
                        filename="chatKikert.csv"
                      >
                        <DownloadOutlined />{" "}
                      </CSVLink>
                    </Button>
                  </Tooltip>
                </Form.Item>
              </Form>
            </div>
          </Spin>
        );
    }

    return {
        messages,
        ChatList,
        AreaMessage,
        DownloadMessagesButton

    }
}


export default useChatList;