import React, { useEffect, useState } from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import { FormattedMessage, injectIntl } from "react-intl";
import { Col, Row, Input, Tooltip, Button, Table, Card, Form, Typography, Divider, Space } from "antd";
import {
  SearchOutlined,
  FilterOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import MainLayoutv2 from "../../layouts/MainLayoutv2";
import MembersTable from "../../components/members/MembersTable";
import UserTable from '../../components/members/UserTable';
import ModalMembers from "../../components/members/ModalMembers";
import DeleteModal from '../../components/modal/DeleteModal';
import styled from "styled-components";
import { connect } from 'react-redux';
import { showMembers, getProfiles, getUsers, changeScreen, userFormShow, clearForm } from "../../redux/membersDuck";
import CreateGroup from "../../components/members/CreateGroup";
import UserForm from '../../components/members/forms/UserForm';

import {
  BrowserRouter as Router,
  Route,
  Link,
  useRouteMatch,
  useHistory,
  useParams,
} from "react-router-dom";

import FilterIcon from '../../image/filter_icon.svg';
import SearchIcon from '../../image/BUSCADOR.png';


const CustomCard = styled(Card)`
    .ant-card-body{
        padding: 20px;
    }
`;



const UsersList = ({ intl ,getProfiles, getUsers, membersState, changeScreen, userFormShow, clearForm, ...props}) => {

  const {Text} = Typography
  const [openModalCreate, setOpenModalCreate] = useState(false);
  const [form] = Form.useForm()
  const [filter, setFilter] = useState(false)
  const [loading, setLoading] = useState(false)
  /* const [userFormShow, setUserFormShow] = useState(false); */
  const [pagination, setPagination] = useState({
    showSizeChanger: true,
    current: 1,
    pageSize: 10,
  });

  const history = useHistory();

  useEffect(() => {
    getUserList()
  }, [])

  const getUserList=async (search='', page=1)=>{
    try{
      setLoading(true)
      const res = await getUsers(search,page)
      console.log('resss',res)
      setPagination({
        ...pagination,
        total: res.data.count,
        current: page,
        pageSize: 10,
      });
    }catch (e){
      console.log('e',e)
    }finally {
      setLoading(false)
    }
  }

  const changePagination = (data) => {
    console.log(data)
    getUserList(form.getFieldValue('search'),data.current)
  }


  const closeModal = () => {
    setOpenModalCreate(false);
  }


  const addUser = () => {
    clearForm();
    history.push("/members_center_users_add");

  }
  const searchUser = (values) => {
    setFilter(true);
    getUserList(values.search)
  }
  
  const clearFilter = () => {
    getUserList()
    form.resetFields();
  }

  return (
    <MainLayoutv2
      title={<FormattedMessage id="members.title" />}
      index="members"
    >
      <Col span={23} style={{ textAlign: "left", marginTop: 20 }}>
        <Space >
          <Button
            htmlType="button"
            /* onClick={() => changeScreen("users")} */
            className={'btn-string-blue-low' }
            /* onMouseEnter={() => setActive(0)}
            onMouseLeave={() => setActive(null)} */
          >
            <UsergroupAddOutlined /> &nbsp; &nbsp;{" "}
            <FormattedMessage id="members.table.users" />
          </Button>
          <Button
            onClick={() => {
                history.push("/members_center_profiles")
            /*   changeScreen("profiles") */
              /* userFormShow(false); */
              }
            }
          >
            <UsergroupAddOutlined /> &nbsp; &nbsp;{" "}
            <FormattedMessage id="members.table.profiles" />
          </Button>
        </Space>
        <Divider/>
      </Col>
      
      <Row justify="space-between" align="bottom">
        <Col md={8}>
            <Row gutter={10} style={{ display: "flex" }} align="bottom">
            <Col flex="auto">
              <Form form={form} onFinish={searchUser} layout="vertical" >
                <Form.Item
                  label={<Text style={{ paddingLeft: 10 }}>Usuarios</Text>}
                  style={{ marginBottom: 0 }}
                  name="search"
                >
                  <Input
                    placeholder={intl.formatMessage({
                      id: "members.search_name",
                    })}
                    className="br-5"
                    suffix={
                      <Tooltip title="Buscar">
                        {
                          filter ? (<span style={{cursor:'pointer'}} onClick={() => clearFilter() }>&#x2715;</span>) : (<img src={SearchIcon} style={{width:15}} />)
                        }
                      </Tooltip>
                    }
                  />
                </Form.Item>
              </Form>
            </Col>
            <Col md={3}>
                <img src={FilterIcon} width="30px" height="30px" style={{cursor:'pointer'}} onClick={() => form.submit() } />
              </Col>
            </Row>
        </Col>
      <Col>
        <Button className="btn-string-blue-low br-5 px-30" onClick={() =>addUser()}>
          <FormattedMessage id="members.new_user" />
        </Button>
      </Col>
      <Col md={24}>
        <CustomCard bordered={false} style={{marginTop: 30 }}>
            <UserTable
                pagination={pagination}
                loading={loading}
                onChange={changePagination}
                history={history} />
        </CustomCard>
      </Col>
      </Row>
      <ModalMembers />
      <CreateGroup isVisible={openModalCreate} closeModal={closeModal} />
    </MainLayoutv2>
  );
};

const mapState = (state) => ({
  membersState: state.members,
});



export default injectIntl(connect(mapState, { showMembers, getProfiles, getUsers, changeScreen, userFormShow, clearForm })(UsersList));