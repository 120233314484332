import React, { useState, useEffect } from 'react'
import {  Menu, Typography,Card, Row, Col, Form, DatePicker, BackTop, List, Tooltip} from 'antd';
import { FieldTimeOutlined,WarningOutlined,CheckSquareOutlined, FilterOutlined, DashboardOutlined} from '@ant-design/icons';
import logo from '../../image/black_icon_kikert.svg'
import turn from '../../image/turn_icon_kikert.svg'
import DashboardLayout from '../../layouts/DashboardLayout';
import moment from 'moment'
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from 'react-redux'
import { changeLangAction } from '../../redux/settingsDuck';
import dash from "../../image/dashboard_pro.png"
import locale from '../../locale/es_mx.json'
import CardStatistics from "../../components/CardStatistics/CardStatistics";
import Chart from "react-apexcharts";
import {
    getWidgetAnalytic
} from "../../redux/dashboardDuck";
import PieChartKikert from "../../components/Charts/PieChartKikert";
import BarChartKikert from "../../components/Charts/BarChartKikert";
import KButton from "../../components/common/elements/KButton/KButton";
import LineChartKikert from "../../components/Charts/LineChartKikert";
import _ from 'lodash'
import MainLayoutv2 from '../../layouts/MainLayoutv2';

const { RangePicker } = DatePicker;
const { Title } = Typography;
moment().locale('es');
const AnalyticPage = ({changeLangAction, intl, settingsStore, getDeliveryToday, dashboardStore,
                          getWidgetAnalytic, ...props}) => {


    const [maxInflux, setMaxInflux] = useState(null) // Día con mayor afluencia

    const { Meta } = Card;
    const { Title, Text } = Typography;
    const [form] = Form.useForm()

    const rangeConfig = {
        rules: [
            {
                type: 'array',
                required: true,
                message: 'Please select time!',
            },
        ],
    };

    const getTenant = () => {
        let defaultTenant = "kikert";
        if (typeof window !== "undefined") {
        let splitDomain = window.location.hostname.split(".");
            if (splitDomain.length > 0) {
                defaultTenant = splitDomain[0];
            }
        }
        return defaultTenant
    }

    const onFinish=(values)=>{
        console.log(values)
        let params = {
            from_date:null,
            to_date:null
        }
        if(values.dates){
            params.from_date = values.dates[0].format('YYYY-MM-DD').toString()
            params.to_date = values.dates[1].format('YYYY-MM-DD').toString()
        }
        let filters =Object.keys(params).map(key => key + '=' + params[key]).join('&');
        getStatistics(filters)

    }

    const getStatistics =(filters)=>{
        getWidgetAnalytic('NUM_ORDERS','count',filters);
        getWidgetAnalytic('AVG_CLOSING_ORDERS_TIME','count',filters);
        getWidgetAnalytic('ORDERS_BY_ASSIGNED_TO','array',filters)
        setMaxInflux(null)
        getWidgetAnalytic('ORDERS_BY_WEEKDAY','array',filters).then((res)=>{
            // contamos los elementos que estan en reparación
            if(res.data){
                let finds = _.maxBy(res.data, function(o){return o.count});
                if(finds){
                    setMaxInflux(finds)
                }
                console.log('finds',finds)
            }
        })
        getWidgetAnalytic('ORDERS_BY_DAY','array',filters)
        getWidgetAnalytic('ORDERS_BY_MONTH','object',filters).then((res)=>{
           console.log(res)
        })
        getWidgetAnalytic('ORDERS_BY_BRAND_MODEL','array',filters)
        getWidgetAnalytic('ORDERS_BY_ACONDITIONERS','array',filters)
        getWidgetAnalytic('ORDERS_BY_COLOR','array',filters)
        getWidgetAnalytic('NUM_CLOSED_ORDERS','count',filters)
        getWidgetAnalytic('OPEN_ORDERS_BY_INSURANCE_TYPE','array',filters)
        getWidgetAnalytic('ORDERS_BY_BRAND','array',filters)
        getWidgetAnalytic('OPEN_ORDERS_BY_INSURANCE','array',filters)
    }

    /** Obtenemos el primer di ay ultimo del mes**/
    const now = new Date();
    const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);

    const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);


    const datwrangeInitial={
        dates:[
            moment(firstDay.toISOString()),
            moment(lastDay.toISOString())
        ]
    }


    useEffect(()=>{
        form.submit()
    },[])

    return (
        <MainLayoutv2 title={<><FormattedMessage id="dashboard.myAnalytics" /> { ' '}
        </>} index="analitica">
            <BackTop />
            <Row style={{marginBottom:50}}>
                <Col>
                    <Form
                        initialValues={datwrangeInitial}
                        layout="inline" form={form} onFinish={onFinish}>
                        <Form.Item name="dates" {...rangeConfig}>
                            <RangePicker locale={locale}
                                         ranges={{
                                             'Hoy': [moment(), moment()],
                                             'Ayer':[moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                             'Últimos 7 días': [moment().subtract(7, 'days'), moment()],
                                             'Este mes': [moment().startOf('month'), moment().endOf('month')],
                                             'Mes pasado': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                                             'Este año': [moment().startOf('year'), moment().endOf('year')],
                                             'Año pasado': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
                                         }}
                                         format="YYYY-MM-DD" />
                        </Form.Item>
                        <Form.Item>
                           <KButton icon={<FilterOutlined />} type={'secondary'} htmlType={'submit'} size={'default'} />
                        </Form.Item>
                    </Form>
                </Col>
            </Row>

            <Row gutter={16}>
                <Col xs={24} lg={6} md={12}>
                    <CardStatistics
                        icon={<CheckSquareOutlined className={'text-primary'} style={{fontSize:40,marginTop:30}}  />}
                        title={'Número de órdenes'}
                        value={dashboardStore?.numOrders}/>
                </Col>
                <Col xs={24} lg={6} md={12}>
                    <CardStatistics
                        icon={<CheckSquareOutlined className={'text-primary'} style={{fontSize:40,marginTop:30}}  />}
                        title={'Número de órdenes cerradas'}
                        value={dashboardStore?.ordersClosed}/>
                </Col>
                <Col xs={24} lg={6} md={12}>
                    <CardStatistics
                        icon={<CheckSquareOutlined className={'text-primary'} style={{fontSize:40,marginTop:30}}  />}
                        title={maxInflux?.day_name ? 'Mayor afluencia: '+maxInflux.day_name : 'Mayor afluencia:'}
                        value={maxInflux?.count ? maxInflux.count : '--'}/>
                </Col>
                <Col xs={24} lg={6} md={12}>
                    <CardStatistics
                        icon={<CheckSquareOutlined className={'text-primary'} style={{fontSize:40,marginTop:30}}  />}
                        title={'Estadía promedio en horas'}
                        value={dashboardStore?.avgClosingOrdersTime}/>
                </Col>
            </Row>
            <Row>

                <Col span={24} style={{marginBottom:20}}>
                    <br/>
                    <CardStatistics
                        type={'chart'}
                        icon={<CheckSquareOutlined className={'text-primary'} style={{fontSize:40,marginTop:30}}  />}
                        title={'Órdenes por mes'}
                        body={
                            <Chart
                                options={{
                                    chart: {
                                        id: "basic-bar"
                                    },
                                    xaxis: {
                                        categories: dashboardStore?.ordersByMonth?.categories
                                    }
                                }}
                                series={dashboardStore?.ordersByMonth?.data ? dashboardStore?.ordersByMonth?.data : [] }
                                colors={['#99C2A2', '#C5EDAC', '#66C7F4']}
                                type="line"
                                width="700"
                            />
                        }/>
                </Col>

            </Row>
            {
                getTenant() === "jacmexico" && (
                    <Row style={{marginTop:20}} gutter={16}>

                        <Col span={24} style={{marginBottom:20}}>
                            <CardStatistics
                                    type={'chart'}
                                    icon={<CheckSquareOutlined className={'text-primary'} style={{fontSize:40,marginTop:30}}  />}
                                    title={'Servicios entregados por acondicionador'}
                                    body={
                                        dashboardStore?.ordersClosedByAconditionerJac ? <BarChartKikert
                                            categoryField={'advisor'}
                                            valueField={'count'}
                                            isDonut={true}
                                            idChart={'orderaconditioners'}
                                            data={dashboardStore?.ordersClosedByAconditionerJac}

                                        />:null
                                }/>
                        </Col>

                    </Row>
                )
            }
            <Row style={{marginTop:20}} gutter={16}>

                <Col span={24} style={{marginBottom:20}}>
                    <CardStatistics
                        type={'chart'}
                        icon={<CheckSquareOutlined className={'text-primary'} style={{fontSize:40,marginTop:30}}  />}
                        title={'Entradas por día'}
                        body={
                            dashboardStore?.orderByDay ? <LineChartKikert
                                categoryField={'day'}
                                valueField={'count'}
                                idChart={'byday'}
                                isDate={false}
                                data={dashboardStore?.orderByDay}

                            />:null
                        }/>
                </Col>
                <Col span={24}>
                    <CardStatistics
                        type={'chart'}
                        icon={<CheckSquareOutlined className={'text-primary'} style={{fontSize:40,marginTop:30}}  />}
                        title={'Entradas por día de la semana'}
                        body={
                            dashboardStore?.ordersByWeekday ? <LineChartKikert
                                categoryField={'day_name'}
                                valueField={'count'}
                                idChart={'byWeek'}
                                data={dashboardStore?.ordersByWeekday}

                            />:null
                        }/>
                </Col>



            </Row>
            <Row style={{marginTop:20}} gutter={16}>
                <Col xs={24} lg={12} md={12}>
                    <CardStatistics
                        type={'chart'}
                        icon={<CheckSquareOutlined className={'text-primary'} style={{fontSize:40,marginTop:30}}  />}
                        title={'Servicios por asesor'}
                        body={
                            dashboardStore?.ordersByAssignedTo ? <BarChartKikert
                                categoryField={'advisor'}
                                valueField={'count'}
                                isDonut={true}
                                idChart={'orderassigsnetto'}
                                data={dashboardStore?.ordersByAssignedTo}

                            />:null
                        }/>
                </Col>
                <Col xs={24} lg={12} md={12}>

                    <CardStatistics
                        title={'Por Color'}
                        type={'chart'}
                        body={
                            <Row>
                                <Col lg={24} md={24}>
                                    {
                                        dashboardStore?.openOrdersByColors &&
                                        <PieChartKikert
                                            categoryField={'color'}
                                            valueField={'count'}
                                            isDonut={false}
                                            idChart={'chartDivByColor'}
                                            data={dashboardStore?.openOrdersByColors}/>
                                    }
                                </Col>
                            </Row>

                        }


                    />


                </Col>
            </Row>


{
     (settingsStore && settingsStore?.insuranceIsActive) && <Row style={{marginTop:30}} gutter={16}>
     <Col span={24}>
     <CardStatistics
                 title={'Por tipo de servicio'}
                 type={'chart'}
                 body={
                     <Row>
                         <Col lg={12} md={24}>
                             {
                                 dashboardStore?.ordersByInsuranceType &&
                                 <PieChartKikert
                                     categoryField={'name'}
                                     valueField={'count'}
                                     isDonut={true}
                                     idChart={'chartDiv'}
                                     data={dashboardStore?.ordersByInsuranceType}/>
                             }
                         </Col>
                         <Col lg={12} md={24}>
                             {
                                 dashboardStore?.ordersByInsurance &&
                                 <List
                                     className="demo-loadmore-list"
                                     itemLayout="horizontal"
                                     size={'large'}
                                     itemLayout={'vertical'}
                                     split={true}
                                     dataSource={dashboardStore?.ordersByInsurance}
                                     renderItem={(item) => (
                                         <List.Item
                                             key={item.id}
                                         >
                                             <List.Item.Meta
                                                 avatar={<img
                                                     width={70}
                                                     alt="logo"
                                                     src={item?.logo_thumbnail}
                                                 />}
                                                 title={<Title level={3}>{item.count}</Title>}
                                                 description={item.name}
                                             />
                                         </List.Item>

                                     )}
                                 />
                             }
                         </Col>
                     </Row>

                 }


             />
     </Col>
 </Row>
}
            
           



            <Row style={{marginTop:30}} gutter={16}>
                <Col span={24}>
                    <CardStatistics
                        type={'chart'}
                        icon={<CheckSquareOutlined className={'text-primary'} style={{fontSize:40,marginTop:30}}  />}
                        title={'Órdenes por marca'}
                        body={
                            dashboardStore?.ordersByBrand ? <BarChartKikert
                                withScroll={true}
                                categoryField={'name'}
                                valueField={'count'}
                                isDonut={true}
                                idChart={'bybrand'}
                                data={dashboardStore?.ordersByBrand}

                            />:null
                        }/>
                </Col>
            </Row>


            <Row style={{marginTop:30}}>
                <Col xs={24} lg={24} md={24}>

                    <CardStatistics
                        type={'chart'}
                        icon={<CheckSquareOutlined className={'text-primary'} style={{fontSize:40,marginTop:30}}  />}
                        title={'Ordenes por modelo'}
                        body={
                            dashboardStore?.ordersByBrandModel ? <BarChartKikert
                                categoryField={'name'}
                                valueField={'count'}
                                isDonut={true}
                                idChart={'bybrandModel'}
                                data={dashboardStore?.ordersByBrandModel}

                            />:null
                        }/>
                </Col>
            </Row>

        </MainLayoutv2>
    )
}

const mapState = (state) => ({
    settingsStore: state.settings,
    dashboardStore: state.dashboardStore
});

export default injectIntl(connect(mapState, {
    changeLangAction,
    getWidgetAnalytic
})(AnalyticPage))
