
import { Tabs } from 'antd';
import { useHistory } from 'react-router-dom';
import { tenantSelected } from '../../../../lib/utils/axiosApi';
import {permissionsExist} from "../../../../lib/utils/utils";   
import { useSelector } from 'react-redux';
const { TabPane } = Tabs;

const TabsConfig = ({keyActive='1'}) => {
  const history = useHistory();
  const permissions = useSelector(state => state.permissions);

  const handleClick = (key) => {
    history.push(`/config/${key}`);
    switch (key) {
      case '1':
        history.push('/config/parts');
        break;
      case '2':
        history.push('/config/suppliers');
        break;
      case '3':
        history.push('/config/insurance');
        break;
      case '4':
        history.push('/config/brands');
        break;
      case '5':
        history.push('/config/models');
        break;
      case '6':
        history.push('/config/colour');
        break;
      case '7':
        history.push('/config/types');
        break;
      case '8':
        history.push('/config/metas');
        break;
      case '9':
        history.push('/dashboard-jac');
        break;
      case '10':
        history.push('/config/warehouse');
        break;
      default:
        break;
    }
  }

  return (
    <Tabs defaultActiveKey={keyActive} animated={false} onChange={(key) => handleClick(key)}>
      {
        tenantSelected !== 'jacmexico' &&
        <TabPane tab="Refacciones" key="1" />
      }
      <TabPane tab="Proveedores" key="2" />
      {
        permissionsExist(permissions.permissionsList,"view_insurance") &&
        <TabPane tab="Aseguradoras" key="3" />
      }

      {
        permissionsExist(permissions.permissionsList,"view_vehiclebrand") &&
        <TabPane tab="Marcas" key="4" />
      }
      {
          permissionsExist(permissions.permissionsList,"view_vehiclemodel") &&
          <TabPane tab="Modelos" key="5" />
      }
      
      {
        permissionsExist(permissions.permissionsList,"view_vehiclecolor") &&
        <TabPane tab="Colores" key="6" />
      }
      {
        permissionsExist(permissions.permissionsList,"view_vehicletype") &&
        <TabPane tab="Tipos" key="7" />
      }
      {
        tenantSelected === 'jacmexico' && 
        <TabPane tab="Metas" key="8" />
      }
      {
        tenantSelected === 'jacmexico' && 
        <TabPane tab="Dashboard de metas" key="9" />
      }

      { 
        <TabPane tab="Almacenes" key="10" />
      }

    </Tabs>
  );
};



const TabsMenuConfig = ({keyActive='1'}) => {
  return <TabsConfig keyActive={keyActive} />;
};

export default TabsMenuConfig;

