import React, { useState, useEffect, } from 'react'
import styles from '../bussiness.module.css'
import BussinessBranchLayout from "../../../layouts/BussinessBranchLayout";
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux'
import { Button, Space, Row, Col, Spin, Typography, Divider } from 'antd';
import { injectIntl } from 'react-intl';
import { setConfigCompanyBranch } from "../../../redux/settingsDuck";
import { useHistory, useParams } from "react-router-dom";
import { API, axiosApi } from "../../../lib/utils/axiosApi";

const BranchPage = ({ settings, security, intl, setConfigCompanyBranch }) => {

    const history = useHistory()
    const {Text} = Typography;
    const [company, setCompany] = useState(null)
    const [branchList, setBranchList] = useState(null)
    const [loading, setloading] = useState(false)

    const params = new URLSearchParams(window.location.search);


    const style = {
        CompanyButton: {
            width: 400,
            height: 80,
            fontSize: 25,
            border: "solid 2px"
        }
    }

    useEffect(() => {
        if (settings) {
            if (settings.settingsData.companySelected) {
                setCompany(settings.settingsData.companySelected.id)
            } else {
                history.push('/company');
            }
        }
    }, [])

    useEffect(() => {
        if (company) {
            // aqui llamamos a las sucursales
            getBranchs(company)
        }
    }, [company])

    const getBranchs = async (id_company) => {
        setloading(true)
        try {
            const res = await axiosApi.get(`${API.BUSINESS.BUSINESS_SELECT_BRANCH}?company__id=${id_company}`)
            console.log(res)
            if (res.data) {
                if (res.data.results.length > 0) {
                    setBranchList(res.data.results)
                    if (res.data.results.length === 1){
                      onClick(res.data.results[0]);
                    }
                }

            }
        } catch (e) {

        } finally {
            setloading(false)
        }
    }


    const onClick = async (branch) => {

        try {
            await setConfigCompanyBranch('branch', branch)
            history.push('/');
        } catch (e) {

        }
    }

    const goBack = () => {
        history.goBack()
    }

    return (
      <BussinessBranchLayout className={styles.container}>
        <div style={{ height: "100%", width: "100%" }}>
          <Row justify="center" align="bottom" style={{ height: "100%" }}>
            <Col>
              <Spin spinning={loading}>
                <Row justify="center">
                  <Col>
                    <h1 style={{ fontSize: 30, fontFamily: "Sharp Grotesk" }}>
                      <FormattedMessage id="bussiness.company.chooseTaller" />
                    </h1>
                    {branchList
                      ? branchList.map((item, i) => {
                          return (
                            <Row
                              key={i}
                              align="middle"
                              style={{ paddingBottom: 20 }}
                            >
                              <Col span={12}>
                                <Button
                                  onClick={() => onClick(item)}
                                  style={style.CompanyButton}
                                  block
                                >
                                  {item.name}
                                </Button>
                              </Col>
                            </Row>
                          );
                        })
                      : null}
                  </Col>
                </Row>
              </Spin>
            </Col>
            <Col span={24} style={{ textAlign: "center" }}>
              <footer style={{ padding: "20px 30px" }}>
                {!params.has("unique") && (
                  <Button
                    size="large"
                    className="btn-string-blue"
                    style={{ fontSize: 12, width: 130, height: 45 }}
                    onClick={goBack}
                  >
                    Regresar
                  </Button>
                )}

                <Divider className="divider-primary" />
                <Text strong>Todos los derechos reservados</Text>
              </footer>
            </Col>
          </Row>
        </div>
      </BussinessBranchLayout>
    );
}

const mapStateToProps = state => ({
    settings: state.settings,
    security: state.security,
})

export default injectIntl(connect(mapStateToProps, { setConfigCompanyBranch })(BranchPage))