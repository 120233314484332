import React, { useState, useEffect } from 'react'
import DashboardLayout from '../../layouts/DashboardLayout';
import { Table, Layout, Form, Button, Modal, Input, Upload, Space, Popconfirm, message, Row, Col, Typography } from 'antd'
import { UploadOutlined } from '@ant-design/icons';
import DeleteLinkComponent from "../../components/DeleteLinkComponent";
import { connect } from 'react-redux'
import { injectIntl } from "react-intl";
import { FormattedMessage } from "react-intl";
import {
    changeLangAction,
    getColorsAction,
    editColorAction,
    saveColorAction,
    deleteColorAction,
} from "../../redux/settingsDuck"
import {permissionsExist} from "../../lib/utils/utils";
import DeleteModal from "../../components/modal/DeleteModal";
import EditIcon from "../../image/edit.svg";
import DeleteIcon from "../../image/delete.svg";
import turn from '../../image/turn_icon_kikert.svg'
import {CustomTable} from "../../components/KComponents";
import KButton from "../../components/common/elements/KButton/KButton";
import KModal from '../../components/common/elements/KModal/KModal';
import MainLayoutv2 from "../../layouts/MainLayoutv2";
import TabsConfig from '../../components/common/elements/TabsMenuConfig/TabsMenuConfig';

const { Header, Content, Footer } = Layout;

const VehicleColor = ({ changeLangAction, intl, settings, getColorsAction, editColorAction, saveColorAction, deleteColorAction, permissions }) => {

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentVehicleColor, setCurrentVehicleColor] = useState(false);
    const [actionView, setActionView] = useState(true);
    const [forDelete, setForDelete] = useState(null)
    const [modalDelete, setModalDelete] = useState(false)
    const [form] = Form.useForm();

    const {Title} = Typography;

    useEffect(() => {
        getColorsAction()
    }, [])

    const showModal = (isEdit = false) => {
        if (!isEdit) {
            form.resetFields()
            setCurrentVehicleColor(null)
        }
        setIsModalVisible(true);
    };

    const handleOk = () => {
        form.submit();
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setCurrentVehicleColor(null)
        form.resetFields()
    };

    const closeModal = () =>{
        setModalDelete(false);
        setForDelete(null);
    }

    const deleteItem = (current) => {
        console.log(current);
        setModalDelete(true);
        setForDelete(current);
    }

    const onDeleteColor = async () => {
        try {
            const res = await deleteColorAction(forDelete.id)
            if (res.success) {
                message.success(intl.formatMessage({ id: "item.deleteSuccess" }))
                await getColorsAction()
                await closeModal()

            } else {
                message.error(intl.formatMessage({ id: "item.brand.registerErr" }))
                await closeModal()

            }
        } catch (e) {
            message.error(intl.formatMessage({ id: "item.brand.registerErr" }))
            await closeModal()
        }
    }

    const onSaveColor = async values => {
        if (values) {
            let res = await saveColorAction(values)
            if (res.success) {
                message.success(intl.formatMessage({ id: "item.saveSuccess" }))
                await getColorsAction()
            } else {
                message.error(intl.formatMessage({ id: "item.brand.element" }))
            }

            form.resetFields()
            setCurrentVehicleColor(null)
        }
        console.log('Received values of form: ', values);
    };

    const onEditColor = async values => {
        if (values) {
            let req = {
                id: currentVehicleColor.id,
                ...values
            }
            let res = await editColorAction(req)
            if (res.success) {
                message.success(intl.formatMessage({ id: "item.editSuccess" }))
                await getColorsAction()
            } else {
                message.error(intl.formatMessage({ id: "item.brand.element" }))
            }

            form.resetFields()
            setCurrentVehicleColor(null)
        }
        console.log('Received values of form: ', values);
    };

    const editColor = (color = null) => {
        setCurrentVehicleColor(color)
        if (color) {
            form.setFieldsValue({
                name: color.name
            })
        }
        showModal(true)
    }

    const changePagination = (page) => {
      console.log("page", page);
      getColorsAction(page);
    };


    const valActions=()=>{
        if (!permissionsExist(permissions.permissionsList,"change_vehiclecolor") &&
            !permissionsExist(permissions.permissionsList,"delete_vehiclecolor")){
            setActionView(false)
        }else {
            setActionView(true)
        }
    }

    useEffect(()=>{
        valActions()
    },[])

    const columns = [
        {
            title: <FormattedMessage id="vehicleColor.nameColor"/>,
            dataIndex: 'name',
            key: 'name',
        },
        ...(actionView ?
                [{
                    title: <FormattedMessage id="layout.actions"/>,
                    dataIndex: 'id',
                    key: 'actions',
                    render: (id, record) => {
                        return <Space size={30}>
                            {
                                permissionsExist(permissions.permissionsList, "change_vehiclecolor") &&
                                <img key="EditIcon" src={EditIcon} style={{cursor:'pointer',  width:12}}
                                onClick={() => editColor(record)}/>
                            }
                            {
                                permissionsExist(permissions.permissionsList, "delete_vehiclecolor") &&
                                <img key="DeleteIcon" src={DeleteIcon} style={{cursor:'pointer', width:12}} onClick={() => deleteItem(record)}/>

                            }
                        </Space>
                    }

                }]
                :
                []
        ),
    ];

    return (
      <MainLayoutv2
        title={intl.formatMessage({ id: "vehicleColor.title" })}
        index="8"
        openKeys={["sub1"]}
      >
        <KModal
          title=""
          footer=""
          visible={isModalVisible}
          /* onOk={handleOk} */
          onCancel={handleCancel}
        >
            <Row>
                <Col span={24}>
                    <Title level={2} style={{ marginBottom: 0 }}>
                        <img
                        src={turn}
                        alt="logo"
                        style={{ height: 60, width: 30, transform: "scaleX(-1)", marginRight:10 }}
                        />
                        <FormattedMessage id={currentVehicleColor ? "vehicleColor.editColor": "vehicleColor.addColor" } />
                    </Title>
                </Col>
                <Col span={24}>
                    <Form
                        layout={"vertical"}
                        form={form}
                        onFinish={currentVehicleColor ? onEditColor : onSaveColor}
                    >
                        
                        <Form.Item
                        name={"name"}
                        label={intl.formatMessage({ id: "vehicleColor.nameColor" })}
                        rules={[{ required: true }]}
                        >
                        <Input
                        className='kinput'
                            placeholder={intl.formatMessage({
                            id: "vehicleColor.nameColor",
                            })}
                        />
                        </Form.Item>
                    </Form>
                </Col>
                <Col span={24} style={{ textAlign: "center" }}>
                    <Space>
                        <Button
                            size="large"
                            className="btn-string-blue btn-report-action"
                            onClick={() => handleCancel()}
                        >
                            <FormattedMessage id="layout.cancel" />
                        </Button>
                        <Button
                            className="btn-blue btn-report-action"
                            size="large"
                            onClick={() => handleOk()       }
                            >
                            <FormattedMessage id="services.members.create.save" />
                        </Button>
                    </Space>
                </Col>
            </Row>
        </KModal>

        <Content
          style={{ padding: "10px 10px", background: "white" }}
        >
            <TabsConfig keyActive={'6'}/>
            {
                permissionsExist(permissions.permissionsList, "add_vehiclecolor") &&
                <KButton
                    onClick={showModal}
                    type="primary"
                    style={{ float: "right", marginBottom: 20 }}
                    size={"large"}
                    text={<FormattedMessage id="layout.createNew" />}
                />
            }

            
          <CustomTable
            loading={settings.fetching}
            dataSource={settings.vehicleColorsList}
            columns={columns}
            pagination={{
              total: settings?.colors?.count,
              pageSize: 25,
              onChange: changePagination,
            }}
          />
        </Content>
          <DeleteModal actionDelete={() => onDeleteColor()} isVisible={modalDelete}
                        loading={settings?.fetching}
                       closeModal={closeModal}
                       modalTitle={<FormattedMessage id="deleted.ask"/>}
                       description={intl.formatMessage({ id:'deleted.description'},{ obj: forDelete && forDelete.name })} />
      </MainLayoutv2>
    );

}

const mapStateToProps = state => ({
    settings: state.settings,
    permissions: state.permissions
})


export default injectIntl(connect(mapStateToProps, {
    getColorsAction,
    changeLangAction,
    saveColorAction,
    deleteColorAction,
    editColorAction
})(VehicleColor))

