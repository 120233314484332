import { useState, useEffect } from "react";
import { axiosApi } from "../lib/utils/axiosApi";


export const useFetch = (url, flagToReload = false) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isPending, setIsPending] = useState(false);

    const fetchData = async () => {
        if(!url) return
        setIsPending(true)
        try {
            let resp = await axiosApi.get(url)
            setData(resp?.data)
        } catch (error) {
            setError(`${error} Could not Fetch Data`)
        } finally {
            setIsPending(false)
        }
    }

    useEffect(() => {
        fetchData()
    }, [url, flagToReload])

    return { data, isPending, error }
}

export default useFetch