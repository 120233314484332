import { Table, Button, Typography, Space, message } from 'antd'
import React, {useState} from 'react'
import { FormattedMessage, injectIntl } from "react-intl";
import {EditOutlined, DeleteOutlined} from '@ant-design/icons';
import styled from 'styled-components'
import {showMembers, getProfiles} from '../../redux/membersDuck';
import { connect } from 'react-redux';
import DeleteModal from '../modal/DeleteModal';
import {axiosApi, API} from '../../lib/utils/axiosApi';

import DeleteIcon from '../../image/delete.svg';
import EditIcon from '../../image/edit.svg';

const CustomTable = styled(Table)`
    border-color: grey;

    .ant-table-thead > tr > th {
        color: #341eff;
        white-space: nowrap;
    }
    .ant-table-cell{
        background: none;
    }
    .ant-table-thead > tr > th {
        border-color: #341eff;
    }

`

const MembersTable = ({style = {}, showMembers, membersState, intl, getProfiles, history, ...props}) => {

    const {Text, Title} = Typography
    const [modalShow, setModalShow] = useState(false)
    const [forDelete, setForDelete] = useState(null)
    const [loading, setLoading] = useState(false)

    

    const deleteItem = (currentProfile) => {
        setModalShow(true);
        setForDelete(currentProfile);
    }

    const closeModal = () =>{
        setModalShow(false);
        setForDelete(null);
        setLoading(false);
    }

    const deleteProfile = async () => {
        try {
            setLoading(true)
            let response = await axiosApi.delete(API.GROUPS.PROFILES + forDelete?.id +"/");
            if(response.status > 200 && response.status < 300){
                closeModal();
                message.success("Grupo eliminado")
                getProfiles();
            }
        } catch (error) {
            closeModal();
            if (error.response) {
                // Request made and server responded
                message.error(error.response.data.message)
                }
        }
    
    }

    const columns = [
        {
            title: <FormattedMessage id="members.table.profiles" />,
            dataIndex: 'name',
            key: 'profiles',
            render: (text, row) => <Space direction="vertical" size={0} >
                <Title level={5} style={{marginBottom:0}}>
                    {text}
                </Title>
                <Text>
                    {row.users.length}  <FormattedMessage id="members.table.users_plural" />
                </Text>
            </Space>
            
        },
        {
            title: <FormattedMessage id="members.table.users" />,
            key: 'name',
            align: 'center',
            render: (row) => 
            <Button type="link" onClick={() => showMembers(row)}>
                <FormattedMessage id="members.table.showUsers" />
            </Button>
        },
        {
            title: <FormattedMessage id="members.table.actions" />,
            key: 'actions',
            align: 'center',
            render: (row) => 
            <Space size={20}>
                <img key="EditIcon" src={EditIcon} style={{cursor:'pointer',  width:12}} onClick={() => history.push(`/members-center/edit/${row.id}`)} />
                <img key="DeleteIcon" src={DeleteIcon} style={{cursor:'pointer', width:12}} onClick={() => deleteItem(row)}/>
            </Space>
        }
    ]


  return (
      <>
        <CustomTable style={style} columns={columns} dataSource={membersState?.membersList} />
        <DeleteModal 
            isVisible={modalShow} 
            closeModal={closeModal} 
            data={forDelete} 
            modalTitle={<FormattedMessage id="deleted.ask" />}
            description={intl.formatMessage({ id:'services.members.delete.description'},{ group: forDelete?.name })}
            actionDelete={deleteProfile}
            loading={loading}
        />
      </>
  )
}

const mapState = (state) => ({
  membersState: state.members,
});

export default  injectIntl(connect(mapState, {showMembers, getProfiles})(MembersTable))