import {axiosApi, config, API} from "../lib/utils/axiosApi"
import cookie from "js-cookie";
import moment from 'moment'

// Datos iniciales
const initialData = {
    serviceDetail:null,
    loading:false,
    loadingServiceOrderDetail: false,
    loadingEntryConfig: false,
    deleting:false,
    closingReasons:null,
    entryConfig:null,
    parts:null
}

// TYPES
const GET_SERVICE = "GET_SERVICE"
const GET_SERVICE_SUCCESS = "GET_SERVICE_SUCCESS"
const GET_SERVICE_ERROR = "GET_SERVICE_ERROR"
const DELETE_ORDER = "DELETE_ORDER"
const DELETE_ORDER_SUCCESS = "DELETE_ORDER_SUCCESS"
const DELETE_ORDER_ERROR = "DELETE_ORDER_ERROR"
const CLOSING_REASONS = "CLOSING_REASONS"
const CLOSING_REASONS_ERROR = "CLOSING_REASONS_ERROR"
const CLOSING_REASONS_SUCCESS = "CLOSING_REASONS_SUCCESS"
const CLOSE_ORDER = "CLOSE_ORDER"
const CHANGE_PROMISE_ORDER = "CHANGE_PROMISE_ORDER"
const CHANGE_ASESOR_ORDER = "CHANGE_ASESOR_ORDER"
const SET_PROMISE_ORDER = "SET_PROMISE_ORDER"
const GET_ENTRY_CONFIG = "GET_ENTRY_CONFIG"
const FINISH_REPAIR = "FINISH_REPAIR"
const GET_ENTRY_CONFIG_SUCCESS = "GET_ENTRY_CONFIG_SUCCESS"
const GET_ENTRY_CONFIG_ERROR = "GET_ENTRY_CONFIG_ERROR"
const GET_PARTS = "GET_PARTS"

export default function reducer(state=initialData, action) {
    switch (action.type) {
        case GET_SERVICE:
            return {...state, loading:true, loadingServiceOrderDetail:true}
        case GET_SERVICE_ERROR:
            return {...state, loading:false, loadingServiceOrderDetail:false}
        case GET_SERVICE_SUCCESS:
            return {...state, loading:false, serviceDetail: action.payload, loadingServiceOrderDetail:false}
        case DELETE_ORDER:
            return {...state, deleting:true}
        case DELETE_ORDER_SUCCESS:
            return {...state, deleting:false}
        case DELETE_ORDER_ERROR:
            return {...state, deleting:false}
        case CLOSING_REASONS:
            return {...state, loading:true}
        case CLOSING_REASONS_SUCCESS:
            return {...state, loading:false, reasons: action.payload}
        case CLOSING_REASONS_ERROR:
            return {...state, loading:false}
        case CLOSE_ORDER:
            return {...state, loading:action.payload}
        case CHANGE_PROMISE_ORDER:
            return {...state, loading:action.payload}
        case GET_ENTRY_CONFIG:
            return {...state, loading:action.payload, loadingEntryConfig:true}
        case GET_ENTRY_CONFIG_SUCCESS:
            return {...state, loading:false, entryConfig: action.payload, loadingEntryConfig:false}
        case GET_ENTRY_CONFIG_ERROR:
            return {...state, loading:action.payload, loadingEntryConfig:false, entryConfig:null}
        case SET_PROMISE_ORDER:
            return {...state, loading:action.payload}
        case CHANGE_ASESOR_ORDER:
            return {...state, loading:action.payload}
        case FINISH_REPAIR:
            return {...state, loading:action.payload}
        case GET_PARTS:
            return {...state, parts:action.payload}
        default:
            return state
    }
}

//ACTIONS
export let getServiceDetail =(idOrder)=>async (dispatch, getState)=>{
    dispatch({
        type:GET_SERVICE
    })

    /* const url = `${API.WORKSHOP.WORKSHOP_ORDER_DETAIL}${expedientId}`; */
    console.log("getServiceDetail", API.WORKSHOP.WORKSHOP_ORDER_DETAIL);
    try{
        const response = await axiosApi.get(
          API.WORKSHOP.WORKSHOP_ORDER_DETAIL + idOrder
        );
        console.log('data data',response.data)
        dispatch({
            type: GET_SERVICE_SUCCESS,
            payload: response.data
        })

        console.log('res')
    }catch (errr){
        console.log(errr)
        dispatch({
            type:GET_SERVICE_ERROR
        })
    }finally{
        
    }
}

export let finishRepair =(orderId, values={})=>async (dispatch, getState)=>{
    dispatch({
        type:FINISH_REPAIR,
        payload: true
    })
    try{
        const response = await axiosApi.post(API.WORKSHOP.WORKSHOP_FINISH_REPAIR.replace('{orderId}',orderId),{
            timestamp:moment().toISOString(),
            ...values
        });
        //if(!response.success){
        //    return {success:false , message: response?.error?.response?.data?.message}
        //}
        return {success:true}
    }catch (err){
        return {success:false, error: err}
    }finally{
        dispatch({
            type:FINISH_REPAIR,
            payload: false
        })
    }
}

export let deleteServiceOrder=(idOrder)=>async (dispatch, getState)=>{
    dispatch({
        type:DELETE_ORDER
    })

    /* const url = `${API.WORKSHOP.WORKSHOP_ORDER_DETAIL}${expedientId}`; */
    console.log("getServiceDetail", API.WORKSHOP.WORKSHOP_ORDER_DETAIL);
    try{
        const response = await axiosApi.delete(
            API.WORKSHOP.WORKSHOP_ORDER_DETAIL + idOrder
        );
        console.log('data data',response.data)
        dispatch({
            type: DELETE_ORDER_SUCCESS,
            payload: response.data
        })

        console.log('res')
    }catch (errr){
        console.log(errr)
    }finally{
        dispatch({
            type: DELETE_ORDER_SUCCESS
        })
    }
}

/**
 * Obtenemos las razones de cierre
 * @returns {(function(*, *): Promise<void>)|*}
 */
export let getClosingReasons=()=>async (dispatch, getState)=>{
    dispatch({
        type:CLOSING_REASONS
    })

    /* const url = `${API.WORKSHOP.WORKSHOP_ORDER_DETAIL}${expedientId}`; */
    try{
        const response = await axiosApi.get(
            API.WORKSHOP.WORKSHOP_ORDER_REASONS+'?order_type__code=WORKSHOP'
        );
        dispatch({
            type: CLOSING_REASONS_SUCCESS,
            payload: response.data.results
        })

        console.log('res')
    }catch (errr){
        console.log(errr)
    }finally{
        dispatch({
            type: CLOSING_REASONS_ERROR
        })
    }
}


/**
 * Para cerrar la orden de servicio
 * @param orderId
 * @param data
 * @returns {(function(*, *): Promise<void>)|*}
 */
export let closeOrder=(orderId, data)=>async (dispatch, getState)=>{
    dispatch({
        type:CLOSE_ORDER,
        payload: true
    })
    try{
        const response = await axiosApi.post(API.WORKSHOP.WORKSHOP_CLOSE_ORDER.replace('{orderId}',orderId),data);
        console.log('data data',response.data)
        return {success:true}
    }catch (err){
        return {success:false, error: err}
    }finally{
        dispatch({
            type:CLOSE_ORDER,
            payload: false
        })
    }
}

/**
 * Para re abrir  la orden de servicio
 * @param orderId
 * @param data
 * @returns {(function(*, *): Promise<void>)|*}
 */
export let reOpenOrder=(orderId, data)=>async (dispatch, getState)=>{
    dispatch({
        type:CLOSE_ORDER,
        payload: true
    })
    try{
        const response = await axiosApi.post(API.WORKSHOP.WORKSHOP_REOPEN_ORDER.replace('{orderId}',orderId),data);
        console.log('data data',response.data)
        return {success:true, data: response.data}
    }catch (err){
        return {success:false, error: err}
    }finally{
        dispatch({
            type:CLOSE_ORDER,
            payload: false
        })
    }
}

/**
 * Para cambiar la fecha promesa del expediente
 * @param orderId
 * @param data
 * @returns {(function(*, *): Promise<void>)|*}
 */
export let changePromiseDateOrder=(orderId, data)=> async (dispatch, getState)=>{
    dispatch({
        type:CHANGE_PROMISE_ORDER,
        payload: true
    })
    try{
        const response = await axiosApi.post(API.WORKSHOP.WORKSHOP_SET_DELIVERY_DATE.replace('{orderId}',orderId),data);
        return {success:true}
    }catch (err){
        console.log("status", err.response.status)
        if(err?.response?.status === 403){
            throw new Error('Acción no permitida');
        }
        return {success:false, error: err}
    }finally{
        dispatch({
            type:CHANGE_PROMISE_ORDER,
            payload: false
        })
    }
}

export let changeAsesor=(orderId, data)=> async (dispatch, getState)=>{
    console.log('changeAsesor', data)
    dispatch({
        type:CHANGE_ASESOR_ORDER,
        payload: true
    })
    try{
        const response = await axiosApi.post(API.WORKSHOP.WORKSHOP_ASSIGNED.replace('{orderId}',orderId),data);
        console.log('data data',response.data)
        return {success:true}
    }catch (err){
        console.log('error',err)
        return {success:false, error: err}
    }finally{
        dispatch({
            type:CHANGE_ASESOR_ORDER,
            payload: false
        })
    }
}

/**
 * Obtenemos la configuración de la entrada a taller
 * @returns {(function(*, *): Promise<{success: boolean}|{success: boolean, error: *}|undefined>)|*}
 */
export let getEntryConfigurations=(orderId)=>async (dispatch, getState)=>{
    dispatch({
        type:GET_ENTRY_CONFIG,
        payload: true
    })
    try{
        const response = await axiosApi.get(API.WORKSHOP.WORKSHOP_GET_ENTRY_CONFIG.replace('{orderId}',orderId));
        dispatch({
            type:GET_ENTRY_CONFIG_SUCCESS,
            payload: response.data
        })
        return {success:true}
    }catch (err){
        dispatch({
            type:GET_ENTRY_CONFIG_ERROR
        })
        return {success:false, error: err}
    }finally{
        
    }
}

/**
 * Obtenemos las refacciones
 * @returns {(function(*, *): Promise<{success: boolean}|{success: boolean, error: *}|undefined>)|*}
 */
export let getParts=()=>async (dispatch, getState)=>{
    try{
        const response = await axiosApi.get(API.WORKSHOP.WORKSHOP_GET_PARTS+'?page_size=100000');
        dispatch({
            type:GET_PARTS,
            payload: response.data?.results
        })
        return {success:true}
    }catch (err){
        dispatch({
            type:GET_PARTS,
            payload: []
        })
        return {success:false, error: err}
    }finally{

    }
}


export let setEntryService=(data)=> async (dispatch, getState)=>{
    dispatch({
        type:SET_PROMISE_ORDER,
        payload: true
    })
    try{
        console.log("data", data)
        const response = await axiosApi.post(API.WORKSHOP.WORKSHOP_SET_ENTRY, data);
        return {success:true}
    }catch (err){
        console.error(err)
        return {success:false, error: err}
    }finally{
        dispatch({
            type:SET_PROMISE_ORDER,
            payload: false
        })
    }
}
