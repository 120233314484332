import React from 'react'

import { Layout } from 'antd'

import DashboardLayout from '../../layouts/DashboardLayout';
import ServiceForm from '../../components/services/ServiceForm';

import { FormattedMessage, injectIntl } from "react-intl";
import { changeLangAction } from '../../redux/settingsDuck';
import { connect } from 'react-redux';

const { Header, Content, Footer } = Layout;

const ServiceUpdatePage = (changeLangAction, intl, settings, ...props) => {
  return (
    <DashboardLayout title={<FormattedMessage id="services.update" />} index="expedientes">
      <Content
        style={{ padding: "10px 10px", background: "white", marginTop: 100 }}
      >
        <ServiceForm />
      </Content>
    </DashboardLayout>
  );
}

const mapState = (state) => ({
  settings: state.settings
});

export default injectIntl(connect(mapState, {
  changeLangAction
})(ServiceUpdatePage));

//export default ServiceCreatePage