import { useState } from "react";
import { useForm } from "antd/lib/form/Form";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import axiosApi, {API} from "../../../../lib/utils/axiosApi";


const useNewServiceOrder = () => {
    
    const [form] = useForm();
    const [loading, setLoading] = useState(false)
    const [loadingChangeAsesor, setLoadingChangeAsesor] = useState(false)
    const { serviceOrder } = useSelector((state) => state.serviceOrder);
    const withtInsurance = useSelector(state => state.settings.insuranceIsActive);
    const orderNumberDisabled = useSelector(state => state.settings.orderNumberDisabled);

    const getUserInfo = async (id) => {
        setLoadingChangeAsesor(true)
        try{
            const response = await axiosApi.get(
                API.SECURITY.USER_PROFILE + id
              );
            return {data: response.data, error: null, success: true}
        }catch(error){
            return {data: null, error: error, success: false}
        }finally{
            setLoadingChangeAsesor(false)
        }
    }
    

    useEffect(() => {
        if (serviceOrder) {
            form.setFieldsValue(serviceOrder);
        }
    }, [serviceOrder]);

    const onFinish = (values) => {
        console.log(values);
    };

    return { serviceOrder, form, onFinish, withtInsurance, orderNumberDisabled, getUserInfo, loading, loadingChangeAsesor };
};

export default useNewServiceOrder;