import React, { useState, useEffect } from 'react'
import { Table, Layout, Form, Button, Modal, Input, Upload, Popconfirm } from 'antd'
import { FormattedMessage, injectIntl } from "react-intl";
import { changeLangAction } from '../../redux/settingsDuck';
import { connect } from 'react-redux';

const ChangeLangButton = ({ changeLangAction, ...props }) => {

    return (
        <Button
            size={"small"}
            block
            type={"link"}
            style={{ textAlign: "right" }}
            onClick={changeLangAction}
        >
            <FormattedMessage id="changelang" />
        </Button>
    )
}

const mapStateToProps = (state) => ({
    settings: state.settings
});


export default injectIntl(connect(mapStateToProps, {
    changeLangAction
})(ChangeLangButton))
