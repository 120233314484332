import React, {useEffect, useState} from 'react'
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";


const BarChartKikert =({
                           data=null,
                           idChart='chartDiv',
                           withScroll=false,
                           isDonut=false,
                           categoryField='',
                           valueField='',
                           barsCurved=false,
                           ...props})=> {

    const root=null;

    useEffect(()=>{
        if(data && !root && data.length>0 ){
            let root = am5.Root.new(idChart);

            root.setThemes([
                am5themes_Animated.new(root)
            ]);

            // Create chart
// https://www.amcharts.com/docs/v5/charts/xy-chart/
            let chart = root.container.children.push(am5xy.XYChart.new(root, {
                panX: true,
                panY: true,
                wheelX: "panX",
                wheelY: "zoomX",
                pinchZoomX:true
            }));

            // Add cursor
// https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
            let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
                behavior: "zoomX"
            }));
            cursor.lineY.set("visible", false);

            let xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 30 });
            xRenderer.labels.template.setAll({
                centerY: am5.p50
            });





            let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
                categoryField: categoryField,
                renderer: xRenderer,
                tooltip: am5.Tooltip.new(root, {})
            }));

            let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
                maxDeviation: 0.3,
                renderer: am5xy.AxisRendererY.new(root, {})
            }));


            if(withScroll){
                chart.set("scrollbarX", am5.Scrollbar.new(root, {
                    orientation: "horizontal"
                }));
            }

            // Create series
// https://www.amcharts.com/docs/v5/charts/xy-chart/series/
            let series = chart.series.push(am5xy.ColumnSeries.new(root, {
                name: "Series 1",
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: valueField,
                categoryXField: categoryField,
                tooltip: am5.Tooltip.new(root, {
                    labelText:"{valueY}"
                })
            }));


            if(barsCurved){
                series.columns.template.set("draw", function (display, target) {
                    let w = target.getPrivate("width", 0);
                    let h = target.getPrivate("height", 0);
                    display.moveTo(0, h);
                    display.bezierCurveTo(w / 4, h, w / 4, 0, w / 2, 0);
                    display.bezierCurveTo(w - w / 4, 0, w - w / 4, h, w, h);
                });
            }



            series.columns.template.setAll({ cornerRadiusTL: 5, cornerRadiusTR: 5 });
            series.columns.template.adapters.add("fill", function(fill, target) {
                return chart.get("colors").getIndex(series.columns.indexOf(target));
            });

            series.columns.template.adapters.add("stroke", function(stroke, target) {
                return chart.get("colors").getIndex(series.columns.indexOf(target));
            });


            xAxis.data.setAll(data);
            series.data.setAll(data);


// Make stuff animate on load
// https://www.amcharts.com/docs/v5/concepts/animations/
            series.appear(1000);
            chart.appear(1000, 100);


            return () => {root.dispose();};
        }


    },[data])

    return (
        <div id={idChart} style={{width: "100%", height: "500px"}}/>
    );

}


export default BarChartKikert;