import { Table, Checkbox } from 'antd'
import React from 'react'
import styled from 'styled-components';
import UnCheked from '../../image/check_vacio.svg';
import Cheked from '../../image/check_blue.svg';
import {TableFormProfile} from '../MyComponents';
import { FormattedMessage } from 'react-intl';


const TableFunctionality = ({ data=[], perms, setPerms, ...props}) => {

    const validateExist = (key) =>{
        return perms.includes(key)
    }

    const changePerms = (key) => {
        //console.log('perms',perms);
        if(validateExist(key)){
            let newPerms = perms.filter(item => item !== key);
            setPerms(newPerms);
        }else{
            setPerms([...perms, key])
        }
    }


    const colummns = [
        {
            title: 'Permitir',
            key: 'allow',
            width: 10,
            render: (record) => (
                <CustomCheckBox cheked={validateExist(record.key)} click={changePerms} recordKey={record.key}  />
            )
        },
        {
            title: '',
            key: 'functionality',
            align:'left',
            render: (row) => (
                <FormattedMessage id={row.title} />
            )
        }
    ]

    const CustomCheckBox = ({ cheked = false, click, recordKey, ...props}) => (
        <img src={cheked?Cheked:UnCheked} alt="" style={{width:16, cursor:'pointer'}} onClick={() => click(recordKey)} />
    )

  return (
    <TableFormProfile columns={colummns} dataSource={data} pagination={false} />
  )
}

export default TableFunctionality