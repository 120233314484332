import React, { useEffect, useState } from "react";
import {
    Select,
    Form,
    AutoComplete
} from "antd";
import { connect } from 'react-redux'
import { injectIntl, FormattedMessage } from "react-intl";
import KSelect from "../common/elements/KSelect/KSelect";
import {getParts} from '../../redux/serviceOrderDuck'
import _ from "lodash";
const { Option } = Select;

const SelectParts=({name='part', label=null,parts, getParts, ...props})=>{

    const [options, setOptions] = useState([]);

    const onChange = (idPart) => {
        let result = _.find(parts, function(o) {
            if(o.id && idPart){
                return o.id===idPart;
            }
        });
        props.onSetPart(result)
    };

    const onSearch = (value) => {
        console.log('search:', value);
    };

    useEffect(()=>{
        if(parts){
            processPart()
        }else{
            setOptions([])
        }
    },[parts])

    const processPart=()=>{
        const opts = parts.map((p)=>{
            return {value:p.id, label:`${p.name}${p.code?':'+p.code:''}`}
        })
        setOptions(opts)
    }

    useEffect(()=>{
        getParts()
    },[])


    return (
        <Form.Item
            label={label ? label : ''}
            name={name}
            style={{width:'100%'}}
            //className="item-expediente-lg mb-0 label-margin"
        >
            <Select
                showSearch
                allowClear={true}
                placeholder="Elige una refacción"
                optionFilterProp="children"
                onChange={onChange}
                onSearch={onSearch}
                filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={options}
            />

        </Form.Item>
    )
}

const mapStateToProps = (state) => ({
    parts: state.serviceOrder.parts
});

export default injectIntl(
    connect(mapStateToProps, { getParts })(SelectParts)
);
