// Convert file to base64 string
export const fileToBase64 = (filename, filepath) => {
    return new Promise(resolve => {
      var file = new File([filename], filepath);
      var reader = new FileReader();
      // Read file content on file loaded event
      reader.onload = function(event) {
        resolve(event.target.result);
      };
      
      // Convert data to base64 
      reader.readAsDataURL(file);
    });
  };

  // Convert file to base64 image content
  export const fileToBase64Image = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(',')[1]);  // Get only the Base64 part
      reader.onerror = error => reject(error);
    });
  };