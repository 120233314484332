import React, {useEffect, useState} from 'react';
import {
    Layout,
    Menu,
    Row,
    Form,
    Col,
    Typography,
    Input,
    Space,
    Button,
    Card,
    Avatar,
    Image,
    Dropdown,
    List,
    Badge,
    message,
    Tag,
    Descriptions,
    Alert
} from 'antd';
import {DownOutlined} from "@ant-design/icons";
import _ from "lodash";
import {FormattedMessage, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {logOut} from "../redux/securitryDuck";
import {useHistory} from "react-router-dom";
import KModal from "./common/elements/KModal/KModal";
import {permissionsExist} from '../lib/utils/utils'


const { Text, Title } = Typography;

const  CardMenuHeader =({settings,logOut,permissions, intl, ...props})=>{

    const history = useHistory()
    const { Item } = List;
    const { Meta } = Card;
    const [visibleQRModal, setVisibleQRModal] =  useState(false)
    const [visibleAboutModal, setVisibleAboutModal] =  useState(false)
    const VERSION_KIKERT = "" || process.env.REACT_APP_VERSION_KIKERT

    const logout = async () => {

        try {
            const success = await logOut()
            if (success) {
                message.success(intl.formatMessage({ id: "security.login.logoutSuccess" }))
                history.push('/security/login')
                window.location.href = '/security/login'
            } else {
                message.error(intl.formatMessage({ id: "security.login.logoutErr" }))
            }
        } catch (e) {

        }

    }


    const menu = (
        <Menu>
            <Menu.Item onClick={() => history.push('/company')}>
                <FormattedMessage id="layout.changeEmpresa" />
            </Menu.Item>
            <Menu.Item onClick={() => {
                setVisibleQRModal(true)
            }}>
                Mi código QR
            </Menu.Item>
            {
                permissionsExist(permissions.permissionsList,"view_paymentorder") &&
                <Menu.Item onClick={() => history.push('/membership')}>
                    Mi membresía
                </Menu.Item>
            }

            {/* <Menu.Item onClick={() => logout()}>
                <FormattedMessage id="layout.logOut" />
            </Menu.Item> */}
            {/* <Menu.Item onClick={() => setVisibleAboutModal(true)}>
               <FormattedMessage id="layout.about" />
            </Menu.Item> */}
        </Menu>
    );

    return (
        <>
            <Card
                size="small"
                className="site-header-card m-auto"
                style={{ cursor: "pointer" }}
            >
                <Dropdown overlay={menu}>
                    <Item extra={<DownOutlined className="site-header-extra" />}>
                        <Meta
                            avatar={
                                <Avatar
                                    src={
                                        !settings ||
                                        (_.get(
                                            settings,
                                            "settingsData.companySelected.logo",
                                            null
                                        )
                                            ? settings.settingsData.companySelected.logo
                                            : "")
                                    }
                                />
                            }
                            title={
                                !settings ||
                                (_.get(
                                    settings,
                                    "settingsData.companySelected.name",
                                    null
                                )
                                    ? settings.settingsData.companySelected.name
                                    : "")
                            }
                            description={
                                <p className="site-header__text">
                                    <b>
                                        {!settings ||
                                            (_.get(
                                                settings,
                                                "settingsData.branchSelected.name",
                                                null
                                            )
                                                ? settings.settingsData.branchSelected.name
                                                : "")}
                                    </b>
                                </p>
                            }
                        />
                    </Item>
                </Dropdown>
            </Card>
            <KModal
                title={intl.formatMessage({ id: "qr.access" })}
                onCancel={()=> setVisibleQRModal(false)}
                loading={false}
                visible={visibleQRModal}>
                <Col span={24} style={{ textAlign: "center"}}>
                    {
                        settings?.generalInfo?.qr &&
                        <><Image
                            width={300}
                            src={settings?.generalInfo?.qr}
                        />
                            <Alert message={intl.formatMessage({ id: "qr.access.description" })} type="info" />
                        </>
                    }

                </Col>
            </KModal>
            <KModal
                title={'Kikert'}
                onCancel={()=> setVisibleAboutModal(false)}
                loading={false}
                visible={visibleAboutModal}>
                <Col span={24} style={{ textAlign: "center"}}>
                    <Descriptions
                    >
                        <Descriptions.Item label="Versión">{VERSION_KIKERT}</Descriptions.Item>
                        <Descriptions.Item label="última actualización">12022033</Descriptions.Item>

                    </Descriptions>
                </Col>
            </KModal>
        </>
    )
}


const mapStateToProps = state => ({
    settings: state.settings,
    security: state.security,
    serviceOrder: state.serviceOrder,
    permissions: state.permissions
})

export default injectIntl(
    connect(mapStateToProps, {logOut})(CardMenuHeader)
);
