import React, {useEffect, useState} from 'react'
import { Redirect } from 'react-router-dom'
import { Spin, Layout } from 'antd';
import logo from '../../image/black_icon_kikert.svg'
import pingPong from '../../services/kikert/security';
import 'animate.css';
const { Header, Footer, Sider, Content } = Layout;
const AuthInitializer = ({children}) => {
    const [loading, setLoading] = useState(false);
    const [initialCheckFinished, setInitialCheckFinished] = useState(false);
    const [successAuth, setSuccessAuth] = useState(false);

    useEffect(
        () => {
            checkAuth()
        }, []
    )

    const checkAuth = async () => {
        setLoading(true)
        try {
            const pong = await pingPong()
            console.log('Ping', pong)
            setSuccessAuth(pong);
        } catch (error) {
            console.log('Error en auth', error)
            setSuccessAuth(false);
        } finally {
            setLoading(false)
            setInitialCheckFinished(true)
        }
    }

    if(loading || !initialCheckFinished) return (<Layout>
        <Content style={{textAlign:'center',height:'100%',paddingTop:400,background:'white'}}>
                <img className="animate__animated animate__heartBeat animate__infinite" src={logo} alt=""/> <br/>
        </Content>
    </Layout>)


    if(!successAuth) return <Redirect to={'/security/login'} />

    return <>{initialCheckFinished && children}</>
}

export default AuthInitializer