import cookie from "js-cookie";

const parseJwt = (token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

const getUserDataLogged = () => {
    /**
     * Get user data trough token decoded
     */
    let token = cookie.get('token') ? cookie.get('token') : '';
    const data = parseJwt(token)
    //console.log('Token', data)
    return data
}

export default getUserDataLogged