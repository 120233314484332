import React, { useState, useEffect } from 'react'
import {injectIntl} from "react-intl";
import { useLocation } from 'react-router';

import DashboardLayout from "../../layouts/DashboardLayout";
import {
    Row,
    Col, Input
} from 'antd'
import useDriveTable from "../../hooks/useDriveTable";
import {queryStringToObject} from "../../lib/utils/utils";
import MainLayoutv2 from "../../layouts/MainLayoutv2";

const { Search } = Input;

const MainListDrivePage=({intl, ...props})=>{

    const location = useLocation();
    const {parent} = queryStringToObject(location.search)
    const [search,setSearch] = useState(null)
    const {TableDrive, MenuDrive, getFileList,files, loadingFiles, PathFiles, DrawerInfo } = useDriveTable(parent, search)


    useEffect(()=>{
        getFileList()
    },[location])



    return (
        <MainLayoutv2 title={intl.formatMessage({id: "drive.my_drive"})} index="drive">
            <Row gutter={16}>
                <Col xs={24} md={4}>
                    <MenuDrive/>
                </Col>
                <Col xs={24} md={20}>
                    <Search
                        size="large"
                        onSearch={(value)=>{
                            setSearch(value)
                            getFileList(1,value)
                        }}
                        allowClear
                        placeholder={'Buscar'}
                        className="kinput-search"
                        style={{ height: 55 }}
                    />
                </Col>

            </Row>

            <Row style={{marginTop:20}}>
                <Col span={24}>
                    <DrawerInfo/>
                    <PathFiles/>
                     <TableDrive/>
                </Col>
            </Row>

        </MainLayoutv2>
    )
}


export default injectIntl(MainListDrivePage)