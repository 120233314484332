import React from "react";
import {
    Form
} from "antd";
import { injectIntl, FormattedMessage } from "react-intl";
import KSelect from "../common/elements/KSelect/KSelect";
import _ from "lodash";
import {ruleRequired} from "../../lib/utils/rules";

const SelectJacOrderTypes=({name='order_type', label='Tipo', required=false,...props})=>{

    const types = [
        {
            label: 'Estándar',
            value: 'Estándar'
        },
        {
            label: 'Retrabajo',
            value: 'Retrabajo'
        },
        {
            label: 'Regreso externo',
            value: 'Regreso externo'
        },
        {
            label: 'Cotización',
            value: 'Cotización'
        }
    ];

    return (
        <Form.Item
            label={label ? label : <FormattedMessage id="Color" />}
            name={name}
            rules={required? [ruleRequired]:[]}
            className="item-expediente-lg mb-0 label-margin"
        >
            <KSelect
                {...props}
                options={
                    types && _.map(types, (o)=>{
                        return {
                            value:o.value,
                            label:`${o.label}`
                        }
                    })
                }
            />
        </Form.Item>
    )
}


export default injectIntl(
    SelectJacOrderTypes
);
