import MainLayoutv2  from "../../layouts/MainLayoutv2";
import { useEffect, useState } from 'react';
import {Layout,Row,Col,Input} from 'antd'
import { CustomTable } from '../../components/KComponents';
import { getCustomers } from '../../services/customerServices';
import { KButton } from '../../components/KComponents';
import { DownloadOutlined } from '@ant-design/icons';

const {Content} = Layout;
const { Search } = Input;

const Customers = () => {
  const [loading, setLoading  ] = useState(false)
  const [customers, setCustomers] = useState([])
  const [responseCustomers, setResponseCustomers  ] = useState(null)
  const [currentPage,setCurrentPage] = useState(1)
  const [search,setSearch] = useState(null)
  const [downloading,setDownloading] = useState(false)

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      render: (text, item) => <a onClick={()=>{
        alert('No cuenta con permisos suficientes')
      }}>{item.first_name} {item.last_name}</a>,
    },
    {
      title: 'Correo',
      dataIndex: 'email',
    },
    {
      title: 'Teléfono',
      dataIndex: 'phone_number',
    },
    {
      title: 'RFC',
      dataIndex: 'rfc',
    },
    {
      title: 'Empresa',
      dataIndex: 'company_name',
    },
    {
      title: 'Acciones',
      dataIndex: 'actions',
      render: (text, item) => <a onClick={()=>{
        alert('No cuenta con permisos suficientes')
      }}>Ver</a>,
    },
  ];

  useEffect(() => {
    _getCustomer()
  }, [])

  const changePagination = (page) => {
      _getCustomer(page, search);
  }


  const downloadReport = async()=>{
    setDownloading(true)

    setTimeout(()=>{
      alert('No cuenta con permisos suficientes')
      setDownloading(false)
    },3000)
     /*^ try{
       const res = await  axiosApi.get(`${urlfull}service/order/${id}/build_report/?type=ADHESION`,{responseType: 'blob'})
        downloadFile(res.data,`carta_adhesion${_.get(
                    serviceOrder,
                    "serviceDetail.folio",
                    ""
                  )}`,'application/pdf')
        console.log(res)
      }catch (e){
        console.log(e)
      }finally {
        setLoadingReport(false)
      }*/
  }
  

  const _getCustomer=async (page=null,search=null)=>{
    setLoading(true)
    try{
      const response = await getCustomers(page, search)
      if(response?.results){
        setCurrentPage(response?.page)
        setResponseCustomers(response)
        setCustomers(response?.results)
        setSearch(search)
      }else{
        setCustomers(search)
      }
    }catch(error){
      console.log(error)
    }finally{
      setLoading(false)
    }
  }


  return (<MainLayoutv2
            title={'Clientes'}
            index="customer"
        >
            <Content
                style={{padding: "10px 10px", marginTop: 10}}
            > 
              <Row gutter={16}>
                  <Col xs={24} md={12}>
                      <Search
                          size="large"
                          onSearch={(value)=>{
                              setSearch(value)
                              _getCustomer(1,value)
                          }}
                          allowClear
                          placeholder={'Buscar'}
                          className="kinput-search"
                          style={{ height: 55 }}
                      />
                  </Col>
                  <Col xs={24} md={12}>
                    <KButton type="primary" loading={downloading} onClick={downloadReport} text={downloading?'Descargando...':'Descargar'} icon={<DownloadOutlined />}/>
                  </Col>

              </Row>
              

                <CustomTable 
                loading={loading}
                columns={columns} 
                scroll={{ x: 1300 }}
                pagination={{
                  total: responseCustomers?.count,
                  pageSize: 25,
                  showSizeChanger:false,
                  pageSizeOptions:[],
                  showTotal:false,
                  showQuickJumper:true, 
                  current:responseCustomers?.page,
                  onChange: changePagination
                }} 
                dataSource={customers} />
            </Content>
    </MainLayoutv2>)
};

export default Customers;