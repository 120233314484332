import React, { useEffect, useState } from 'react'
import { Button, List, Typography, Row, Col, Spin, Modal, Result } from 'antd';
import { KButton } from '../../components/KComponents';
import SurveyComponent from '../../components/surveys/SurveyComponent';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { getSurveyByID, submitSurvey } from '../../services/surveyServices';




const SurveyDetail = () => {

    const [survey, setSurvey] = useState(null)
    const [submitSuccess, setSubmitSuccess] = useState(false)
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const { id, orderId } = useParams();
    const settings = useSelector(state => state.settings);
    const [loading, setLoading] = useState(false)
    const history = useHistory()


    useEffect(() => {
        if(id){
            setLoading(true)
            _getSurveyByID()
        }
        return () => {
            setSurvey(null)
        }
    }, [id])

    const _getSurveyByID = async () => {
        setLoading(true)
        try{    
            const response = await getSurveyByID(id)
            console.log('survey',response)
            setSurvey(response)
        } catch (error) {
            console.log(error)
        } finally{
            setLoading(false)
        }
    }


    const handleSubmit = async (answers) => {
        setLoading(true)
        console.log('answers',answers)
        try{
            const response = await submitSurvey(answers)
            console.log(response)
            setSubmitSuccess(true)
        }catch(error){
            Modal.error({
                title: 'No se pudo enviar tus respuestas',
                content: 'Por favor, intenta nuevamente, si esto continúa contácta a tu asesor.',
              });
        }finally{
            setLoading(false)
        }
    }


  return (
    <Col span={24} style={{ padding: '20px', textAlign: 'center', height: '100vh', width: '100%' }}>
      <Row justify="center" style={{ backgroundColor: '#eceef8', paddingTop:20, borderRadius: 10, width: '100%' }}>
        {
            settings?.settingsData?.companySelected &&
            <Col xs={24} align='center'>
                <img src={settings?.settingsData?.companySelected?.logo} alt={settings?.settingsData?.companySelected?.name} style={{ width: '100px', height: 'auto' }} />
                <Button onClick={() => history.push(`/service-detail/${orderId}`)} style={{ margin: '10px 0', color: '#0819b7', display:'flex', alignItems: 'center', justifyContent: 'center' }}>
                    Regresar
                </Button>
            </Col>
        }
        {
            survey?.sections && survey.sections.length > 0 && !submitSuccess &&
            <Col span={24}>
                <SurveyComponent loading={loading} onSubmit={handleSubmit} orderId={orderId} survey={survey}/>
            </Col>
        }


        {
            submitSuccess &&
            <Result
                status="success"
                title="¡Gracias por responder!"
                subTitle="Tus respuestas han sido enviadas correctamente."
            />
        }


      </Row>
    </Col>
  );
};

export default SurveyDetail;

