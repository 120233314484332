import React, { useState, useEffect } from 'react'
import { Table, Layout, Form, Button, Modal, Input, Space, message, Row, Col, Typography, Drawer, Select } from 'antd'
import { connect } from 'react-redux'
import { injectIntl } from "react-intl";
import { FormattedMessage } from "react-intl";
import { permissionsExist } from "../../lib/utils/utils";
import { axiosApi, API } from "../../lib/utils/axiosApi"
import DeleteModal from "../../components/modal/DeleteModal";
import EditIcon from "../../image/edit.svg";
import DeleteIcon from "../../image/delete.svg";
import { CustomTable } from "../../components/KComponents";
import KButton from "../../components/common/elements/KButton/KButton";
import KModal from '../../components/common/elements/KModal/KModal';
import MainLayoutv2 from "../../layouts/MainLayoutv2";
import TabsConfig from '../../components/common/elements/TabsMenuConfig/TabsMenuConfig';
import moment from 'moment';

const { Content } = Layout;
const { Option } = Select;

const WarehousePage = ({ intl, settings, permissions }) => {

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentElement, setCurrentElement] = useState(null);
    const [forDelete, setForDelete] = useState(null)
    const [modalDelete, setModalDelete] = useState(false)
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm();
    const [elements, setElements] = useState([])
    const [filteredElements, setFilteredElements] = useState([]); // Estado para los elementos filtrados
    const [searchTerm, setSearchTerm] = useState(''); // Estado para el término de búsqueda

    const [isSectionModalVisible, setIsSectionModalVisible] = useState(false);
    const [currentSection, setCurrentSection] = useState(null);
    const [sections, setSections] = useState([]);
    const [sectionLoading, setSectionLoading] = useState(false);
    const [sectionForm] = Form.useForm();
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);

    useEffect(() => {
        getElements()
    }, [])

    useEffect(() => {
        // Filtrar los elementos en función del término de búsqueda
        setFilteredElements(
            elements.filter(element =>
                element.name.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, elements]);

    const getSections = async (warehouseId) => {
        try {
            setSectionLoading(true);
            const res = await axiosApi.get(`${API.WORKSHOP.WORKSHOP_WAREHOUSE_SECTION}?warehouse=${warehouseId}`);
            if (res.status === 200) {
                setSections(res.data?.results);
            }
        } catch (e) {
            console.log(e);
        } finally {
            setSectionLoading(false);
        }
    };

    const showSectionModal = (isEdit = false) => {
        if (!isEdit) {
            sectionForm.resetFields();
            setCurrentSection(null);
        }
        setIsSectionModalVisible(true);
    };

    const handleSectionOk = () => {
        sectionForm.submit();
        setIsSectionModalVisible(false);
    };

    const handleSectionCancel = () => {
        setIsSectionModalVisible(false);
        setCurrentSection(null);
        sectionForm.resetFields();
    };

    const onSaveSection = async values => {
        if (values) {
            try {
                setSectionLoading(true);
                values.warehouse = currentElement.id;
                values.level = 0;
                const res = await axiosApi.post(API.WORKSHOP.WORKSHOP_WAREHOUSE_SECTION, values);
                if (res.status === 201) {
                    message.success('Sección creada correctamente');
                    sectionForm.resetFields();
                    setCurrentSection(null);
                    getSections(currentElement.id);
                } else {
                    message.error('Error al crear la sección');
                }
            } catch (e) {
                console.log(e);
            } finally {
                setSectionLoading(false);
                setCurrentSection(null);
            }
        }
    };

    const onEditSection = async values => {
        if (values) {
            let req = {
                id: currentSection.id,
                warehouse: currentElement.id,
                ...values
            };
            let res = await axiosApi.put(`${API.WORKSHOP.WORKSHOP_WAREHOUSE_SECTION}${currentSection.id}`, req);
            if (res.status === 200) {
                message.success('Sección editada correctamente');
                getSections(currentElement.id);
            } else {
                message.error('Error al editar la sección');
            }
            sectionForm.resetFields();
            setCurrentSection(null);
        }
    };

    const editSection = (section = null) => {
        setCurrentSection(section);
        if (section) {
            sectionForm.setFieldsValue({
                name: section.name,
                description: section.description,
                //level: section.level,
                parent: section.parent
            });
        }
        showSectionModal(true);
    };

    const deleteSection = async (sectionId) => {
        try {
            const res = await axiosApi.delete(`${API.WORKSHOP.WORKSHOP_WAREHOUSE_SECTION}${sectionId}`);
            if (res.status === 204) {
                message.success('Sección eliminada correctamente');
                getSections(currentElement.id);
            } else {
                message.error('Error al eliminar la sección');
            }
        } catch (e) {
            message.error('Error al eliminar la sección');
        }
    };

    const getElements = async () => {
        try {
            setLoading(true)
            const res = await axiosApi.get(API.WORKSHOP.WORKSHOP_WAREHOUSE)
            if (res.status === 200) {
                setElements(res.data?.results)
            }
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    }

    const showModal = (isEdit = false) => {
        if (!isEdit) {
            form.resetFields()
            setCurrentElement(null)
        }
        setIsModalVisible(true);
    };

    const handleOk = () => {
        form.submit();
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setCurrentElement(null)
        form.resetFields()
    };

    const closeModal = () => {
        setModalDelete(false);
        setForDelete(null);
    }

    const deleteItem = (current) => {
        setModalDelete(true);
        setForDelete(current);
    }

    const onDeleteElement = async () => {
        try {
            const res = await axiosApi.delete(`${API.WORKSHOP.WORKSHOP_WAREHOUSE}${forDelete.id}`)
            if (res.status === 204) {
                message.success('Almacén eliminado correctamente')
                getElements()
            }
            await closeModal()
        } catch (e) {
            message.error(intl.formatMessage({ id: "item.deleteError" }))
            await closeModal()
        }
    }

    const onSaveElement = async values => {
        if (values) {
            try {
                setLoading(true)
                values.branch = settings?.settingsData?.branchSelected?.id;
                const res = await axiosApi.post(API.WORKSHOP.WORKSHOP_WAREHOUSE, values)
                if (res.status === 201) {
                    message.success('Almacén creado correctamente')
                    form.resetFields()
                    setCurrentElement(null)
                    getElements()
                } else {
                    message.error('Error al crear el almacén')
                }
            } catch (e) {
                console.log(e)
            } finally {
                setLoading(false)
                setCurrentElement(null)
            }
        }
    };

    const onEditElement = async values => {
        if (values) {
            let req = {
                id: currentElement.id,
                branch: settings?.settingsData?.branchSelected?.id,
                ...values
            }
            let res = await axiosApi.put(`${API.WORKSHOP.WORKSHOP_WAREHOUSE}${currentElement.id}`, req)
            if (res.status === 200) {
                message.success(intl.formatMessage({ id: "item.editSuccess" }))
                await getElements()
            } else {
                message.error(intl.formatMessage({ id: "item.editError" }))
            }
            form.resetFields()
            setCurrentElement(null)
        }
    };

    const editElement = (element = null) => {
        setCurrentElement(element)
        if (element) {
            form.setFieldsValue({
                name: element.name,
                description: element.description,
                branch: element.branch
            })
            showModal(true)
        }
    }

    const openSectionsDrawer = async (element) => {
        setCurrentElement(element);
        getSections(element.id);
        setIsDrawerVisible(true);
    }

    const changePagination = (page) => {
        getElements(page);
    };

    const columns = [
        {
            title: 'Nombre',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Descripción',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Creado el',
            dataIndex: 'timestamp',
            key: 'timestamp',
            render: (timestamp) => {
                return <span>{moment(timestamp).format('DD/MM/YYYY HH:mm')}</span>
            }
        },
        {
            title: 'Acciones',
            dataIndex: 'id',
            key: 'actions',
            render: (id, record) => {
                return <Space size={30}>
                    <img key="EditIcon" src={EditIcon} style={{ cursor: 'pointer', width: 12 }}
                        onClick={() => editElement(record)} />
                    <img key="DeleteIcon" src={DeleteIcon} style={{ cursor: 'pointer', width: 12 }} onClick={() => deleteItem(record)} />
                    <Button type="link" onClick={() => openSectionsDrawer(record)}>Secciones</Button>
                </Space>
            }
        }
    ];

    const sectionColumns = [
        {
            title: 'Nombre',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Descripción',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Acciones',
            dataIndex: 'id',
            key: 'actions',
            render: (id, record) => {
                return <Space size={30}>
                    <img key="EditIcon" src={EditIcon} style={{ cursor: 'pointer', width: 12 }}
                        onClick={() => editSection(record)} />
                        
                    <img key="DeleteIcon" src={DeleteIcon} style={{ cursor: 'pointer', width: 12 }} onClick={() => deleteSection(record.id)} />
                </Space>
            }
        }
    ];

    return (
        <MainLayoutv2
            title={'Almacenes'}
            index="8"
            openKeys={["sub1"]}
        >
            <KModal
                title={currentElement ? 'Editar Almacén' : 'Agregar Almacén'}
                loading={loading}
                visible={isModalVisible}
                onCancel={handleCancel}
            >
                <Row>
                    <Col span={24}>
                        <Form
                            layout={"vertical"}
                            form={form}
                            onFinish={currentElement ? onEditElement : onSaveElement}
                        >
                            <Form.Item
                                name={"name"}
                                label={'Nombre'}
                                rules={[{ required: true, max: 500, min: 1 }]}
                            >
                                <Input
                                    className='kinput'
                                />
                            </Form.Item>
                            <Form.Item
                                name={"description"}
                                label={'Descripción'}
                                rules={[{ max: 500 }]}
                            >
                                <Input
                                    className='kinput'
                                />
                            </Form.Item>
                        </Form>
                    </Col>
                    <Col span={24} style={{ textAlign: "center" }}>
                        <Space>
                            <Button
                                size="large"
                                className="btn-string-blue btn-report-action"
                                onClick={() => handleCancel()}
                            >
                                <FormattedMessage id="layout.cancel" />
                            </Button>
                            <Button
                                className="btn-blue btn-report-action"
                                size="large"
                                onClick={() => handleOk()}
                            >
                                <FormattedMessage id="services.members.create.save" />
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </KModal>

            <KModal
                title={currentSection ? 'Editar Sección' : 'Agregar Sección'}
                loading={sectionLoading}
                visible={isSectionModalVisible}
                onCancel={handleSectionCancel}
            >
                <Row>
                    <Col span={24}>
                        <Form
                            layout={"vertical"}
                            form={sectionForm}
                            onFinish={currentSection ? onEditSection : onSaveSection}
                        >
                            <Form.Item
                                name={"name"}
                                label={'Nombre'}
                                rules={[{ required: true, max: 500, min: 1 }]}
                            >
                                <Input
                                    className='kinput'
                                />
                            </Form.Item>
                            <Form.Item
                                name={"description"}
                                label={'Descripción'}
                                rules={[{ max: 500 }]}
                            >
                                <Input
                                    className='kinput'
                                />
                            </Form.Item>
                            {/*<Form.Item
                                name={"level"}
                                label={'Nivel'}
                                rules={[{ required: true, type: 'number', min: -2147483648, max: 2147483647 }]}
                            >
                                <Input
                                    className='kinput'
                                    type="number"
                                />
                            </Form.Item> */}
                           {/*} <Form.Item
                                name={"parent"}
                                label={'Sección padre'}
                                rules={[{ type: 'number' }]}
                            >
                                <Select
                                    className='kinput'
                                    allowClear
                                    placeholder="Seleccione una sección padre"
                                >
                                    {sections.map(section => (
                                        <Option key={section.id} value={section.id}>{section.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item> */}
                        </Form>
                    </Col>
                    <Col span={24} style={{ textAlign: "center" }}>
                        <Space>
                            <Button
                                size="large"
                                className="btn-string-blue btn-report-action"
                                onClick={() => handleSectionCancel()}
                            >
                                <FormattedMessage id="layout.cancel" />
                            </Button>
                            <Button
                                className="btn-blue btn-report-action"
                                size="large"
                                onClick={() => handleSectionOk()}
                            >
                                <FormattedMessage id="services.members.create.save" />
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </KModal>

            <Content
                style={{ padding: "10px 10px", background: "white" }}
            >
                <TabsConfig keyActive={'10'} />
                
                <Row style={{ marginBottom: 20 }}>
                    <Col span={12}>
                        <Input
                            placeholder="Buscar almacén"
                            value={searchTerm}
                            onChange={e => setSearchTerm(e.target.value)}
                        />
                    </Col>
                    <Col span={12} style={{ textAlign: "right" }}>
                        <KButton
                            onClick={showModal}
                            type="primary"
                            size={"large"}
                            text={<FormattedMessage id="layout.createNew" />}
                        />
                    </Col>
                </Row>

                <CustomTable
                    loading={loading}
                    dataSource={Array.isArray(filteredElements) ? filteredElements : []}
                    columns={columns}
                    pagination={{ pageSize: 25 }}
                />
            </Content>

            <Drawer
                title={`Secciones de ${currentElement?.name}`}
                width={720}
                onClose={() => setIsDrawerVisible(false)}
                visible={isDrawerVisible}
                bodyStyle={{ paddingBottom: 80 }}
            >
                <KButton
                    onClick={() => showSectionModal(false)}
                    type="primary"
                    style={{ float: "right", marginBottom: 20 }}
                    size={"large"}
                    text={<FormattedMessage id="layout.createNew" />}
                />
                <CustomTable
                    loading={sectionLoading}
                    dataSource={Array.isArray(sections) ? sections : []}
                    columns={sectionColumns}
                />
            </Drawer>

            <DeleteModal actionDelete={() => onDeleteElement()} isVisible={modalDelete}
                loading={settings?.fetching}
                closeModal={closeModal}
                modalTitle={<FormattedMessage id="deleted.ask" />}
                description={intl.formatMessage({ id: 'deleted.description' }, { obj: forDelete && forDelete.name })} />
        </MainLayoutv2>
    );
}

const mapStateToProps = state => ({
    settings: state.settings,
    permissions: state.permissions
})

export default injectIntl(connect(mapStateToProps, {
})(WarehousePage))