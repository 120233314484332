import { Table, Button, Typography, Space, message } from 'antd'
import React, {useState} from 'react'
import { FormattedMessage, injectIntl } from "react-intl";
import {EditOutlined, StopOutlined, CheckCircleOutlined} from '@ant-design/icons';
import styled from 'styled-components'
import { getUsers } from '../../redux/membersDuck';
import { connect } from 'react-redux';
import DeleteModal from '../modal/DeleteModal';
import {axiosApi, API} from '../../lib/utils/axiosApi';


import EditIcon from '../../image/edit.svg';

const CustomTable = styled(Table)`
    border-color: grey;

    .ant-table-thead > tr > th {
        color: #341eff;
        white-space: nowrap;
    }
    .ant-table-cell{
        background: none;
    }
    .ant-table-thead > tr > th {
        border-color: #341eff;
    }

`

const UsersTable = ({history, style={}, intl, membersState, getUsers,  ...props}) => {
  const {Text, Title} = Typography
  const [modalShow, setModalShow] = useState(false)
  const [forDelete, setForDelete] = useState(null)
  const [loading, setLoading] = useState(false)
  const [modalDelete, setModalDelete] = useState(false)

  const closeModal = () =>{
      setModalDelete(false);
      setForDelete(null);
  }

  const deleteItem = (current) => {
        console.log(current);
        setModalDelete(true);
        setForDelete(current);
    }

  const confirmDelte  = async () => {
    try {
      let res = await axiosApi.delete(API.SECURITY.USER_PROFILE+forDelete.id+"/")
      console.log('res',res)  
      if(res.status === 204){
        closeModal();
        getUsers();
        message.success(intl.formatMessage({
                                id: "members.delete_user_success",
                            }))
      }
    } catch (error) {
      console.log('error',error);
    }
  }

  const columns = [
        {
            title: <FormattedMessage id="name" />,
            dataIndex: 'first_name',
            key: 'profiles',
        },
        {
            title: <FormattedMessage id="last_name" />,
            dataIndex: 'last_name',
            key: 'profiles',
        },
        {
            title: <FormattedMessage id="email" />,
            dataIndex: 'email',
            key: 'profiles',
        },
        {
            title: <FormattedMessage id="phone" />,
            dataIndex: ['userprofile','phone_number'],
            key: 'profiles',
        },
        {
            title: <FormattedMessage id="profile_assigned" />,
            dataIndex: ['group','name'],
            key: 'profiles',
        },
      {
            title: 'Activo',
            dataIndex: 'is_active',
            key: 'is_active',
            render:(active)=> <p>{active?<CheckCircleOutlined style={{color:'green'}} />:<StopOutlined style={{color:'red'}} />}</p>
        },
        {
            title: <FormattedMessage id="actions" />,
            key: 'actions',
            align: 'center',
            render: (row) => 
            <Space size={20}>
                <img key="EditIcon" src={EditIcon} style={{cursor:'pointer',  width:12}} onClick={() => history.push(`/members_center_users_edit/${row.id}`)} />
                {/* <img key="DeleteIcon" src={DeleteIcon} style={{cursor:'pointer', width:12}} onClick={() => deleteItem(row)}/> */}
            </Space>
        }
    ]

  return (
    <>
      <CustomTable style={style}
                   columns={columns}
                   pagination={props.pagination}
                   onChange={props.onChange}
                   loading={props.loading}
                   dataSource={membersState?.usersList} />
      <DeleteModal actionDelete={() => confirmDelte()} isVisible={modalDelete} 
        closeModal={closeModal}
        modalTitle={<FormattedMessage id="members.delete_user_title"/>} 
        description={intl.formatMessage({ id:'members.delete_user_description'},{ user: forDelete&& forDelete.first_name+" "+forDelete.last_name })} />
    </>
  )
}

const mapState = (state) => ({
  membersState: state.members,
});

export default  injectIntl(connect(mapState, {getUsers})(UsersTable))