import React, { useEffect, useState, useContext } from "react";
import {Row, Col, Layout, Divider, Typography, Space, Input} from 'antd';
import ButtonLightHeader from "../components/common/elements/ButtonLightHeader/ButtonLightHeader";
import mensaje_icon from "../image/detail/mensaje_icon_1.svg";
import KButton from "../components/common/elements/KButton/KButton";
import KSelect from "../components/common/elements/KSelect/KSelect";
import SectionTitle from "../components/common/elements/SectionTitle/SectionTitle";
import CardPurpleLight from "../components/common/elements/CardPurpleLight/CardPurpleLight";
import _ from "lodash";
import turn from "../image/turn_icon_kikert.svg";
import NumericInput from "../components/common/NumericInput";
import NumberFormat from "../components/common/NumberFormat";
import DragAndDrop from "../components/DragAndDrop";


const { Header, Content, Footer } = Layout;
const { Title, Paragraph, Text, Link } = Typography;

const DemoComponents = ({...props})=> {

    const [fileList, setFileList] = useState([]);
    return (
      <>
        <Layout>
          <Content>
            <Row
              justify={"center"}
              style={{ minHeight: "100vh", marginTop: 30, marginBottom: 100 }}
            >
              <Col sm={0} md={12}>
                <Title>
                  <img
                    src={turn}
                    alt="logo"
                    style={{ width: 40, transform: "scaleX(-1)" }}
                  />{" "}
                  Componentes Kikert
                </Title>
                <Paragraph>
                  Los componentes que aquí se muestran están basados de la
                  librería{" "}
                  <a href="https://ant.design/" target={"_blank"}>
                    antdesign
                  </a>{" "}
                  y customizados especialmente para Kikert, es necesario seguir
                  los lineamientos presentados para el desarrollo estandarizado.
                </Paragraph>
                <Divider></Divider>

                <Space direction={"vertical"}>
                  <Title level={2}>Botones</Title>

                  <SectionTitle
                    title={
                      "ButtonLightHeader para usasrse en los headers - (src/components/common/elements/ButtonLightHeader)"
                    }
                  />

                  <ButtonLightHeader
                    style={{ marginTop: 10 }}
                    text={"Asesores"}
                  />
                  <Text code={true}>
                    {`<ButtonLightHeader text={'Asesores'}/>`}
                  </Text>
                  <ButtonLightHeader
                    icon={<img src={mensaje_icon} />}
                    text={"Con imagen"}
                  />
                  <Text code={true}>
                    {`<ButtonLightHeader icon={<img src={mensaje_icon}/>} text={'Con imagen'}/>`}
                  </Text>

                  <SectionTitle
                    title={
                      "KButton para usarse en los headers - (src/components/common/elements/KButton)"
                    }
                  />

                  <KButton
                    style={{ marginTop: 10 }}
                    type={"primary"}
                    text={"primary"}
                  />
                  <Text code={true}>
                    {`<KButton type={'primary'} text={'primary'}/>`}
                  </Text>

                  <KButton loading={true} type={"primary"} text={"loading"} />
                  <Text code={true}>
                    {`<KButton loading={true} type={'primary'} text={'loading'}/>`}
                  </Text>

                  <KButton type={"secondary"} text={"secondary"} />
                  <Text code={true}>
                    {`<KButton type={'secondary'} text={'secondary'}/>`}
                  </Text>
                  <KButton type={"third"} text={"third"} />
                  <Text code={true}>
                    {`<KButton type={'third'} text={'third'}/>`}
                  </Text>
                  <KButton type={"default"} text={"default"} />
                  <Text code={true}>
                    {`<KButton  type={'default'} text={'default'}/>`}
                  </Text>
                  <KButton type={"outline"} text={"outline"} />
                  <Text code={true}>
                    {`<KButton disabled={true} type={'outline'} text={'outline'}/>`}
                  </Text>
                  <KButton
                    disabled={true}
                    type={"secondary"}
                    text={"secondary disabled"}
                  />
                  <Text code={true}>
                    {`<KButton disabled={true} type={'secondary'} text={'secondary disabled'}/>`}
                  </Text>
                  <Space>
                    <KButton
                      icon={<img src={mensaje_icon} />}
                      size={"small"}
                      type={"secondary"}
                      text={"secondary small"}
                    />
                    <KButton
                      size={"normal"}
                      type={"secondary"}
                      text={"secondary normal"}
                    />
                    <KButton
                      size={"large"}
                      type={"secondary"}
                      text={"secondary large"}
                    />
                  </Space>
                  <Text code={true}>
                    {`<KButton icon={<img src={mensaje_icon}/>} size={'small'} type={'secondary'} text={'secondary small'}/>`}
                  </Text>
                  <Text code={true}>
                    {`<KButton size={'normal'} type={'secondary'} text={'secondary normal'}/>`}
                  </Text>
                  <Text code={true}>
                    {`<KButton  size={'large'} type={'secondary'} text={'secondary large'}/>`}
                  </Text>

                  <SectionTitle title={"Formularios"} />
                  <KSelect
                    options={[
                      { value: 9, label: "ejempplo" },
                      { value: 10, label: "ejemplo 2" },
                    ]}
                  />
                  <Text code={true}>
                    {`<KSelect
                                    options={
                                        [
                                            {value: 9, label: 'ejempplo'},
                                            {value: 10, label: 'ejemplo 2'}
                                        ]
                                    }
                                />`}
                  </Text>

                  <Divider></Divider>
                  <Title level={2}>Otros</Title>
                  <SectionTitle
                    title={
                      "CardPurpleLight para mostrar un texto y una descripción, generalmente el folio  - (src/components/common/elements/CardPurpleLight)"
                    }
                  />
                  <div style={{ width: "30%", marginTop: 30 }}>
                    <CardPurpleLight
                      title={`Folio 1`}
                      style={{ width: 20 }}
                      description={`Audi A1`}
                    />
                  </div>
                  <Text code={true}>
                    {`<CardPurpleLight
                                    title={\`Folio 1\`}
                                    description={\`Audi A1\`}/>`}
                  </Text>

                  <p>Texto con formato moneda</p>
                  <NumberFormat
                    number={7809766}
                    prefix={"$"}
                    numberDecimal={4}
                  />
                  <Text code={true}>
                    {`<NumberFormat number={7809766} prefix={'$'} numberDecimal={4}/>
                                        `}
                  </Text>

                  <Divider></Divider>
                  <Title level={2}>Input</Title>
                  <SectionTitle
                    title={
                      "Se usará el input tradicional de antdesign , sin embargo es importante añadirle la clase kinput"
                    }
                  />

                  <Input className={"kinput"} />
                  <Text code={true}>{`<Input className={'kinput'}/>`}</Text>

                  <Title level={2}>Input numérico</Title>
                  <NumericInput placeholder={"Monto "} />
                  <Text code={true}>
                    {`<NumericInput placeholder={'Monto '}/>
                                        `}
                  </Text>
                  <br />

                  <Title level={2}>Drag and Drop Files</Title>
                  <DragAndDrop
                    isMultiple
                    fileList={fileList}
                    setFileList={setFileList}
                  />
                  <Text code={true}>
                    {`<DragAndDrop
                    isMultiple
                    fileList={fileList}
                    setFileList={setFileList}
                  />`}
                  </Text>
                </Space>
              </Col>
            </Row>
          </Content>
        </Layout>
      </>
    );
}

export default DemoComponents;
