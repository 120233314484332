import React, {useEffect, useState} from "react";
import {
    Select,
    Form
} from "antd";
import { connect } from 'react-redux'
import { injectIntl, FormattedMessage } from "react-intl";
import KSelect from "../common/elements/KSelect/KSelect";
import _ from "lodash";
import {ruleRequired} from "../../lib/utils/rules";
import axiosApi from "../../lib/utils/axiosApi";
const { Option } = Select;

const SelectLocations=({name='location',members,required=false, label=null, ...props})=>{

    const [insurance, setinsurances] = useState(null)

    useEffect(()=>{
        getInsurances()
    },[])

    const getInsurances = async () => {
        const url = `/inventory/location/?page_size=100000`;
        try{
            const response = await axiosApi.get(url);
            if(response?.data?.results){
                setinsurances(response?.data?.results)
            }else{
                setinsurances([])
            }
        }catch (e){
            setinsurances([])
        }
    };


    return (
        <Form.Item
            label={label ? label : <FormattedMessage id="services.filter.vehicleLocation" />}
            name={name}
            rules={required?[ruleRequired]:[]}
            className="item-expediente-lg mb-0 label-margin"
        >
            <KSelect
                {...props}
                options={
                    insurance && _.map(insurance, (o)=>{
                        return {
                            value:o.id,
                            label:`${o.name}`
                        }
                    })
                }
            />
        </Form.Item>
    )
}

const mapStateToProps = (state) => ({
    members: state.members
});

export default injectIntl(
    connect(mapStateToProps, )(SelectLocations)
);
