import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux'
import {
  Layout,
  Menu,
  Row,
  Form,
  Col,
  Typography,
  Input,
  Space,
  Button,
  Card,
  Avatar,
  Dropdown,
  List,
  Badge,
  message,
  Alert
} from 'antd';
import {
  DownOutlined,
  PaperClipOutlined,
  MailOutlined,
  HomeOutlined,
  DownloadOutlined,
  PoweroffOutlined,
  MenuOutlined,
  BellTwoTone
} from '@ant-design/icons';
import turn from '../image/turn_icon_kikert.svg'
import _ from 'lodash'
import { injectIntl } from 'react-intl';
import { useHistory, Redirect, useLocation, useParams } from "react-router-dom"
import { logOut } from "../redux/securitryDuck";
import MainSider from "./MainSider";
import { toggleMenu } from "../redux/settingsDuck";
import { changeLangAction } from '../redux/settingsDuck';
import lenguaje from "../image/idiomas_icon.svg"
import ButtonLightHeader from '../components/common/elements/ButtonLightHeader/ButtonLightHeader';
import CardPurpleLight from '../components/common/elements/CardPurpleLight/CardPurpleLight';
import axiosApi, { urlfull } from "../lib/utils/axiosApi";
import { downloadFile } from '../lib/utils/utils'
import styles from '../components/common/elements/ButtonLightHeader/ButtonLightHeader.module.css'
import CardMenuHeader from "../components/CardMenuHeader";
import KButton from "../components/common/elements/KButton/KButton";
import StatusOrder from "../components/services/StatusOrder";
import PendingPaymentAlert from "../components/PendingPaymentAlert";
import BadgeNotifications from "../components/BadgeNotifications";
import CloseButton from "../components/CloseButton";
import { useMediaQuery } from 'react-responsive';
const ServiceLayout = ({ serviceOrder, changeLangAction, intl, title, subtitle, index, settings, logOut, toggleMenu, openKeys, ...props }) => {

  const { Header, Content, Footer, Sider } = Layout;
  const { Item } = List;
  const { SubMenu } = Menu;
  let { id } = useParams();
  const { Search } = Input;
  const { Meta } = Card;
  const { Title, Text } = Typography;
  const history = useHistory()
  const [isLogout, setIsLogout] = useState(false)
  const [loading, setloading] = useState(false)
  const [loadingReport, setLoadingReport] = useState(false)
  const URLBASE = process.env
      ? process.env.REACT_APP_DOMAIN_BASE : '';
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const [sidebar, setSidebar] = useState(false);

  const toggleSideBar = () => {
    setSidebar((prevState) => !prevState);
  }

  /* const [openKeys, setOpenkeys] = useState([]); */
  const [rootSubmenuKeys] = useState(["sub1"]);
  const location = useLocation();


  useEffect(()=>{
   isMobile && toggleMenu(true)
  },[])

  const report1 = async()=>{
    setLoadingReport(true)
      try{
       const res = await  axiosApi.get(`${urlfull}service/order/${id}/build_report/?type=COVER`,{responseType: 'blob'})
        downloadFile(res.data,`caratula_${_.get(
                    serviceOrder,
                    "serviceDetail.folio",
                    ""
                  )}`,'application/pdf')
        console.log(res)
      }catch (e){
        console.log(e)
      }finally {
        setLoadingReport(false)
      }
  }

  const exitPassReport = async()=>{
    setLoadingReport(true)
      try{
       const res = await  axiosApi.get(`${urlfull}service/order/${id}/build_report/?type=EXIT_PASS`,{responseType: 'blob'})
        downloadFile(res.data,`pase_salida_${_.get(
                    serviceOrder,
                    "serviceDetail.folio",
                    ""
                  )}`,'application/pdf')
        console.log(res)
      }catch (e){
        console.log(e)
      }finally {
        setLoadingReport(false)
      }
  }

  const reportAdhesion = async()=>{
    setLoadingReport(true)
      try{
       const res = await  axiosApi.get(`${urlfull}service/order/${id}/build_report/?type=ADHESION`,{responseType: 'blob'})
        downloadFile(res.data,`carta_adhesion${_.get(
                    serviceOrder,
                    "serviceDetail.folio",
                    ""
                  )}`,'application/pdf')
        console.log(res)
      }catch (e){
        console.log(e)
      }finally {
        setLoadingReport(false)
      }
  }

  const menuReports = (
      <Menu
          items={[
            {
              label: (
                  <ButtonLightHeader text={'Carátula'} target="_blank" type={'outline'} onClick={report1}/>
              ),
              key: '1',
            },
            {
              label: (
                  <a target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com">
                    2nd menu item
                  </a>
              ),
              key: '2',
            },

          ]}
      />
  );


  const style = {
    contenidoInicial: {
      margin: '0 auto',
      width: '70%'
    },
    contenidoInicialTitle: {
      paddingTop: 10,
      color: 'white',
      fontSize: 52,
      fontWeight: 480,
      textAlign: 'left',
      fontFamily: 'Sharp Grotesk, Book 20'
    },
    textWhite: {
      color: 'white',
      fontFamily: 'Sharp Grotesk',
      fontSize: 18,
      fontWeight: 200
    },
    divlogo: {
      backgroundColor: '#fff',
      marginTop: 30
    },
    logoMenu: {
      height: 64,
      width: 28,
      marginLeft: 40
    },
    logo: {
      height: 64,
      width: 28,
      transform: 'scaleX(-1)'
    },
    profilepic: {
      marginLeft: 50,
    },
    mainContent: {
      paddingTop: 120
    },
    divCenterButton: {
      paddingTop: 30,
      paddingLeft: 40,
    },
    centerButton: {
      color: 'white',
      padding: '12px 40px',
      backgroundColor: '#090731',
      borderColor: '#090731',
      borderRadius: 18,
      fontSize: 14,
    },
    footerContent: {
      margin: '0 auto',
      width: '75%',
      fontSize: 19,
      fontFamily: 'Sharp Grotesk',
      textAlign: 'left',
      color: 'white',
      verticalAlign: 'end',
      paddingLeft: 100,
      paddingTop: 50,
      marginTop: 50,
    }
  }

  return (
    <Layout>
      {isLogout && <Redirect to="/security/login" />}

      <MainSider
        index={index}
        /* onOpenChange={onOpenChange} */ openKeys={openKeys}
      />
      <Layout style={{ height:"100vh", padding: "0 30px", overflowY: "scroll" }}>
        <PendingPaymentAlert/>


{
  !isMobile ? (
    <Header style={{ padding: 0, paddingTop: 10, margin: 0 }}>
    <Row justify="space-between">
      <Col>
        <Space direction="horizontal">
          <Title level={1} className="mb-0" onClick={() => toggleMenu(!settings.menu.collapsed)}>
            <img src={turn} alt="logo" style={style.logo} />{" "}
            {title ? title : "Mi Dashboard"}
            <Text style={{ fontWeight: 400 }}>
              {subtitle ? ` - ${subtitle}` : ""}
            </Text>
          </Title>
        </Space>
      </Col>
      <Col className="d-flex">
        <Space align="baseline">
          <BadgeNotifications/>
          <CloseButton/>
          <CardMenuHeader/>
        </Space>

      </Col>
    </Row>
  </Header>
  ):<Header style={{ padding: 0, paddingTop: 10, margin: 0 }}>
    
  <Row justify="space-between">
  <Col>
        <Space direction="horizontal">
          <Title level={5} className="mb-0" onClick={() => toggleMenu(!settings.menu.collapsed)}>
            <MenuOutlined />{" "}
            {title ? title : "Mi Dashboard"}
            <Text style={{ fontWeight: 100 }}>
              {subtitle ? ` - ${subtitle}` : ""}
            </Text>
          </Title>
        </Space>
      </Col>
  </Row>
  <Row span="24" style={{display:"flex",justifyContent:"flex-start"}}>
        <Col>
          <BadgeNotifications/>
        </Col>
        <Col>
          <CloseButton/>
        </Col>
      </Row>
</Header>
}

       

        <Row style={{ paddingTop: 40 }}>
          <Col span={24}>
          {
  !isMobile ? (<Space>
  {_.has(serviceOrder, "serviceDetail.folio") && (
    <CardPurpleLight
      title={`Folio ${_.get(
        serviceOrder,
        "serviceDetail.folio",
        ""
      )}`}
    />
  )}
  <Button
    className={styles.button_header}
    size={"large"}
    icon={<HomeOutlined />}
    onClick={() => history.push(`/service-detail/${id}/`)}
  >
    <FormattedMessage id="servicesDetails.home" />
  </Button>
  <ButtonLightHeader
      loading={loadingReport}
      className={styles.button_header}
      size={"large"}
      icon={<DownloadOutlined />}
      text={ <FormattedMessage id="cover" />}
      onClick={report1}
  />

  <ButtonLightHeader
      loading={loadingReport}
      className={styles.button_header}
      size={"large"}
      icon={<DownloadOutlined />}
      text={ "Carta de adhesión"}
      onClick={reportAdhesion}
  />

  {
    serviceOrder?.serviceDetail?.status === 'CLOSED' && (
      <ButtonLightHeader
        loading={loadingReport}
        className={styles.button_header}
        size={"large"}
        icon={<DownloadOutlined />}
        text={ "Pase de salida"}
        onClick={exitPassReport}
      />
    )
  }
  

  <ButtonLightHeader
    size={"large"}
    onClick={() => history.push(`/service-messages/${id}/`)}
    icon={<MailOutlined />}
    text={<FormattedMessage id="services.mess" />}
  />
  <ButtonLightHeader
    size={"large"}
    icon={<PaperClipOutlined />}
    onClick={() => history.push(`/service-files/${id}/`)}
    text={<FormattedMessage id="services.files.files" />}
  />
</Space>):<ul style={{listStyle:"none",margin:0,padding:0}}>
  {
    _.has(serviceOrder, "serviceDetail.folio") && (
      <li>
    <CardPurpleLight
      title={`Folio ${_.get(
        serviceOrder,
        "serviceDetail.folio",
        ""
      )}`}
    />
  </li>
  )
  }
  <li>
  <Button
    className={styles.button_header}
    size={"large"}
    icon={<HomeOutlined />}
    onClick={() => history.push(`/service-detail/${id}/`)}
  >
    <FormattedMessage id="servicesDetails.home" />
  </Button>
  </li>
  <li>
  <ButtonLightHeader
      loading={loadingReport}
      className={styles.button_header}
      size={"large"}
      icon={<DownloadOutlined />}
      text={ <FormattedMessage id="cover" />}
      onClick={report1}
  />
  </li>
  <li>
  <ButtonLightHeader
      loading={loadingReport}
      className={styles.button_header}
      size={"large"}
      icon={<DownloadOutlined />}
      text={ "Carta de adhesión"}
      onClick={reportAdhesion}
  />
  </li>
  {
    serviceOrder?.serviceDetail?.status === 'CLOSED' && (
      <li>
  <ButtonLightHeader
      loading={loadingReport}
      className={styles.button_header}
      size={"large"}
      icon={<DownloadOutlined />}
      text={ "Pase de salida"}
      onClick={exitPassReport}
  />
  </li>
  )
  }
  <li>
  <ButtonLightHeader
    size={"large"}
    onClick={() => history.push(`/service-messages/${id}/`)}
    icon={<MailOutlined />}
    text={<FormattedMessage id="services.mess" />}
  />
  </li>
  <li>
  <ButtonLightHeader
    size={"large"}
    icon={<PaperClipOutlined />}
    onClick={() => history.push(`/service-files/${id}/`)}
    text={<FormattedMessage id="services.files.files" />}
  />
  </li>
</ul>
}

         
            <StatusOrder style={{marginTop:10}}/>
          </Col>
        </Row>
        <Content>{props.children}</Content>
      </Layout>
    </Layout>
  );
}

const mapStateToProps = state => ({
  settings: state.settings,
  security: state.security,
  serviceOrder: state.serviceOrder
})

export default injectIntl(
  connect(mapStateToProps, {
    logOut,
    changeLangAction,
    toggleMenu,
  })(ServiceLayout)
);
