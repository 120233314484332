import React, { useEffect, useCallback, useState } from "react";
import {
    Select,
    Form
} from "antd";
import { useSelector } from 'react-redux'
import { injectIntl, FormattedMessage } from "react-intl";
import {axiosApi, API} from "../../lib/utils/axiosApi";
import KSelect from "../common/elements/KSelect/KSelect";
import _ from "lodash";

const SelectSuppliers=({name='asesor',extra=null, label=null})=>{

    const {suppliersList} = useSelector(state => state.settings);

    return (
        <Form.Item
            label={label ? label : <FormattedMessage id="services.page.advisor" />}
            name={name}
            extra={extra}
            className="item-expediente-lg mb-0 label-margin"
        >
            <KSelect // Replacing KSelect with native Select from Ant Design
                showSearch
                allowClear
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                options={
                    suppliersList && _.map(suppliersList, (o)=>{
                        return {
                            value:o.id,
                            label:`${o.name}`
                        }
                    })
                }
            />
        </Form.Item>
    )
}



export default injectIntl(SelectSuppliers);
