import React, {useEffect, useState} from 'react'
import {List, Form, Input, Popconfirm, message} from 'antd';
import {
    EditOutlined,
    DeleteOutlined
} from '@ant-design/icons';
import KButton from "./common/elements/KButton/KButton";
import { useSelector, useDispatch, connect } from 'react-redux'
import {getCategoriesSupplier} from "../redux/businessDuck";
import axiosApi, {API} from "../lib/utils/axiosApi";

const TableCategory = ({getCategoriesSupplier}) => {

    const [form] = Form.useForm()
    const categories = useSelector((state) => state.business.categories)
    const [loading, setLoading] = useState(false)


    useEffect(()=>{
        getCategoriesSupplier()
    },[])




    const onFinish=async (values)=>{
        console.log(values)
        if(!values?.id){
            setLoading(true)
            try{
                const res = await axiosApi.post(API.BUSINESS.SUPPLIER_CATEGORY, {company:1,...values})
                message.success('Guardado correctamente')
                form.resetFields()
                getCategoriesSupplier()
            }catch (e){
                message.error('Error al guardar')
            }finally {
                setLoading(false)
            }
        }else{
            try{
                setLoading(true)
                const res = await axiosApi.put(API.BUSINESS.SUPPLIER_CATEGORY+values.id+'/', {company:1,...values})
                message.success('Editado correctamente')
                form.resetFields()
                getCategoriesSupplier()
            }catch (e){
                message.error('Error al editar')
            }finally {
                setLoading(false)
            }
        }
    }

    const deleteItem=async (item)=>{
        try{
            const res = await axiosApi.delete(API.BUSINESS.SUPPLIER_CATEGORY+item.id+'/')
            message.success('Eliminado correctamente')
            form.resetFields()
            getCategoriesSupplier()
        }catch (e){
            message.error('Error al guardar')
        }
    }

    return (
        <div>

            <Form
                onFinish={onFinish}
                form={form}
                layout="vertical"
            >

                <Form.Item
                    name="id"
                    style={{padding:0, margin:0 }}
                >
                    <Input value={12} hidden />
                </Form.Item>

                <Form.Item
                    label="Categoria"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: 'Es requerido ingresar un valor',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item

                >
                    <KButton type={'secondary'} loading={loading} htmlType="reset" text={'Limpiar'} />
                    <KButton type={'primary'} loading={loading} htmlType="submit" text={'Guardar'} />
                </Form.Item>
            </Form>

            <List
                className="demo-loadmore-list"
                itemLayout="horizontal"
                dataSource={categories}
                bordered
                renderItem={(item) => (
                    <List.Item
                        actions={[<a key="list-loadmore-edit" onClick={()=>{
                            form.setFieldsValue({
                                name: item.name,
                                id:item.id
                            })
                        }} ><EditOutlined style={{color:'blue'}} /></a>,
                            <Popconfirm onConfirm={()=>deleteItem(item)} title="¿Seguro de eliminar?" okText="Si" cancelText="No"> <a key="list-loadmore-more"><DeleteOutlined style={{color:'red'}} /></a></Popconfirm>]}
                    >
                            <List.Item.Meta
                                title={<p>{item.name}</p>}
                            />
                    </List.Item>
                )}
            />


        </div>
    )
}

const mapStateToProps = state => ({
    business: state.business
})


export default connect(mapStateToProps,{getCategoriesSupplier})(TableCategory)