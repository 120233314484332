import React, { Children } from 'react';
import styles from "./KButton.module.css"
import { Button } from 'antd';

const KButton=({
               text,
               icon,
               type='primary',
               size='large',
               ...props})=>{

    return (
        <Button type={type!=='link'?'':type} icon={icon} size={size} {...props} className={
             type==='secondary'?styles.button_secondary:
                 type==='third'?styles.button_third:
                    type==='outline'?styles.button_outline:
                        type==='default'?styles.button_default:
                            type==='success'?styles.button_success:
                                type==='error'?styles.button_error:
                                    type==='link'?'':styles.button_primary} >
            {text}
        </Button>
    )
}

export default KButton;