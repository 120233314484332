import { axiosApi, API } from "../lib/utils/axiosApi";

// initial state
let initialData = {
    openModalFilter: false
}

const MODAL_FILTER = "MODAL_FILTER";

// Reducer
export default function reducer(state=initialData, action) {
    switch (action.type) {
      case MODAL_FILTER:
        return { ...state, openModalFilter: action.payload };
      default:
        return state;
    }
}


// Actions

export let setModalFilter = (open) => async ( dispatch, getState ) => {
    dispatch({
      type: MODAL_FILTER,
      payload: open,
    });
}