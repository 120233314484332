import { Col, Row , Form, Upload, Button, Input, Image, Typography, Select, Checkbox, Spin, Space, message} from 'antd'
import React, {useEffect, useState} from 'react'
import { KeyOutlined  } from '@ant-design/icons';
import type, { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import {getProfiles, userFormShow} from '../../../redux/membersDuck';
import {getBranchesAction} from '../../../redux/businessDuck';
import { FormattedMessage, injectIntl } from 'react-intl';
import {axiosApi, API} from '../../../lib/utils/axiosApi';
import ChangePassword from '../../modal/ChangePassword';

import img_Stoke from "../../../image/image-fill.svg"
import { connect } from 'react-redux';
import {KButton,KSelect} from '../../KComponents'

const UserForm = ({getProfiles, membersState, getBranchesAction, businessState, userFormShow, intl, history,...props}) => {
    const { Title } = Typography;

    const [form] = Form.useForm();
    const [currentPhoto, setCurrentPhoto] = useState(null)
    const [optionsGroups, setOptionsGroups] = useState([])
    const [branchesList, setBranchesList] = useState([])
    const [imgFill, setImgFill] = useState(img_Stoke)
    const [saving, setSaving] = useState(false)
    const [updPassword, setUpdPassword] = useState(false)

    const ruleRequired = { required: true, message: intl.formatMessage({ id: "form.is_required" }) };
    const ruleEmail = {type: 'email', message: intl.formatMessage({ id: "form.type_email" }) }
    const ruleConfirmPassword = ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password_one') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(intl.formatMessage({ id: "form.confirmPassword" })));
            },
          })

    useEffect(() => {
        getProfiles(); 
        getBranchesAction()
    }, [])
    

    useEffect(() => {
        if(membersState.membersList){
            let options = [];
            membersState.membersList.map(item => {
                options.push({
                    'label': item.name,
                    'value': item.id
                })
            });
            setOptionsGroups(options)
        }

        console.log(membersState.userForEdit);

        if(membersState.userForEdit){
            const {userForEdit} = membersState;
            form.setFieldsValue({ 
                first_name: userForEdit.first_name,
                last_name: userForEdit.last_name,
                phone_number: userForEdit.userprofile?.phone_number,
                is_active: userForEdit.is_active,
                email: userForEdit.email,
                group: userForEdit.group?.id,
                branches: userForEdit.userprofile?.branches
            });
            if(userForEdit.userprofile){
                if(userForEdit.userprofile.image_profile){
                    setImgFill(userForEdit.userprofile.image_profile);
                }else{
                    setImgFill(img_Stoke)
                }
                
            }else{
                setImgFill(img_Stoke)
            }
        }

    }, [membersState])




    useEffect(() => {
      if(businessState.branchesList){
          let options = [];
          businessState.branchesList.map(item => {
              options.push({
                  label: item.name,
                  value: item.id
              })
          })
          setBranchesList(options)
      }
    }, [businessState])
    
    const saveInfo = (values) => {
        /* setLoading(true) */
        
        console.log('values =>',values);
        let req = new FormData()
        req.append('first_name', values.first_name)
        req.append("last_name", values.last_name)
        req.append("phone_number", values.phone_number)
        req.append("is_active", values.is_active ? values.is_active : false)
        req.append("username", values.email)
        req.append("email", values.email)
        
        let branchesStr = "";
        /* if(values.branches){
            values.branches.map(item => {
                branchesStr += ` ${item},`;
            })
        } */
        req.append("branches", values.branches)
        req.append("group", values.group)
        req.append("password_one", membersState.userForEdit ? values.password_one : '')
        req.append("password_two", membersState.userForEdit ? values.password_two : '')

        if(membersState.userForEdit){
            req.append("user_id", membersState.userForEdit.id)
        }

        if (currentPhoto)
            req.append('image_profile', currentPhoto)
        /* if(addUser(req)){
            history.push("/members_center_users")
        } */
        save(req);
        
        /* req.append('company', settings.settingsData.companySelected.id) */
    }

    const save = async (info) =>{
        setSaving(true)
        try {
            const res = await axiosApi.post(API.SECURITY.USER_PROFILE, info)
            message.success("Usuario agregado")
            setSaving(false)
            if(res.data?.id){
                history.push("/members_center_users_edit/"+res.data.id)
            }
        } catch (error) {
            console.error('error',error)
            const isUsernameAlreadyExists = error?.response?.data?.username
            if(isUsernameAlreadyExists)
                message.error("Ya existe un usuario con el email proporcionado")
        } finally {
            setSaving(false);
        }
    }

    const setLogo = (e) => {
        console.log('imagen nueva', e.target.files) //Muestra la imagen que vamos a cambiar
        if (e.target.files)
            setCurrentPhoto(e.target.files[0])
    }

    const submitForm = (value) => {
        console.log(value);
    }

    const closeUpdPassword = () =>{
        setUpdPassword(false);
    }
    


  return (
      <>
        <Form form={form} onFinish={saveInfo} style={{padding:'40px 30px'}} layout="vertical" className='formUser'>
            <Spin spinning={saving} >
            <Row gutter={30}>
                <Col md={6}>
                    <Form.Item >
                        <div className='divPhotoUSer'>
                            <img src={currentPhoto?URL.createObjectURL(currentPhoto):imgFill} style={{width:'100%'}} className="previewPhoto" />
                        </div>
                        <Input type={"file"} className={'kinput'} onChange={setLogo} />
                    </Form.Item>   
                </Col>
                <Col md={{ span:'7', offset:2 }}>
                    <Title level={5} >Datos personales</Title>
                    <Form.Item name={'first_name'} label="Nombre" rules={[ruleRequired]}>
                        <Input className={'kinput'}/>
                    </Form.Item>
                    <Form.Item name={'last_name'} label="Apellido" rules={[ruleRequired]}>
                        <Input className={'kinput'}/>
                    </Form.Item>
                    <Form.Item name={'phone_number'} label="Teléfono" rules={[ruleRequired]}>
                        <Input className={'kinput'}/>
                    </Form.Item>
                    <Form.Item name="is_active" valuePropName="checked">
                        <Checkbox>Activo</Checkbox>
                    </Form.Item>
                </Col>
                <Col md={{ span:'7'}}>
                    <Title level={5} >Datos de acceso</Title>
                    <Form.Item name={'email'} label="Correo electronico" rules={[ruleRequired, ruleEmail ]}>
                        <Input className={'kinput'}/>
                    </Form.Item>
                    {
                        !membersState.userForEdit && (
                            <>
                                <Form.Item name={'password_one'} label="Contraseña" rules={[ ruleRequired ]}>
                                    <Input.Password className={'kinput'}/>
                                </Form.Item>
                                <Form.Item name={'password_two'} label="Repite la contraseña" dependencies={['password_one']} rules={[ ruleRequired, ruleConfirmPassword ]}>
                                    <Input.Password className={'kinput'}/>
                                </Form.Item>
                            </>
                        )
                    }
                    <Form.Item  name="group" label="Perfil" rules={[ ruleRequired ]}>
                        <KSelect options={optionsGroups} allowClear={true}/>
                    </Form.Item>
                    <Form.Item name={'branches'} label="Talleres asignados" rules={[ ruleRequired ]}>
                        <KSelect allowClear mode="multiple" options={branchesList} />
                    </Form.Item>
                    <div style={{textAlign:'right'}}>
                        <Space>
                            {
                                membersState.userForEdit.id &&
                                (<Button type="link" block onClick={() => setUpdPassword(true) } ><KeyOutlined />Asignar contraseña</Button>)
                            }
                            
                            <KButton onClick={() => history.push("/members_center_users") } type={'secondary'} size={'large'} text={'Regresar'}/>
                            <KButton type="primary" size={'large'} htmlType='submit' text={'Guardar'}/>
                        </Space>
                    </div>
                </Col>
           </Row>
           </Spin>
        </Form>
        <ChangePassword isVisible={updPassword} closeModal={closeUpdPassword} />
    </>
  )
}

const mapState = (state) => ({
  membersState: state.members,
  businessState: state.business
});

export default injectIntl(connect(mapState, {getProfiles,  getBranchesAction, userFormShow})(UserForm))