import React, { useEffect } from "react";
import {
    Select,
    Form
} from "antd";
import { connect } from 'react-redux'
import { injectIntl, FormattedMessage } from "react-intl";
import KSelect from "../common/elements/KSelect/KSelect";
import _ from "lodash";
import {getUsersBudgetReceivers} from "../../redux/membersDuck";
const { Option } = Select;

const SelectBudgetReceivers=({name='asesor',extra=null,members,getUsersBudgetReceivers, label=null, ...props})=>{

    useEffect(()=>{
        getUsersBudgetReceivers()
    },[])


    return (
        <Form.Item
            label={label ? label : <FormattedMessage id="services.page.advisor" />}
            name={name}
            extra={extra}
            className="item-expediente-lg mb-0 label-margin"
        >
            <KSelect
                {...props}
                showSearch
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                options={
                    members?.usersBudgetReceiversList && _.map(members?.usersBudgetReceiversList, (o)=>{
                        return {
                            value:o.id,
                            label:`${o.first_name} ${o.last_name}`
                        }
                    })
                }
            />
        </Form.Item>
    )
}

const mapStateToProps = (state) => ({
    members: state.members
});

export default injectIntl(
    connect(mapStateToProps, { getUsersBudgetReceivers })(SelectBudgetReceivers)
);
