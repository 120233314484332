import React, { useEffect, useState, useContext } from "react";
import { Row, Col, Layout, Button, Menu, Breadcrumb } from 'antd';
import {FormattedMessage} from "react-intl";
import logo from '../image/logo_kikert.svg';
import imglogin from '../image/img_login.png';
import icon from '../image/icon_kikert.svg';
import { Global, css } from "@emotion/react";
import axiosApi, {  tenantSelected } from '../lib/utils/axiosApi';


const { Header, Content, Footer } = Layout;

const style={
    contenidoInicial:{
        margin:'0 auto',
        width:'70%'
    },
    contenidoInicialTitle:{
        paddingTop: 10,
        color:'white', 
        fontSize:52, 
        fontWeight:480, 
        textAlign:'left', 
        fontFamily:'Sharp Grotesk, Book 20'
    },
    textWhite:{
        color:'white',
        fontFamily:'Sharp Grotesk', 
        fontSize:18, 
        fontWeight:200
    },
    logo:{
        width: 200,
        marginTop: 50,
        marginBottom:20
    },
    mainContent:{
        paddingTop:120
    },
    divCenterButton:{
        paddingTop: 30,
        paddingLeft:40,
    },
    centerButton:{
        color: 'white', 
        padding:'12px 40px',
        backgroundColor: '#090731', 
        borderColor: '#090731', 
        borderRadius: 18,
        fontSize:14,
    },
    footerContent:{
        margin:'0 auto',
        width:'75%',    
        fontSize: 19,
        fontFamily:'Sharp Grotesk',
        textAlign: 'left',
        color:'white',
        verticalAlign:'end',
        paddingLeft: 100,
        paddingTop: 50,
        marginTop: 50,
    }
}

const SecurityLayout = ({...props})=> {
    return (
      <>
        <Global
          styles={css`
            * {
              font-family: "Sharp Grotesk",serif !important;
              letter-spacing: 0.2px;
            }
            .bannerLogin {
              background-image: url(${props?.backgroundImage ? props?.backgroundImage : (localStorage.getItem('image_background') ? localStorage.getItem('image_background') : '/login_kikert03.png')});
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
            }
            .ant-input-lg {
              padding: 25px 30px;
            }
            .btn-login{
                height: 50px;
                border:none;
            }
          `}
        />
        <Layout className="layoutbackground">
          <Content
          >
            <Row justify={"center"} style={{ minHeight: "100vh" }}>
              <Col sm={0} md={12} className="bannerLogin" />
              <Col sm={24} md={12} style={{ display: "flex" }}>
                <div style={{ margin: "auto", width: 400 }}>
                  {props.children}
                </div>
              </Col>
              {/* <Col className={'content-secondary'} style={{padding:40,paddingLeft:60}}
                        xs={0} md={11} lg={12} xl={12}>
                        <div style={style.contenidoInicial}>
                            <img src={logo} alt="logo" style={style.logo}/>
                            <h1 style={style.contenidoInicialTitle}>
                                <p>Tu tablero de control de servicios</p>
                            </h1>
                            <p style={{...style.textWhite, width:'70%'}}>
                                Tu herramienta digital alojada en la nube diseñada para administrar los procesos
                                de los talleres volviéndolos eficientes
                                y rentables
                            </p>
                            <div style={style.divCenterButton}>
                                <button style={style.centerButton}> <FormattedMessage id="security.login.buy"/></button>
                            </div>
                        </div>
                        <div style={style.footerContent}>
                            <p>www.kikert.com</p>
                        </div>
                        <img src={imglogin} alt="login" style={{position:"absolute",right:0, bottom:1}} />
                    </Col>
                    <Col xs={24} lg={6} md={20} style={{padding:20}} className={'layoutbackground'}>
                        <img src={icon} style={{position:"absolute",right:-10, top:1}} />
                        <div style={style.mainContent}>
                            {props.children}
                        </div>

                    </Col> */}
            </Row>
          </Content>
        </Layout>
      </>
    );
}

export default SecurityLayout;
