import React, { useEffect } from 'react'
import {
  SearchOutlined,
  UploadOutlined,
  TableOutlined,
  UnorderedListOutlined,
  FolderAddOutlined,
  FileFilled,
  DownloadOutlined,
  DeleteOutlined,
  InboxOutlined,
} from "@ant-design/icons";
import { Upload, message } from 'antd';
import { FormattedMessage } from 'react-intl';

const DragAndDrop = ({ isMultiple = false, fileList = [], setFileList, maxSize = false }) => {
  const { Dragger } = Upload;

  const UploadProps = {
    showUploadList: false,
    multiple: isMultiple,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file, files) => {
      if(maxSize){
        const isLt2M = file.size / 1024 / 1024 < maxSize;
          if (!isLt2M) {
            message.error(`El peso maximo por archivo es de ${maxSize}MB!`);
            setFileList([])
            return false;

          }
      }
      if (isMultiple) {
        setFileList([...fileList, ...files]);
      } else {
        setFileList([file]);
      }
      return false;
    },
    fileList,
  };

  useEffect(() => {
    console.log("fileList", fileList);
  }, [fileList]);


  const RenderFles = ({files}) => (
    <> 
      {
         files && <span>
            {
              isMultiple ?  `${files.length} archivos seleccionados`  : files[0].name
            }
            <br/>
          </span>
      }
    </>
  )

  return (
    <Dragger {...UploadProps}>
      {fileList.length > 0 ? 
        <RenderFles files={fileList} />
      : (
        <>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            <FormattedMessage id="drag&Drop" />
          </p>
          {/* <p className="ant-upload-hint">
            Support for a single or bulk upload. Strictly prohibit from
            uploading company data or other band files
          </p> */}
        </>
      )}
    </Dragger>
  );
};

export default DragAndDrop