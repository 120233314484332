import { Col, Row, Typography } from 'antd'
import React from 'react'
import { FormattedMessage } from 'react-intl';
import TableFunctionality from '../TableFunctionality';
import TableCatalogs from '../TableCatalogs';

import CatalogsIcon from '../../../image/admin.svg';
import FunctionsIcon from '../../../image/procesos.svg';

const ServicesPermissionsForm = ({perms, setPerms, ...props}) => {
    const {Text,Title} = Typography

    const functionalities = [
        {
            title: 'members.in_process_repair',
            key: "show_in_workshoporder_assignment_list"
        },
        {
            title: 'members.can_recepit_unit',
            key: "receive_order"
        },
        {
          title: 'members.can_reopen_order',
          key: "reopen_order"
      },
        {
          title: 'members.can_change_promise_date',
          key: "change_promise_date_service_order"
        },
        {
          title: 'members.receive_promise_date_noti',
          key: "receive_change_promise_date_service_order_notification"
        },

    ]

    const catalogs = [
      {
          title: 'servicesDetails.valuation',
          show: "view_valuation",
          add: "add_valuation",
          edit: "change_valuation",
          delete: "delete_valuation"
      }
    ]

    return (
    <Row justify={'space-between'}>
        <Col span={11}>
            <Title level={5} style={{color:'#3f4bfb'}} >
              <img src={CatalogsIcon} alt="" style={{ width:12 }} />
              &nbsp;
              <FormattedMessage id="layout.functions" />
            </Title>
          <div style={{border: 'solid 1px #cecece', borderRadius:20, padding:'0px 20px'}}>
            <TableFunctionality data={functionalities} perms={perms} setPerms={setPerms} />
          </div>
        </Col>
        <Col span={11}>
            <Title level={5} style={{color:'#3f4bfb'}} >
            <img src={FunctionsIcon} alt="" style={{ width:15 }} />
              &nbsp;
              <FormattedMessage id="layout.catalogs" />
          </Title>
          <div style={{border: 'solid 1px #cecece', borderRadius:20, padding:'0px 20px'}}>
            <TableCatalogs data={catalogs} perms={perms} setPerms={setPerms} />
          </div>
        </Col>
    </Row>
  )
}

export default ServicesPermissionsForm