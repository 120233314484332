import {axiosApi, API} from "../lib/utils/axiosApi"

let initialData = {
    fetching: false,
    permissionsList: [],
    error:null
}

const LOAD_PERMISSIONS = "LOAD_PERMISSIONS"
const LOAD_GET_PERMISSIONS_SUCCESS = "LOAD_GET_PERMISSIONS_SUCCESS"
const LOAD_GET_PERMISSIONS_ERROR = "LOAD_GET_PERMISSIONS_ERROR"

 const  permissionsReducer = (state=initialData, action) =>{
    switch (action.type) {
        case LOAD_PERMISSIONS:
            return { ...state, fetching: true};
        case LOAD_GET_PERMISSIONS_SUCCESS:
            return {...state, fetching: false, permissionsList: action.payload};
        case LOAD_GET_PERMISSIONS_ERROR:
            return { ...state, fetching: false, error:action.payload };
        default:
            return state
    }

}

export default permissionsReducer;

export let  saveStoragePermissions=(permissions)=>{
    localStorage.setItem('permissions', JSON.stringify(permissions))
}


export let getPermissionsConfig  = (id) => async ( dispatch, getState ) => {
    dispatch({
        type: LOAD_PERMISSIONS
    })

    await axiosApi.get(API.GROUPS.PROFILES+`${id}/`)
        .then((response) => {
            dispatch({
                type: LOAD_GET_PERMISSIONS_SUCCESS,
                payload: response.data.permissions_list,
            });
            saveStoragePermissions(response.data.permissions_list)
        })
        .catch((error) => {
            console.log("error", error);
            dispatch({
                type: LOAD_GET_PERMISSIONS_ERROR,
            });
        });
 }

export let restorePermissionsAction = () => (dispatch, getState) => {
    let permissionsLocal = localStorage.getItem('permissions')
    if (permissionsLocal &&  permissionsLocal.length >0) {
        permissionsLocal = JSON.parse(permissionsLocal)
        dispatch({
            type: LOAD_GET_PERMISSIONS_SUCCESS,
            payload: permissionsLocal
        })
    } else {
        localStorage.removeItem('permissions')
    }

}
