import React, { useState, useEffect } from 'react'
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import {
    Row,
    Col,
    Card,
    Typography,
    Layout,
    Form,
    Input,
    Switch,
    DatePicker,
    Space,
    Popover,
    Spin,
    Radio,
    message
} from 'antd';
import ServiceLayout from '../../layouts/ServiceLayout';
import {getServiceDetail, getEntryConfigurations, setEntryService, changePromiseDateOrder} from '../../redux/serviceOrderDuck'
import MenuServiceDetail from "../../components/services/MenuServiceDetail";
import SectionTitle from "../../components/common/elements/SectionTitle/SectionTitle";
import _ from 'lodash'
import locale from 'antd/es/date-picker/locale/es_ES'
import KSelect from "../../components/common/elements/KSelect/KSelect";
import moment from "moment";
import KButton from "../../components/common/elements/KButton/KButton";
import {onlyNumeric, rfcFormat, ruleRequired} from "../../lib/utils/rules";
import { InfoCircleOutlined, CalendarOutlined  } from '@ant-design/icons';
import FormChangePromiseDate from "../../components/services/FormChangePromiseDate";
import KModal from "../../components/common/elements/KModal/KModal";
import CanvasDraw from "react-canvas-draw";
import useSettingsKikert from "../../hooks/useSettingsKikert";
import { axiosApi, urlfull } from "../../lib/utils/axiosApi";
import {downloadFile, camelize} from "../../lib/utils/utils";
import MainLayoutv2 from '../../layouts/MainLayoutv2';

const { TextArea } = Input;
const WorkshopEntrancePage = ({user,getServiceDetail,getEntryConfigurations,serviceOrder,changePromiseDateOrder, setEntryService,intl, settings, ...props})=>{
    //const [messages, setMessages] = useState(null)
    const { id } = useParams();
    let history = useHistory();
    const [form] = Form.useForm();
    const [accesorieSelected, setAccesoriesSelected]  = useState([])
    const [accessories, setAccessories] = useState([])
    const [groups, setGroups]  = useState([])
    const [saveableCanvas, setSaveableCanvas]  = useState(null)
    const [existsDeliveryPromiseDate, setExistsDeliveryPromiseDate]  = useState(false)
    const [actionEdit, setActionEdit] = useState(false)
    const [visibleModalPromise, setVisibleModalPromise] = useState(false)
    const {settingsKikert, getMetaValue} = useSettingsKikert()
    const [loadingReport,setLoadingReport] = useState(false)
    const [optionsAccesoriesRaw,setOptionsAccesoriesRaw] = useState("")
    const [optionsAccesories,setOptionsAccesories] = useState([])
    const [imageDimensions, setImageDimensions] = useState({});
    const [imgUrlCanvaBack, setImgUrlCanvaBAck] = useState(null)

    const generalLoading = serviceOrder.loadingServiceOrderDetail || serviceOrder.loadingEntryConfig

    useEffect(()=>{
        if(id){
            getEntryConfigurations(id)
            getServiceDetail(id)
        }
    },[id])

    useEffect(() => {
        if(settings?.generalInfo?.inventory_list_accesory)
            setOptionsAccesoriesRaw(settings?.generalInfo?.inventory_list_accesory)
        console.log('settings?.generalInfo', settings?.generalInfo)
    }, [settings.generalInfo])

    useEffect(()=>{
        if(optionsAccesoriesRaw){
            const optionsSplitted = optionsAccesoriesRaw.split(',')
            const options = []
            for(let index in optionsSplitted){
                const option = optionsSplitted[index]
                const optionSplitted = option.split('-')
                const optionObj = {
                    label: optionSplitted[1],
                    value: optionSplitted[0]
                }
                options.push(optionObj)
            }
            setOptionsAccesories(options)
        }
    },[optionsAccesoriesRaw])

    /**
     *
     * @param fromConfig
     */
    const setValues=()=>{
        //console.log('data order', serviceOrder)
        /// asignamos valores que podemos tomar de serviceDetail
        const assigned_to = serviceOrder.serviceDetail?.assigned_to;
        const order_number = serviceOrder.serviceDetail?.order_number;
        const delivery_promise_date = serviceOrder.serviceDetail?.delivery_promise_date;
        const order_dynamic_data = serviceOrder.serviceDetail?.order_dynamic_data;
        form.resetFields()
        form.setFieldsValue({
            pyramid:_.get(order_dynamic_data,'pyramid',''),
            order: order_number
        })
        if(delivery_promise_date) {
            setExistsDeliveryPromiseDate(true)
            form.setFieldsValue({
                delivery_promise_date_static: moment(delivery_promise_date).format('DD/MM/YYYY, h:mm A')
            })
        }else{
            setExistsDeliveryPromiseDate(false)
        }

        if(assigned_to) form.setFieldsValue({received_by:assigned_to.id})

        /// seteamos valores que vienen de una entrada previa
        if(serviceOrder?.entryConfig?.entry){
            let entry = serviceOrder?.entryConfig?.entry;
            /*const {damage_image_canva} = serviceOrder?.entryConfig?.entry;
            console.log("damage_image_canva", damage_image_canva)
            if(saveableCanvas && damage_image_canva){
                saveableCanvas.loadSaveData(damage_image_canva)
            }*/
            form.setFieldsValue({
                mileage: entry.mileage,
                fuel_level:entry.fuel_level,
                entry_type:entry.entry_type,
                pre_existing_damages: entry.pre_existing_damages,
                other_accessories: entry.other_accessories,
                comments_by_customer: entry.comments_by_customer,
                location: entry.location?.id
            })
        }else{
            form.setFieldsValue({
                fuel_level:serviceOrder.entryConfig.config.fuel_levels[0].value,
                entry_type:serviceOrder.entryConfig.config.entry_type[0].value
                
            })
        }

        if(serviceOrder?.entryConfig){
            if(serviceOrder?.entryConfig?.accessories){
                let acce =  _.filter(serviceOrder?.entryConfig.accessories,{selected:true})
                setAccesoriesSelected(acce)
            }
        }

        let groups = _.get(serviceOrder, 'entryConfig.config.accessories',[]).map((i) => i.group)
        
        
        groups = _.filter(groups, 'id');
        groups = _.uniqBy(groups, 'name');
        groups = groups.sort((a, b) => a?.order - b?.order);  // Added for group sorting by order value.
        
        const accesoriesDynamic = serviceOrder?.entryConfig?.entry ?
            _.get(serviceOrder, 'entryConfig.entry.accessories',[]) :
            _.get(serviceOrder, 'entryConfig.config.accessories',[])
        
        
        groups = groups.map((g) => {
            if(g){
                const accesoriesForThisGroup = []
                accesoriesDynamic.map((a) => {
                    if(a?.group?.id === g?.id){
                        accesoriesForThisGroup.push(a)
                    }
                })
                g.accesories = accesoriesForThisGroup ? accesoriesForThisGroup : []
                return g;
            }
        })
        setAccessories(accesoriesDynamic)
        setGroups(groups)

    }

    const loadImageDimensions = (setValuesDim, imageUrl) => {
        const img = new Image();
        img.src = imageUrl;
        console.log('dan imageUrl', imageUrl)
      
        img.onload = () => {
            console.log('dan img', img.height, img.width)
            setValuesDim({
            height: img.height,
            width: img.width
          });
        };
        img.onerror = (err) => {
          console.log("img error");
          console.error(err);
        };
      };

    const onChangePromise= async (values)=>{
        try{
            const res = await  changePromiseDateOrder(id,{
                reason: values.reason,
                datetime: values.datetime
            })
            message.success(intl.formatMessage({ id: "update_success" }))
        }catch (e){
            console.log(e)
            message.error(`${e}`)
        }finally {
            setVisibleModalPromise(false)
        }
    }


    useEffect(()=>{
        if(!generalLoading && serviceOrder?.entryConfig && serviceOrder?.serviceDetail){
            // para saber si tomamos el valor de entry o de config
            setValues()
        }
    },[serviceOrder.entryConfig, serviceOrder.serviceDetail, generalLoading])

    useEffect(()=>{
        if(_.has(serviceOrder,'entryConfig.entry.accessories')){
            let acce =  _.filter(serviceOrder?.entryConfig?.entry?.accessories,{selected:true})
            setAccesoriesSelected(acce)
        }
    },[serviceOrder?.entryConfig?.entry?.accessories])

    const onFinish=async (values)=>{

        if(values?.delivery_promise_date){
            let dateStr = moment(values?.delivery_promise_date).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]')
            values.delivery_promise_date = dateStr;
        }

        let req = {
            ...values,
            workshop_order:serviceOrder?.serviceDetail['WORKSHOP'].id,
            accessories: _.map(accessories, (i) => {
                return {
                    id: i.id,
                    selected: i.selected || false,
                    value: i.value || 0,
                    code: i.code
                }
            }), //_.map(accesorieSelected, (a)=> a.id),
            damage_image_canva: settingsKikert.entryDamageImgIsActive ? saveableCanvas.getSaveData() : "{\"lines\":[],\"width\":1000,\"height\":1000}",
            damage_image_signature: settingsKikert.entryDamageImgIsActive ? saveableCanvas.getDataURL() : ""
        }
        console.log("req", req, accessories)
        try{
            const res = await setEntryService(req)
            if(res.success){
                getServiceDetail(id)
                getEntryConfigurations(id)
                message.success(intl.formatMessage({ id: "item.saveSuccess" }))
            }else{
                message.error("Error al guardar entrada de taller")
            }
            
        }catch (e){
            console.error("Erro al guardar entrada de taller", e)
        }finally {
        }

    }

    const addAccesorie=(checked, item)=>{
        let arrAccesories = [...accesorieSelected]
            let exists = existsInArray(item.id)
            if(checked){
                if(!exists){
                    arrAccesories.push({...item, group: _.get(item, "group.id", null)})
                }
            }else{
                if(exists){
                  _.remove(arrAccesories, (a)=> a.id === item.id)
                }
            }
            setAccesoriesSelected(arrAccesories)
    }

    const existsInArray=(id)=>{
        let arrIds = _.map(accesorieSelected, (a)=> a.id)
        return arrIds.includes(id)
    }

    const replaceAccessories = (accesoryList, newAccessory) => {
        const index = _.findIndex(accesoryList, {id: newAccessory.id});
        accesoryList.splice(index, 1, newAccessory)
        return accesoryList
    }

    const handleAccessories = (accessory, event, group) => {
        let accesoriesIn = accessories
        const index = _.findIndex(accesoriesIn, {id: accessory.id});
        let newAccessory = accessory
        newAccessory.value = accessory.value_type === "BOOL" ?  event.target.value ? 1 : 0 : event.target.value
        newAccessory.selected = !!(accessory.value_type === "BOOL" && event.target.value)
        accesoriesIn.splice(index, 1, newAccessory)

        let newGroup = group
        newGroup.accesories = replaceAccessories(group.accesories, newAccessory)
        
        let newGroups = _.map(groups, (i) => {
            if(i.id == group.id){
                return newGroup
            }
            return i
        })
        setGroups(newGroups)

        // accesoriesIn[code] = event.target.value
        setAccessories(accesoriesIn)
        //var emails = this.state.emails.slice(); // Make a copy of the emails first.
        //emails[index] = event.target.value; // Update it with the modified email.
        // this.setState({emails: emails}); // Update the state.
    }

    const downloadInventoryReport = async()=>{
        setLoadingReport(true)
        try{
          const res = await  axiosApi.get(`${urlfull}service/order/${id}/build_report/?type=ENTRY_INVENTORY`,{responseType: 'blob'})
          downloadFile(res.data,`inventario_${_.get(
              serviceOrder,
              "serviceDetail.folio",
              ""
          )}`,'application/pdf')
          console.log(res)
        }catch (e){
          console.log(e)
        }finally {
          setLoadingReport(false)
        }
      }
    
      // Logic for get canva url image as background
      const entryId = serviceOrder?.entryConfig?.entry?.id
      let image_canva_vehicle = '/damage.jpg'
      if(entryId && serviceOrder?.entryConfig?.entry?.url_image_vehicle_canva ){
        image_canva_vehicle = serviceOrder?.entryConfig?.entry?.url_image_vehicle_canva
      }
      if(!entryId && settings?.generalInfo?.canva_vehicle){
        image_canva_vehicle = settings?.generalInfo?.canva_vehicle
      }

    useEffect(()=>{
        if(!generalLoading && serviceOrder?.entryConfig && serviceOrder?.serviceDetail && settings?.generalInfo){
            // para saber si tomamos el valor de entry o de config
            // Logic for get canva url image as background
            const entryId = serviceOrder?.entryConfig?.entry?.id
            let image_canva_vehicle = '/damage.jpg'
            if(entryId && serviceOrder?.entryConfig?.entry?.url_image_vehicle_canva ){
                image_canva_vehicle = serviceOrder?.entryConfig?.entry?.url_image_vehicle_canva
            }
            if(!entryId && settings?.generalInfo?.canva_vehicle){
                image_canva_vehicle = settings?.generalInfo?.canva_vehicle
            }
            setImgUrlCanvaBAck(image_canva_vehicle)
        }
    },[generalLoading, settings.generalInfo])

    useEffect(() => {
        if(imgUrlCanvaBack)
            loadImageDimensions(setImageDimensions, imgUrlCanvaBack);
    }, [imgUrlCanvaBack]);

    useEffect(() => {
        if(!generalLoading && saveableCanvas && serviceOrder?.entryConfig?.entry && imageDimensions?.height){
            const {damage_image_canva} = serviceOrder?.entryConfig?.entry
            if(damage_image_canva)
                saveableCanvas.loadSaveData(damage_image_canva)
        }
    }, [saveableCanvas, serviceOrder?.entryConfig?.entry, imageDimensions, generalLoading]);

    return (
        <MainLayoutv2
            isServiceDetail={true}
            index={'expedientes'}
            title={'Registro de entrada'}>

            <Spin spinning={generalLoading || serviceOrder.loading}>
                <Layout style={{ marginTop: 10 }}>

                    <Card
                        className="card_details_service"
                    >
                        <MenuServiceDetail menuSelected={1} />
                        <br/>
                        <br/>



                        {
                            serviceOrder.serviceDetail?.status!=='CLOSED' &&
                            <Row justify={'end'}>
                                {/*<Col>*/}
                                {/*    {*/}
                                {/*        serviceOrder?.entryConfig?.entry &&*/}
                                {/*        <KButton onClick={()=>setActionEdit(!actionEdit)} icon={<EditOutlined />} size={'large'} type={'primary'} text={'Editar'}/>*/}
                                {/*    }*/}
                                {/*</Col>*/}
                                <Col>
                                    {
                                        serviceOrder?.serviceDetail?.delivery_promise_date &&
                                        <KButton size={'large'} icon={<CalendarOutlined />} onClick={()=>setVisibleModalPromise(true)} type={'secondary'} text={'Cambiar fecha promesa'}/>
                                    }
                                </Col>
                            </Row>
                        }



                        <Row>
                            <Col span={24}>


                                <Form layout={'vertical'}
                                      disabled={serviceOrder?.entryConfig?.entry && !actionEdit}
                                      form={form}
                                      name="control-hooks"
                                      onFinish={onFinish}>
                                        
                                        <SectionTitle title={
                                        `Recepción de vehículo`
                                    }/>
                                    <br/>

                                    <Row gutter={16}>
                                        <Col xs={24} lg={12}>
                                            {
                                                _.has(serviceOrder,'entryConfig.config.locations') &&
                                                <Form.Item
                                                    name={'location'}
                                                    label={<>
                                                        <span style={{marginRight: 3}}>{'Locación'}</span>
                                                        <Popover content={
                                                            <ul>
                                                            {
                                                                serviceOrder.serviceDetail?.movements.map((i) => {
                                                                    return (
                                                                        <li><b>{i?.location?.name}</b> {i?.timestamp?moment(i?.timestamp).format('DD/MM/YYYY hh:mm a') : 'N/A'}</li>
                                                                    )
                                                                })
                                                            }
                                                            </ul>
                                                        } 
                                                        title="Historial de movimientos" trigger="hover">
                                                         <InfoCircleOutlined />
                                                        </Popover>
                                                        </>}
                                                    rules={[ruleRequired]}
                                                >
                                                    <KSelect
                                                        options={
                                                            _.map(serviceOrder.entryConfig.config.locations,(a,i)=>{
                                                                return {
                                                                    label: `${a.name}`,
                                                                    value: a.id
                                                                }
                                                            })
                                                        }
                                                    />
                                                </Form.Item>
                                            }

                                        </Col>
                                        {
                                            getMetaValue('HIDE_PYRAMID_FIELD')!=="1" && (
                                                <Col xs={24} lg={12}>

                                                    <Form.Item
                                                        name={'pyramid'}
                                                        label={'Pirámide'}
                                                    >
                                                        <Input className={'kinput'}/>
                                                    </Form.Item>
                                                </Col>
                                            )
                                        }
                                        
                                    </Row>


                                    <SectionTitle title={
                                        `General`
                                    }/>


                                    <br/>
                                    <Row gutter={16}>
                                        <Col xs={24} lg={12}>
                                            {
                                                serviceOrder.entryConfig?.config?.agents &&
                                                <Form.Item
                                                    name={'received_by'}
                                                    label={'Recibido por'}
                                                    rules={[ruleRequired]}
                                                >
                                                    <KSelect
                                                        options={
                                                            _.map(serviceOrder.entryConfig.config.agents,(a,i)=>{
                                                                return {
                                                                    label: `${a.first_name} ${a.last_name}`,
                                                                    value: a.id
                                                                }
                                                            })
                                                        }
                                                    />
                                                </Form.Item>
                                            }
                                        </Col>
                                        <Col xs={24} lg={12}>

                                            <Form.Item
                                                name={'mileage'}
                                                label={'Kilometraje'}
                                                rules={[onlyNumeric]}
                                            >
                                                <Input className={'kinput'} />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row gutter={16}>
                                        {
                                            !existsDeliveryPromiseDate ? <Col xs={24} lg={12}>
                                                <Form.Item
                                                    name={'delivery_promise_date'}
                                                    label={'Fecha promesa entrega'}
                                                >
                                                    <DatePicker
                                                        locale={locale}
                                                        className={'kinput'}
                                                        style={{width:'100%'}}
                                                        format={'DD/MM/YYYY, h:mm A'}
                                                        showTime={true}
                                                        placeholder={intl.formatMessage({ id: "saleOrder.datedelivery" })}
                                                    />
                                                </Form.Item>
                                            </Col> :
                                                <Col xs={24} lg={12}>

                                                        <Form.Item
                                                            name={'delivery_promise_date_static'}
                                                            label={'Fecha promesa entrega'}
                                                        >
                                                            <Input disabled className={'kinput'}/>
                                                        </Form.Item>

                                                </Col>
                                        }

                                        <Col xs={24} lg={12}>

                                            <Form.Item
                                                name={'order'}
                                                label={'Num. orden'}
                                            >
                                                <Input disabled={getMetaValue('ORDER_NUMBER_DISABLED')==='1'} className={'kinput'}/>
                                            </Form.Item>
                                        </Col>
                                    </Row>


                                    {
                                        (getMetaValue('HIDE_FUEL_LEVEL_INPUT')==='0' ||
                                            getMetaValue('HIDE_ENTRY_TYPE_INPUT')==='0') &&
                                        <SectionTitle title={
                                            `Entrada`
                                        }/>
                                    }


                                    <br/>

                                    <Row gutter={16}>

                                        {
                                            getMetaValue('HIDE_FUEL_LEVEL_INPUT')==='0' && <Col xs={24} lg={12}>
                                                {
                                                    _.has(serviceOrder,'entryConfig.config.fuel_levels') &&
                                                    <Form.Item
                                                        name={'fuel_level'}
                                                        label={'Nivel de combustible'}
                                                        rules={[ruleRequired]}
                                                    >
                                                        <KSelect
                                                            options={serviceOrder.entryConfig.config.fuel_levels}
                                                        />
                                                    </Form.Item>
                                                }


                                            </Col>

                                        }


                                        {
                                            getMetaValue('HIDE_ENTRY_TYPE_INPUT')==='0' && <Col xs={24} lg={12}>
                                                {
                                                    _.has(serviceOrder,'entryConfig.config.entry_type') &&
                                                    <Form.Item
                                                        name={'entry_type'}
                                                        label={'Tipo de ingreso del vehículo'}
                                                        rules={[ruleRequired]}
                                                    >
                                                        <KSelect
                                                            options={serviceOrder.entryConfig.config.entry_type}
                                                        />
                                                    </Form.Item>
                                                }

                                            </Col>
                                        }

                                    </Row>

                                    <SectionTitle title={
                                        `Inventario`
                                    }/>
                                    <Row justify={"end"} style={{ marginTop: 20 }}>
                                        <Col>
                                            <Space>
                                                <KButton
                                                    loading={loadingReport}
                                                    onClick={downloadInventoryReport}
                                                    type={"outline"}
                                                    size={"small"}
                                                    text={"Descargar inventario"}
                                                />
                                            </Space>
                                        </Col>
                                    </Row>

                                    <Row gutter={16} >
                                        <Col xs={24} lg={24}>
                                            {
                                                groups.map((g)=>{
                                                    g.accesories.sort((a, b) => {
                                                        return a?.order - b?.order
                                                    })
                                                    return <>
                                                        <h3 style={{ marginTop: 10 }}>{g.name}</h3>
                                                        <Space size={[16, 24]} wrap>
                                                        {g.accesories.map((a) => {
                                                                if (a.value_type === "BOOL"){
                                                                    return (
                                                                        <Form.Item>
                                                                            <Switch checked={existsInArray(a.id)} onChange={(e)=> {addAccesorie(e,a); handleAccessories(a, {target: {value: e}}, g) }} /> {camelize(a.name)}
                                                                        </Form.Item>
                                                                    )
                                                                }
                                                                if (a.value_type === "RADIO"){
                                                                    const radio_value = a?.value?.toString ? a.value.toString() : null
                                                                    return (
                                                                        <Form.Item label={camelize(a.name)}> 
                                                                            <Radio.Group
                                                                                onChange={(e) => {handleAccessories(a, e, g)}}
                                                                                optionType="button"
                                                                                buttonStyle="solid"
                                                                                value={radio_value}
                                                                            >
                                                                                {
                                                                                    optionsAccesories.map(
                                                                                        (option) => <Radio.Button value={option.value}>{option.label}</Radio.Button>
                                                                                    )
                                                                                }
                                                                            </Radio.Group>
                                                                        </Form.Item>
                                                                    )
                                                                }
                                                                return (
                                                                    <Form.Item
                                                                        label={camelize(a.name)} 
                                                                    >
                                                                        <Input value={a.value} className={'kinput'} onChange={(e) => {handleAccessories(a, e, g)}}/>
                                                                    </Form.Item>
                                                                )
                                                            }
                                                        )}
                                                        </Space>
                                                    </>
                                    
                                                })
                                            }

                                        </Col>
                                    </Row>
                                    {
                                        settingsKikert.entryDamageImgIsActive && (
                                            <Row gutter={16} >
                                                <Col xs={24} lg={12}>
                                                    <h3>Señalar daños</h3>
                                                    <CanvasDraw 
                                                        ref={canvasDraw => (setSaveableCanvas(canvasDraw))}
                                                        imgSrc={imgUrlCanvaBack}
                                                        brushColor="#191574"
                                                        enablePanAndZoom
                                                        loadTimeOffset={0}
                                                        immediateLoading={true}
                                                        hideInterface={true}
                                                        lazyRadius={0}
                                                        brushRadius={2}
                                                        enablePanAndZoom={true}
                                                        clampLinesToDocument={true}
                                                        zoomExtents={{ min: 0.99, max: 10 }}
                                                        canvasWidth={imageDimensions?.width ? imageDimensions?.width : 1000}
                                                        canvasHeight={imageDimensions?.height ? imageDimensions?.height : 1000}
                                                        onChange={() => console.log("onChange")} 
                                                        style={{ border: "1px solid black", marginLeft: 20 }}
                                                    />
                                                    <KButton
                                                        text={'Limpar todo'}
                                                        htmlType={'button'}
                                                        size="small"
                                                        onClick={() => {
                                                            saveableCanvas && saveableCanvas.eraseAll();
                                                        }}
                                                    />
                                                    <KButton
                                                        text={'Deshacer último cambio'}
                                                        htmlType={'button'}
                                                        size="small"
                                                        onClick={() => {
                                                            saveableCanvas && saveableCanvas.undo();
                                                        }}
                                                    />

                                                </Col>
                                            </Row>
                                        )
                                    }
                                    <Row gutter={16} >
                                        <Col xs={24} lg={12}>
                                            <Form.Item
                                                name={'pre_existing_damages'}
                                                label={'Daños pre-existentes'}
                                            >
                                                <TextArea className={'kinput'} rows={4} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <Form.Item
                                                name={'other_accessories'}
                                                label={'Otros accesorios'}
                                            >
                                                <TextArea className={'kinput'} rows={4} />
                                            </Form.Item>
                                            <Form.Item
                                                name={'comments_by_customer'}
                                                label={'Comentarios del cliente'}
                                            >
                                                <TextArea className={'kinput'} rows={4} />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <KButton
                                        style={{float:'right'}}
                                        text={'Guardar'}
                                        htmlType={'submit'}
                                    />
                                </Form>
                            </Col>
                        </Row>


                    </Card>

                </Layout>
            </Spin>
            <KModal
                title={intl.formatMessage({ id: "servicesDetails.changeDatePromise" })}
                onCancel={()=> setVisibleModalPromise(false)}
                loading={generalLoading}
                visible={visibleModalPromise}>
                <Col span={24}>
                    <FormChangePromiseDate onCancel={()=>{
                        getServiceDetail(id?id:0)
                        setVisibleModalPromise(false)
                    }} orderId={id?id:0}
                    currentDatePromise={_.get(serviceOrder, 'serviceDetail.delivery_promise_date',undefined)}
                    onSubmit={onChangePromise}/>
                </Col>
            </KModal>


        </MainLayoutv2>
    )
}


const mapStateToProps = state => ({
    settings: state.settings,
    user: state.user,
    serviceOrder: state.serviceOrder
})

export default injectIntl(connect(mapStateToProps,{getServiceDetail,changePromiseDateOrder, getEntryConfigurations, setEntryService})(WorkshopEntrancePage))