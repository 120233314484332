import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {injectIntl } from "react-intl";
import {
    Layout,
    Card,
    Row,
    Col,
    Select,
    Input,
    Spin,
    Divider,
} from 'antd'
import {useHistory, useParams} from "react-router-dom";
import {getServiceDetail, getClosingReasons, closeOrder, reOpenOrder} from '../../redux/serviceOrderDuck'
import _ from "lodash";
import useSettingsKikert from "../../hooks/useSettingsKikert";
import axiosApi, {API} from "../../lib/utils/axiosApi";
import MainLayoutv2 from '../../layouts/MainLayoutv2';
import Chart from 'react-apexcharts';
import DonutChart from './DonutChart';
import { colorConOpacidad } from '../../lib/utils/utils';

const DashboardJacPage=({intl,...props})=>{

    let { id } = useParams();
    let history = useHistory();
    const {settingsKikert, getMetaValue} = useSettingsKikert()
    const [loading, setLoading] = useState(false)

    const chartOptions = {
        chart: {
          type: 'donut',
        },
        legend: {
            show: false,
        },
        labels: ['1 Pieza', '2 Piezas'],
        colors: ['#E91E63', '#FF9800', '#FF5722', '#9C27B0'],
        legend: {
          position: 'bottom',
        },
        plotOptions: {
            pie: {
                donut: {
                    labels: {
                        show: true,
                    },
                    total: {
                        show: true,
                        label: `11%`,
                        formatter: () => `112%`,
                      }
                },
            },
        },
        dataLabels: {
          enabled: true,
          formatter: (val) => `${val.toFixed(1)}%`,
        },
      };

      const chartSeries = [24.1, 36.5];

      const progressBarOptions = {
        chart: {
            type: 'bar',
            height: 50,
            sparkline: {
                enabled: true
            }
        },
        plotOptions: {
            bar: {
                horizontal: true,
                barHeight: '100%',
                colors: {
                    ranges: [{
                        from: 0,
                        to: 100,
                        color: '#621ee9'
                    }]
                }
            },
        },
        tooltip: {
            enabled: false
        },
        xaxis: {
            categories: ['+ DE 3 PIEZAS'],
            max: 100
        },
        dataLabels: {
            enabled: true,
            formatter: (val) => `${val}%`,
            style: {
                colors: ['#000']
            }
        }
    };
    
    const progressBarSeries = [{
        data: [11],
    }];


    return <>
        <MainLayoutv2 isServiceDetail={false} title={intl.formatMessage({ id: "service.dataInvoice" })} index="invoicing">
            <Spin spinning={false}>
            <Layout style={{ marginTop: 10 }}>
                <Card className="card_details_service">
                    <Row>
                        <Col xs={24} md={12}>
                            <h2 style={{paddingLeft: 120}}>U'S PINTURA MAYO 2024</h2>
                            <Chart options={chartOptions} series={chartSeries} type="donut" width="80%" />
                        </Col>
                        <Divider/>
                    </Row>
                    <Row gutter={16} justify='center' style={{marginTop: '20px'}}>
                        <Col xs={24} md={6}>
                            <DonutChart title="1 PIEZA" percentage={24.1} value={295} color="#E91E63" />
                        </Col>
                        <Col xs={24} md={6}>
                        <DonutChart title="2 PIEZAS" percentage={36.5} value={445} color="#FF9800" />
                        </Col>
                        <Col xs={24} md={6}>
                            <DonutChart title="+ DE 3 PIEZAS" percentage={11} value={135} color="#9C27B0" />
                        </Col>
                    </Row>
                    <Row gutter={16} justify='center' style={{marginTop: '20px', alignItems: 'center'}}>
                    <Col xs={4} style={{ textAlign: 'right' }}>
                        <span>+ DE 3 PIEZAS</span>
                    </Col>
                    <Col xs={16} style={{background:colorConOpacidad('#621ee9'),padding:0}}>
                        <Chart options={progressBarOptions} series={progressBarSeries} type="bar" height="50" />
                    </Col>
                    <Col xs={4} style={{ textAlign: 'left' }}>
                        <span>135 U'S</span>
                    </Col>
                    <Divider/>
                </Row>
                </Card>
            </Layout>
            </Spin>
        </MainLayoutv2>
    </>
}




export default injectIntl(DashboardJacPage);