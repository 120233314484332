import React, { useState, useEffect } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import {
  Form,
  Input,
  Table,
  Avatar,
  Layout,
  Button,
  Modal,
  Upload,
  Tag,
  Space,
  Row,
  Checkbox,
  Col,
  Skeleton,
  Card,
  Image,
  message,
  Typography,
  DatePicker,
  Mentions,
  Select,
  Spin,
  Comment,
  Tooltip,
} from "antd";

import ItemChatService from "../../components/services/ItemChatService";

import {
  deleteServiceOrder,
  getServiceDetail,
  changePromiseDateOrder,
} from "../../redux/serviceOrderDuck";
import _ from "lodash";
import moment from "moment";
import { Global, css } from "@emotion/react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { changeLangAction } from "../../redux/settingsDuck";
import "../../components/sideBarDerecha2/SiderBarRight.css";
import ServiceLayout from "../../layouts/ServiceLayout";
import axiosApi, { API } from "../../lib/utils/axiosApi";
import { getUsers } from "../../redux/membersDuck";
import {getTeams} from "../../redux/securitryDuck";
import KButton from "../../components/common/elements/KButton/KButton";
import MainLayoutv2 from '../../layouts/MainLayoutv2';

const { Header, Content, Footer } = Layout;
const { Text, Title } = Typography;

const ServiceMessagesPage = ({
  changeLangAction,
  intl,
  settings,
  getServiceDetail,
  changePromiseDateOrder,
  serviceOrder,
  deleteServiceOrder,
  getUsers,
  members,
  getTeams,
  teams,
  ...props
}) => {
  let { id } = useParams();
  let history = useHistory();
  const { Option } = Select;
  const [form] = Form.useForm();
  const [listForTag, setListForTag] = useState([]);
  const [listTeams, setListTeams] = useState([]);
  const [messagesList, setMessagesList] = useState([]);
  const [savingMessage, setSavingMessage] = useState(false)
  const [loadingMessages, setLoadingMessages] = useState(false)


    useEffect(()=>{
        getUsers()
    },[])

    getUsers=async ()=>{
      let settings = localStorage.getItem('settings_kk')
      if(settings) settings = JSON.parse(settings);
        try{
          const res = await axiosApi.get(API.USER.USERS_LIST_BRANCH.replace('{branchID}', settings?.branchSelected?.id)+'&page_size=10000')
          let users = res?.data?.results.map((item) => {
            return { value: item.id, label: `@${item.first_name} ${item.last_name}` };
          });
          setListForTag(users);
        }catch (e){

        }finally {

        }
    }

  useEffect(() => {
    let users = teams.map((item) => {
      return { value:item?.users.join(), label: item.name };
    });
    setListTeams(users);
  }, [teams]);

  useEffect(() => {
    if (id) {
      console.log("id", id);
      getServiceDetail(id);
      getTeams()
      getUsers()
    }
  }, []);

  const resetListUsers=()=>{
      form.setFieldsValue({
        tagged_users:[],
        teams:[]
      })
  }

  const sendComment = async (values) => {
    setSavingMessage(true)
    values["is_autogenerated"] = false;
    values["order"] = serviceOrder.serviceDetail.WORKSHOP.order;
    values["is_for_client"] = values.is_for_client ? values.is_for_client : false;
    values["level"] = 1;
    values["tagged_users"] = values.tagged_users ? values.tagged_users : []
    try {
      let resp = await axiosApi.post(API.SERVICE.SERVICE_ORDER_MESSAGES, values);
      if(resp.status === 201){
        message.success("Mensaje enviado")
        getMessages();
        form.resetFields()
        setSavingMessage(false);
      }

    } catch (error) {
      console.log("error", error);
    }
  }

  useEffect(() => {
    
    if (serviceOrder?.serviceDetail?.WORKSHOP.order) {
      getMessages();
    }
  }, [serviceOrder]);

  const handleChangeTeam=(teamsSelected)=>{
    let userSelected=[]
    if(teamsSelected){
      console.log('teamsSelected',teamsSelected)
      teamsSelected.map((ele)=>{
          let arr =  ele.split(',')
         arr.map((e)=>{
            let existe = _.find(listForTag, function(o) { return o.value == e; });
            if(existe){
              userSelected.push(parseInt(e))
            }

         })

      })

      if(teamsSelected.length>0){
        //console.log('existe en list',_.find(listForTag, {'id':  }) )
        form.setFieldsValue({
          tagged_users: _.uniq(userSelected)
        })
      }else{
        form.setFieldsValue({
          tagged_users: []
        })
      }
    }
  }
  
  const getMessages = async () => {
    try {
      setLoadingMessages(true);
      let resp = await axiosApi.get(
        `${API.SERVICE.SERVICE_ORDER_MESSAGES}?order=${serviceOrder.serviceDetail.WORKSHOP.order}`
      );
      if (resp.status === 200) {

        let sortMessages = resp.data.results.sort(
          (a, b) => b.timestamp.localeCompare(a.timestamp)
        );

        setMessagesList(sortMessages);
        setLoadingMessages(false);
      }
      
    } catch (error) {
      
    }
  }


  return (
    <>
      <Global
        styles={css`
          .itemDescripcion > .ant-form-item-label > label {
            font-weight: normal !important;
          }
          .cardInfoBlue {
            background-color: var(--secondaryColor) !important;
            border-radius: 5px;
            padding: 10px 15px;
            margin-bottom: 5px;
          }
          .textBlue {
            color: var(--primaryColor);
          }
          .btnEnviar {
            background-color: var(--primaryColor);
            color: white;
            padding-left: 25px;
            padding-right: 25px;
          }
          .selectmentions
            .ant-select-selection-overflow-item
            .ant-select-selection-item {
            border: solid 1px var(--primaryColor) !important;
            background: none !important;
            border-radius: 5px !important;
            color: var(--primaryColor);
          }
          .itemMessage .ant-comment-content-author {
            display: grid;
          }
          .mentionInMessage {
            border-radius: 5px;
            border: solid 1px var(--primaryColor);
            background: none;
            color: var(--primaryColor);
            padding: 2px 7px;
            margin-left: 5px;
          }
          .ant-comment-actions {
            text-align: end;
          }
          .ant-comment-actions {
            margin-top: 10px;
          }
          .tangNotifyMail {
            margin-top: 20px;
            padding: 5px 10px;
            background: var(--primaryColor);
            color: white;
            border-radius: 5px;
          }
        `}
      />
      <MainLayoutv2 
        isServiceDetail={true}
        title={intl.formatMessage({ id: "servicesDetails.servicesDetails" })}
        index="expedientes"
      >
        <Layout style={{ marginTop: 10 }}>
          <Card className="card_details_service" style={{ paddingBottom: 20 }}>
            <Title level={5}>Mensajes</Title>
            <Row>
              <Col xs={24} md={12}>
                <div
                  style={{ backgroundColor: "rgb(230, 230, 255)", padding: 20 }}
                >
                  <Spin spinning={savingMessage}>
                    <Form layout="vertical" form={form} onFinish={sendComment}>
                      <Form.Item rules={[
                        {
                          required: true,
                        },
                      ]} name="comment" label="Nuevo mensaje">
                        <Input.TextArea className={'kinput'} />
                      </Form.Item>
                      <Row justify="end">
                        <Col span={24}>
                          <Form.Item label="Equipo" name="teams">
                            <Select
                                mode="multiple"
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                onChange={handleChangeTeam}
                                showSearch
                                options={listTeams}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item label="Notificar a" name="tagged_users">
                            <Select
                              mode={'multiple'}
                              filterOption={(input, option) =>
                                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                              }

                              showSearch
                              options={listForTag}
                            />
                          </Form.Item>
                        </Col>
                        <Col>
                          <KButton text={'Limpiar selección'} type={'secondary'} onClick={()=>resetListUsers()}/>
                          <KButton text={'Enviar'} htmlType="submit"/>
                        </Col>
                      </Row>
                    </Form>
                  </Spin>
                </div>
              </Col>
              <Col
                xs={24}
                md={12}
                style={{
                  padding: "10px 20px",
                  border: "solid 1px rgb(230, 230, 255)",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom: "solid 1px black",
                  }}
                >
                  <Text>Recientes</Text>
                </div>
                <div
                  style={{
                    height: "calc(100vh - 310px)",
                    overflow: "scroll",
                  }}
                >
                  <Spin
                    spinning={loadingMessages}
                    style={{ margin: "auto", paddingTop: 50 }}
                  >
                    {messagesList.map((item,i) => (
                      <ItemChatService key={i} message={item} />
                    ))}
                  </Spin>
                </div>
              </Col>
            </Row>
          </Card>
        </Layout>
      </MainLayoutv2>
    </>
  );
};

const mapStateToProps = (state) => ({
  settings: state.settings,
  serviceOrder: state.serviceOrder,
  members: state.members,
  teams: state?.security?.teams,
});

export default injectIntl(
  connect(mapStateToProps, {
    getUsers,
    deleteServiceOrder,
    changeLangAction,
    getTeams,
    getServiceDetail,
    changePromiseDateOrder,
  })(ServiceMessagesPage)
);
