import React, { useState, useEffect } from 'react'
import { axiosApi, API, tenantSelected, typeHttp } from '../lib/utils/axiosApi'
import moment from 'moment'
import 'moment/locale/es'
import {
  FileExcelOutlined,
  PlusCircleOutlined,
  ControlOutlined,
  MailOutlined,
  EditOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
  FullscreenOutlined
} from "@ant-design/icons";
import { connect } from 'react-redux'
import DashboardLayout from "../layouts/DashboardLayout";
import { injectIntl, FormattedMessage } from "react-intl";
import _ from 'lodash'
import { Layout, Form, Input, Tag, Space, Typography, Row, Col, Checkbox, Menu, Card, Statistic, Button } from 'antd'
import {
  useHistory,
  useParams,
  useLocation
} from "react-router-dom";
import { Global, css } from '@emotion/react';
import Democharts from '../components/Charts/Democharts'
import MainLayoutv2 from '../layouts/MainLayoutv2';
import Indicators from '../components/Charts/jac_dashboard/Indicators';
import BarChart from '../components/Charts/jac_dashboard/BarChart';
import Speedometer from '../components/Charts/jac_dashboard/Speedometer';

const { Header, Content, Footer } = Layout;
const { Search } = Input;
const {Title} = Typography




const JacDashboard = ({ intl, ...props }) => {
  const [data, setData] = useState({})
  const [dataBar, setDataBar] = useState([])
  const [fullScreen, setFullScreen] = useState(false);
  const [flag, setFlag] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setFlag((flag) => !flag);
    }, 60000);
    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, []);

  useEffect(() => { 
    getInfoJac();
  }, [flag])

  const getInfoJac = async () => {
    try {
      let response = await axiosApi.get(API.WORKSHOP.WORKSHOP_JAC_DASHBOARD);
      if (response.status === 200 ){
        let dataRes = response.data 
        getDataBar(dataRes)
        setData(dataRes)
        
      }
      
    } catch (error) {
      console.log('error',error)
        /* message.error("No se pudo crear")
        setSending(false) */
    }
  }

  const toggleFullScreen = (boolean) => {
    setFullScreen(boolean);
  };


  document.addEventListener(
    "keydown",
    function (e) {
      if (e.key === "Escape") {
        toggleFullScreen(false);
      }
    },
    false
  );
  

  const getDataBar = (info) => {
    let bars = []
    console.log('info',info)
    /* info de recibidas */
    info.services_received_today_by_vehicle.map((item) => {
      let idx = bars.findIndex((element) => element.name === item.model);
      if (idx > -1) {
        bars[idx]['received'] = item.count
      }else{
        let newItem = {
          name: item.model,
          received: item.count,
          delivered: 0
        }
        bars.push(newItem)
      }
    })

    /* Info liberadas */
    info.services_delivered_today_by_vehicle.map((item) => {
      let idx = bars.findIndex((element) => element.name === item.model);
      if (idx > -1) {
        bars[idx]['delivered'] = item.count
      }else{
        let newItem = {
          name: item.model,
          received: 0,
          delivered: item.count
        }
        bars.push(newItem)
      }
    })

    setDataBar(bars)


  }


  return (
    <>
    <Global
        styles={css`
            .cardStatics{
                min-width:200px;
                min-height: 175px;
            }
            .ant-layout-content{
              padding-top: 30px !important;
            }
            .boardFullScreen{
              background-color: white;
              padding:  50px;
            }
        `
        }
    />
    <MainLayoutv2 title={intl.formatMessage({ id: "dashboard.title" })} index="9.2">
      <Layout >
        <Row justify='end'>
          <Col>
            <Button
              className="btns_services"
              icon={<FullscreenOutlined />}
              style={{ marginBottom:10 }}
              onClick={() => toggleFullScreen(true)}
            >
              FullScreen
            </Button>
          </Col>
        </Row>
        <div className={fullScreen && "boardFullScreen"}>
        <Row justify="space-between">
            <Col>
                <Indicators title='Meta' value={data.goal} color="#959677" />
            </Col>
            <Col>
                <Indicators title='Buffer' value={data.services_previus_buffer}  color="brown"/>
            </Col>
            <Col>
                <Indicators title='Ingresadas' value={data.services_received_today} color="#959677" />
            </Col>
            <Col>
                <Indicators title='Liberadas' value={data.services_delivered_today} color="#D14930" />
            </Col>
            <Col>
                <Indicators title='Buffer final' value={data.services_final_buffer} color="#D1BB30" subtitle="Buffer + Ingresadas - Liberadas" />
            </Col>
            <Col span={18}>
              {
                dataBar.length > 0 && <BarChart  data={dataBar} />
              }

            </Col>
            <Col span={6}>
                {
                  data.received_vs_delivered_percent >= 0  && <Speedometer value={data.received_vs_delivered_percent} />
                }
                
            </Col>
        </Row>
        </div>
      </Layout>
    </MainLayoutv2>
    </>
  );
};

const mapState = (state) => ({
  servicesStatus: state.services,
  settings: state.settings
});

export default injectIntl(connect(mapState)(JacDashboard)
);
