import React from 'react';
import { Global, css } from "@emotion/react";
import styles from "./KModal.module.css"
import {FormattedMessage} from "react-intl";
import {Button, Col, Row, Space, Modal, Typography, Spin} from "antd";
import turn from "../../../../image/turn_icon_kikert.svg";


const KModal=({title,loading=false, visible, onCancel, ...props})=>{

    const { Text, Title } = Typography;
    return (
        <Modal
            title=""
            footer=""
            visible={visible}
            onCancel={!loading&&onCancel}
            {...props}
        >
            <Spin spinning={loading}>

            <Row justify="space-between">
                {
                    title && <Col md={24} style={{ borderBottom: "solid 1px #341eff" }}>
                        <Title level={3} style={{ marginBottom: 0 }}>
                            <img
                                src={turn}
                                alt="logo"
                                style={{ height: 64, width: 28, transform: "scaleX(-1)" }}
                            />{" "}
                            {title}
                        </Title>
                    </Col>
                }

            </Row>
            <Row>
                <Col span={24} style={{paddingTop:20}}>
                    {props.children}
                </Col>

            </Row>
            </Spin>
        </Modal>
    )
}


export default KModal;