import React from "react";
import {Form, Image, Input, Modal} from "antd";
import {FormattedMessage} from "react-intl";

const CreateFileModal =({isVisible,onOk,onCancel,form,onFinish,setFile,...props})=>{
    return(
        <Modal title={<FormattedMessage id="services.files.newFile"/>} visible={isVisible} onOk={onOk} onCancel={onCancel}>
            <Form
                layout={'vertical'}
                form={form}
                onFinish={onFinish}
            >

                <Form.Item name={'file'} label={<FormattedMessage id="services.files.newFile"/>}>
                    <Input type={'file'} onChange={setFile} />
                </Form.Item>
            </Form>
        </Modal>
    )
}
export default CreateFileModal
