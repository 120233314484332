import React from 'react'
import styled from 'styled-components'
import _KSelect from "./common/elements/KSelect/KSelect";
import _KButton from "./common/elements/KButton/KButton";
import _CardPurpleLight from "./common/elements/CardPurpleLight/CardPurpleLight";
import _ButtonLightHeader from "./common/elements/ButtonLightHeader/ButtonLightHeader";
import { Row, DatePicker, Form, Modal, Table,Input } from 'antd';

const { Item } = Form

const HeaderSection = styled(Row)`
    margin-bottom: 20px;
    pagging-bottom: 10px;
    border-bottom: solid 1px #2D2CF5;
    .ant-form-item{
        height: 100% !important;
    }
    .ant-radio-group{
        height: 100% !important;
    }
    .ant-radio-inner{
        border-radius: 25%;
        border-color: #2D2CF5;
    }
    .ant-radio-checked .ant-radio-inner{
        background-color: #2D2CF5;
    }
    .ant-radio-inner::after{
        background-color: #2D2CF5;
        border-color: #2D2CF5;
    }
`;

export const InputDate = styled(DatePicker)``;

export const ItemForm = styled(Item)`
`;

export const CustomModal = styled(Modal)`
        .ant-modal-content{
            border-radius: 20px;
        }
`;

export const TableFormProfile = styled(Table)`
    th.ant-table-cell{
        background: none !important;
        padding-bottom: 5px;
        padding-top: 15px;
        border-bottom: solid 1px #3f4bfb;
    }
    th.ant-table-cell::before{
        background: none !important;
    }
    td.ant-table-cell, :hover{
        background: none !important;
    }
    
`;

export const CustomTable = styled(Table)`
    border-color: grey;

   .ant-table-cell-fix-left, .ant-table-cell-fix-right{
     background:white !important;
   }
    .ant-table-thead > tr > th {
        color: #341eff;
        white-space: nowrap;
    }
    .ant-table-cell{
        background: none;
    }
    .ant-table-thead > tr > th {
        border-color: #341eff;
    }
`


export const KSelect=_KSelect;
export const KButton=_KButton;
export const CardPurpleLight = _CardPurpleLight;
export const ButtonLightHeader = _ButtonLightHeader;
export default HeaderSection;
