import React, { useState, useEffect } from 'react';
import { Typography, Row,  Card,  Input, Modal } from 'antd';
import { KButton } from '../KComponents';
import useResponsive from '../../hooks/useResponsive';

const SurveyComponent = ({ survey, onSubmit, orderId, loading }) => {
  const [answers, setAnswers] = useState({});
  const [answersToSend, setAnswersToSend] = useState([]);
  const [unansweredRequired, setUnansweredRequired] = useState([]);
  const { isMobile } = useResponsive();

  useEffect(() => {
    const requiredQuestions = new Set();
    survey.sections.forEach(section => {
      section.questions.forEach(question => {
        if (question.is_required) {
          const questionId = `${section.id}-${question.id}`;
          if (!answers[questionId]) {
            requiredQuestions.add(questionId);
          }
        }
      });
    });
    setUnansweredRequired(requiredQuestions);
  }, [survey, answers]);

  const handleOptionSelect = (section, question, option) => {
    const questionId = `${section?.id}-${question?.id}`;
    updateUnansweredRequired(questionId, question.is_required, option.id);
    const newAnswer = {
      question: question.id,
      choice: option.id,
      text: option.text,
      value: option.id.toString()
    };

    setAnswers(prevAnswers => ({
      ...prevAnswers,
      [questionId]: option?.id,
    }));

    let newAnswersToSend = [...answersToSend];
    newAnswersToSend = newAnswersToSend.filter((ans) => ans.question !== newAnswer.question);
    newAnswersToSend.push(newAnswer);
    setAnswersToSend(newAnswersToSend);

  };


  // Actualiza el estado de las preguntas sin respuesta
  const updateUnansweredRequired = (questionId, isRequired, answer) => {
    setUnansweredRequired(prev => {
      const newUnanswered = new Set(prev);
      if (answer && isRequired) {
        newUnanswered.delete(questionId);
      } else if (isRequired) {
        newUnanswered.add(questionId);
      }
      return newUnanswered;
    });
  };

  const handleTextChange = (section, question, text) => {
    const questionId = `${section.id}-${question.id}`;
    updateUnansweredRequired(questionId, question.is_required, text);
    const newAnswer = {
      question: question.id,
      //choice: null, // No hay opción seleccionada en respuestas de tipo texto
      text: text
    };
  
    setAnswers(prevAnswers => ({
      ...prevAnswers,
      [questionId]: text,
    }));
  
    let newAnswersToSend = [...answersToSend];
    newAnswersToSend = newAnswersToSend.filter((ans) => ans.question !== newAnswer.question);
    newAnswersToSend.push(newAnswer);
    setAnswersToSend(newAnswersToSend);
  };

  const handleSubmit = () => {
    if (unansweredRequired.size > 0) {
      Modal.info({
        title: 'No se pudo enviar tus respuestas',
        content: (
          <div>
            <p>Por favor, responde todas las preguntas requeridas antes de enviar.</p>
          </div>
        ),
        onOk() {},
      });
      return;
    }
    const payload = {
      survey: survey.id, // ID de la encuesta
      service_order: orderId, // ID de la orden de servicio
      answers: answersToSend,
    };

    onSubmit(payload);
  };

  return (
    <div style={{ padding: '20px' }}>
      <Typography.Title level={2} style={{ textAlign: 'center' }}>
        {survey.title}
      </Typography.Title>
      <Typography.Paragraph style={{ textAlign: 'center' }}>
        {survey.description}
        {`Las preguntas con `} <span style={{ color: 'red' }}>*</span> {`son obligatorias`}
      </Typography.Paragraph>
      {survey.sections.map((section, sectionIndex) => (
        <div key={sectionIndex} style={{ marginBottom: '20px', width: '100%' }}>
          <Typography.Title level={4} style={{ backgroundColor: '#0819b7', color: '#fff', padding: '10px', width: '100%' }}>
            {section.title}
          </Typography.Title>
          {section?.questions && section.questions.map((question, questionIndex) => (
            <div key={questionIndex} style={{ marginBottom: '20px' }}>
              <Typography.Paragraph style={{ fontSize: 16, margin:0, padding:0 }}>{question.text} {question.is_required && <span style={{ color: 'red' }}>*</span>}</Typography.Paragraph>
              <Typography.Paragraph style={{ fontSize: 12, margin:0, padding:0, marginBottom: 10 }}>{question.description}</Typography.Paragraph>
              <Row justify="center">
                {question?.question_type === 'CHOICE' && question?.choices && question.choices.map((option, optionIndex) => (
                  <div key={optionIndex} style={{ width: `${isMobile?'100':'150'}px`, float:'left', overflow: 'hidden',marginLeft:5, marginRight:5 }}>
                    <Card
                      hoverable
                      style={{
                        textAlign: 'center',
                        minHeight: `${option.image ? isMobile?100:150 : isMobile?50:50}px`,
                        marginBottom: 10,
                        paddingTop: 5,
                        borderRadius: 10,
                        border: answers[`${section?.id}-${question?.id}`] === option?.id ? '2px solid #0819b7' : '1px solid #d9d9d9',
                      }}
                      onClick={() => handleOptionSelect(section, question, option)}
                    >
                      {
                        option.image &&
                        <img alt={option.text} src={option.image} style={{ width: '100%', backgroundSize: 'contain' }} />
                      }
                      
                      <p>
                        {option.text}
                      </p>
                    </Card>
                  </div>
                    ))}

                    {
                      question?.question_type === 'TEXT' &&
                      <Input.TextArea
                        value={answers[`${section?.id}-${question?.id}`] || ''}
                        onChange={(e) => handleTextChange(section, question, e.target.value)}
                      />
                    }
              </Row>
            </div>
          ))}
        </div>
      ))}
      <KButton type="primary" loading={loading} onClick={handleSubmit}  text='Enviar' style={{  width: '100%' }}/>
    </div>
  );
};

export default SurveyComponent;