import React from 'react'
import DashboardLayout from '../../../layouts/DashboardLayout';
import error404 from "../../../image/error 404.png"

import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from 'react-redux'
import { changeLangAction } from '../../../redux/settingsDuck';

import { RollbackOutlined, FolderOpenOutlined } from "@ant-design/icons";

import { Table, Layout, Form, Button, Input, Upload, Tag, Space, Row, Col } from 'antd'
import {
    BrowserRouter as Router,
    Route,
    Link,
    useRouteMatch,
    useHistory,
    useParams
} from "react-router-dom";

const PageNotFound = (changeLangAction, intl, settings, ...props) => {

    const history = useHistory();
    return (

        <div align="middle" style={{ paddingTop: 50 }}>
            <img src={error404} width="50%" height="50%" />

            <Col span={23} style={{ textAlign: "center", paddingTop: 20 }}>
                <Button
                    htmlType="button"
                    onClick={() => history.push("/")}
                    className="btn-string-blue btn-report-action"
                >
                    <RollbackOutlined /> <FormattedMessage id="pageNotFound.404" />
                </Button>
            </Col>
        </div>


    )
}

const mapState = (state) => ({
    settings: state.settings
});

export default injectIntl(connect(mapState, {
    changeLangAction
})(PageNotFound)) 
