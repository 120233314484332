import {createStore, combineReducers, compose, applyMiddleware} from 'redux'
import userReducer, {restoreSessionAction} from './userDuck'
import charsReducer, {getCharactersAction} from './charsDuck'
import settingsReducer, {restoreSettingsAction, getGeneralConfig, getGeneralMetaConfig} from './settingsDuck'
import securityReducer, {getLicenseStatus} from './securitryDuck'
import userProfileReducer from './userProfileDuck'
import businessReducer from './businessDuck'
import serviceOrderReducer from './serviceOrderDuck'
import salesDuck from './salesDuck'
import workflow from './workflowDuck'
import dashboardDuck from './dashboardDuck'
import servicesDuck from "./servicesDuck";
import membersDuck, {getUsersAdvisors} from "./membersDuck";
import permissionsReducer, {restorePermissionsAction} from "./permissionsDuck";
import thunk from "redux-thunk";


let rootReducer = combineReducers({
    user: userReducer,
    characters: charsReducer,
    settings: settingsReducer,
    security: securityReducer,
    userProfile: userProfileReducer,
    business: businessReducer,
    sales: salesDuck,
    workflow: workflow,
    serviceOrder: serviceOrderReducer,
    services: servicesDuck,
    permissions: permissionsReducer,
    members: membersDuck,
    dashboardStore:dashboardDuck
});
// nos sirve para ver las herramientas de desarrollo https://github.com/zalmoxisus/redux-devtools-extension (tambien hay que instalar el componente de chrome)
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function generateStore() {
    let store = createStore(rootReducer,
        composeEnhancers(applyMiddleware(thunk)))
    // esto se ejecuta antes de devolver la store
    /// este getCharactersAction obtiene una funcion y dde esa funcion ejecutas lo que necesita esa funcion
    //getCharactersAction()(store.dispatch, store.getState)
    getGeneralConfig()(store.dispatch)
    getUsersAdvisors()(store.dispatch)
    getGeneralMetaConfig()(store.dispatch)
    restoreSessionAction()(store.dispatch) // esto nos ayuda a recuperar la sesion si ya existiera
    restoreSettingsAction()(store.dispatch) // esto nos ayuda a recuperar los settings si ya existen almacenados con el usuario
    restorePermissionsAction()(store.dispatch) // esto nos ayuda a recuperar los permisisosde configuración si ya existen almacenados con el usuario
    ///***
    return store
}
