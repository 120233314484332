import { Col, Row, Typography } from 'antd'
import React from 'react'
import { FormattedMessage } from 'react-intl';
import HeaderSection from '../../MyComponents';
import TableFunctionality from '../TableFunctionality';
import CatalogsIcon from '../../../image/admin.svg';
import FunctionsIcon from '../../../image/procesos.svg';
import TableCatalogs from '../TableCatalogs';

const DashboardForm = ({perms, setPerms, ...props}) => {

    const {Text,Title} = Typography
    
    const functionalities = [
      {
        title: 'services.dash',
        key: "Dashboard"
      }
    ]

    const catalogs = []

    

  return (
    <Row justify={'space-between'}>
        <Col span={11}>
            <Title level={5} style={{color:'#3f4bfb'}} >
              <img src={CatalogsIcon} alt="" style={{ width:12 }} />
              &nbsp;
              <FormattedMessage id="layout.functions" />
            </Title>
          <div style={{border: 'solid 1px #cecece', borderRadius:20, padding:'0px 20px'}}>
            <TableFunctionality data={functionalities} perms={perms} setPerms={setPerms} />
          </div>
        </Col>
        <Col span={11}>
          <Title level={5} style={{color:'#3f4bfb'}} >
            <img src={FunctionsIcon} alt="" style={{ width:15 }} />
              &nbsp;
              <FormattedMessage id="layout.catalogs" />
          </Title>
          <div style={{border: 'solid 1px #cecece', borderRadius:20, padding:'0px 20px'}}>
            <TableCatalogs data={catalogs} perms={perms} setPerms={setPerms} />
          </div>
        </Col>
    </Row>
  )
}

export default DashboardForm