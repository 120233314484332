import WebApi from "./Api";

class DriveApi {

    static getCompany(){
        let settings = localStorage.getItem('settings_kk')
        if(settings.companySelected){
            return settings.companySelected.id
        }
        return null
    }

    static getFileList(query) {
        return WebApi.call(`${WebApi.URLS.DRIVE.LIST_FILE}${query}`, 'get')
    }

    static updateFile(fileId,data){
        return WebApi.call(`${WebApi.URLS.DRIVE.CREATE_FILE}${fileId}/`,'patch',data)
    }

    static addRemoveFavorite=async(fileId, isFavorite)=>{
        let data = new FormData();
        data.append('is_favorite',isFavorite)
        return WebApi.call(`${WebApi.URLS.DRIVE.CREATE_FILE}${fileId}/`,'patch',data)
    }

    static editNameDescription=async(fileId, name='', description='')=>{
        let data = new FormData();
        data.append('name',name)
        data.append('description',description)
        return WebApi.call(`${WebApi.URLS.DRIVE.CREATE_FILE}${fileId}/`,'patch',data)
    }

    static createFolder(folderName,description='',parent_id=null){
        let data = new FormData();
        data.append('name',folderName)
        data.append('description',description)
        data.append('is_folder',1)
        let company = this.getCompany()
        if(parent_id){
            data.append('parent_id', parent_id);
        }
        if(company){
            data.append('company',company)
        }
        return WebApi.call(WebApi.URLS.DRIVE.CREATE_FILE,'post',data)
    }

    static createFile(fileName, file,parent_id=null, description=''){
        let data = new FormData();
        data.append('name',fileName)
        data.append('description',description)
        data.append('is_folder',false)
        data.append('file',file)
        let company = this.getCompany()

        if(parent_id){
            data.append('parent_id', parent_id);
        }
        if(company){
            data.append('company',company)
        }
        return WebApi.call(WebApi.URLS.DRIVE.CREATE_FILE,'post',data)
    }


    static deleteElement(idElement){
        return WebApi.call(`${WebApi.URLS.DRIVE.CREATE_FILE}${idElement}/`,'delete')
    }

}

export default DriveApi