import React, { useEffect, useState } from 'react';
import {
  AutoComplete,
  Button,
  Col,
  Form,
  Card,
  Input,
  Layout,
  Radio,
  Row,
  Select,
  Space,
  Upload,
  Tooltip,
  InputNumber,
  message,
  Divider,
  Tabs,
  Typography
} from "antd";
import { UserOutlined, EditOutlined, UploadOutline, SaveOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom"
import styled from 'styled-components'
import { connect } from 'react-redux'
import { injectIntl, FormattedMessage } from "react-intl";
import _ from 'lodash';

import { getCompaniesAction, postCustomerAction } from '../../../redux/businessDuck';
import { getAccountsAction } from '../../../redux/securitryDuck'
import { getBrandsAction, getColorsAction, getModelsAction, changeLangAction } from '../../../redux/settingsDuck';
import { postUserProfileAction } from '../../../redux/userProfileDuck';
import { axiosApi, API } from "../../../lib/utils/axiosApi"
import FormModal from '../../../components/modal/FormModal';
import DashboardLayout from '../../../layouts/DashboardLayout';
import { CustomerForm } from '../../../components/services/ServiceForm';
import { Global, css } from "@emotion/react";


const NewServiceOrderPage = ({ changeLangAction, intl, settings, security, userProfiles, postCustomerAction, business, getCompaniesAction, getAccountsAction, postUserProfileAction, getBrandsAction, getColorsAction, getModelsAction }) => {

  const { Title, Text } = Typography;

  // Constantes 
  const validateMessages = {
    required: '${label} ' + intl.formatMessage({ id: "services.saleOrder.obligatory" }),
    types: {
      email: '${label} no es un email válido',
      number: '${label} no es un número válido',
    },
    number: {
      range: '${label} debe ser entre ${min} y ${max}',
    },
  }

  // Instnce form method for Order Form
  const [form] = Form.useForm();
  // Instance form method for User Form.Item
  const [userForm] = Form.useForm();

  const { Item } = Form
  const { TabPane } = Tabs;

  const history = useHistory()

  const { TextArea } = Input;

  const [selectedId, setSelectedId] = useState([]);
  const [options, setOptions] = useState([]);
  // Constantes para la visualizacion del modal
  const [isModalVisible, setIsModalVisible] = useState(false);
  // Constante para guardar el perfil seleccionado.
  const [profileSelected, setProfileSelected] = useState([]);
  // Constante para alternar entre campos para edición.
  const [isEdit, setIsEdit] = useState(false);

  const [customerSelectedForUpd, setCustomerSelectedForUpd] = useState(null)
  const [customerModeUI, setCustomerModeUI] = useState('search')
  const [customerData, setCustomerData] = useState(null)
  const [loading, setLoading] = useState(false)

  const rulesRequired = [
    {
      required: true,
    },
  ]
  const isUpd = false

  useEffect(() => {
    getCompaniesAction();
    getBrandsAction();
    getModelsAction();
    getColorsAction();
    getAccountsAction();
  }, [])

  // Funciones

  // Funcion para activar el modal
  const showModal = () => {
    setIsModalVisible(true);
  };

  // Funcion para la confirmacion del modal
  const handleOk = async (values) => {
    console.log(values)
    console.log(typeof (values.phone_number))
    let request = {};
    request.first_name = values.first_name ? values.first_name : "";
    request.last_name = values.last_name ? values.last_name : "";
    request.mlast_name = values.mlast_name ? values.mlast_name : "";
    request.email = values.email ? values.email : "";
    request.address = values.address ? values.address : "";
    request.phone_number = values.phone_number ? values.phone_number : "";
    request.company = values.company ? values.company : "";
    request.user_account = values.user_account ? values.user_account : "";
    await axiosApi.post(API.BUSINESS.CUSTOMER, request)
      .then((response) => {
        console.log(response)
        const customer = response.data
        if (customer != null) {
          let firstname = customer.first_name ? customer.first_name : ""
          let lastname = customer.last_name ? customer.last_name : ""
          setProfileSelected(customer);
          form.setFieldsValue({
            first_name: firstname,
            f_lastname: lastname,
            m_lastname: customer.mlast_name ? customer.mlast_name : "",
            phone_number: customer.phone_number ? customer.phone_number : "",
            email: customer.email ? customer.email : "",
            client: `${firstname} ${lastname}`
          })
          setIsEdit(true);
        }
      })
      .catch((error) => {

      })
    setIsModalVisible(false);
  }


  // Funcion para la cancelacion del modal.
  const handleCancel = () => {
    setIsModalVisible(false);
  }

  const getRequest = (values) => {

    const isNewCustomer = customerModeUI !== 'search'
    console.log('isNewCustomer', isNewCustomer)


    let customerInfo;

    if (isNewCustomer) {
      customerInfo = {
        first_name: values.customerName,
        mlast_name: values.customerMiddleName,
        last_name: values.customerLastName,
        email: values.email,
        phone_number: values.telephone,
        address: values.address,
      };
    } else {
      customerInfo = { ...customerData };
    }

    let request = {
      customer: customerInfo,
      customer_id: isNewCustomer ? null : _.get(customerData, 'id'),
      car_brand: _.get(values, 'carBrand'),
      car_year: _.get(values, 'carYear'),
      car_model: _.get(values, 'carModel'),
      car_color: _.get(values, 'carColor'),
      comments: _.get(values, 'details'),
      sale_type: _.get(values, 'payment_type'),
    }

    return request
  }

  // Función para guardar
  const onFinish = (values) => {
    console.log('values', values)
    const request = getRequest(values)
    console.log('Begin save, request:', request)
    save(request)
  };

  const save = async (request) => {
    try {
      setLoading(true)
      const response = await axiosApi.post(API.SALE.SALE_ORDER, request)
      console.log('Se guardo el orden de venta', response.data)
      message.success({ content: intl.formatMessage({ id: "saleOrder.saved" }) });
      history.push(`/sale-orders/tracking/${response.data.sale_order_id}`)
    } catch (error) {
      console.log('Error al guardar orden de servicio', error)
    } finally {
      setLoading(false)
    }
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    message.warning(intl.formatMessage({ id: "services.saleOrder.validateData" }))
  };

  const onSearchId = async (searchText) => {
    // Conseguimos la palabra clave
    const keyword = searchText ? searchText : ""
    // Realizamos la busqueda.
    await axiosApi.get(API.BUSINESS.CUSTOMER + `?search=${keyword}`)
      .then((response) => {
        // Validamos que vengan datos de la respuesta.
        if (response.data.results.length > 0) {
          console.log(response.data.results)
          const userList = _.map(response.data.results, (result) => {
            const user = {
              id: result.id,
              value: `${result.first_name} ${result.last_name}`,
            }
            return user;
          })

          setOptions(userList);
        }
        else {
          setOptions([
            {
              id: 0,
              value: "Agregar nuevo usuario",
              label: (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                  onClick={() => showModal()}
                >
                  Agregar nuevo usuario
                  <span> <UserOutlined /></span>
                </div>
              )
            },
          ]);
        }
      })
      .catch((error) => {
        console.log(error)
        setOptions([
          {
            id: 0,
            value: "Agregar nuevo usuario",
            label: (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
                onClick={() => showModal()}
              >
                Agregar nuevo usuario
                <span> <UserOutlined /></span>
              </div>
            )
          },
        ]);
      })
  }

  const onSelectId = async (value, option) => {

    console.log("opción -> ", option)
    if (option.id > 0) {
      await axiosApi.get(API.BUSINESS.CUSTOMER + `${option.id}/`)
        .then((response) => {
          console.log(response);
          if (response.data) {
            const profile = response.data;
            setProfileSelected(profile);
            form.setFieldsValue({
              first_name: profile.first_name ? profile.first_name : "",
              f_lastname: profile.last_name ? profile.last_name : "",
              m_lastname: profile.mlast_name ? profile.mlast_name : "",
              phone_number: profile.phone_number ? profile.phone_number : "",
              email: profile.email ? profile.email : ""
            })
            setIsEdit(true);
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  const onChange = (data) => {
    setSelectedId(data);
  }

  const onSuccess = (text) => {
    message.success(text)
  }

  const onError = (text) => {
    message.error(text);
  }

  // Estilos

  const styles = {
    radioGroup: {
      right: "0px",
      justifyContent: "end",
      alignItems: "flex-end",
      float: "right",
      marginLeft: "auto",
      display: "flex",
      paddingBottom: 0,
    },
    spaceCol: {
      marginBottom: '20px',
    },
    spaceForm: {
      borderBottom: '1px solid blue',
      width: '100%',
      marginBottom: 0
    },
    spaceItem: {
      paddingBottom: 0
    },
    inputForm: {
      borderRadius: 5,
      padding: 10,
    },
    inputSearch: {
      borderRadius: 5,
      padding: 10,
      color: "#341EFF"
    },
    FormLayout: {
      backgroundColor: "#fff",
      borderRadius: "20px",
    }
  }

  const StyledItem = styled(Item)`
    .ant-select{
        color: #341EFF;
    };
    .ant-input{
        color: #341EFF;
    };
    .ant-input:placeholder-shown{
        color: #341EFF;
    }
    `;

  const StyledSelect = styled(Select)`
    border: 1px solid rgb(217, 217, 217);
    border-radius: 8%;
    .ant-select-selector{
        border: 0px !important;
        border-radius: 0px;
    }`;

  const SpaceLabel = styled.div`
    right: 1;
    `;

  const HeaderRow = styled(Row)`
    margin-bottom: 0px;
    pagging-bottom: 10px;
    --antd-wave-shadow-color: #2D2CF5;
    .ant-form-item{
        height: 100% !important;
    }
    .ant-radio-group{
        height: 100% !important;
    }
    .ant-radio-inner{
        border-radius: 25%;
        border-color: #2D2CF5;
    }
    .ant-radio-checked .ant-radio-inner{
        background-color: #2D2CF5;
    }
    .ant-radio-inner::after{
        background-color: #2D2CF5;
        border-color: #2D2CF5;
    }
    `;
  // Renderizado

  const [viewBrand, setViewBrand] = useState()

  const getValue = (value) => {
    const nameBrand = value;
    const filtros = settings.modelsList.filter(element => element.brand.name === nameBrand)
    setViewBrand(filtros);
  }

  return (
    <DashboardLayout
      //title={intl.formatMessage({ id: "services.saleOrder.title" })}
      title={intl.formatMessage({ id: "services.saleOrder.createNew" })}
      index="ordenes_de_venta"
    >
      <Layout style={styles.FormLayout}>
        <Card className="br-10" style={{ padding: "30px 20px" }}>
          <Form
            size="large"
            form={form}
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            validateMessages={validateMessages}
          >
            <Row>
              <Col span={24}>
                <HeaderRow align="middle" justify="space-between">
                  <Col>
                    <Title level={3}>
                      <FormattedMessage id="services.page.costumer" />{" "}
                    </Title>
                  </Col>
                  <Col>
                    <Row gutter={20}>
                      <Col style={{ display: 'flex' }} >
                        <Text strong style={{ margin: 'auto' }}>
                          <FormattedMessage id="services.saleOrder.typePayment" />
                        </Text>
                      </Col>
                      <Col>
                        <Form.Item
                          label={intl.formatMessage({ id: "services.saleOrder.typePayment" })}
                          rules={rulesRequired}
                          name="payment_type"
                          className="m-0"
                        >
                          <Radio.Group>
                            <Space direction="horizontal" align="end">
                              <Radio value="CREDIT">
                                <FormattedMessage id="services.saleOrder.credit" />
                              </Radio>
                              <Radio value="CASH">
                                <FormattedMessage id="services.saleOrder.cash" />
                              </Radio>
                            </Space>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </HeaderRow>
                <Divider className="custom-divider" />
              </Col>
              <Col span={24}>
                <CustomerForm
                  customerSelectedForUpd={customerSelectedForUpd}
                  setCustomerModeUI={setCustomerModeUI}
                  isUpd={isUpd}
                  setCustomerdataHeader={setCustomerData}
                />
              </Col>
              <Col span={24} style={styles.spaceCol}>
                <Space
                  direction="horizontal"
                  size="large"
                  style={styles.spaceForm}
                >
                  <div>
                    <h1>
                      <FormattedMessage id="services.saleOrder.carOfInterest" />
                    </h1>
                  </div>
                </Space>
              </Col>
              <Col span={5} offset={1}>
                <Form.Item
                  label={intl.formatMessage({ id: "layout.brand" })}
                  rules={rulesRequired}
                  name="carBrand"
                  tooltip="Campo requerido"
                  className="item-expediente-lg"
                >
                  <Select
                    loading={loading}
                    style={{ width: "100%" }}
                    onChange={getValue}
                    className="selectFormCredit"
                  >
                    {/*LISTAR MARCAS*/}
                    {settings.brandsList.map((item) => (
                      <Select.Option value={item.name} key={item.id}>{item.name}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                {/* <StyledItem label="Marca" name="label">
                                <StyledSelect size="large" placeholder="Seleccione una marca...">
                                    {
                                        settings.brandsList.length > 0 ? settings.brandsList.map((item, index) => (
                                            <Select.Option key={index}>{item.name}</Select.Option>
                                        ))
                                            : null
                                    }
                                </StyledSelect>
                            </StyledItem> */}
              </Col>
              <Col span={5} offset={1}>
                <Form.Item
                  label={intl.formatMessage({ id: "layout.model" })}
                  rules={rulesRequired}
                  name="carModel"
                  tooltip="Campo requerido"
                  className="item-expediente-lg"
                >
                  <Select
                    loading={loading}
                    style={{ width: "100%" }}
                    onChange={() => { }}
                    className="selectFormCredit"
                  >
                    {/*LISTAR MODELOS*/}
                    {viewBrand ?
                      viewBrand.map((item) => (
                        <Select.Option key={item.id}>{item.name}</Select.Option>
                      )) : ''
                    }
                    {/*{settings.modelsList.map((item) => (
                      <Select.Option key={item.id}>{item.name}</Select.Option>
                    ))}*/}
                  </Select>
                </Form.Item>
                {/* <StyledItem label="Modelo" name="modelo">
                                <StyledSelect size="large" placeholder="Seleccione un modelo...">
                                    {
                                        settings.modelsList.length > 0 ? settings.modelsList.map((item, index) => (
                                            <Select.Option key={index} >{item.name}</Select.Option>
                                        ))
                                            : null
                                    }
                                </StyledSelect>
                            </StyledItem> */}
              </Col>
              <Col span={5} offset={1}>
                <Form.Item
                  label={intl.formatMessage({ id: "services.saleOrder.year" })}
                  name="carYear"
                  rules={rulesRequired}
                  tooltip="Campo requerido"
                  className="item-expediente-lg"
                >
                  <Input
                    placeholder={intl.formatMessage({
                      id: "services.saleOrder.year",
                    })}
                    size="large"
                  />
                </Form.Item>
                {/* <StyledItem label="Año" name="year">
                                <Input style={styles.inputForm} placeholder="Ingrese el año del modelo..." />
                            </StyledItem> */}
              </Col>
              <Col span={5} offset={1}>
                <Form.Item
                  label={intl.formatMessage({
                    id: "services.createNew.colour",
                  })}
                  rules={rulesRequired}
                  name="carColor"
                  tooltip="Campo requerido"
                  className="item-expediente-lg"
                >
                  <Select
                    loading={loading}
                    style={{ width: "100%" }}
                    onChange={() => { }}
                    className="selectFormCredit"
                  >
                    {settings.vehicleColorsList.map((item) => (
                      <Select.Option key={item.id}>{item.name}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                {/* <Form.Item
                    label="Color"
                    rules={rulesRequired}
                    name="carColor"
                  >
                    <Select
                      loading={loading}
                      style={{ width: "100%" }}
                      onChange={() => {}}
                      className="selectFormCredit"
                    >
                      {settings.colorsList.map((item) => (
                        <Select.Option key={item.id}>{item.name}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item> */}
                {/* <StyledItem label="Color" name="color">
                                <StyledSelect placeholder="Ingrese el color del modelo..." size="large">
                                    {
                                        settings.colorsList.length > 0 ? settings.colorsList.map((item, index) => (
                                            <Select.Option key={index}>{item.name}</Select.Option>
                                        ))
                                            : null
                                    }
                                </StyledSelect>
                            </StyledItem> */}
              </Col>
              <Col span={23} offset={1}>
                <StyledItem
                  label={intl.formatMessage({
                    id: "services.createNew.comments",
                  })}
                  name="details"
                >
                  <TextArea style={styles.inputForm} />
                </StyledItem>
              </Col>
              <Col span={23} offset={1} style={{ textAlign: "center" }}>
                <Space size="middle">
                  <Button
                    htmlType="button"
                    onClick={() => history.push("/sale-orders")}
                    className="btn-string-blue btn-report-action"
                  >
                    <FormattedMessage id="layout.cancel" />
                  </Button>
                  <Button
                    type="primary"
                    /* icon={<SaveOutlined />} */
                    loading={loading}
                    htmlType="submit"
                    className="btn-string-blue-low btn-report-action"
                  >
                    <FormattedMessage id="services.filter.save" />
                  </Button>

                </Space>
              </Col>
            </Row>
          </Form>
        </Card>

        {/* Modal para añadir nuevos perfiles */}
        <FormModal
          form={userForm}
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          title={"Nuevo usuario"}
          okText={"Agregar usuario"}
          cancelText={"Cancelar"}
        >
          <Form form={userForm} layout="vertical">
            <StyledItem
              label="Nombre(s)"
              name="first_name"
              rules={[
                { required: true, message: "Por favor ingrese su nombre." },
              ]}
            >
              <Input />
            </StyledItem>
            <StyledItem
              label="Apellido Paterno"
              name="last_name"
              rules={[
                {
                  required: true,
                  message: "Por favor ingrese su apellido paterno.",
                },
              ]}
            >
              <Input />
            </StyledItem>
            <StyledItem
              label="Apellido Materno"
              name="mlast_name"
              rules={[
                {
                  required: true,
                  message: "Por favor ingrese su apellido materno.",
                },
              ]}
            >
              <Input />
            </StyledItem>
            <StyledItem
              label="Correo electrónico"
              name="email"
              rules={[
                {
                  type: "email",
                  message: "El correo ingresado es incorrecto.",
                },
                {
                  required: true,
                  message: "Por favor ingrese un correo electrónico.",
                },
              ]}
            >
              <Input />
            </StyledItem>
            <StyledItem
              label="Dirección"
              name="address"
              rules={[
                {
                  max: 100,
                  message: "Ha excedido el limite de 100 caracteres.",
                },
              ]}
            >
              <Input />
            </StyledItem>
            <StyledItem
              label="Numero telefonico"
              name="phone_number"
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? String(value).length > 20
                        ? Promise.reject(
                          new Error("Ha excedido el limite de 20 caracteres")
                        )
                        : Promise.resolve()
                      : Promise.resolve(),
                },
              ]}
            >
              <InputNumber style={{ width: "100%" }} />
            </StyledItem>
            <StyledItem label="Empresa" name="company">
              <Select placeholder="Seleccione una empresa">
                {business.companiesList.length > 0
                  ? business.companiesList.map((item) => (
                    <Select.Option value={item.id}>{item.name}</Select.Option>
                  ))
                  : null}
              </Select>
            </StyledItem>
            <StyledItem label="Cuenta" name="user_account">
              <Select placeholder="Seleccione una cuenta">
                {_.get(security, "accountsList.length", 0) > 0
                  ? security.accountsList.map((item) => (
                    <Select.Option
                      value={item.user.id}
                    >{`${item.user.name} ${item.user.last_name}`}</Select.Option>
                  ))
                  : null}
              </Select>
            </StyledItem>
          </Form>
        </FormModal>
      </Layout>
    </DashboardLayout>
  );
}

const mapStateToProps = state => ({
  settings: state.settings,
  userProfiles: state.userProfile,
  business: state.business,
  security: state.security,
})


export default injectIntl(connect(mapStateToProps, {
  getCompaniesAction,
  changeLangAction,
  getAccountsAction,
  postCustomerAction,
  postUserProfileAction,
  getBrandsAction,
  getModelsAction,
  getColorsAction,
})(NewServiceOrderPage));