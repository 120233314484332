import React from 'react';
import { Form, Input, Select, Typography } from 'antd';
import { RocketOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import HeaderSection from "../../../../components/MyComponents";

const { Option } = Select;
const { Title } = Typography;

const UnitSection = ({user, form}) => {
  return (
    <div className="section">
      <HeaderSection>
            <Title level={4}>
            <RocketOutlined />{" "}
              <FormattedMessage id="services.createNew.unit" />
            </Title>
          </HeaderSection>
      <Form layout="vertical" form={form}>
        <Form.Item label="Número de serie">
          <Input value="9287696556" readOnly />
        </Form.Item>
        <Form.Item label="ID UNIDAD">
          <Input value="NUEVO" readOnly />
        </Form.Item>
        <Form.Item label="Tipo de unidad">
          <Select defaultValue="Camión">
            <Option value="Camión">Camión</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Placas">
          <Input value="YNR826Z672" readOnly />
        </Form.Item>
        <Form.Item label="Marca">
          <Input value="Mazda" readOnly />
        </Form.Item>
        <Form.Item label="Modelo">
          <Input value="3 Touring" readOnly />
        </Form.Item>
        <Form.Item label="Año">
          <Input value="2021" readOnly />
        </Form.Item>
        <Form.Item label="Color">
          <Input value="2021" readOnly />
        </Form.Item>
        <Form.Item label="Número económico">
          <Input value="KRT100" readOnly />
        </Form.Item>
        <Form.Item label="Tipo de transmisión">
          <Select defaultValue="Automática">
            <Option value="Automática">Automática</Option>
          </Select>
        </Form.Item>
      </Form>
    </div>
  );
};

export default UnitSection;