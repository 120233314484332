import React from 'react'
import styled from 'styled-components'
import { Row, DatePicker, TimePicker, Form, Modal, Table } from 'antd';

const { Item } = Form

const HeaderSection = styled(Row)`
    margin-bottom: 20px;
    pagging-bottom: 10px;
    border-bottom: solid 1px #2D2CF5;
    .ant-form-item{
        height: 100% !important;
    }
    .ant-radio-group{
        height: 100% !important;
    }
    .ant-radio-inner{
        border-radius: 25%;
        border-color: #2D2CF5;
    }
    .ant-radio-checked .ant-radio-inner{
        background-color: #2D2CF5;
    }
    .ant-radio-inner::after{
        background-color: #2D2CF5;
        border-color: #2D2CF5;
    }
`;

export const InputDate = styled(DatePicker)``;
export const InputTime = styled(TimePicker)``;

export const ItemForm = styled(Item)`
    .ant-form-item-control-input > .ant-form-item-control-input-content > .ant-select > .ant-select-selector,
    .ant-form-item-control-input > .ant-form-item-control-input-content > .ant-picker,
    .ant-form-item-control-input > .ant-form-item-control-input-content > input.ant-input{
        border-radius: 6px !important;
        color: var(--primaryColor) !important;
        height: 40px;
    }

    .ant-form-item-control-input > .ant-form-item-control-input-content > textarea.ant-input{
        border-radius: 10px !important;
        color: var(--secondaryColor) !important;
    }

    .ant-form-item-control-input > .ant-form-item-control-input-content > .ant-select > .ant-select-selector >.ant-select-selection-search > input{
        height: 100%;
    }

    /* .ant-form-item-control-input > .ant-form-item-control-input-content > .ant-select > .ant-select-selector > .ant-select-selection-placeholder,
    .ant-form-item-control-input > .ant-form-item-control-input-content > .ant-select > .ant-select-selector > .ant-select-selection-item{
        line-height: 45px;
    } */
    
`

export const CustomModal = styled(Modal)`
        .ant-modal-content{
            border-radius: 20px;
        }
`

export const TableFormProfile = styled(Table)`
    th.ant-table-cell{
        background: none !important;
        padding-bottom: 5px;
        padding-top: 15px;
        border-bottom: solid 1px #3f4bfb;
    }
    th.ant-table-cell::before{
        background: none !important;
    }
    td.ant-table-cell, :hover{
        background: none !important;
    }
    
`

export default HeaderSection;
