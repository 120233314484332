import React, { Children } from 'react';
import styles from "./ButtonLightHeader.module.css"
import { Button } from 'antd';

const ButtonLightHeader=({icon,style,loading=false,onClick,text,size,active=false,...props})=>{

    return (
        <Button loading={loading} size={size}  style={style} onClick={onClick} className={active?styles.button_header_active:styles.button_header} icon={icon}>
            {text}
        </Button>
    )
}


export default ButtonLightHeader;