import React, { useState, useEffect } from 'react'
import Card from '../card/Card'
import styles from './home.module.css'
import {connect} from   'react-redux'
import {removeCharacterAction} from '../../redux/charsDuck'

const Home=({chars,removeCharacterAction})=> {

    const a = 12
    const renderCharacter=()=> {
        let char = chars[0]
        return (
            <Card leftClick={nextCharacter} {...char} />
        )
    }

    function nextCharacter( ){
        removeCharacterAction()
    }

    return (
        <div className={styles.container}>
            <h2>Personajes de Rick y Morty</h2>
            <div>
                {renderCharacter()}
            </div>
        </div>
    )
}

//saca loq ue tenga Redux y lo pone en los props de este component (recibe el store)
function mapState(state){
    return {
        chars:state.characters.array
    }
}
// en esta parte conectamos a reduz a Home y se le pasaron los prop del mapState
export default connect(mapState,{
    removeCharacterAction // aqui tambien le pasamos el action a los props del componente
})(Home)
