import { Button, Col, Modal, Row, Space, Spin, Typography } from 'antd'
import React from 'react'
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import turn from '../../image/turn_icon_kikert.svg'
import KModal from "../common/elements/KModal/KModal";


const DeleteModal = ({ modalTitle, isVisible=false, closeModal, description = "", actionDelete=null, loading=false, txtConfirm=null, ...props }) => {
  const { Text, Title } = Typography;
  
  return (
    <KModal
      width={600}
      title={modalTitle}
      visible={isVisible === true}
      onCancel={closeModal}
    >
        <Spin spinning={loading}>
      <Row justify="space-between">
        <Col span={24} style={{ textAlign: "center", marginTop: 30 }}>
          <Text style={{ fontSize: 18 }}>{description}</Text>
        </Col>
        <Col span={24} style={{ textAlign: "center", marginTop: 40 }}>
          <Space>
            <Button
              size="large"
              className="btn-string-blue btn-report-action"
              onClick={() => closeModal()}
            >
              <FormattedMessage id="layout.cancel" />
            </Button>
            <Button
              className="btn-blue btn-report-action"
              size="large"
              onClick={() => actionDelete()}
            >
              {
                txtConfirm ?
                txtConfirm :
                <FormattedMessage id="layout.delete" />
              }
              
            </Button>
          </Space>
        </Col>
      </Row>
      </Spin>
    </KModal>
  );
};

export default DeleteModal