import React, { useEffect } from 'react'

const AuthRequired = ({children}) => {

    useEffect(
        () => {
            //console.log('Auth required mounted')
        }, []
    )

    return <>{children}</> 
}

export default AuthRequired