import axiosApi, { API } from "../../../lib/utils/axiosApi"


const pingPong = async () => {
    try {
        const response = await axiosApi.get(API.SECURITY.PING)
        console.log('Response', response)
        return true;
    } catch (error) {
        console.log('Error')
        return false;
    }
}

export default pingPong;