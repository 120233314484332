import React, { useState, useEffect } from "react";
import {
  message,
  Card,
  Layout,
  Row,
  Col,
  Popconfirm,
  Input,
  Table,
  DatePicker,
  Form,
  Space,
  Typography,
  Statistic
} from "antd";

import {
    EditOutlined, DeleteOutlined
  } from '@ant-design/icons';

import { useParams, useHistory } from "react-router-dom";

import { KButton, CustomTable, KSelect } from "../KComponents";
import KModal from "../common/elements/KModal/KModal";
import { Global, css } from "@emotion/react";
import "moment/locale/es-mx";
import moment from "moment";
import axiosApi, {API, urlfull} from "../../lib/utils/axiosApi";
import {ruleRequired, twoDecimal} from "../../lib/utils/rules";
import locale from "../../locale/es_mx.json";
import useFetch from "../../hooks/useFetch";
import {useSelector, useDispatch} from "react-redux";
import {getWorkFlow, getUsersWorkFlow} from "../../redux/workflowDuck";
import MenuServiceDetail from "../../components/services/MenuServiceDetail";
import _ from "lodash";

const { Text, Link } = Typography;

const ActivitiesTable = ({loading, data, setUpdate, handleDelete}) => {
    const [fixedTop, setFixedTop] = useState(false)
    const columns = [
        {
          title: 'Actividad',
          width: 300,
          dataIndex: 'activity_name',
          key: 'activity_name',
          fixed: 'left',
        },
        {
          title: 'Equipo',
          width: 200,
          dataIndex: 'team_label',
          key: 'team',
          fixed: 'left',
        },
        {
          title: 'Responsable',
          dataIndex: 'in_charge_label',
          key: 'in_charge',
          width: 200,
        },
        {
          title: 'Horas estimadas',
          dataIndex: 'estimated_hours',
          key: 'estimated_hours',
          width: 150,
        },
        {
          title: 'Horas reales',
          dataIndex: 'real_hours',
          key: 'real_hours',
          width: 150,
        },
        {
            title: 'Total horas',
            dataIndex: 'total_hours',
            key: 'total_hours',
            width: 150,
            render: (text, record) => {
              const totalHours = record.estimated_hours - record.real_hours;
              return <span style={{ color: totalHours < 0 ? 'red' : 'black' }}>{totalHours}</span>;
            }
          },
        {
          title: 'Horas no cobradas',
          dataIndex: 'unpaid_hours',
          key: 'unpaid_hours',
          width: 150,
        },
        {
          title: 'Horas no trabajadas',
          dataIndex: 'reworked_hours',
          key: 'reworked_hours',
          width: 150,
        },
        {
          title: 'Fecha',
          dataIndex: 'created_date',
          key: 'created_date',
          width: 150,
        },
        {
          title: 'Estatus',
          dataIndex: 'status_label',
          key: 'status',
          width: 150,
        },
        {
          title: 'Bloque / Etapa',
          dataIndex: 'stage',
          key: 'stage',
          width: 150,
          render: (i) => <>Bloque {i}</>
        },
        {
          title: 'Acción',
          key: 'operation',
          fixed: 'right',
          width: 200,
          render: (record) => (
            <Space size={20}>
                <Link style={{ color: 'blue' }} onClick={() => setUpdate(record.id)}>
                    <EditOutlined />
                </Link>

                <Popconfirm
                    placement="top"
                    title={"¿Está seguro de que desea eliminar este registro?"}
                    onConfirm={() => handleDelete(record.id)}
                    okText="Si"
                    cancelText="No"
                    >
                        <Link style={{ color: 'red' }}><DeleteOutlined /></Link>
                </Popconfirm>
            </Space>
          ),
        },
      ];
    return (
        <CustomTable
            columns={columns}
            dataSource={data}
            loading={loading}
            scroll={{
                x: 1500,
            }}
            summary={() => (
                <Table.Summary fixed={fixedTop ? 'top' : 'bottom'}>
                <Table.Summary.Row>
                   
                </Table.Summary.Row>
                </Table.Summary>
            )}
            sticky
        />
    )
}


const ServiceActivities = ({workflowCode = "WORKSHOP"}) => {
    let { id } = useParams();

    const [activityForUpdate, setActivityForUpdate] = useState(false)
    const [showActivyModal, setShowActivityModal] = useState(false)
    const [flagToReloadActivities, setFlagToReloadActivities] = useState(false)
    const [loading, setLoading] = useState(false)
    const users = useFetch(API.SECURITY.USER_PROFILE)
    const activitiesResponse = useFetch(API.WORKSHOP.WORKSHOP_RAPAIR_ACTIVITY + '?order__id=' + id, flagToReloadActivities)
    const workflow = useSelector((state) => state.workflow)
    const settings = useSelector((state) => state.settings)
    const dispatch = useDispatch();
    const [steps, setSteps] = useState([]) 
    const [form] = Form.useForm()

    const userOptions = users?.data?.results?.map((user) => {
        return {value: user.id, label: user.first_name + ' ' + user.last_name}
    })
    const activitiesList = activitiesResponse?.data?.results

    useEffect(()=>{
        if(workflowCode && settings?.settingsData?.branchSelected?.id){
            dispatch(getWorkFlow(workflowCode, settings?.settingsData?.branchSelected?.id))
                //getUsersWorkFlow(settings.settingsData.branchSelected.id)
            
        }
    },[workflowCode])

    useEffect(()=>{
        if(workflow.workflow[0]){
            let stp = workflow.workflow[0].steps.map((s)=> {
               return {order: s.order, label:s.name, value:s.id}
            } );
            //stp.push({order: orderId, label:'Finalizar reparación', value:-1}) // para el step de finalizar rep.
            //stp.push({order: orderId, label:'Finalizar reparación y entregar vehículo', value:-2}) // para el step de finalizar rep.
            setSteps(_.sortBy(stp, ['order']))
        }
    },[workflow])

    const status = [
        {
            "label": "En progreso",
            "value": "IN_PROGRESS"
        },
        {
            "label": "Pendiente",
            "value": "PENDING"
        },
        {
            "label": "Terminado",
            "value": "COMPLETED"
        }
    ]

    const stages = () => {
        let response = []
        for(let i = 1; i <= 30; i++){
            response.push({
                "label": `Bloque ${i}`,
                "value": `${i}`
            })
        }
        return response
    }

    const onFinish = (values) => {
        values.order = id
        values.created_date = new Date(values.created_date).toISOString().split('T')[0];
        const isForUpdate = !!activityForUpdate
        setLoading(true)

        if(!isForUpdate)
            axiosApi.post(API.WORKSHOP.WORKSHOP_RAPAIR_ACTIVITY, values)
                .then(response => {
                    console.log('Repair activity posted successfully:', response);
                    // Handle success
                    setShowActivityModal(false)
                    setFlagToReloadActivities(!flagToReloadActivities)
                    message.success('Actividad creada correctamente')
                    form.resetFields()
                    
                })
                .catch(error => {
                    console.error('Error posting repair activity:', error);
                    message.success('Hubo un error en la creación de la actividad')
                    // Handle error
                }).finally(() => {setLoading(false)});
        else
            axiosApi.put(`${API.WORKSHOP.WORKSHOP_RAPAIR_ACTIVITY}${activityForUpdate?.id}/`, values)
                .then(response => {
                    console.log('Repair activity posted successfully:', response);
                    // Handle success
                    setShowActivityModal(false)
                    setFlagToReloadActivities(!flagToReloadActivities)
                    message.success('Actividad actualizada correctamente')
                    form.resetFields()
                    
                })
                .catch(error => {
                    console.error('Error posting repair activity:', error);
                    message.success('Hubo un error en la actualización de la actividad')
                    // Handle error
                }).finally(() => {setLoading(false)});
    }

    const onValuesChangeFormPart = () => {}

    const setUpdate = (id) => {
        const activityForUpdate = activitiesList.find(activity => activity.id === id);
        console.log("activityForUpdate", activityForUpdate)
        form.setFieldsValue({
            ...activityForUpdate,
            created_date: moment(activityForUpdate.created_date),
            stage: activityForUpdate.stage.toString()
        })
        setActivityForUpdate(activityForUpdate)
        setShowActivityModal(true)
    }

    const handleDelete = (id) => {
        axiosApi.delete(`${API.WORKSHOP.WORKSHOP_RAPAIR_ACTIVITY}${id}/`)
                .then(response => {
                    // Handle success
                    setShowActivityModal(false)
                    setFlagToReloadActivities(!flagToReloadActivities)
                    message.success('Actividad eliminada correctamente')
                    form.resetFields()
                    
                })
                .catch(error => {
                    console.error('Error posting repair activity:', error);
                    message.success('Hubo un error en la eliminación de la actividad')
                    // Handle error
                }).finally(() => {setLoading(false)});
    }

  return (
    <>
      <Global
        styles={css`


            .modalfiles .ant-modal-body {
              height: 700px;
            }
            
          .spaceButtonsActions .ant-space-item {
            margin-top: auto;
          }
          .spaceButtonsActions .ant-space-item > span.anticon {
            margin-top: auto;
            font-size: 25px;
          }
          .breadcrumbFiles {
            font-size: 18px;
            font-weight: 500px;
          }
          .ant-breadcrumb {
            font-size: 18px;
            font-weight: 500px;
          }
        `}
      />
      <Layout>
        <Card className="card_details_service" loading={loading}>
            <MenuServiceDetail menuSelected={8} />
          <Row justify="space-between">
            <Col>
                <KButton
                    text={'Agregar actividad'}
                    onClick={()=>{form.resetFields();setActivityForUpdate(false);setShowActivityModal(true)}}
                    type="primary"
                    style={{
                        marginBottom: 16,
                    }}
                />
                <Row justify="end" style={{ marginBottom: 16 }} gutter={16}>
                    <Col xs={24} sm={12} md={6}>
                        <Statistic title="Total Horas Estimadas" value={activitiesList?.reduce((acc, curr) => acc + parseFloat(curr.estimated_hours), 0).toFixed(2)} />
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                        <Statistic title="Total Horas Reales" value={activitiesList?.reduce((acc, curr) => acc + parseFloat(curr.real_hours), 0).toFixed(2)} />
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                        <Statistic title="Total Horas No Trabajadas" value={activitiesList?.reduce((acc, curr) => acc + parseFloat(curr.unpaid_hours), 0).toFixed(2)} />
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                        <Statistic title="Total Horas Retrabajadas" value={activitiesList?.reduce((acc, curr) => acc + parseFloat(curr.reworked_hours), 0).toFixed(2)} />
                    </Col>
                </Row>
                <ActivitiesTable loading={loading} data={activitiesList} setUpdate={setUpdate} handleDelete={handleDelete}/>
            </Col>
            <KModal title={`${activityForUpdate.activity_name||'Crear registro'}`} onCancel={()=>setShowActivityModal(false)} visible={showActivyModal}>
                <Row>
                    <Col span={24}>
                        <Form
                            name="basic"
                            onFinish={onFinish}
                            autoComplete="off"
                            loading={loading}
                            form={form}
                            onValuesChange={onValuesChangeFormPart}
                            layout={'vertical'}
                        >
                            <Form.Item name={'activity_name'} label="Actividad" rules={[ruleRequired]}>
                                <Input  className={'kinput'}/>
                            </Form.Item>

                            <Form.Item  label={'Equipo'} name="team" rules={[ruleRequired]}>
                                <KSelect
                                    style={{ width: '100%' }}
                                    options={steps}
                                />
                            </Form.Item>

                            <Form.Item  label={'Responsable'} name="in_charge">
                                <KSelect
                                    style={{ width: '100%' }}
                                    options={userOptions}
                                />
                            </Form.Item>

                            <Form.Item name={'estimated_hours'} label="Horas estimadas" rules={[twoDecimal]} initialValue={0}>
                                <Input  className={'kinput'}/>
                            </Form.Item>

                            <Form.Item name={'real_hours'} label="Horas reales" rules={[twoDecimal]} initialValue={0}>
                                <Input  className={'kinput'}/>
                            </Form.Item>

                            <Form.Item name={'unpaid_hours'} label="Horas no cobradas" rules={[twoDecimal]} initialValue={0}>
                                <Input  className={'kinput'}/>
                            </Form.Item>
                            
                            <Form.Item name={'reworked_hours'} label="Horas retrabajadas" rules={[twoDecimal]} initialValue={0}>
                                <Input  className={'kinput'}/>
                            </Form.Item>

                            <Form.Item name={'created_date'} label="Fecha" rules={[ruleRequired]}>
                                <DatePicker
                                    locale={locale}
                                    className={'kinput'}
                                    style={{width:'100%'}}
                                    format={'DD/MM/YYYY'}
                                />
                            </Form.Item>

                            <Form.Item label={'Estatus'} name="status">
                                <KSelect
                                    style={{ width: '100%' }}
                                    options={status} defaultValue="PENDING"
                                />
                            </Form.Item>

                            <Form.Item label={'Bloque/etapa'} name="stage">
                                <KSelect
                                    style={{ width: '100%' }}
                                    options={stages()}
                                    defaultValue={"1"}
                                />
                            </Form.Item>


                            <Form.Item style={{marginTop:20}}>
                                <KButton loading={loading} text={'Cancelar'} onClick={()=>setShowActivityModal(false)} type={'secondary'} />
                                <KButton loading={loading} text={'Guardar'} htmlType={'submit'} type={'Primary'} />
                            </Form.Item>

                        </Form>

                    </Col>
                </Row>

            </KModal>
          </Row>
        </Card>
      </Layout>
    </>
  );
};

export default ServiceActivities;

