import React from 'react'
import { injectIntl } from "react-intl";
import ServiceActivities from "../../components/services/ServiceActivities";
import { useParams, useHistory } from "react-router-dom";
import MainLayoutv2 from '../../layouts/MainLayoutv2';
import "moment/locale/es-mx";

const ActivitiesPage = ({ intl , ...props}) => {

  const { id, name, idFolder } = useParams();

  return (
    <>
      <MainLayoutv2
        isServiceDetail={true}
        title={intl.formatMessage({ id: "servicesDetails.servicesDetails" })}
        index="expedientes"
      >
        <ServiceActivities serviceId={id} />
      </MainLayoutv2>
    </>
  );
};

export default injectIntl(ActivitiesPage);