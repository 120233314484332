import { async } from "@firebase/util";
import {axiosApi, API} from "../lib/utils/axiosApi";
import { message } from "antd";

let initialData = {
  fetching: false,
  saving: false,
  membersList: [],
  usersList: [],
  current: {},
  show_modal_members: false,
  screenVisible: "profiles",
  formShow: false,
  updId: null,
  userForEdit: {},
  usersAdvisorsList:[],
  usersBudgetReceiversList:[],
  supplierList:[]
};


const UPD_MEMBERS_LIST = "GETUPD_MEMBERS_LIST_CHARACTERS";
const EXTRA_ACTION = "EXTA_ACTION";
const SHOW_MEMBERS = "SHOW_MEMBERS";
const GET_PROFILES = "GET_PROFILES";
const GET_PROFILES_SUCCESS = "GET_PROFILES_SUCCESS";
const GET_PERMISSIONS = "GET_PERMISSIONS";
const GET_PERMISSIONS_SUCCESS = "GET_PERMISSIONS_SUCCESS";
const GET_PERMISSIONS_ERROR = "GET_PERMISSIONS_ERROR";
const SAVING_PERMISSIONS = "SAVING_PERMISSIONS"
const SAVING_PERMISSIONS_SUCCESS = "SAVING_PERMISSIONS_SUCCESS";
const GET_USERS = "GET_USERS";
const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
const SAVING_USER = "SAVING_USER";
const SAVE_USER_SUCCESS = "SAVE_USER_SUCCESS";
const GET_USER = "GET_USER";
const GET_USER_SUCCESS = "GET_USER_SUCCESS";
const GET_USER_ADVISORS = "GET_USER_ADVISORS";
const GET_USER_ADVISORS_SUCCESS = "GET_USER_ADVISORS_SUCCESS";
const GET_USER_BUDGET_RECEIVERS = "GET_USER_BUDGET_RECEIVERS";
const GET_SUPPLIERS = "GET_SUPPLIERS";

///reducers
export default function reducer(state=initialData, action){
    switch (action.type) {
      case UPD_MEMBERS_LIST:
        return { ...state, membersList: action.payload };
      case EXTRA_ACTION:
        console.log('o',action.payload);
        return { ...state, ...action.payload };
      case SHOW_MEMBERS:
        return { ...state, show_modal_members: true, current: action.payload };
      case GET_PROFILES:
        return { ...state, fetching: true };
      case GET_PROFILES_SUCCESS:
        return { ...state, fetching: true, membersList: action.payload };
      case GET_USERS:
        return { ...state, fetching: true };
      case GET_USERS_SUCCESS:
        return { ...state, fetching: true, usersList: action.payload };
      case GET_PERMISSIONS:
        return { ...state, fetching: true };
      case GET_PERMISSIONS_SUCCESS:
        return { ...state, fetching: false, current: action.payload };
      case GET_PERMISSIONS_ERROR:
        return { ...state, fetching: false };
      case SAVING_PERMISSIONS:
        return { ...state, saving: true };
      case SAVING_PERMISSIONS_SUCCESS:
        return { ...state, saving: false };
      case SAVING_USER:
        return { ...state, saving: true };
      case SAVE_USER_SUCCESS:
        return { ...state, saving: false, formShow: false, screenVisible: "users" };
      case GET_USER:
        return { ...state, saving: true };
      case GET_USER_SUCCESS: 
        return { ...state, saving: false, ...action.payload };
    case GET_USER_ADVISORS:
        return { ...state, fetching: action.payload};
    case GET_USER_ADVISORS_SUCCESS:
            return { ...state, fetching: false, usersAdvisorsList: action.payload};
    case GET_USER_BUDGET_RECEIVERS:
            return { ...state, fetching: false, usersBudgetReceiversList: action.payload};
    case GET_SUPPLIERS:
            console.log('desde el action',action.payload)
            return { ...state, fetching: false, supplierList: action.payload};
      default:
        return state;
    }
}


export const showMembers = (profile) => async (dispatch) =>{
    dispatch({
      type: SHOW_MEMBERS,
      payload: profile,
    });
}

export const closeModalMembers = () => async (dispatch) => {
    dispatch({
      type: EXTRA_ACTION,
      payload: { show_modal_members : false},
    });
}

export const changeScreen = (screen) => async (dispatch, getState) => {
  console.log("screen=>", screen);
  dispatch({
    type: EXTRA_ACTION,
    payload: { screenVisible: screen },
  });
};

export const userFormShow = (visible) => async (dispatch, getState) => {
  dispatch({
    type: EXTRA_ACTION,
    payload: { formShow: visible },
  });
}

 export const clearForm = () => async (dispatch, getState ) => {
   dispatch({
     type: EXTRA_ACTION,
     payload: { userForEdit: {} },
   });
 }
 

export const userGetForEdit = (id) => async (dispatch, getState) => {
  console.log('id ===========>',id)
  dispatch({
    type: EXTRA_ACTION,
    payload: { formShow: true, updId: id, saving: true },
  });
    await axiosApi
      .get(API.SECURITY.USER_PROFILE + `${id}/`)
      .then((res) => {
        console.log("response", res);
        dispatch({
          type: GET_USER_SUCCESS,
          payload: { userForEdit: res.data },
        });
        return true;
        /* dispatch(getUsers());
      message.success("Usuario agregado") */
      })
      .catch((e) => {
        console.log("e", e);
      });
};


export let getProfiles = () => async ( dispatch, getState ) => {
    dispatch({
        type: GET_PROFILES
    })
    await axiosApi.get(API.GROUPS.PROFILES)
    .then((response) => {
        console.log("PROFILES", response);
        dispatch({
          type: GET_PROFILES_SUCCESS,
          payload: response.data.results,
        });
    })
    .catch((error) => {
        console.log('error', error)
        /* dispatch({
            type: GET_CAR_AVAILABILITY_ERROR
        }); */
    })
}

export let getPermissionsProfile = (id) => async (dispatch, getState) => {
  dispatch({
    type: GET_PERMISSIONS,
  });
  await axiosApi
    .get(API.GROUPS.PROFILES+`${id}/`)
    .then((response) => {
      console.log("permissions", response);
      dispatch({
        type: GET_PERMISSIONS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.log("error", error);
      dispatch({
        type: GET_PERMISSIONS_ERROR,
      });
    });
};

export let savePermissions = (id, data) => async (dispatch, getState) => {
    dispatch({
      type: SAVING_PERMISSIONS,
    });
    await axiosApi
    .post(API.GROUPS.PROFILES+`${id}/permissions/`, data)
    .then((response) => {
      console.log("response", response);
      dispatch(getPermissionsProfile(id))
      message.success("Grupo creado correctamente");
      dispatch({
        type: SAVING_PERMISSIONS_SUCCESS,
      });
    })
    .catch((error) => {
      message.error("No se pudo crear");
      dispatch({
        type: SAVING_PERMISSIONS_SUCCESS,
      });
    });
}


export let getUsers = (search=null,page=1,page_size=10) => async (dispatch, getState) => {
  dispatch({
    type: GET_USERS,
  });

  try{
      const response = await axiosApi.get(API.USER.USERS_LIST +"?"+ (search !== null ? "search="+search: "")+"&page="+page +(page_size !== null ? "&page_size="+page_size: ""));
      dispatch({
          type: GET_USERS_SUCCESS,
          payload: response.data.results,
      });
      return response

  }catch (e){
      console.log("error", e);
      dispatch({
          type: GET_USERS_SUCCESS,
          payload: [],
      });
  }
}


export let getUsersBudgetReceivers = (search=null) => async (dispatch, getState) => {
    dispatch({
        type: GET_USER_BUDGET_RECEIVERS,
        payload:[]
    });
    try{
        const res =await  axiosApi.get(API.USER.USERS_BUDGET_RECEVERS + '?page_size=10000'+(search !== null ? "&search="+search: "")) ;
        console.log('getUsersBudgetReceivers', res)
        dispatch({
            type: GET_USER_BUDGET_RECEIVERS,
            payload: res.data,
        });
        console.log('getUsersBudgetReceivers  ====== success', res)
    }catch (e){
        dispatch({
            type: GET_USER_BUDGET_RECEIVERS,
            payload:[]
        });
    }finally {

    }
}

export let getSuppliers = (search=null) => async (dispatch, getState) => {
    dispatch({
        type: GET_SUPPLIERS,
        payload:[]
    });
    try{
        const res =await  axiosApi.get(API.BUSINESS.SUPPLIERS+'?page_size=1000');
        dispatch({
            type: GET_SUPPLIERS,
            payload: res?.data?.results ? res?.data?.results  :[],
        });
    }catch (e){
        dispatch({
            type: GET_SUPPLIERS,
            payload:[]
        });
    }finally {

    }
}


export let getUsersAdvisors = (search=null) => async (dispatch, getState) => {
    dispatch({
        type: GET_USER_ADVISORS,
        payload:true
    });
    try{
        const res =await  axiosApi.get(API.USER.USERS_ADVISORS + (search !== null ? "?search="+search: ""));
        dispatch({
            type: GET_USER_ADVISORS_SUCCESS,
            payload: res.data,
        });
    }catch (e){
        dispatch({
            type: GET_USER_ADVISORS,
            payload:false
        });
    }finally {
        dispatch({
            type: GET_USER_ADVISORS,
            payload:false
        });
    }
}
