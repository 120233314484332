import React, { useState, useEffect } from 'react'
import DashboardLayout from '../../layouts/DashboardLayout';
import { Table, Layout, Form, Button, Modal, Input, Upload, Space,InputNumber, Popconfirm, message, Row, Col, Typography, Alert } from 'antd'
import { UploadOutlined, DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import {
    changeLangAction,
    deleteBrandAction,
    editBrandAction,
    getBrandsAction,
    saveBrandAction
} from '../../redux/settingsDuck'
import { connect } from 'react-redux'
import { injectIntl } from "react-intl";
import { FormattedMessage } from "react-intl";
import {downloadFile, permissionsExist} from "../../lib/utils/utils";
import DeleteModal from "../../components/modal/DeleteModal";
import DeleteIcon from "../../image/delete.svg";
import turn from '../../image/turn_icon_kikert.svg'
import EditIcon from "../../image/edit.svg";
import {CustomTable} from "../../components/KComponents";
import KButton from "../../components/common/elements/KButton/KButton";
import KModal from "../../components/common/elements/KModal/KModal";
import { CustomModal } from '../../components/MyComponents'
import CloseIcon from '../../image/close-outline.svg';
import axiosApi, {urlfull} from "../../lib/utils/axiosApi";
import _ from "lodash";
import MainLayoutv2 from "../../layouts/MainLayoutv2";
import TabsMenuConfig from "../../components/common/elements/TabsMenuConfig/TabsMenuConfig";
import SelectSuppliers from "../../components/Selects/SelectSuppliers";


const { Header, Content, Footer } = Layout;

const PartsPage = ({ changeLangAction, intl, getBrandsAction, settings, saveBrandAction, deleteBrandAction, editBrandAction, permissions }) => {

    const {Title} = Typography

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalDeleteVisible, setModalDeleteVisible] = useState(false);
    const [modalCreateEdit, setModalCreateEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingTemplate, setLoadingTemplate] = useState(false);
    const [forDelete, setForDelete] = useState(null)
    const [fileList, setFileList] = useState([]);
    const [parts, setParts] = useState([]);
    const [form] = Form.useForm();
    const [searchText, setSearchText] = useState('');
    const [formCreate] = Form.useForm();
    const [pagination, setPagination] = useState({
        showSizeChanger: true,
        current: 1,
        pageSize: 20,
      });


    const props = {
        listType:"picture",
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            setFileList([file]);
            return false;
        },
        fileList,
    };


    const closeModal = () =>{
        setModalDeleteVisible(false);
        setIsModalVisible(false)
        setForDelete(null);
        setFileList([])
    }

    const deleteItem = (current) => {
        console.log(current);
        setModalDeleteVisible(true);
        setForDelete(current);
    }

    useEffect(()=>{
        getParts(pagination.current, pagination.pageSize)
    },[])

    const editPart=(item)=>{
        if(item){
            formCreate.setFieldsValue({...item})
            setModalCreateEdit(true)
        }
    }

    const columns = [
        {
            title: "Código",
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: <FormattedMessage id="layout.name" />,
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: "Costo",
            dataIndex: 'cost',
            key: 'cost',
            render: (id, record) => {
                return <p>$ {record.cost}</p>
            }
        },
        {
            title: "Precio",
            dataIndex: 'price',
            key: 'price',
            render: (id, record) => {
                return <p>$ {record.price}</p>
            }
        },
        {
            title: <FormattedMessage id="layout.actions"/>,
            dataIndex: 'id',
            key: 'actions',
            render: (id, record) => {
                return <Space size={30}>

                        <img key="EditIcon" src={EditIcon} style={{cursor:'pointer',  width:12}}
                             onClick={() => editPart(record)}/>
                    <img key="DeleteIcon" src={DeleteIcon} style={{cursor: 'pointer', width: 12}}
                         onClick={() =>deleteItem(record)}/>
                </Space>
            }
        }
    ];

    const onDelete=async()=>{
        if(forDelete){
            setLoading(true)
            try{
                const res = await axiosApi.delete(`spareparts/sparepart/${forDelete.id}`);
                getParts()
                closeModal()
            }catch (e){
                message.error('Error al eliminar, intente nuevamente')
            }finally {
                setLoading(false)
            }
        }
    }


    const getParts = async (page, page_size) => {
        setLoading(true)
        try{
        let url = 'spareparts/sparepart/?'
        if(page && page_size){
            url += `page=${page}&page_size=${page_size}`
        }
        if(searchText){
            url += `&search=${searchText}`
        }
          const res = await axiosApi.get(url);

          if(page && page_size){
            setPagination({
                ...pagination,
                total: res.data.count,
                current: page,
                pageSize: page_size,
              });
          }else{
            setPagination({
                ...pagination,
                total: res.data.count,
                current: 1,
                pageSize: 20,
              });
          }
          const filteredParts = res.data.results.filter(part => part.name.toLowerCase().includes(searchText.toLowerCase()));
          setParts(filteredParts);
          console.log(res)
        }catch (e){

        }finally {
            setLoading(false)
        }
    }

    const changePagination = (data) => {
        getParts(data.current, data.pageSize);
    };


    const uploadFile= async ()=>{
        setLoading(true)
        try{
            const formData = new FormData();
            formData.append('file', fileList[0]);
            const res = await axiosApi.post(`spareparts/sparepart/import/`,formData);
            message.success('Importado correctamente')
            getParts()
            closeModal()
        }catch (e){
            message.error('Error al eliminar, intente nuevamente')
        }finally {
            setLoading(false)
        }
    }


    const downloadTemplate = async()=>{
        setLoadingTemplate(true)
        try{
            const res = await  axiosApi.get(`${urlfull}spareparts/sparepart/import-template/`,{responseType: 'blob'})
            downloadFile(res.data,`template_refacciones`,'application/vnd.ms-excel')
            console.log(res)
        }catch (e){
            message.error('Hubo un error por favor intenta nuevamente')
        }finally {
            setLoadingTemplate(false)
        }
    }

    const onFinishCreateEdit=(values)=>{
        console.log(values)
        createPart(values)
    }

    const createPart=async(values)=>{
        setLoadingTemplate(true)
        if(!values.id){
            try{
                const res = await  axiosApi.post(`${urlfull}spareparts/sparepart/`,values)
                console.log(res)
                formCreate.resetFields()
                getParts()
            }catch (e){
                console.log(e)
            }finally {
                setLoadingTemplate(false)
                setModalCreateEdit(false)
            }

        }else{
            try{
                const res = await  axiosApi.patch(`${urlfull}spareparts/sparepart/${values.id}/`,values)
                console.log(res)
                formCreate.resetFields()
                getParts()
            }catch (e){
                console.log(e)
            }finally {
                setLoadingTemplate(false)
                setModalCreateEdit(false)
            }
        }
    }

    const FormCreateEdit = () => {
        return (
            <Form layout={'vertical'} form={formCreate} name="control-hooks" onFinish={onFinishCreateEdit}>
                <Form.Item
                    name="id"
                    label="id"
                    hidden={true}
                >
                    <Input className={'kinput'} />
                </Form.Item>

                <Form.Item
                    name="name"
                    label="Nombre"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input className={'kinput'} />
                </Form.Item>
                <Form.Item
                    name="code"
                    label="Código/Num. Parte"
                    /*rules={[
                        {
                            required: true,
                        },
                    ]}*/
                >
                    <Input className={'kinput'} />
                </Form.Item>


                <SelectSuppliers style={{marginBottom:20}} allowClear={true} name={'supplier'} label="Proveedor" />
          

                <Form.Item
                    name="cost"
                    label="Costo"
                >
                    <InputNumber
                        style={{width:'100%'}}
                        className={'kinput'}
                        formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}

                    />
                </Form.Item>
                <Form.Item
                    name="price"
                    label="Precio"
                >
                    <InputNumber
                        style={{width:'100%'}}
                        className={'kinput'}
                        formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}

                    />
                </Form.Item>
                <Form.Item>
                    <KButton text={'Cancelar'} type={'secondary'} onClick={()=>setModalCreateEdit(false)} />
                    <KButton text={'Guardar'} type="primary" htmlType="submit" />
                </Form.Item>
            </Form>
        )
    }

    return (
      <MainLayoutv2
        title={intl.formatMessage({ id: "servicesDetails.spareParts" })}
        index="12"
        openKeys={["sub1"]}
      >

        

          <KModal
              title={'Nuevo'}
              onCancel={()=> setModalCreateEdit(false)}
              loading={loadingTemplate}
              visible={modalCreateEdit}>

              <Row>
                  <Col span={24}>
                      <FormCreateEdit/>
                  </Col>
              </Row>


          </KModal>

        <CustomModal
            title=""
            footer=""
            width={1000}
          visible={isModalVisible}
          closeIcon={<img src={CloseIcon} style={{width:15}} />}
          onCancel={()=>setIsModalVisible(false)}

        >
            <Row gutter={16} >
                <Col span={24} >
                    <Title level={2} style={{ marginBottom: 0 }}>
                        <img
                        src={turn}
                        alt="logo"
                        style={{ height: 60, width: 30, transform: "scaleX(-1)", marginRight:10 }}
                        />
                        Importar refacciones
                    </Title>
                </Col>
                <Col span={24} style={{marginBottom:50}}>
                    <Alert
                        showIcon={true}
                        message="Importar refacciones"
                        description="Descargue el archivo plantilla y posteriormente ingrese los valores solicitados para que pueda importar los elementos utilizando el botón 'Subir plantilla'"
                        type="info"
                    />
                </Col>
                <Col span={12} style={{marginBottom:100}}>
                    <p>Paso 1. Descarga y llena tu plantilla.</p>
                    <KButton loading={loadingTemplate} icon={<DownloadOutlined />} onClick={downloadTemplate} type={'outline'} text={'Descarga tu plantilla'}/>
                </Col>
                <Col span={12}>
                    <p>Paso 2. Sube tu información</p>
                    <Upload style={{width:'100% !important'}} multiple={false} maxCount={1} {...props}>
                        <KButton block type={'outline'} icon={<UploadOutlined />} text={'Subir plantilla'}/>
                    </Upload>
                </Col>

                <Col span={24} style={{ textAlign: "center" }}>
                        <Space>
                            <Button
                                size="large"
                                className="btn-string-blue btn-report-action"
                                onClick={() => setIsModalVisible(false)}
                            >
                                <FormattedMessage id="layout.cancel" />
                            </Button>
                            <Button
                                className="btn-blue btn-report-action"
                                size="large"
                                onClick={() => uploadFile() }
                            >
                                <FormattedMessage id="services.members.create.save" />
                            </Button>
                        </Space>
                    </Col>

            </Row>
          
        </CustomModal>

        <Content
          style={{ padding: "10px 10px", background: "white" }}
        >
            
            <TabsMenuConfig keyActive={'1'}/>
            <Row>
                <Col span={12}>
                <Space direction='horizontal'>
                <Input
                placeholder="Buscar por nombre"
                value={searchText}
                allowClear={true}
                onPressEnter={()=>getParts()}
                onChange={e => setSearchText(e.target.value)}
                />
                <Button disabled={loading} loading={loading} onClick={()=>getParts()}>
                   {!loading && <SearchOutlined />}
                </Button>
                </Space>
                
                </Col>
                <Col span={12}>
                
            <KButton  style={{float: "right", marginBottom: 20}} text={'Crear nuevo'} onClick={()=> {setModalCreateEdit(true);formCreate.resetFields()}}/>
            <KButton  style={{float: "right", marginBottom: 20}} text={'Importar datos'} type={'secondary'} icon={<UploadOutlined />} onClick={()=> setIsModalVisible(true)}/>
            
                </Col>
            </Row>
            
          <CustomTable
            fixed={true}
            scroll={{ x: 1500 }}
            loading={loading}
            dataSource={parts}
            onChange={changePagination}
            columns={columns}
            pagination={pagination}
          />
        </Content>
          <DeleteModal actionDelete={() => onDelete()} isVisible={modalDeleteVisible}
                       closeModal={()=> closeModal() }
                       loading={loading}
                       modalTitle={<FormattedMessage id="deleted.ask"/>}
                       description={intl.formatMessage({ id:'deleted.description'},{ obj: forDelete && forDelete.name })} />
      </MainLayoutv2>
    );
}


const mapStateToProps = state => ({
    settings: state.settings,
    permissions:state.permissions
})


export default injectIntl(connect(mapStateToProps, {
    getBrandsAction,
    changeLangAction,
    saveBrandAction,
    deleteBrandAction,
    editBrandAction
})(PartsPage))

