import React, {useEffect, useState} from 'react'

import { Table, Layout, Form, Button, Modal, Input, Upload, Card } from "antd";
import ServiceForm from '../../components/services/ServiceForm';
import styled from "styled-components";
import {useLocation} from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import { changeLangAction } from '../../redux/settingsDuck';
import { connect } from 'react-redux';
import ServiceFormJAC from "../../components/services/ServiceFormJAC";
import ServiceFormGiksa from '../../components/services/ServiceFormGiksa';
import useSettingsKikert from "../../hooks/useSettingsKikert";
import MainLayoutv2 from '../../layouts/MainLayoutv2';

const { Header, Content, Footer } = Layout;

const NewCard = styled(Card)`
  .ant-card-body {
    padding: 0px 10px !important;
  }
`;

const ServiceCreatePage = (changeLangAction, intl, settings, ...props) => {
  const search = useLocation().search;
  const type = new URLSearchParams(search).get('type');
  const [isJAC, setIsJAC] = useState(false)
  const [clientCode, setClientCode] = useState(false)
  const {settingsKikert, getMetaValue} = useSettingsKikert()

  useEffect(()=>{
    console.log('client code', getMetaValue('TENANT_CODE'))
    if(type){
      setIsJAC(true)
    }
  },[type])

  useEffect(()=>{
    const tenantCode = getMetaValue('TENANT_CODE')
    setClientCode(tenantCode)
  },[])

  const getServiceForm = () => {
    const key = clientCode ? clientCode : 'default'
    const currentServiceForm = {
      default: <ServiceForm/>,
      giksa: <ServiceFormGiksa />,
    }
    return key in currentServiceForm ? currentServiceForm[key] : currentServiceForm.default
  }

  return (
    <MainLayoutv2  title={<FormattedMessage id="services.createNew" />} index="expedientes">
      <Layout style={{ marginTop: 20 }}>
        <NewCard className="br-10" style={{ padding: "10px", marginTop: 20 }}>
          {
            isJAC ?  <ServiceFormJAC/> : getServiceForm()
          }
        </NewCard>
      </Layout>
    </MainLayoutv2>
  );
}
const mapState = (state) => ({
  settings: state.settings
});

export default injectIntl(connect(mapState, {
  changeLangAction
})(ServiceCreatePage)) 