import React, {useEffect, useState} from 'react'
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import CardStatistics from "../CardStatistics/CardStatistics";


const PieChartKikert =({data=null,idChart='chartDiv',isDonut=false, categoryField='', valueField='',...props})=> {

    const root=null;

    useEffect(()=>{
        if(data && !root && data.length>0 ){
            let root = am5.Root.new(idChart);

            root.setThemes([
                am5themes_Animated.new(root)
            ]);

            let chart = root.container.children.push(
                am5percent.PieChart.new(root, {
                    layout: root.horizontalLayout,
                    innerRadius: isDonut ? 120 : 0,
                    fontSize: 14
                })
            );

            let series = chart.series.push(
                am5percent.PieSeries.new(root, {
                    name: "Series",
                    valueField: valueField,
                    legendValueText: "[bold{fill}]: {value}[/]",
                    categoryField: categoryField
                })
            );
            
            series.data.setAll(data);
            series.labels.template.set("forceHidden", true);
            series.ticks.template.set("forceHidden", true);
            let legend = chart.children.push(am5.Legend.new(root, {
                y: am5.percent(50),
                centerY: am5.percent(50),
                layout: root.verticalLayout
            }));

            legend.data.setAll(series.dataItems);

            return () => {root.dispose();};
        }


    },[data])

        return (
                <div id={idChart} style={{width: "100%", height: "500px"}}/>
        );

}


export default PieChartKikert;