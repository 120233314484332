import React, { useEffect } from "react";
import {
  Modal,
  Select,
  Form,
  Typography,
  Button,
  Row,
  Col,
  Space,
  Divider,
  Input,
  DatePicker,
  Checkbox
} from "antd";
import { tenantSelected } from '../../lib/utils/axiosApi'
import turn from "../../image/turn.svg";
import { Global, css } from "@emotion/react";
import 'moment/locale/es-mx'
import locale from '../../locale/es_mx.json'
import { connect } from 'react-redux'
import { injectIntl, FormattedMessage } from "react-intl";
import { changeLangAction } from '../../redux/settingsDuck';
import { setModalFilter } from '../../redux/servicesDuck'
import SelectAsesor from "../Selects/SelectAsesor";
import moment from 'moment'
import KSelect from "../common/elements/KSelect/KSelect";
import { ClearOutlined } from '@ant-design/icons';
import SelectInsurance from "../Selects/SelectInsurance";
import SelectLocations from "../Selects/SelectLocations";
import SelectStepsWorkshop from "../Selects/SelectStepsWorkshop";
import SelectSubtypes from "../Selects/SelectSubtypes";
const FilterServicesModal = ({ changeLangAction, intl, settings, services, setModalFilter, ...props }) => {
  const { Title, Text } = Typography;
  const { Option } = Select;
  const { RangePicker } = DatePicker;

  /* useEffect(() => {
    console.log("servicesStatus", servicesStatus);
  }, [servicesStatus]); */

  const onFinish=(values)=>{
    console.log(values)
    debugger;

    if(values.created_at){
      values.created_at_after = moment(values.created_at[0]).format('YYYY-MM-DD');
      values.created_at_before = moment(values.created_at[1]).format('YYYY-MM-DD');
    }

    if(values.delivery_promise_date){
      values.delivery_promise_date_after = moment(values.delivery_promise_date[0]).format('YYYY-MM-DD');
      values.delivery_promise_date_before = moment(values.delivery_promise_date[1]).format('YYYY-MM-DD');
    }

    delete values.created_at;
    delete values.delivery_promise_date;

    if(!values.search){
      delete values.search;
    }

    if(!values.status){
      delete values.status;
    }

    if(!values.current_step__in){
      delete values.current_step__in;
    }


    if(!values.location){
      delete values.location;
    }

    if(!values.repair_status){
      delete values.repair_status;
    }

    if(!values.delivery_promise_date_status){
      delete values.delivery_promise_date_status;
    }

    if(!values.is_invoiced){
      delete values.is_invoiced;
    }


    if(!values.insurance){
      delete values.insurance;
    }

    if(!values.delivery_status){
      delete values.delivery_status;
    }

    if(!values.insurance_type){
      delete values.insurance_type;
    }

    if(!values.is_deleted){
      delete values.is_deleted;
    }

    if(!values.with_deductible){
      delete values.with_deductible;
    }

    if(!values.order_subtype){
      delete values.order_subtype;
    }

    let params = Object.keys(values).map(key => key + '=' + values[key]).join('&');
    props.onFinish(params)
  }

  return (
    <>
      <Global
        styles={css`
          .modal_filter_service .ant-modal-content {
            border-radius: 15px;
            padding: 0px 15px;
          }
          .label-margin .ant-form-item-label {
            margin-left: 15px;
          }

          .label-margin .ant-form-item-label label {
            font-weight: 500 !important;
          }
          .title_date {
            font-weight: normal !important;
            margin-left: 15px;
          }
          .text_dates {
            color: var(--secondaryColor);
            margin-left: 15px;
          }
          .ant-checkbox-inner {
            border-radius: 25%;
            border-color: #2d2cf5;
          }
          .ant-checkbox-checked .ant-checkbox-inner {
            background-color: #2d2cf5;
          }
          .ant-checkbox-inner::after {
            background-color: #2d2cf5;
            border-color: #2d2cf5;
          }
        `}
      />
      <Modal
        centered
        visible={services.openModalFilter}
        title=""
        footer=""
        className="modal_filter_service"
        width={700}
        onCancel={() => setModalFilter(false)}
        onClick={() => setModalFilter(false)}
      >
        <Title level={3} className="title-modal-filte">
          <img src={turn} alt="logo" style={{ width: 25 }} /> <FormattedMessage id="services.filter.filter" />
        </Title>
        <Divider className="divider-blue" />
        <Form onFinish={onFinish} layout="vertical">
          <Row style={{ paddingTop: 10 }} gutter={[20, 20]}>
            {/*<Col xs={24} md={12}>*/}
            {/*  /!*<SelectAsesor/>*!/*/}
            {/*</Col>*/}
            <Col span={24}>
              <Form.Item
                  label={intl.formatMessage({ id: 'services.searchtext' })}
                  name={'search'}
                  className="item-expediente-lg mb-0 label-margin"
              >
                <Input className={'kinput'} size="large" />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label={intl.formatMessage({ id: "services.filter.date" })}
                name={'created_at'}
                className="item-expediente-lg mb-0 label-margin"
              >
                <RangePicker size="large" locale={locale} />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                  label={intl.formatMessage({ id: "servicesDetails.deliveryPromise" })}
                  name={'delivery_promise_date'}
                  className="item-expediente-lg mb-0 label-margin"
              >
                <RangePicker size="large" locale={locale} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                  label={'Estatus'}
                  name={'status'}
                  className="item-expediente-lg mb-0 label-margin"
              >
                <KSelect
                    allowClear={true}
                    placeholder={'Elige'}
                    options={[
                      {value:'',label:'Todos'},
                      {value:'OPEN',label:'Abierto'},
                      {value:'RE-OPENED',label:'Re abierto'},
                      {value:'CLOSED',label:'Entregado'}
                    ]}
                />
              </Form.Item>
            </Col>
            {
              tenantSelected !== 'jacmexico' && <Col xs={24} md={12}>
                <SelectSubtypes/>
              </Col>
            }
            
            
            <Col xs={24} md={12}>
              <Form.Item
                  label={'¿Facturado?'}
                  name={'is_invoiced'}
                  className="item-expediente-lg mb-0 label-margin"
              >
                <KSelect
                    allowClear={true}
                    placeholder={'Elige'}
                    options={[
                      {value:'',label:'Todos'},
                      {value:true,label:'Si'},
                      {value:false,label:'No'},
                    ]}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                  label={'Estatus de reparación'}
                  name={'repair_status'}
                  className="item-expediente-lg mb-0 label-margin"
              >
                <KSelect
                    allowClear={true}
                    placeholder={'Elige'}
                    options={[
                      {value:'',label:'Todos'},
                      {value:0,label:'Sin iniciar'},
                      {value:2,label:'En proceso'},
                      {value:1,label:'Finalizado'}
                    ]}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <SelectStepsWorkshop name={'current_step__in'} mode="multiple" label="Etapas de reparación" placeholder={<FormattedMessage id="choose" />} allowClear={true} className={'item-expediente-lg mb-0 label-margin'}/>
            </Col>
            
            <Col xs={24} md={12}>
              <Form.Item
                  label={'Tipo de atención'}
                  name={'insurance_type'}
                  className="item-expediente-lg mb-0 label-margin"
              >
                <KSelect
                  allowClear={true}
                 placeholder={'Elige'}
                 options={[
                   {value:'',label:'Todos'},
                   {value:'I',label:'Por aseguradora'},
                   {value:'P',label:'Particular'}
                 ]}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
                <SelectInsurance placeholder={<FormattedMessage id="choose" />} allowClear={true} className={'item-expediente-lg mb-0 label-margin'}/>
            </Col>
            <Col xs={24} md={12}>
                <SelectLocations placeholder={<FormattedMessage id="choose" />} allowClear={true} className={'item-expediente-lg mb-0 label-margin'}/>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                  label={'Estatus de entrega'}
                  name={'delivery_promise_date_status'}
                  className="item-expediente-lg mb-0 label-margin"
              >
                <KSelect
                    allowClear={true}
                    placeholder={'Elige'}
                    options={[
                      {value:'',label:'Todos'},
                      {value:0,label:'Atrasados'},
                      {value:2,label:'Se entregan hoy'},
                      {value:1,label:'En tiempo'}
                    ]}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                  label={'¿Aplica deducible?'}
                  name="with_deductible"
              >
                <KSelect
                    allowClear={true}
                    placeholder={'Elige'}
                    options={[
                      {value:'all',label:'Todos'},
                      {value:'true',label:'Si'},
                      {value:'false',label:'No'},
                    ]}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                  label={'¿Mostrar solo ordenes eliminados?'}
                  name="is_deleted"
              >
                <KSelect
                    allowClear={true}
                    placeholder={'Elige'}
                    options={[
                      {value:'false',label:'Todos'},
                      {value:'true',label:'Si'},
                      {value:'false',label:'No'},
                    ]}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Button onClick={()=>props.onClear()} type="link"><ClearOutlined />Reiniciar filtro</Button>
            </Col>
          </Row>
          <Row justify="center" style={{marginTop:30}}>
            <Col>
              <Space size="middle">
                <Button
                  htmlType="button"
                  className="btn-string-blue btn-report-action"
                  onClick={() => setModalFilter(false)}
                >
                  <FormattedMessage id="layout.close" />
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="btn-string-blue-low btn-report-action"
                >
                  <FormattedMessage id="accept" />
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  services: state.services,
  settings: state.settings
});

export default injectIntl(
  connect(mapStateToProps, { setModalFilter, changeLangAction })(FilterServicesModal)
);
