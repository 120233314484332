import {
    AutoComplete,
    Button,
    Cascader,
    Checkbox,
    Col,
    Form,
    Input,
    message,
    InputNumber,
    Row,
    Select,
  } from 'antd';
  import React, { useState, useEffect } from 'react';
  import HeaderSection, { InputDate, ItemForm } from "../../components/MyComponents";
  import { injectIntl, FormattedMessage } from "react-intl";
  import axiosApi, { API } from '../../lib/utils/axiosApi';
  import _ from "lodash"

  const { Option } = Select;

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 8,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 16,
      },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };
  
  const App = ({unitData}) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true)
    const [carTypes, setcarTypes] = useState([])
    const [vinLength, setVinLength] = useState(null)
    const [carTypeSelected, setCarTypeSelected] = useState(null)
    const [loadingCarTypes, setLoadingCarTypes] = useState(false)
    const [statusVinField, setStatusVinField] = useState("")
    const [statusVinFieldMsg, setStatusVinFieldMsg] = useState("")
    const [isValidVin, setIsValidVin] = useState(false)
    const [carBrands, setCarBrands] = useState([])
    const [carModels, setCarModels] = useState([])
    const [carColors, setCarColors] = useState([])
    const [viewBrand, setViewBrand] = useState()
    const [carModelSelected, setCarModelSelected] = useState(null)
    const [carColorSelected, setCarColorSelected] = useState(null)
    const [carBrandModels, setcarBrandModels] = useState([])
    
    const setErrorVin = (type = "error", msg = "") => {
        setStatusVinField(type)
        setStatusVinFieldMsg(msg)
    }
    const readOnly = false
  
    const onFinish = async (values) => {
        setLoading(true)
        let req = {...values}
        req.vin=values.serialNumber;
        if(values.model){
            req.model=values.model[1];
        }

        try{
            const res = await axiosApi.patch(API.BUSINESS.UNITS+unitData.id,req)
            console.log(res)
            message.success('Actualizado correctamente')
        }catch (e){
            console.log('error update')
        }finally {
            setLoading(false)
        }

    };

    const loadData = async () => {
        try {
          setLoading(true)
          await getCarTypes()
          await getCarBrands()
          await getCarModels()
          await getCarColors()
          setUpdateData()
        } catch (error) {
          console.error('Error al cargar datos de marca', error)
        } finally {
          setLoading(false)
        }
      }
    
    const setUpdateData = () => {
      let carBrandAndModel = [unitData?.model?.brand?.id, unitData?.model?.id]
      form.setFieldsValue({
        color: unitData?.color?.id,
        type: unitData?.type,
        economic_number: unitData?.economic_number,
        license_plate: unitData?.license_plate,
        serialNumber: unitData?.vin,
        model:carBrandAndModel
      })
    }

    useEffect(
        () => {
        loadData()
        }, []
    )

    useEffect(
        () => {
            if(!loading && carBrands && carModels){
                console.log("BrandModels carBrands", carBrands, "carModels", carModels)
                makeBrandModels()
            }
        }, [loading]
    )

    const makeBrandModels = () => {
        let response = _.map(carBrands, (brand) => {
            console.log("modelsForThisBrand before" , carModels)
            const modelsForThisBrand = _.filter(carModels, (m) => m?.brand?.id === brand?.id)
            console.log("modelsForThisBrand", modelsForThisBrand, carModels)
            return {
                label: brand?.name,
                value: brand?.id,
                children: _.map(modelsForThisBrand, (model) => {
                    return {
                        value: model.id,
                        label: model.name
                    }
                })
            }
        })
        console.log("BrandModels", response)
        setcarBrandModels(response)
    }

    const verifyVin = async (search = '', callback = () => { }) => {
        try {
          console.log("VIN", search)
          //setLoading(true)
          const response = await axiosApi.get(`${API.WORKSHOP.WORKSHOP_CAR}?vin=${search}`)
          console.log("Response vehicle vin", response.data)
          //setCustomers(response.data.results)
          if(response.data.results.length > 0){
            setIsValidVin(false)
            callback("error", "El VIN ya existe")
          }else{
            setIsValidVin(true)
            callback("success", "VIN validado")
          }
        } catch (error) {
          callback("")
          console.error('Error al validat vin', error)
        } finally {
          //setLoading(false)
        }
      }
      const handleCarBrands = (val) => {
        console.log('handleCarBrands',val)
        console.log("carModels", carModels);
        const nameBrand = val;
        const filtros = carModels.filter(element => element.brand.id === nameBrand)
        //console.log('Value de las marcas', nameBrand)
        //carModels.map((item) => (console.log('MAURICIO: ', item.name)))
        setViewBrand(filtros)
      }

      const handleCarColor = (val) => {
    
        setCarColorSelected(val)
      }

    const debounceValidateVin = _.debounce(
        (query, callback) => {
          verifyVin(query, callback)
        },
        500
      )

    const asyncValidateVin = (e) => {
        const vin = e.target.value;
        if(vinLength){
          if(vin && vin.length === vinLength){
            setStatusVinField("validating")
            setStatusVinFieldMsg("Validando...")
            debounceValidateVin(vin, setErrorVin)
          }
          else{
            setStatusVinField("error")
            setStatusVinFieldMsg("El VIN debe contener por lo menos "+ vinLength +" caracteres")
          }
        }
    
      }
      const setDefaultUnitValue = (item, value) => {
        form.setFieldsValue({
          [item] : value
        });
      }

    const handleCarTypes = (val, e) => {
        let cartype = carTypes.find((item)=> item.id === val)
        if(cartype){
          setVinLength(cartype.vin_validation? cartype.vin_length : null)
        }
        setCarTypeSelected(val)
      }

      const handleCarModel = (val) => {
    
        setCarModelSelected(val)
      }
    
    const getCarTypes = async () => {
    try {
        setLoadingCarTypes(true)
        const response = await axiosApi.get(`${API.WORKSHOP.WORKSHOP_CAR_TYPES}`)
        //console.log('Se ejecuto vehiculos busqyeda', response.data)
        setcarTypes(response.data.results)
        if (response.data.results.length === 1 ) {
        //setDefaultUnitValue("carType", response.data.results[0]['id']);
        let _carType = response.data.results[0] ;
        setVinLength(_carType.vin_validation? _carType.vin_length : null)
        }
    } catch (error) {
        console.error('Error al buscar vehiculos', error)
    } finally {
        setLoadingCarTypes(false)
    }
    }

    const getCarBrands = async () => {
        const response = await axiosApi.get(API.WORKSHOP.WORKSHOP_CARBRAND)
        
        setCarBrands(response.data.results)
        /* if (response.data.results.length === 1) {
          setDefaultUnitValue("carBrand", response.data.results[0]["id"]);
        } */
      }
    
      const getCarModels = async () => {
        console.log('getCarModels===')
        const responseModels = await axiosApi.get(`${API.WORKSHOP.WORKSHOP_CARMODEL}?page_size=100000`)
        console.log(responseModels.data.results);
        setCarModels(responseModels.data.results)
      }
    
      const getCarColors = async () => {
        const response = await axiosApi.get(API.WORKSHOP.WORKSHOP_CARCOLOR)
        setCarColors(response.data.results)
        if (response.data.results.length === 1) {
          setDefaultUnitValue("carColor", response.data.results[0]["id"]);
        }
      }
  
    const prefixSelector = (
      <Form.Item name="prefix" noStyle>
        <Select
          style={{
            width: 70,
          }}
        >
          <Option value="86">+86</Option>
          <Option value="87">+87</Option>
        </Select>
      </Form.Item>
    );
    const suffixSelector = (
      <Form.Item name="suffix" noStyle>
        <Select
          style={{
            width: 70,
          }}
        >
          <Option value="USD">$</Option>
          <Option value="CNY">¥</Option>
        </Select>
      </Form.Item>
    );
    const [autoCompleteResult, setAutoCompleteResult] = useState([]);
  
    const onWebsiteChange = (value) => {
      if (!value) {
        setAutoCompleteResult([]);
      } else {
        setAutoCompleteResult(['.com', '.org', '.net'].map((domain) => `${value}${domain}`));
      }
    };
  
    const websiteOptions = autoCompleteResult.map((website) => ({
      label: website,
      value: website,
    }));
    return (
      <Form
        {...formItemLayout}
        form={form}
        name="register"
        onFinish={onFinish}
        initialValues={{
          residence: ['zhejiang', 'hangzhou', 'xihu'],
          prefix: '86',
        }}
        scrollToFirstError
      >

        <ItemForm
              label={<FormattedMessage id="services.createNew.typeVehicle" />}
              rules={[]}
              name="type"
              tooltip="Campo requerido"
          >
            <Select
                loading={loading}
                /* defaultValue={carTypeSelected} */
                style={{ width: "100%" }}
                onChange={handleCarTypes}
                readOnly={false}
            >
                {carTypes.map((item) => (
                    <Option value={item.id} key={item.id}>
                    {item.name}
                    </Option>
                ))}
            </Select>
        </ItemForm>
        <ItemForm
            label={<FormattedMessage id="services.createNew.serialNumber" />}
            name="serialNumber"
            // rules={fieldsRequired && [rulesRequired, vinRule]}
            tooltip="Campo requerido"
            validateStatus={statusVinField}
            hasFeedback
            help={statusVinFieldMsg}
          >
            <Input
              size="large"
              maxLength={vinLength?vinLength:17}
              //status={vinIsValid}
              placeholder="Número de serie"
              value={unitData ? unitData.vin : ""}
              onChange={asyncValidateVin}
            />
          </ItemForm>
        
          <ItemForm
            label={<FormattedMessage id="services.createNew.plates" />}
            name="license_plate">
            <Input size="large" placeholder="Placas" />
          </ItemForm>

          <ItemForm
            label={"Núm. económico"}
            name="economic_number">
            <Input size="large" placeholder="Núm económico" />
          </ItemForm>
          <ItemForm
            label={"Marca y modelo"}
            name="model"
          >
            <Cascader options={carBrandModels} />
          </ItemForm>
        
          <ItemForm
            /* initialValue={unitData ? unitData.color.id : ""} */
            //rules={fieldsRequired && rulesRequired}
            label={<FormattedMessage id="services.createNew.colour" />}
            name="color"
          >
            <Select
              loading={loading}
              style={{ width: "100%" }}
              /* value={carColorSelected} */
              onChange={handleCarColor}
              readOnly={readOnly}
            >
              {carColors.map((item) => (
                <Option value={item.id} key={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </ItemForm>
  
  
        <Form.Item {...tailFormItemLayout}>
          {
             <Button type="primary" htmlType="submit">
                      Actualizar
                    </Button>
          }
        </Form.Item>
      </Form>
    );
  };
  
  export default App;