import React, { useEffect, useState } from 'react'
import { Button, List, Typography, Row, Col, Spin } from 'antd';
import { KButton } from '../../components/KComponents';
import SurveyComponent from '../../components/surveys/SurveyComponent';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getSurveys } from '../../services/surveyServices';
import { useHistory, useParams } from 'react-router-dom';


const SurveysPage = () => {


    const { id } = useParams();
    const [showSurvey, setShowSurvey] = useState(false)
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const orderId = queryParams.get('order_id');
    const settings = useSelector(state => state.settings);
    const [surveys, setSurveys] = useState(null)
    const [loading, setLoading] = useState(false)
    const history = useHistory()

    useEffect(() => {
        _getSurveys() 
    }, [])

    const _getSurveys = async () => {
      setLoading(true)
      try{
        const response = await getSurveys()
        if(response?.results?.length === 0){
          return
        }else if(response?.results?.length === 1){
          let survey = response?.results[0] 
          history.push(`/survey/${survey.id}/${orderId}`)
        }else{
          setSurveys(response?.results)
        }
      } catch (error) {
        console.log(error)  
      }finally{
        setLoading(false)
      }

    }


  return (
    <div style={{ padding: '20px', textAlign: 'center', height: '100vh', backgroundColor: '#0819b7' }}>
      <Row justify="center" style={{ backgroundColor: '#eceef8', paddingTop:20, borderRadius: 10 }}>
        {
            settings?.settingsData?.companySelected &&
            <Col xs={24}>
                <img src={settings?.settingsData?.companySelected?.logo} alt={settings?.settingsData?.companySelected?.name} style={{ width: '100px', height: 'auto' }} />
            </Col>
        }
        <Col span={24} style={{ padding: 20 }}>
        {
          loading ? 
          <Spin/>
          : surveys ? <List
          dataSource={surveys}
          renderItem={item => (
            <List.Item>
              <KButton type="primary" onClick={() => history.push(`/survey/${item.id}/${orderId}`)} text={item.title} style={{ width: '100%' }}/>
            </List.Item>
          )}
          style={{ margin: '0 auto' }}
        /> :<><Typography.Paragraph style={{ fontSize: 16, margin:0, padding:0 }}>No se encontraron encuestas, porfavor contacta a tu asesor Kikert para más información sobre el módulo de encuestas.</Typography.Paragraph>
            <KButton type="primary" onClick={() => history.push(`/service-detail/${orderId}`)} text={'Regresar'} />
            
        </>
         }
        </Col>
      
      </Row>
    </div>
  );
};

export default SurveysPage;

