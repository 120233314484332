import React, { useState, useEffect } from 'react';
import {Typography,Badge,Card,Drawer,Space,Button,List,Avatar,notification,Spin, Tooltip} from 'antd'
import {
    BellTwoTone,
    ArrowRightOutlined,
    NotificationOutlined,
    MessageOutlined
} from '@ant-design/icons';
import turn from '../image/turn_icon_kikert.svg'
import kikert from '../image/icon_kikert.svg'
import { ref, onValue, set } from "firebase/database";
import fireDB from '../firebase/firebaseConfig';
import AxiosApi, {tenantSelected} from "../lib/utils/axiosApi";
import {playNotification} from '../lib/utils/utils'
import { useHistory } from "react-router-dom"
import {useSelector} from "react-redux";
import moment from 'moment'
const { Title, Text } = Typography;

const BadeNotifications =({...props })=>{
    const [count, setCount] = useState(0);
    const [notifications, setNotifications] = useState([]);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const user = useSelector(state => state.user);
    const branchSelected = useSelector(state => state.settings.settingsData);
    const history = useHistory()

    const onClose=()=>{
        setOpen(false)
    }


    useEffect(() => {
            obtenerData()
    }, [])

    const getListNotifications=async (page=1)=>{
        setLoading(true)
        try{
            const res = await AxiosApi.get(`/noticenter/notification/?user=${user.id}&page=${page}`)
            console.log(res?.data?.results)
            if(res.data?.results){
                setNotifications(res?.data?.results)
            }
        }catch (e){

        }finally {
            setLoading(false)
        }
    }

    const openNotification = () => {
        if(notification){
            notification.destroy()
        }
        const args = {
            message: 'Notificaciones',
            description:
                'Tienes notificaciones sin leer',
            duration: 3,
            icon:<img src={turn} width={30} alt = "" />,
            onClick: function (){
                setOpen(true)
                notification.destroy()
            }
        };
        notification.open(args);
    };

    const processMetaNotification=(noti)=>{
        console.log(noti)
        let meta = noti?.meta;
        switch (meta?.type){
            case "TAGGED_IN_COMMENT":
                //history.push(`/service-messages/${meta?.order_id}`);
                window.location.href = `/service-messages/${meta?.order_id}`;
                setOpen(false)
                break;
            case "NEW_CHAT_MESSAGE":
                //history.push(`/order-chat/${meta?.order_id}/`);
                window.location.href = `/order-chat/${meta?.order_id}/`;
                setOpen(false)
                break;
            default:
                //history.push(`/service-detail/${meta?.order_id}/`);
                window.location.href = `/service-detail/${meta?.order_id}/`;
                setOpen(false)
                break;

        }
    }


    const obtenerData=async ()=>{
        let settings = localStorage.getItem('settings_kk')
        settings = JSON.parse(settings)
        const variable = ref(fireDB,`${tenantSelected}/notifications/${user.id}/${settings?.branchSelected?.id}`);
        onValue(variable, (snapshot) => {
            const data = snapshot.val();
            const [value] = Object.values(data);
            if (data) {
                if(value?.unread_notifications){
                    const numNoti = localStorage.getItem("unread_notifications");
                    setCount(value.unread_notifications)
                    if(numNoti!=value?.unread_notifications){
                        if(value?.unread_notifications!=='0'){
                            playNotification()
                            openNotification()
                        }

                    }
                    localStorage.setItem("unread_notifications", value?.unread_notifications);
                }else{
                    localStorage.removeItem("unread_notifications");
                    setCount(0)
                }

            } else {

            }
        });
    }




    const Titulo=()=>{
       return <Space direction="horizontal">
            <Title level={2} className="mb-0">
                <img src={turn} alt="logo" style={{
                    height: 64,
                    width: 30,
                    transform: 'scaleX(-1)'
                }} />{" "}
                <Text style={{ fontWeight: 400 }}>
                    Notificaciones
                </Text>
            </Title>
        </Space>
    }

    const IconNoty=({noti})=>{
        let meta = noti?.meta;
        const style={
            color:'black'
        }
        switch (meta?.type){
            case "TAGGED_IN_COMMENT":
                return <NotificationOutlined style={style}  />
                break;
            case "NEW_CHAT_MESSAGE":
                return <MessageOutlined  style={style}/>
                break;
            default:
                return <NotificationOutlined   style={style}/>
                break;

        }
    }

    const {Text} = Typography
    return (
        <>
            <Drawer
                title={<Titulo/>}
                placement="right"
                onClose={onClose}
                visible={open}
            >

                <Spin tip="Loading" spinning={loading} size="large">
                    <List
                        dataSource={notifications}
                        renderItem={(item) => (
                            <List.Item
                                key={item.id}
                            >
                                <List.Item.Meta
                                    avatar={
                                        <Avatar style={{transform: 'scaleX(-1)',backgroundColor:'rgb(226,226,232)'}} src={<IconNoty noti={item}  />} />
                                    }
                                    title={<a onClick={()=>processMetaNotification(item)}>  {item.message} {
                                        !item.is_read && <Badge status="processing" />
                                    }</a>}
                                    description={
                                        <div>


                                            <p style={{margin:0,padding:0}}>Folio: {item?.meta?.order_folio} {item?.meta?.folio}</p>
                                            <p>{moment(item.timestamp).format("DD/MM/YYYY hh:mm  a")}</p>

                                        </div>

                                    }
                                />
                            </List.Item>
                        )}
                    />
                </Spin>
            </Drawer>
            <Badge count={count}>
                <Tooltip title="Notificaciones">
                <Button icon={<BellTwoTone twoToneColor="#341EFF" />} onClick={()=> {
                        setOpen(true)
                        getListNotifications()
                    }} shape="circle" />
                </Tooltip>
            </Badge>
            
            
        </>

    );
}


export default BadeNotifications