import React, { useEffect, useState } from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import { FormattedMessage, injectIntl } from "react-intl";
import { Col, Row, Input, Tooltip, Button, Table, Card, Form, Typography, Divider, Space } from "antd";
import {
  SearchOutlined,
  FilterOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import MembersTable from "../../components/members/MembersTable";
import UserTable from '../../components/members/UserTable';
import ModalMembers from "../../components/members/ModalMembers";
import DeleteModal from '../../components/modal/DeleteModal';
import styled from "styled-components";
import { connect } from 'react-redux';
import { showMembers, getProfiles, getUsers, changeScreen, userFormShow , userGetForEdit} from "../../redux/membersDuck";
import CreateGroup from "../../components/members/CreateGroup";
import UserForm from '../../components/members/forms/UserForm';

import {
  BrowserRouter as Router,
  Route,
  Link,
  useRouteMatch,
  useHistory,
  useParams,
} from "react-router-dom";

import FilterIcon from '../../image/filter_icon.svg';
import SearchIcon from '../../image/BUSCADOR.png';
import MainLayoutv2 from "../../layouts/MainLayoutv2";


const CustomCard = styled(Card)`
    .ant-card-body{
        padding: 20px;
    }
`;



const UserAddUpd = ({ intl , membersState, userGetForEdit,  ...props}) => {

  const {Text} = Typography
  const [openModalCreate, setOpenModalCreate] = useState(false);
  /* const [userFormShow, setUserFormShow] = useState(false); */
  const { id } = useParams();

  const history = useHistory();

    useEffect(() => {
        console.log('id =>',id)
        if(id){
            userGetForEdit(id);
        }
    }, [id])


  return (
    <MainLayoutv2
      title={<FormattedMessage id="members.title" />}
      index="members"
    >
      <Col span={23} style={{ textAlign: "left" }}>
        <Space >
          <Button
              onClick={() => {
                  history.push("/members_center_users")
                  /*   changeScreen("profiles") */
                  /* userFormShow(false); */
              }
              }
            className={'btn-string-blue-low' }
          >
            <UsergroupAddOutlined /> &nbsp; &nbsp;{" "}
            <FormattedMessage id="members.table.users" />
          </Button>
          <Button
            onClick={() => {
                history.push("/members_center_profiles")
            /*   changeScreen("profiles") */
              /* userFormShow(false); */
              }
            }
          >
            <UsergroupAddOutlined /> &nbsp; &nbsp;{" "}
            <FormattedMessage id="members.table.profiles" />
          </Button>
        </Space>
        <Divider/>
      </Col>
          <Row justify="space-between" align="bottom">
            <Col md={24}>
                <CustomCard bordered={false} style={{marginTop: 30 }}>
                    <UserForm history={history} />
                </CustomCard>
            </Col>
          </Row>
    </MainLayoutv2>
  );
};

const mapState = (state) => ({
  membersState: state.members,
});



export default injectIntl(connect(mapState, { userGetForEdit })(UserAddUpd));