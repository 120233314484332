import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux'
import { Layout, Menu,Avatar,Button,Row,Col } from 'antd';
import { CarOutlined, AppstoreOutlined,TagOutlined,
  FolderFilled,PieChartOutlined, DashboardOutlined,UserOutlined,CloudServerOutlined, SettingOutlined,  LeftOutlined,BlockOutlined,
  RightOutlined,ToolOutlined,TeamOutlined } from '@ant-design/icons';
import _ from 'lodash'
import { injectIntl } from 'react-intl';
import { useHistory, NavLink, Redirect } from "react-router-dom"
import { toggleMenu } from '../redux/settingsDuck';
import { Global, css } from '@emotion/react';
import { changeLangAction } from '../redux/settingsDuck';
import imgUser from "../image/UserSinBarba.png"
import EsEn from "../image/idiomas_icon.svg"
import { useMediaQuery } from 'react-responsive';

import VentasIconBlanco from "../image/ventas_icon2.svg"
import ServiciosIconBlanco from "../image/servicios_icon2.svg"

import MembersIcon from "../image/member_center_icon.svg";
import {permissionsExist,version } from "../lib/utils/utils";
import refacciones_icon from "../image/detail/refacciones_icon_white.svg";
import { tenantSelected } from '../lib/utils/axiosApi';



const MainSider = ({ openKeys,branchSelected,companySelected, usernameFirst,user, security, usernameLast, changeLangAction, toggleMenu, settings, intl, index,permissions, ...props }) => {

  const { Sider } = Layout;
  const { SubMenu } = Menu;
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const history = useHistory()

  const [collapsed, setCollapsed] = useState(() => {
    const savedCollapsed = localStorage.getItem('siderCollapsed');
    return savedCollapsed ? JSON.parse(savedCollapsed) : (isMobile ? true : false);
  });

  const [imageprofile,setImageProfile] = useState(imgUser)
  
  const [companyLogo,setCompanyLogo] = useState(null)
  const toggleCollapsed = () => {
    const newCollapsed = !collapsed;
    setCollapsed(newCollapsed);
    localStorage.setItem('siderCollapsed', JSON.stringify(newCollapsed));
  };

  useEffect(() => {
    if(user?.userprofile?.image_profile){
          setImageProfile(user?.userprofile?.image_profile)
    }
    if(companySelected?.logo){
      setCompanyLogo(companySelected?.logo)
    }
  }, [user,companySelected])
  


  /***Validador de permisos***/
  const permissionsExist_ = (arr=[],permission)=> {
         if (permissions.permissionsList && permissions.permissionsList.length>0){
           return  permissions.permissionsList.some(function(el) {
             return el.codename === permission;
           });
         }else {
           return false
         }
  }


  /**
   * Esta funcio nos sirve para identificar si hay que mostrar o no el menu de ventas (es mediante los metas del admin)
   * @returns {boolean}
   */
  const showSales=()=>{
    let metas= settings?.generalInfoMeta?.results;
    if(metas){
      let data =metas.find((item) => item.key==='SALES_IS_ACTIVE' && item.value==='1');
      if(data){
        return true
      }
    }
    return false
  }


  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 767 && !collapsed) {
        setCollapsed(true);
        localStorage.setItem('siderCollapsed', JSON.stringify(true));
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [collapsed]);

  return (
    <>
      <Global
        styles={css`
          .ant-layout-sider {
            background-color: #0E0E40 !important;
          }
          .ant-menu.ant-menu-dark {
            background-color: transparent;
          }
          .ant-menu-dark .ant-menu-item-selected {
            background: linear-gradient(90deg, #4A5CFF 0%, #2E3ED1 50%, #1E2F65 100%) !important;
            border-radius: 8px;
            margin: 4px 8px;
            width: calc(100% - 16px);
            box-shadow: 0 2px 8px rgba(74, 92, 255, 0.3);
          }
          .ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon {
            color: white;
          }
          .ant-menu-dark .ant-menu-item-selected .ant-menu-title-content {
            color: white;
            font-weight: bold;
          }
          .ant-menu-dark .ant-menu-item {
            margin: 4px 16px;
            width: calc(100% - 3px);
          }
          .ant-menu-dark .ant-menu-item:hover {
            background-color: rgba(255, 255, 255, 0.05);
          }
          .ant-menu-dark .ant-menu-item-active {
            background-color: rgba(255, 255, 255, 0.05);
          }
          .ant-menu-dark .ant-menu-submenu-title:hover {
            background-color: rgba(255, 255, 255, 0.05);
          }
          .ant-menu-dark .ant-menu-submenu-active {
            background-color: rgba(255, 255, 255, 0.05);
          }
          .ant-layout-sider-collapsed .ant-menu-dark .ant-menu-item-selected,
          .ant-layout-sider-collapsed .ant-menu-dark .ant-menu-item {
            margin: 4px 8px;
            width: calc(100% - 16px);
          }

          .ant-menu-submenu > .ant-menu-submenu-title {
            left: 0;
            //padding: 0 calc(50% - 16px / 2);
            text-overflow: clip;
            //padding-left: ${collapsed ? '100px !important' : '0 calc(50% - 16px / 2)'};
          }

          .company-header {
            background: linear-gradient(90deg, #1E2F65 0%, #3A4B8C 100%);
            padding: 5px;
            margin: 8px;
            border-radius: 8px;
            color: white;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          }

          .ant-menu-inline-collapsed {
            width: 80px;  // Ajusta este valor según sea necesario
          }

          .ant-menu-inline-collapsed .ant-menu-item,
          .ant-menu-inline-collapsed .ant-menu-submenu-title {
            padding: 0 24px !important;
            text-align: left;
          }

          .ant-menu-inline-collapsed .ant-menu-item .ant-menu-item-icon,
          .ant-menu-inline-collapsed .ant-menu-submenu-title .ant-menu-item-icon {
            margin-right: 0;
            font-size: 16px;
          }

          .ant-menu-inline-collapsed .ant-menu-item-selected {
            margin: 4px 8px !important;
            width: calc(100% - 16px) !important;
          }

          // Ajuste adicional para los íconos personalizados (imágenes)
          .ant-menu-inline-collapsed .ant-menu-item img,
          .ant-menu-inline-collapsed .ant-menu-submenu-title img {
            margin-left: -4px;  // Ajusta este valor para alinear los íconos de imagen
          }

          .ant-menu-item-group-title {
            color: rgba(255, 255, 255, 0.65) !important;
            font-size: 12px;
            padding: 8px 16px 4px !important;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            margin-bottom: 8px;
          }

          .ant-menu-inline-collapsed .ant-menu-item-group-title {
            display: none;
          }

          // Ajuste para el espacio entre grupos
          .ant-menu-item-group + .ant-menu-item-group {
            margin-top: 16px;
          }
        `}
      />
      <Sider collapsible trigger={null} className={'siderColor'} collapsed={collapsed} width={240}>
        <div className="company-header">
          {!collapsed ? (
            <Row gutter={[16, 16]} align="middle">
              <Col>
                <Avatar src={companyLogo} style={{ border: '2px solid white' }} />
              </Col>
              <Col flex="auto">
                <div style={{ fontSize: '16px', fontWeight: 'bold' }}>{companySelected?.name}</div>
                {branchSelected && (
                  <div style={{ fontSize: '12px', opacity: 0.7 }}>{branchSelected?.name}</div>
                )}
              </Col>
            </Row>
          ) : (
            <Avatar src={companyLogo} style={{ display: 'block', margin: '0 auto', border: '2px solid white' }} />
          )}
        </div>
        
        <Menu
          theme="dark"
          mode="inline"
          defaultOpenKeys={openKeys}
          selectedKeys={index ? [index] : []}
          style={{ border: 'none' }}
        >
          <Menu.ItemGroup key="dashboards" title={!collapsed && "Dashboards"}>
            <Menu.Item key="Dashboard" icon={<AppstoreOutlined />} onClick={() => history.push("/")}>
              <FormattedMessage id="services.dash" />
            </Menu.Item>
            <Menu.Item key="analitica" icon={<PieChartOutlined />} onClick={() => history.push("/analytics")}>
              <FormattedMessage id="services.analytics" />
            </Menu.Item>
          </Menu.ItemGroup>

          <Menu.ItemGroup key="ordenes" title={!collapsed && "Órdenes de Servicio"}>
            {showSales() && (
              <Menu.Item style={{paddingLeft:0}} key="ordenes_de_venta" icon={<img src={VentasIconBlanco} width="18px" height="18px" />} onClick={() => history.push("/sale-orders")}>
                <FormattedMessage id="services.sale" />
              </Menu.Item>
            )}
            <Menu.Item key="expedientes" icon={<ToolOutlined />} onClick={() => history.push("/services/list")}>
              <FormattedMessage id="services.service" />
            </Menu.Item>
            <Menu.Item key="electroplan" icon={<DashboardOutlined />} onClick={() => history.push("/electroplan_")}>
              <FormattedMessage id="services.page.electroplan" />
            </Menu.Item>
            {settings && settings.valuationIsActive && tenantSelected !== 'jacmexico' && (
              <Menu.Item key="parts" icon={<BlockOutlined />} onClick={() => history.push("/services/parts")}>
                <FormattedMessage id="servicesDetails.spareParts" />
              </Menu.Item>
            )}
            <Menu.Item key="promos" icon={<TagOutlined />} onClick={() => history.push("/promos")}>
              Promociones
            </Menu.Item>
          </Menu.ItemGroup>

          <Menu.ItemGroup key="administracion" title={!collapsed && "Administración"}>
            <Menu.Item key="customer" icon={<UserOutlined />} onClick={() => history.push("/customers")}>
              Clientes
            </Menu.Item>
            <Menu.Item key="units" icon={<CarOutlined />} onClick={() => history.push("/units")}>
              Unidades
            </Menu.Item>
            <Menu.Item key="members" icon={<TeamOutlined />} onClick={() => history.push("/members_center_users")}>
              <FormattedMessage id="services.members_center" />
            </Menu.Item>
          </Menu.ItemGroup>

          <Menu.ItemGroup key="drive" title={!collapsed && "Mi Drive"}>
            <Menu.Item key="drive" icon={<FolderFilled />} onClick={() => history.push("/drive")}>
              <FormattedMessage id="drive.my_drive" />
            </Menu.Item>
          </Menu.ItemGroup>
        </Menu>

        <div>
          <Button  onClick={toggleCollapsed} style={{ marginBottom: 16, width: '100%',backgroundColor:'transparent', color:'white',borderRadius:'0px' }}>
              {React.createElement(collapsed ? RightOutlined : LeftOutlined)}
          </Button>
        </div>
        {
          !collapsed && (
            <a href={version.url} target="_blank" style={{color:'white',fontSize:10,display:'block',textAlign:'center',padding:10}}>
              v {version.version}
            </a>
          )
        }
      </Sider>
    </>
  )
}

const mapState = (state) => {
  return {
    menu: state.settings.menu,
    settings: state.settings,
    user:state.user,
    security: state.security,
    usernameFirst: state.user.first_name,
    usernameLast: state.user.last_name,
    permissions:state.permissions,
    companySelected: state?.settings?.settingsData?.companySelected,
    branchSelected: state?.settings?.settingsData?.branchSelected,
  }
}

export default injectIntl(connect(mapState, { toggleMenu, changeLangAction })(MainSider))




