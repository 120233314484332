import React, { useState, useEffect } from 'react'
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import useChatList, { descargarCsv } from "../../hooks/useChatList";
import AxiosApi , {API} from "../../lib/utils/axiosApi";
import {useHistory, useParams} from "react-router-dom";
import {Row, Col, Card, Typography, Layout} from 'antd';
import axiosApi from "../../lib/utils/axiosApi";
import ServiceLayout from '../../layouts/ServiceLayout';
import {getServiceDetail} from '../../redux/serviceOrderDuck'
import MenuServiceDetail from "../../components/services/MenuServiceDetail";
import SectionTitle from "../../components/common/elements/SectionTitle/SectionTitle";
import KButton from "../../components/common/elements/KButton/KButton";
import { LeftOutlined } from "@ant-design/icons";
import _ from 'lodash'
import MainLayoutv2 from '../../layouts/MainLayoutv2';
const { Title, Text } = Typography;

const ServiceOrderChatPage = ({user,getServiceDetail,serviceOrder,intl,...props})=>{
    //const [messages, setMessages] = useState(null)
    const { id } = useParams();
    let history = useHistory();
    const [saleOrder, setSaleOrder] = useState(null);
    //const [orderId, setOrderId] = useState('12345667')
    const {messages, ChatList, AreaMessage, DownloadMessagesButton} = useChatList(id,user.id)

    useEffect(()=>{
        window.scrollTo(0,document.body.scrollHeight);
        getServiceDetail(id)
    },[])



    return (
        <MainLayoutv2
            isServiceDetail={true}
            index={'expedientes'}
            title={'Chat con cliente'}>

            <Layout style={{ marginTop: 10 }}>

                <Card
                    className="card_details_service"
                >
                    <MenuServiceDetail menuSelected={5} />
                    <br/>
                    <SectionTitle title={
                      `${ intl.formatMessage({ id: "chat.chatCustomer" })} ${_.has(serviceOrder,'serviceDetail.customer') && `${serviceOrder.serviceDetail.customer.first_name} ${serviceOrder.serviceDetail.customer.last_name}`}`
                    }/>
                    <br/>

                    <ChatList/>
                    <hr/>
                    {
                        serviceOrder.serviceDetail?.status!=='CLOSED' && <AreaMessage/>
                    }

                    {
                        serviceOrder.serviceDetail?.status==='CLOSED' && <DownloadMessagesButton/>
                    }

                </Card>

            </Layout>

        </MainLayoutv2>
    )
}

const mapStateToProps = state => ({
    settings: state.settings,
    user: state.user,
    serviceOrder: state.serviceOrder
})

export default injectIntl(connect(mapStateToProps,{getServiceDetail})(ServiceOrderChatPage))