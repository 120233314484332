import React, { Children } from 'react';
import { Card } from 'antd';
import styles from "./CardPurpleLight.module.css"
import {
    RightOutlined
  } from "@ant-design/icons";
  import { Global, css } from "@emotion/react";


const CardPurpleLight=({title,description,...props})=>{

    const { Meta } = Card;

    return (
      <>

      <Global
        styles={css`
          .card-folio .ant-card-meta-title {
            color: #341eff !important;
            font-weight: 600;
            font-size: 24px;
          }
          .card-folio .ant-card-meta-detail .ant-card-meta-title {
            height: 30px;
          }
          .card-folio .ant-card-meta-description, .ant-card-meta-avatar {
            color: #341eff !important;
          }
          
        `}
      />

       <Card style={{
          paddingTop: 10,
          paddingBottom: 10,
          borderRadius: 10,
          background: "#e6e6ff",
        }} className={styles.card_purple} >

           

                <Meta
                  className={'card-folio'}
                  avatar={<RightOutlined style={{ marginTop: 12 }} />}
                  title={title}
                  description={description}
                />
        </Card>
      </>
       
    )
}


export default CardPurpleLight;