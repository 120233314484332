import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Typography, Select, Radio, Avatar, Space, Row, Col } from 'antd';
import { UserOutlined, RocketOutlined, CloseOutlined, EditOutlined, CheckOutlined } from '@ant-design/icons';
import HeaderSection from "../../../../components/MyComponents";
import { injectIntl, FormattedMessage } from "react-intl";
import SelectAsesor from "../../../../components/Selects/SelectAsesor";
import useSettingsKikert  from '../../../../hooks/useSettingsKikert';
import KButton from "../../../../components/common/elements/KButton/KButton";
import KSelect from "../../../../components/common/elements/KSelect/KSelect";
import {subtypes} from '../../../../lib/utils/utils'
import useNewServiceOrder from '../hooks/useNewServiceOrder';
import '../ReceptionSection.css'; // Asegúrate de crear y ajustar este archivo CSS
const { Title } = Typography;
const { Option } = Select;

const ReceptionSection = ({ form, user, orderID }) => {

  /* const [receptionType, setReceptionType] = useState(insuranceType) */
  const {serviceOrder, 
    withtInsurance, 
    getUserInfo,
    loadingChangeAsesor,
    orderNumberDisabled} = useNewServiceOrder()
  const {settingsKikert, getMetaValue} = useSettingsKikert()
  const HIDE_PRIVATE_INDIVIDUAL_CHECK=getMetaValue('HIDE_PRIVATE_INDIVIDUAL_CHECK', '0');
  const [changeAsesor, setChangeAsesor] = useState(false)
  const [userData, setUserData] = useState(null)

  useEffect(()=>{
    if(user){
      setUserData(user)
    }else{
      setUserData(null)
    }
  }, [user])


  const onChangeAsesor = async ()=>{
    try{
      const assignedTo = form.getFieldValue('assigned_to')
      const response = await getUserInfo(assignedTo);
      if(response.success){
        setUserData(response.data)
        setChangeAsesor(false)
      }
    }catch(error){
      console.log(error)
    }
  }

  useEffect(()=>{
    if(orderID && form){
      form.setFieldsValue('orderNumber', orderID)
    }else if(form){
      setInitialDataNew();
    }
  }, [orderID])

  const setInitialDataNew = () => {
    const initialData = {
      recepteionDate: new Date().toISOString().split('T')[0], // Formato YYYY-MM-DD
      pyramid: '',
      orderType: subtypes[0].value, // Asumiendo que quieres inicializar el tipo de orden
    };
    form.setFieldsValue(initialData);
  };

  return (
    <div className="section">
      <HeaderSection>
            <Title level={4}>
            <RocketOutlined />{" "}
              <FormattedMessage id="services.createNew.reception" />
            </Title>
          </HeaderSection>
          <span style={{fontSize: '12px', color: 'gray', float: 'right'}}>Creado por: {user?.first_name} {user?.last_name}</span>
      <div className="header">
     
        <Space direction="horizontal" size="large" className="advisor-info">
          {
            userData?.userprofile?.image_profile ? 
            <img style={{width: 100, height: 100, borderRadius: '50%'}} 
            onError={(e) => {
              e.target.onerror = null; 
              e.target.src = '/avatarprof.jpg'; 
            }}            
            src={userData?.userprofile?.image_profile}/>
            : <Avatar size={100} icon={<UserOutlined />} className="advisor-avatar" />
          }
          <div>
            <p><strong>Asesor asignado.</strong></p>
            <p>{userData?.first_name} {userData?.last_name}</p>
            <p>{userData?.email}</p>
            <Space direction='vertical'>
              
            {changeAsesor && <SelectAsesor name='assigned_to' allowClear={true} style={{width: '200px'}} />}
            <Space>
                <KButton type="secondary" 
                size='small'
                text={changeAsesor ? 'Cancelar' : 'Cambiar asesor ...'} 
                icon={changeAsesor ? <CloseOutlined /> : <EditOutlined />}
                onClick={()=>setChangeAsesor(!changeAsesor)}/>
                {
                  changeAsesor && (
                    <KButton type="primary" 
                    text={'Aceptar'} 
                    size='small'
                    loading={loadingChangeAsesor}
                    icon={<CheckOutlined />}
                    onClick={()=>onChangeAsesor()}/>
                  )
                }
              </Space>
            </Space>
            
          </div>
        </Space>
      </div>
      
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={8}>
            <Form.Item 
              name="recepteionDate"
              rules={[{ required: true, message: 'Seleccione una fecha' }]}
              label="Fecha de recepción">
              <Input className='kinput' type='date' />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Form.Item label="Tipo de orden">
              <KSelect
                allowClear={true}
                showSearch
                defaultValue={subtypes[0].value}
                placeholder="Selecciona un tipo de orden"
                options={
                    subtypes && subtypes.map((o)=>{
                        return {
                            value:o.value,
                            label:o.label
                        }
                    })
                }
            />

            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Form.Item label="Número de orden"> 
            <Input size="large" disabled={orderNumberDisabled} placeholder="Generado al guardar" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Form.Item 
              name='pyramid'
              label="Pirámide">
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Form.Item 
              name='insuranceType'
              label="Tipo">
              <Radio.Group defaultValue="P">
                <Radio.Button value="P"> Particular</Radio.Button>
                {
                  withtInsurance && <Radio.Button value="I"> Aseguradora</Radio.Button>
                }
                
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
    </div>
  );
};

export default ReceptionSection;