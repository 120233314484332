import React, { useEffect, useState } from "react";
import {
    Select,
    Form,
    message
} from "antd";
import KSelect from "../common/elements/KSelect/KSelect";
import axiosApi, {API} from "../../lib/utils/axiosApi";
import {subtypes} from "../../lib/utils/utils";
const { Option } = Select;

const SelectVehicleType=({name='type',allowClear=true,label=null, ...props})=>{
    const [vehicleTypes,setVehicleTypes]=useState([])

    useEffect(()=>{
        getVehicleTypes()
    },[])


    const getVehicleTypes=async()=>{
        try{
            const response=await axiosApi.get(`${API.WORKSHOP.WORKSHOP_CAR_TYPES}`)
            setVehicleTypes(response.data?.results)
        }catch(error){
            console.log(error)
            setVehicleTypes([])
            message.error({
                message: 'Error al obtener los tipos de vehiculos',
                description: 'Por favor, intente nuevamente'
            })
        }
    }

    return (
        <Form.Item
            label={label ? label : 'Tipo de vehiculo'}
            name={name}
            {...props}
            className="item-expediente-lg label-margin"
        >
            <KSelect
                allowClear={allowClear}
                options={
                  vehicleTypes.length>0 && vehicleTypes.map((type)=>{
                        return {
                            label:type.name,
                            value:type.id
                        }
                    })
                }
            />
        </Form.Item>
    )
}


export default SelectVehicleType
