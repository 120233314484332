import React from 'react'
import styles from '../login.module.css'
import SecurityLayout from "../../../layouts/SecurityLayout";
import { FormattedMessage } from "react-intl";
import { connect } from 'react-redux'
import { Button, message } from 'antd';
import { changeLangAction } from '../../../redux/settingsDuck'
import { getResetAction } from '../../../redux/securitryDuck'
import { Form, Input, Radio, Checkbox, Typography } from 'antd';
import { injectIntl } from "react-intl";
import { useHistory } from "react-router-dom"
import { ItemForm } from "../../../components/MyComponents";
import { RightOutlined } from "@ant-design/icons";



const ResetPaswordPage = ({ changeLangAction, getResetAction, settings, security, intl }) => {
  const [form] = Form.useForm();

  const history = useHistory()
  const { Text, Title } = Typography;

  const onFinish = async (values) => {
    // Obtenemos el input de username
    const username = values.username;
    // Armamos el json
    let data = {};
    data.email = username;
    // Usamos redux
    await getResetAction(data)
  }
  const style = {
    Dash: {
      border: 'none',
      borderTop: '1px dashed #341EFF',
      marginLeft: 5,
      color: '#341EFF',
      backgroundColor: '#341EFF',
      height: 5,
      width: '7%',
    },
  }

  if (security.isEmailSent) {
    //message.success('Se ha enviado correo de recuperacion');
    message.success(intl.formatMessage({ id: "security.forgot.messageDone" }));
  }

  return (
    <SecurityLayout className={styles.container}>
      {/* <div style={style.Dash}></div> */}
      <Title level={2} className="font-white">
        <FormattedMessage id="security.forgot.title" />
      </Title>
      <Text className="font-white">
        <FormattedMessage id="security.forgot.instruction" />
      </Text>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        style={{ marginTop: 20 }}
      >
        <ItemForm
          required
          tooltip="This is a required field"
          name="username"
          rules={[
            {
              required: true,
              message: <FormattedMessage id="security.login.messageUser" />,
            },
          ]}
        >
          <Input
            size="large"
            placeholder={intl.formatMessage({ id: "security.login.email" })}
          />
        </ItemForm>
        <Form.Item>
          <Button
            loading={false}
            size={"large"}
            block
            className={"btn-string-blue btn-login"}
            type="primary"
            htmlType="submit"
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {/* <FormattedMessage id="security.login.signin" /> */}
              <FormattedMessage id="security.forgot.send" />
              <RightOutlined
                style={{ marginTop: "auto", marginBottom: "auto" }}
              />
            </div>
          </Button>
        </Form.Item>
      </Form>
      <Button
        size={"large"}
        block
        type={"link"}
        className="font-white"
        style={{ textAlign: "left" }}
        onClick={changeLangAction}
      >
        <FormattedMessage id="changelang" />
      </Button>
      <Button
        size={"large"}
        type={"link"}
        block
        className="font-white"
        style={{ textAlign: "left" }}
        onClick={() => history.push("/login")}
      >
        {/* <FormattedMessage id="security.forgot.login" /> */}
        <FormattedMessage id="security.forgot.back" />
      </Button>
    </SecurityLayout>
  );
}

function mapState(state) {
  return {
    settings: state.settings,
    security: state.security
  }
}

export default injectIntl(connect(mapState, {
  changeLangAction,
  getResetAction,
})(ResetPaswordPage))
