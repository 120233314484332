import React, { useEffect } from "react";
import {
    Modal,
    Select,
    Form,
    Typography,
    Button,
    Row,
    Col,
    Space,
    Divider,
    Input,
    DatePicker,
    Checkbox
} from "antd";
import { connect } from 'react-redux'
import { injectIntl, FormattedMessage } from "react-intl";
import KSelect from "../common/elements/KSelect/KSelect";
import _ from "lodash";
import {getUsersAdvisors} from "../../redux/membersDuck";
import {subtypes} from "../../lib/utils/utils";
const { Option } = Select;

const SelectSubtypes=({name='order_subtype',allowClear=true,label=null, ...props})=>{

    useEffect(()=>{
        getUsersAdvisors()
    },[])


    return (
        <Form.Item
            label={label ? label : 'Tipos'}
            name={name}
            className="item-expediente-lg mb-0 label-margin"
        >
            <KSelect
                allowClear={allowClear}
                options={
                    subtypes
                }
            />
        </Form.Item>
    )
}


export default SelectSubtypes
