import React from 'react';
import { Form, Input } from 'antd';

const ObservationsSection = () => {
  return (
    <div className="section">
      <h2>Observaciones</h2>
      <Form layout="vertical">
        <Form.Item>
          <Input.TextArea value="Entra para mantenimiento general" readOnly />
        </Form.Item>
      </Form>
    </div>
  );
};

export default ObservationsSection;