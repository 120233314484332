import axiosApi , {API} from "../lib/utils/axiosApi";

class WebApi {
    static call= (url, method = "post", params = {}) => {
        switch (method) {
            case "post":
                return axiosApi.post(url, params);
                break;
            case "put":
                return axiosApi.put(url, params);
                break;
            case "get":
                return axiosApi.get(url, params);
                break;
            case "delete":
                return axiosApi.delete(url);
            case "patch":
                return axiosApi.patch(url, params);
                break;
        }
    };

    static URLS = API;
}

export default WebApi;
