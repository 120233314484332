import React, { useEffect } from "react";
import {
    Modal,
    Select,
    Form,
    Typography,
    Button,
    Row,
    Col,
    Space,
    Divider,
    Input,
    DatePicker,
    Checkbox
} from "antd";
import { connect } from 'react-redux'
import { injectIntl, FormattedMessage } from "react-intl";
import KSelect from "../common/elements/KSelect/KSelect";
import _ from "lodash";
import {getWorkFlowSteps} from "../../redux/workflowDuck";
const { Option } = Select;

const SelectStepsWorkshop=({name='step',steps,getWorkFlowSteps, label=null, ...props})=>{

    useEffect(()=>{
        getWorkFlowSteps()
    },[])


    return (
        <Form.Item
            label={label ? label : <FormattedMessage id="services.page.advisor" />}
            name={name}
            className="item-expediente-lg mb-0 label-margin"
        >
            <KSelect
                {...props}
                options={
                    steps && _.map(steps, (o)=>{
                        return {
                            value:o.id,
                            label:`${o.name}`
                        }
                    })
                }
            />
        </Form.Item>
    )
}

const mapStateToProps = (state) => ({
    steps: state.workflow?.workflowSteps
});

export default injectIntl(
    connect(mapStateToProps, { getWorkFlowSteps })(SelectStepsWorkshop)
);
