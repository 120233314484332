import { Button, Col, Modal, Row, Typography } from 'antd'
import React from 'react'
import { FormattedMessage, injectIntl } from "react-intl";
import { RightOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import {closeModalMembers} from '../../redux/membersDuck';
import MembersList from './MembersList';
import styled from "styled-components";

import LeftArrowCircle from '../../image/arrow-back-circle-outline.svg';
import CloseIcon from '../../image/close-outline.svg';
import Arrow from '../../image/flecha_abajo_azul.svg';

const ModalMembers = ({ isVisible, members , profileName = "", membersState, closeModalMembers, ...props}) => {

    const {Title, Text} = Typography
    const {show_modal_members, current} = membersState;

    const CustomModal = styled(Modal)`
        .ant-modal-content{
            border-radius: 20px;
        }
    `
    


  return (
    <CustomModal  width={600} title={null} visible={show_modal_members} footer={null} onCancel={() => closeModalMembers()} closeIcon={<img src={CloseIcon} style={{width:15}} />}>
        <Row justify="space-between">
            <Col>
                <Title level={4}>
                    <span style={{ marginRight: 10, cursor:'pointer' }} onClick={() => closeModalMembers()}>
                        <img src={LeftArrowCircle} style={{width:25}} />
                    </span>
                    <FormattedMessage id="members.profile" /> 
                    <img src={Arrow} alt="" style={{widht:12, height:6, marginLeft:5, marginRight:5}} className="rotate_45" />
                     {current?.name}
                </Title>
            </Col>
        </Row>
        <MembersList members={current?.users} />
    </CustomModal>
  )
}

const mapState = (state) => ({
  membersState: state.members,
});

export default connect(mapState, {closeModalMembers})(ModalMembers)