import React, { useState, useEffect } from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import { FormattedMessage } from "react-intl";
import { connect } from 'react-redux'
import { Avatar, Button, Card, Col, Divider, Form, Input, Row, Space, Spin, Typography } from "antd";
import { CloudUploadOutlined, UserOutlined } from '@ant-design/icons';
import { Global, css } from "@emotion/react";
import {ItemForm} from '../../components/MyComponents';
import { useHistory } from "react-router-dom"
import axiosApi, {API} from '../../lib/utils/axiosApi'



const Profile = ({user, ...props}) => {
    const { Title } = Typography
    const history = useHistory()
    const [currentUser, setCurrentUser] = useState(null)
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      getInfoUser();
    }, []);

    const getInfoUser = async () => {
        try {
            setLoading(true)
            const response = await axiosApi.get(
              API.SECURITY.USER_PROFILE + user.id
            );
            if (response.status === 200){
                setCurrentUser(response.data)
                setLoading(false);
                form.setFieldsValue({
                  name: response.data.first_name,
                  mlast_name: response.data.last_name,
                  email: response.data.email,
                  phone: response.data.userprofile?.phone_number,
                });
            }    
        } catch (error) {
            console.log('error', error)
        }
    
    }    

    

  return (
    <>
      <Global
        styles={css`
          .no-padding .ant-card-body {
            padding: 0px;
          }
          .card-form {
            padding-top: 20px;
            padding-bottom: 20px;
          }
          .no-margin-bottom {
            margin-bottom: 0px !important;
          }
          .col-item-bottom {
            display: flex;
          }
          .col-item-bottom > .ant-form-item {
            margin-top: auto;
            width: 100%;
          }
          .ant-form-item-label {
            font-weight: 100;
          }
        `}
      />
      <DashboardLayout title={<FormattedMessage id="security.profile.title" />}>
        <Card className="no-padding card-form">
          <Row>
            <Col
              xs={24}
              md={5}
              style={{ textAlign: "center", borderRight: "solid 2px #cccccc" }}
            >
              <Title level={5}>Información básica</Title>
              <Avatar
                size={120}
                icon={<UserOutlined />}
                src={
                  currentUser &&
                  currentUser['userprofile']['image_profile']
                    ? currentUser['userprofile']['image_profile']
                    : null
                }
              />
              <br />
              <Button type="link" style={{ marginTop: 15 }}>
                <CloudUploadOutlined />
                Reemplazar
              </Button>
            </Col>
            <Col md={15}>
              {loading ? (
                <Spin />
              ) : (
                <Form layout="vertical" form={form}>
                  <Row gutter={20} justify="center">
                    <Col md={10}>
                      <ItemForm label="Nombre" name={"name"}>
                        <Input />
                      </ItemForm>
                    </Col>
                    <Col md={10}>
                      <ItemForm label="Apellido paterno" name={"mlast_name"}>
                        <Input />
                      </ItemForm>
                    </Col>
                    <Col md={10}>
                      <ItemForm label="Apellido materno" name={"flast_name"}>
                        <Input />
                      </ItemForm>
                    </Col>
                    <Col md={10}>
                      <ItemForm label="Email" name={"email"}>
                        <Input />
                      </ItemForm>
                    </Col>
                    <Col md={10}>
                      <ItemForm label="Telefono" name={"phone"}>
                        <Input />
                      </ItemForm>
                    </Col>
                    <Col md={10}>
                      <ItemForm label="Dirección" name={"address"}>
                        <Input />
                      </ItemForm>
                    </Col>
                    {/* <Col md={10} className="col-item-bottom">
                                        <ItemForm >
                                            <Input />
                                        </ItemForm>
                                    </Col>
                                    <Col md={10}>
                                        <ItemForm label="Cambiar contraseña" className="no-margin-bottom">
                                            <Input />
                                        </ItemForm>
                                    </Col>
                                    <Col md={10} className="col-item-bottom">
                                        <ItemForm label="" className="no-margin-bottom item-bottom">
                                            <Input />
                                        </ItemForm>
                                    </Col> */}
                  </Row>
                </Form>
              )}
            </Col>
          </Row>
          <Divider
            style={{ margin: "35px 25px", borderBottom: "solid 1px #2D2CF5" }}
          />
          <Row justify="center">
            <Col>
              <Space size={10}>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="btn-string-blue btn-report-action"
                  size="large"
                >
                  <FormattedMessage id="services.filter.save" />
                </Button>
                <Button
                  type="default"
                  htmlType="button"
                  onClick={() => history.goBack()}
                  className="btn-blue btn-report-action"
                  size="large"
                >
                  <FormattedMessage id="layout.cancel" />
                </Button>
              </Space>
            </Col>
          </Row>
        </Card>
      </DashboardLayout>
    </>
  ); 
};

const mapState = (state) => ({
  user: state.user,
});

export default connect(mapState)(Profile);
