import React, { useEffect, useState, useContext } from "react";
import { Row, Col, Layout, Button, Menu, Breadcrumb, Spin } from 'antd';
import icon from '../image/black_icon_kikert.svg';
import logo from '../image/logo_kikert.svg';
import imgcompany from '../image/login_kikert01.png';

import { Global, css } from "@emotion/react";


const { Content, Footer } = Layout;

const style = {
    contenidoInicial: {
        margin: '0 auto',
        width: '70%'
    },
    contenidoInicialTitle: {
        paddingTop: 10,
        color: 'white',
        fontSize: 52,
        fontWeight: 480,
        textAlign: 'left',
        fontFamily: 'Sharp Grotesk, Book 20'
    },
    textWhite: {
        color: 'white',
        fontFamily: 'Sharp Grotesk',
        fontSize: 18,
        fontWeight: 200
    },
    logo: {
        width: 200,
        marginTop: 50,
        marginBottom: 20
    },
    mainContent: {
        paddingTop: 120
    },
    divCenterButton: {
        paddingTop: 30,
        paddingLeft: 40,
    },
    centerButton: {
        color: 'white',
        padding: '12px 40px',
        backgroundColor: '#090731',
        borderColor: '#090731',
        borderRadius: 18,
        fontSize: 14,
    },
    footerContent: {
        margin: '0 auto',
        width: '75%',
        fontSize: 19,
        fontFamily: 'Sharp Grotesk',
        textAlign: 'left',
        color: 'white',
        verticalAlign: 'end',
        paddingLeft: 100,
        paddingTop: 50,
        marginTop: 50,
    },
    layoutContent: {
        padding: '0 50px',
        height: '100vh',
    },
    layoutDiv: {

    },
    layoutCol: {

    },
    footer: {
        textAlign: 'center',
    }
}


const BussinessCompanyLayout = ({ loading = false, ...props }) => {
    return (
        <>
            <Global
                styles={css`
            * {
              font-family: "Sharp Grotesk" !important;
              letter-spacing: 0.2px;
            }
            .bannerLogin2 {
              background-image: url("./login_kikert01.png");
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
            }
            .ant-input-lg {
              padding: 25px 30px;
            }
            .btn-login{
                height: 50px;
                border:none;
            }
          `}
            />
            <Layout className="layoutbackgroundbussiness">
                <Content
                    style={{ background: "#FFFFFF 0% 0% no-repeat padding-box" }}
                >
                    <Row justify={"center"} style={{ minHeight: "100vh" }}>
                        <Col sm={0} md={12} className="bannerLogin2" />
                        <Col xs={24} lg={12} md={12} >
                            <Spin spinning={loading}>
                                <div style={style.mainContent}>
                                <img src={icon} alt="icon" style={{ position: "absolute", right: 20, top: 20, width:40 }} />
                                    {props.children}
                                </div>

                            </Spin>
                        </Col>

                    </Row>
                </Content>
            </Layout>
        </>
    )
}

export default BussinessCompanyLayout;