import React, {Component} from 'react';
import {Card, Space, Typography, Spin} from "antd";
import styles from './CardStatistics.module.css'
import {FieldTimeOutlined} from "@ant-design/icons";
import KButton from "../common/elements/KButton/KButton";
const { Title } = Typography;

const CardStatistics=({type='num',value='0',title='',fontSize=100,textDetail=null, icon=null, iconRight=null,loading=false,body=null, ...props})=>{
        return (
            <Spin spinning={loading}>
                <Card className={styles.cardStatistic} {...props} title={title}
                    // extra={<a href="#">More</a>}
                >

                    {
                       type==='num' && <div style={{textAlign:'center'}}>
                            <Space align={'start'}>
                                {
                                    icon ? icon : null
                                }

                                <Title style={{fontSize:fontSize,marginBottom:0,paddingBottom:0}} className={'text-primary'}>{value?value:'0'}</Title>

                                    {
                                            iconRight ? iconRight : null
                                    }
                            </Space>
                        </div>
                    }


                    {
                        textDetail &&   <div style={{textAlign:'right'}}>
                            <KButton type={'outline'} size={'normal'} style={{marginBottom:10,display:'block',width:'100%'}} text={textDetail?textDetail:'Detalle'} onClick={()=>{
                                props.onDetail()
                            }}/>
                        </div>
                    }

                    {
                        body && body

                    }



                </Card>
            </Spin>

        );
}

export default CardStatistics;