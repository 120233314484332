import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Home from './components/home/HomePage'
import FavPage from './components/favs/FavPage'
import LoginPage from './pages/security/login/LoginPage'
import LoginPageV2 from './pages/security/login/LoginPagev2'
import ResetPaswordPage from './pages/security/reset/ResetPaswordPage'
import ConfirmPasswordPage from './pages/security/confirm/ConfirmPasswordPage'
import CompanyPage from './pages/bussiness/company/CompanyPage'
import BranchPage from './pages/bussiness/branch/BranchPage'
import DashboardPage from './pages/dashboard/DashboardPage'
import AnalyticPage from './pages/dashboard/AnalyticPage'
import MembershipPage from './pages/membership/MembershipPage'
import UserPage from './pages/dashboard/UserPage'
import ServiceOrderPage from './pages/dashboard/serviceorder/ServiceOrderPage'
import NewServiceOrderPage from './pages/dashboard/serviceorder/NewServiceOrderPage'
import TrackingServiceOrderPage from './pages/dashboard/serviceorder/TrackingServiceOrderPage'
import InsurancePage from "./pages/config/InsurancePage";
import ConfigPage from "./pages/config/ConfigPage";
import BrandsPage from "./pages/config/BrandsPage";
import PartsPage from "./pages/config/PartsPage";
import ModelsPage from "./pages/config/ModelsPage";
import VehicleColor from './pages/config/VehicleColor';
import Profile from "./pages/security/Profile";
import PageNotFound from './pages/MensajesAplicacion/404/PageNotFound'
import ServicePartsPage from './pages/services/ServicePartsPage'
import ServiceOrderChatPage from "./pages/services/ServiceOrderChatPage";
import MainListDrivePage from "./pages/Drive/MainListDrivePage";
/* Members Center */
import MembersCenter from "./pages/members/List";
import UsersList from "./pages/members/Users";
import UserAddUpd from "./pages/members/UserAddUpd";
import UpdProfile from "./pages/members/UpdProfile";


// Services CRUD
import ServicePage from "./pages/services/ServicePage";
import ServiceValuation from './pages/services/ServiceValuation'
import ServiceCreatePage from "./pages/services/ServiceCreatePage";
import Electroplan from "./pages/dashboard/serviceorder/Electroplan";
import Electroplan_ from "./pages/dashboard/serviceorder/Electroplan_";
import ElectroplanLight from "./pages/dashboard/serviceorder/ElectroplanLight";
import ServiceUpdatePage from "./pages/services/ServiceUpdatePage"
import ServiceDetail from "./pages/services/detail/ServiceDetail";
import ServiceRepair from "./pages/services/detail/ServiceRepair";
import ServiceMessagesPage from "./pages/services/ServiceMessagesPage";
import VehicleType from './pages/config/VehicleType'
import ChatPage from "./pages/chat/ChatPage";
import ServicesFilesPage from "./pages/services/ServicesFilesPage";
import ServicesFilesPage_ from "./pages/services/ServicesFilesPage_";
import DemoComponents from "./pages/DemoComponents";
import ServiceClosePage from "./pages/services/ServiceClosePage";
import WorkshopEntrancePage from "./pages/services/WorkshopEntrancePage";
import UnitsPage from "./pages/bussiness/units/UnitsPage";
import UnitDetailPage from "./pages/bussiness/units/UnitDetailPage";
import ServiceInvoicing from "./pages/services/ServiceInvoicing";
import WorkshopPartsPage from "./pages/services/WorkshopPartsPage";
import useSettingsKikert from "./hooks/useSettingsKikert";
import Suppliers from './pages/config/Suppliers'
import MetasPage from './pages/config/MetasPage';
import WarehousePage from './pages/config/WarehousePage';
import {permissionsExist} from "./lib/utils/utils";
import {useSelector} from "react-redux";
import JacDashboard from './pages/JacDashboard'
import DashboardKPIPage from "./pages/dashboard/DashboardKPIPage";
import PromoListPage from "./pages/promos/PromoListPage";
import WorkshopEntrancePageJAC from "./pages/services/WorkshopEntrancePageJAC";
import SurveysPage from './pages/surveys/SurveysPage'
import SurveyDetail from './pages/surveys/SurveyDetail'
import DashboardJacPage from './pages/jac/DashboardJacPage'
import Customers from './pages/customers'
import CreateServicePage from './pages/services/createServicePage/CreateServicePage'
import ActivitiesPage from './pages/services/ActivitiesPage'

import ServiceDetailPublic from './pages/services/detail/ServiceDetailPublic'
// funciona como middleware nos sirve para mantenerla sesin o bloquear las rutas
const PrivateRoute = ({ path, component, ...rest }) => {
  console.log('Private route')
  let storage = localStorage.getItem('storage')

  storage = JSON.parse(storage)
  //return <Route path={path} component={component} />
  if (storage && storage.user) {
    //const pong = await pingPong()
    //console.log('Ping pong', pong)
    return <Route path={path} component={component} />
  } else {
    return <Redirect to={'/security/login'} {...rest} />
  }
}

export const PrivateRoutes = () => {
  const {settingsKikert, getMetaValue} = useSettingsKikert()
    const permissions = useSelector(state => state.permissions);
  return (
    <Switch>
      <PrivateRoute exact path="/" component={DashboardPage} />
      <PrivateRoute exact path="/dashboard-jac" component={JacDashboard} />
      <PrivateRoute exact path="/dashboard-kpi" component={AnalyticPage} />
      <PrivateRoute exact path="/analytics" component={DashboardKPIPage} />
      <PrivateRoute exact path="/config/models" component={ModelsPage} />
      <PrivateRoute exact path="/config/insurance" component={InsurancePage} />
      <PrivateRoute exact path="/config/warehouse" component={WarehousePage} />
      <PrivateRoute exact path="/config/brands" component={BrandsPage} />
      <PrivateRoute exact path="/config/parts" component={PartsPage} />
      <PrivateRoute exact path="/config/colour" component={VehicleColor} />
      <PrivateRoute exact path="/config/types" component={VehicleType} />
      <PrivateRoute exact path="/config/suppliers" component={Suppliers} />
      <PrivateRoute exact path="/config/meetas" component={MetasPage} />
      <PrivateRoute exact path="/config" component={ConfigPage} />
        <PrivateRoute exact path="/services/parts" component={ServicePartsPage} />
      <PrivateRoute path="/favs" component={FavPage} />
      <Route path="/surveys" component={SurveysPage} />
      <Route path="/survey/:id/:orderId" component={SurveyDetail} />
      <PrivateRoute exact path="/profile" component={Profile} />
      <PrivateRoute exact path="/customers" component={Customers} />
      {/* Enlace a los expedientes */}
      <PrivateRoute path="/sale-orders/new" component={NewServiceOrderPage} />
      <PrivateRoute
        path="/sale-orders/tracking/:id"
        component={TrackingServiceOrderPage}
      />
      <PrivateRoute path="/sale-orders" component={ServiceOrderPage} />

      <PrivateRoute path="/electroplan" component={Electroplan} />
      <PrivateRoute path="/Electroplan_" component={Electroplan_} />
      <PrivateRoute path="/electroplan-light" component={ElectroplanLight} />

      <PrivateRoute path="/members_center_profiles" component={MembersCenter} />
      <PrivateRoute path="/members_center_users" component={UsersList} />
      <PrivateRoute path="/members_center_users_add/" component={UserAddUpd} />
      <PrivateRoute
        path="/members_center_users_edit/:id"
        component={UserAddUpd}
      />
      <PrivateRoute path="/members-center/edit/:id" component={UpdProfile} />

      {/* Enlace a los usuarios. */}
      <PrivateRoute path="/user" component={UserPage} />
      <Route path="/components" component={DemoComponents} />
      {/* Enlace a los talleres */}
      <PrivateRoute path="/branch" component={BranchPage} />
      <PrivateRoute path="/company" component={CompanyPage} />
      <PrivateRoute path="/units" component={UnitsPage} />
      <PrivateRoute path="/unit/:id" component={UnitDetailPage} />
      <PrivateRoute path="/services/list" component={ServicePage} />
      <PrivateRoute path="/services/new" component={ServiceCreatePage} />
      <PrivateRoute path="/services/create" component={CreateServicePage} />
      <PrivateRoute path="/service-detail/:id" component={ServiceDetail} />
      <PrivateRoute path="/service-parts/:id" component={WorkshopPartsPage} />
        {
            getMetaValue('INVOICE_IS_ACTIVE')==='1' &&  <PrivateRoute path="/service-invoicing/:id" component={ServiceInvoicing} />
        }

        {/*Membresias**/}

        {
            permissionsExist(permissions.permissionsList,"view_paymentorder") && <PrivateRoute path="/membership" component={MembershipPage} />
        }


      <PrivateRoute path="/service-repair/:id" component={ServiceRepair} />

      {
          permissionsExist(permissions.permissionsList, "view_valuation") && (
            <PrivateRoute
              path="/service-valuation/:id"
              component={ServiceValuation}
            />
          )
      }
      


      <PrivateRoute
        path="/service-messages/:id"
        component={ServiceMessagesPage}
      />
      <PrivateRoute path="/service-files/:id" component={ServicesFilesPage_} />
      <PrivateRoute path="/activities/:id" component={ActivitiesPage} />
      <PrivateRoute path="/services/:id" component={ServiceCreatePage} />
      <PrivateRoute path="/service/close/:id" component={ServiceClosePage} />
      <PrivateRoute
        path="/workshop-entrance/:id"
        component={WorkshopEntrancePage}
      />

        <PrivateRoute
            path="/workshop-entrance-jac/:id"
            component={WorkshopEntrancePageJAC}
        />

        <PrivateRoute
            path="/dashboard-jac-page"
            component={DashboardJacPage}
        />

      <PrivateRoute
        path="/services-files/:id/:nameFolder?/:idFolder?"
        component={ServicesFilesPage}
      />
        <PrivateRoute
            path="/drive"
            component={MainListDrivePage}
        />
      <PrivateRoute path="/service-chat/:id" component={ChatPage} />
      {
        getMetaValue('CHAT_SECTION_IS_ACTIVE','1') === '1' && (
          <PrivateRoute path="/order-chat/:id" component={ServiceOrderChatPage} />
        )
      }


        <PrivateRoute path="/promos" component={PromoListPage} />

      <PrivateRoute path="*" component={PageNotFound} />

    </Switch>
  );
}

export const PublicRoutes = () => {
  return (
    <Switch>
      <Route path="/security/login" component={LoginPageV2} />
      <Route path="/security/login-v2" component={LoginPageV2} />
      <Route path="/security/forgot" component={ResetPaswordPage} />
      <Route path="/security/reset/:uid/:token" component={ConfirmPasswordPage} />
    </Switch>
  )
}

export const PublicRoutesClient = () => {
  return (
    <Switch>
      <Route path="/client/public/service-detail/:id" component={ServiceDetailPublic} />
    </Switch>
  )
}
