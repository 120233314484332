import React from 'react';
import { Form, Input, Select, Checkbox, DatePicker } from 'antd';

const { Option } = Select;

const InsuranceSection = () => {
  return (
    <div className="section">
      <h2>Aseguradora</h2>
      <Form layout="vertical">
        <Form.Item label="No. Siniestro">
          <Input value="98909080" readOnly />
        </Form.Item>
        <Form.Item label="Aseguradora">
          <Select defaultValue="Axxa">
            <Option value="Axxa">Axxa</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Ajustador">
          <Input value="Luis Valentino" readOnly />
        </Form.Item>
        <Form.Item label="No. Póliza">
          <Input />
        </Form.Item>
        <Form.Item label="Fecha vencimiento">
          <DatePicker />
        </Form.Item>
        <Form.Item>
          <Checkbox checked readOnly>Aplica deducible</Checkbox>
        </Form.Item>
      </Form>
    </div>
  );
};

export default InsuranceSection;