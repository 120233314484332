import {axiosApi, conifg, API} from "../lib/utils/axiosApi"

// Datos iniciales
const initialData = {
    fetching: false,
    usersProfiles: null,
    userProfilesList:[],
}

const GET_USERS_PROFILE = "GET_USERS_PROFILE"
const GET_USERS_PROFILE_SUCCESS = "GET_USERS_PROFILE_SUCCESS"
const GET_USERS_PROFILE_ERROR = "GET_USERS_PROFILE_ERROR"

const SAVE_USERS_PROFILE = "SAVE_USERS_PROFILE"
const SAVE_USERS_PROFILE_SUCCESS = "SAVE_USERS_PROFILE_SUCCESS"
const SAVE_USERS_PROFILE_ERROR = "SAVE_USERS_PROFILE_ERROR"


export default function reducer(state=initialData, action) {
    switch (action.type) {
        case GET_USERS_PROFILE:
            return { ...state, fetching: true};
        case GET_USERS_PROFILE_SUCCESS:
            return { ...state, fetching: false, ...action.payload }
        case GET_USERS_PROFILE_ERROR:
            return { ...state, fetching: false, userProfiles: [] }
        case SAVE_USERS_PROFILE:
            return {...state, fetching: true}
        case SAVE_USERS_PROFILE_SUCCESS:
            return {...state, fetching: false }
        case SAVE_USERS_PROFILE_ERROR:
            return {...state, fetching: false, brands: []}
        default:
            return state
    }
}



export let postUserProfileAction = (data) => async ( dispatch, getState ) => {
    dispatch({
        type: SAVE_USERS_PROFILE
    })

    let request = {
        ...data
    }

    await axiosApi.post(API.USER.USER_PROFILE, request)
    .then((response) => {
        const profile = response.data;
        dispatch({
            type: SAVE_USERS_PROFILE_SUCCESS
        })
        return profile;
    })
    .catch((error) => {
        dispatch({
            type: SAVE_USERS_PROFILE_ERROR
        })
        return null;
    })
}

export let getUserProfiles = () => async (dispatch, getState) => {
    dispatch({
      type: GET_USERS_PROFILE,
    });
    await axiosApi.get(API.USER.USER_PROFILE)
    .then((response) => {
        const profiles = response.data.results;
        console.log("profiles =>", profiles);
        dispatch({
          type: GET_USERS_PROFILE_SUCCESS,
          payload: { userProfilesList: profiles },
        });
        //return profile;
    })
    .catch((error) => {
        dispatch({
          type: GET_USERS_PROFILE_ERROR,
        });
        return null;
    })
}