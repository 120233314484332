import { async } from '@firebase/util';
import { message } from 'antd';
import { axiosApi, API } from '../lib/utils/axiosApi';

// initial state
let initialData = {
  fetching: false,
  fetching_history: false,
  updating_status: false,
  workflow: [],
  workflowResponse: null,
  workflow_logs: [],
  all_steps: [],
  fetchingUsers:false,
  usersWorkflow:[],
  error:'',
  workflowSteps:[]
};

const GET_WORKFLOW = "GET_WORKFLOW";
const GET_WORKFLOW_SUCCESS = "GET_WORKFLOW_SUCCESS";
const GET_WORKFLOW_ERROR = "GET_WORKFLOW_ERROR";
const GET_WORKFLOW_LOG = "GET_WORKFLOW_LOG";
const GET_WORKFLOW_LOG_SUCCESS = "GET_WORKFLOW_LOG_SUCCESS";
const GET_WORKFLOW_LOG_ERROR = "GET_WORKFLOW_LOG_ERROR";
const SET_ALL_WORKFLOW = "SET_ALL_WORKFLOW";
const CHANGE_VISIBLE = "CHANGE_VISIBLE";
const UPDATING = "UPDATING";
const UPDATING_SUCCESS = "UPDATING_SUCCESS";
const UPDATING_ERROR = "UPDATING_ERROR";
const GET_USERS_WORKFLOW = "GET_USERS_WORKFLOW";
const GET_USERS_WORKFLOW_SUCCESS = "GET_USERS_WORKFLOW_SUCCESS";
const GET_USERS_WORKFLOW_ERROR = "GET_USERS_WORKFLOW_ERROR";
const ASSIGN_USER_TO_STEP = "ASSIGN_USER_TO_STEP";
const ASSIGN_USER_TO_STEP_ERROR = "ASSIGN_USER_TO_STEP_ERROR";
const ASSIGN_USER_TO_STEP_SUCCESS = "ASSIGN_USER_TO_STEP_SUCCESS";
const FETCHING = "FETCHING";
const GET_WORKFLOW_STEPS = "GET_WORKFLOW_STEPS";

// Reducer
export default function reducer(state=initialData, action) {
    switch (action.type) {
      case GET_WORKFLOW:
        return { ...state, fetching: true };
      case GET_WORKFLOW_LOG:
        return { ...state, fetching_history: true };
      case GET_WORKFLOW_SUCCESS:
        return { ...state, fetching: false, ...action.payload };
      case GET_WORKFLOW_STEPS:
        return { ...state, fetching: false, workflowSteps:action.payload };
      case GET_WORKFLOW_ERROR:
        return {
          ...state,
          fetching: false,
          workflow: [],
          workflowResponse: null,
        };
      case GET_WORKFLOW_LOG_SUCCESS:
        return { ...state, ...action.payload };
      case SET_ALL_WORKFLOW:
        return { ...state, all_steps: action.payload };
      case UPDATING: 
        return { ...state, updating_status: action.payload }
      case UPDATING_SUCCESS: 
        return { ...state, updating_status: false };
      case GET_USERS_WORKFLOW:
        return { ...state, fetchingUsers: true };
      case GET_USERS_WORKFLOW_ERROR:
        return { ...state, fetchingUsers: false, error:action.payload};
      case GET_USERS_WORKFLOW_SUCCESS:
        return { ...state, fetchingUsers: false, usersWorkflow:action.payload};
      case ASSIGN_USER_TO_STEP:
        return { ...state, fetching: true };
      case ASSIGN_USER_TO_STEP_ERROR:
        return { ...state, fetching: false, error:action.payload};
      case ASSIGN_USER_TO_STEP_SUCCESS:
        return { ...state, fetching: false};
      case FETCHING:
        return { ...state, fetching: action.payload};

      default:
        return state;
    }
}

// Actions

export let getWorkFlowSteps = () => async (dispatch, getState) => {
  dispatch({
    type: GET_WORKFLOW_STEPS,
    payload:[]
  });

  try{
    const res = await axiosApi.get(API.WORKSHOP.WORKSHOP_STEPS)
    dispatch({
      type: GET_WORKFLOW_STEPS,
      payload:res.data.results,
    });

  }catch(e){
    dispatch({
      type: GET_WORKFLOW_STEPS,
      payload:[]
    });
  }


};

export let getWorkFlow = (order_type_code = "", branch_id = "") => async (dispatch, getState) => {
  dispatch({
    type: GET_WORKFLOW,
  });
  await axiosApi
    .get(API.WORKFLOW.WORKFLOW + `?order_type__code=${order_type_code}`)
    .then((response) => {
      const workflow_branch = response.data.results.filter((item) => item.branch == branch_id)
      const workflow_global = response.data.results.filter((item) => !item.branch)
      const selectedWorkflow = workflow_branch.length > 0 ? workflow_branch : workflow_global
      //console.log('workflow_branch', selectedWorkflow, workflow_branch, workflow_global)
      dispatch({
        type: GET_WORKFLOW_SUCCESS,
        payload: {
          workflowResponse: response.data,
          workflow: selectedWorkflow,
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_WORKFLOW_ERROR,
      });
    });
};

export let getAllWorkFlow = () => async (dispatch, getState) => {
  await axiosApi
    .get(API.WORKFLOW.WORKFLOW)
    .then((response) => {
      let allsteps = [];
      response.data.results.map((item) => {
        item.steps.map( (step) => {
          allsteps.push(step)
        });
      });
      dispatch({
        type: SET_ALL_WORKFLOW,
        payload: allsteps,
      });
    })
    .catch((error) => {
      /* dispatch({
        type: GET_WORKFLOW_ERROR,
      }); */
    });
};

export let getHistory = (order_type_code = 'SALE', service_order__id = 0,) => async (dispatch, getState)  => {
  dispatch({
    type: GET_WORKFLOW_LOG,
  });
    await axiosApi
      .get(
        API.WORKFLOW.LOG +
          `?step__workflow__order_type__code=${order_type_code}&service_order__id=${service_order__id}`
      )
      .then((response) => {
        dispatch({
          type: GET_WORKFLOW_LOG_SUCCESS,
          payload: {
            workflow_logs: response.data.results,
            fetching_history: false
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_WORKFLOW_LOG_ERROR,
        });
      });
}

export let changeVisible = (id, visible, functionSuccess)  => async (dispatch, getState) => {
  dispatch({
    type: UPDATING,
    payload: id
  });
  try {
    let response = await axiosApi.patch(API.WORKFLOW.CHANGE_VISIBLE + `${id}/`, {"visible_for_client": visible});
    if(response.status === 200){
      dispatch({
        type: UPDATING_SUCCESS,
      });
      functionSuccess();
    }else{
      dispatch({
        type: UPDATING_ERROR,
      });
      message.error("Ocurrio un error");
    }
  } catch (error) {
    dispatch({
      type: UPDATING_SUCCESS,
    });
  }
}

/**
 * Obtenemos los usuarios operativos que pueden ser elegibles para poner como responsables de step
 * @param branch
 * @returns {(function(*, *): Promise<void>)|*}
 */
export let getUsersWorkFlow=(branch)=> async (dispatch, getState)=>{
  dispatch({
    type: GET_WORKFLOW
  });
  try{
   // /security/user/workshop_users
    let response = await axiosApi.get(API.WORKFLOW.USERS_WORKFLOW + `?userprofile__branches=${branch}`, );
    dispatch({
      type: GET_USERS_WORKFLOW_SUCCESS,
      payload: response.data
    });
  }catch (e) {
    dispatch({
      type: GET_WORKFLOW_ERROR,
      payload: e
    });
  }
}


/**
 * Sirve para asignar a un usuario a una etapa del proceso
 * @param data
 * @returns {(function(*, *): Promise<void>)|*}
 */
export let assignStepFlow=(data)=>async (dispatch, getState)=>{
  dispatch({
    type: ASSIGN_USER_TO_STEP
  });
  try{
    let response = await axiosApi.post(API.WORKFLOW.LOG, data);
    dispatch({
      type: ASSIGN_USER_TO_STEP_SUCCESS
    });
  }catch (e) {
    dispatch({
      type: ASSIGN_USER_TO_STEP_ERROR,
      payload: e
    });
  }
}

export let changeStatusStopStart=(action,stepId)=>async (dispatch, getState)=>{
  dispatch({
    type: FETCHING,
    payload:true
  });

  try{
    let response = await axiosApi.get(API.WORKFLOW.STEP_LOG+`${stepId}/${action}/`);
    dispatch({
      type: FETCHING,
      payload:false
    });
    return {status:true}
  }catch (e) {
    dispatch({
      type: FETCHING,
      payload:false
    });
    return {status:false}
  }

}