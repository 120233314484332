import React, { useState, useEffect } from 'react';
import styles from '../login.module.css'
import SecurityLayout from "../../../layouts/SecurityLayout";
import { FormattedMessage } from "react-intl";
import { connect } from 'react-redux'
import { changeLangAction } from '../../../redux/settingsDuck'
import { getAuthAction, sendLogin } from "../../../redux/securitryDuck";
import { Button, Form, Input, Checkbox, Space, Typography } from 'antd';
import { injectIntl } from "react-intl";
import { useHistory, Redirect } from "react-router-dom"
import { ItemForm } from "../../../components/MyComponents";
import { RightOutlined, LoadingOutlined } from "@ant-design/icons";
import ChangeLangButton from "../../../components/changeLangButton/ChangeLangButton"



const LoginPage = ({ changeLangAction, getAuthAction, settings, intl, isLoggedIn, ...props }) => {
  const [form] = Form.useForm();
  const { Text, Title } = Typography;
  const [loading, setLoading] = useState(false);

  const history = useHistory()

  const onFinish = values => {
    // Obtenemos el input de password.
    const password = values.password;
    // Obtenemos el input de username
    const username = values.username;
    // Armamos el json
    let data = {};
    data.username = username;
    data.password = password;
    /* setLoading(true); */
    // Usamos redux
    getAuthAction(data)
  }
  const style = {
    Dash: {
      border: 'none',
      borderTop: '1px dashed #341EFF',
      marginLeft: 5,
      color: '#341EFF',
      backgroundColor: '#341EFF',
      height: 5,
      width: '7%',
    },
  }

  /*  */

  useEffect(() => {
    if (settings?.generalInfo?.image_background) {
      localStorage.setItem('image_background', settings?.generalInfo?.image_background);
    }
  }, [settings])




  return (
    <SecurityLayout backgroundImage={settings?.generalInfo?.image_background} className={styles.container}>
      {isLoggedIn && <Redirect to="/company" />}
      <Title level={2} className="font-white">
        <FormattedMessage id="security.login.title" />
      </Title>
      <Form form={form} layout="vertical" onFinish={(e) => onFinish(e)}>
        <ItemForm
          required
          tooltip="This is a required field"
          name="username"
          rules={[
            {
              required: true,
              message: <FormattedMessage id="security.login.messageUser" />,
            },
          ]}
        >
          <Input
            size="large"
            placeholder={intl.formatMessage({ id: "security.login.email" })}
          />
        </ItemForm>
        <ItemForm
          required
          tooltip="This is a required field"
          name="password"
          rules={[
            {
              required: true,
              message: <FormattedMessage id="security.login.messagePass" />,
            },
          ]}
        >
          <Input
            size="large"
            placeholder={intl.formatMessage({
              id: "security.login.password",
            })}
            type={"password"}
          />
        </ItemForm>
        {props.errorLogin && (
          <Form.Item>
            <Text type="danger">{props.errorLogin}</Text>
          </Form.Item>
        )}
        <Form.Item>
          <Checkbox className={"font-white"}>
            <small><FormattedMessage id="security.login.accepTerms" /></small>
          </Checkbox>
        </Form.Item>
        <Form.Item>
          <Button
            disabled={props.loadingLogin}
            size={"large"}
            block
            className={"btn-string-blue btn-login"}
            type="primary"
            htmlType="submit"
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <FormattedMessage id="security.login.signin" />
              {props.loadingLogin ? (
                <LoadingOutlined
                  style={{ marginTop: "auto", marginBottom: "auto" }}
                />
              ) : (
                <RightOutlined
                  style={{ marginTop: "auto", marginBottom: "auto" }}
                />
              )}
            </div>
          </Button>
        </Form.Item>
      </Form>
      <ChangeLangButton />
    </SecurityLayout>
  );
}

const mapStateToProps = (state) => ({
  settings: state.settings,
  isLoggedIn: state.security.isLoggedIn,
  loadingLogin: state.security.loadingLogin,
  errorLogin: state.security.errorLogin,
});

export default injectIntl(connect(mapStateToProps, {
  changeLangAction,
  getAuthAction,
})(LoginPage))
