import React from 'react';
import Chart from 'react-apexcharts';
import { colorConOpacidad } from '../../lib/utils/utils';

const DonutChart = ({ title, percentage, value, color }) => {


  const chartOptions = {
    chart: {
      type: 'donut',
    },
    labels: [title,'faltante'],
    colors: [color, colorConOpacidad(color)],
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      formatter: (val) => `${val.toFixed(1)}%`,
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              label: `${percentage}%`,
              formatter: () => `${percentage}%`,
            },
          },
        },
      },
    },
  };

  const chartSeries = [percentage, 100 - percentage];

  return (
    <div style={{ textAlign: 'center' }}>
      <h3 style={{marginTop: '10px', textAlign: 'center'}}>{title}</h3>
        <Chart options={chartOptions} series={chartSeries} type="donut" width="100%" />      
      <p style={{marginTop: '10px', textAlign: 'center'}}>{value} U'S</p>
    </div>
  );
};

export default DonutChart;