import React from 'react';
import { Scanner } from '@yudiel/react-qr-scanner';

const QRReaderComponent = ({
  readyToReadQr,
  setReadyToReadQr,
  getResultQr
}) => {

  const handleScan = (data) => {
    if (data && readyToReadQr) {
      getResultQr(data)
      setReadyToReadQr(false)
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  return (
        <Scanner
            onResult={(text) => handleScan(text)}
            onError={(error) => handleError(error)}
            options = {{constraints: {aspectRatio: 1, facingMode: {ideal: "environment"}}}}
        />
  );
};

export default QRReaderComponent;
