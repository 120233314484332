import React, { useState, useEffect } from 'react'
import { PoweroffOutlined } from "@ant-design/icons";
import {
    Card,
    message,
    Tooltip
  } from 'antd';
  import {connect} from "react-redux";
  import {logOut} from "../redux/securitryDuck";
  import {useHistory} from "react-router-dom";
  import {FormattedMessage, injectIntl} from "react-intl";
  import { Global, css } from "@emotion/react";

const CloseButton=({intl,...props})=>{

    const history = useHistory()
    
    const logout = async () => {

        try {
            const success = await logOut()
            if (success) {
                message.success(intl.formatMessage({ id: "security.login.logoutSuccess" }))
                history.push('/security/login')
                window.location.href = '/security/login'
            } else {
                message.error(intl.formatMessage({ id: "security.login.logoutErr" }))
            }
        } catch (e) {

        }

    }

    return (
        <>
        <Global
        styles={css`
        .ant-card-small > .ant-card-body {
            padding:5px !important
          }
          
        `}
      />
      
          <Card
                size="small"
                className="site-header-card m-auto"
                style={{ cursor: "pointer",background:'transparent' }}
                onClick={()=> {
                    logout()
                }}
            >
                <div style={{width:'100%',marginTop:15}}></div>
                <Tooltip placement="bottom" title={ <FormattedMessage id="layout.logOut" />}>
                <PoweroffOutlined  style={{fontSize:30, color:"#341EFF"}}/>
                </Tooltip>
            </Card>
        </>
      
    )
}


const mapStateToProps = state => ({
    settings: state.settings,
    security: state.security,
    serviceOrder: state.serviceOrder,
    permissions: state.permissions
})

export default injectIntl(
    connect(mapStateToProps, {logOut})(CloseButton)
);