import React, {useState, useEffect} from 'react'
import {CarOutlined} from '@ant-design/icons';
import {Typography, Menu, Button} from 'antd'
const { Paragraph } = Typography;

const SectionTitle =({icon=null,title='Sección',...props })=>{
    const { Text } = Typography;
    return (
      <div>
        {/* <Button style={style.titleMenu} size={'large'} type="text" icon={icon?icon:<CarOutlined />} > */}
        {/* <Button style={style.titleMenu} size={'large'} type="text" icon={icon?icon:null} >
                {title}
            </Button> */}
        {icon && icon}
        <Text strong style={style.title}>
          {title}
        </Text>
        <div style={style.divider} className="bg-primary"></div>
      </div>
    );
}


const style={
    divider:{
        height:'1px'
    },
    title:{
        fontSize:'16px'
    },
    titleMenu:{
        fontWeight:'bolder'
    }
}

export default SectionTitle;