import React, { useState, useEffect } from 'react'
import DashboardLayout from '../../layouts/DashboardLayout';
import { Table, Layout, Form, Button, Modal, Input, Upload, Space, Popconfirm, message, Row, Col, Typography } from 'antd'
import { UploadOutlined } from '@ant-design/icons';
import {
    changeLangAction,
    deleteBrandAction,
    editBrandAction,
    getBrandsAction,
    saveBrandAction
} from '../../redux/settingsDuck'
import { connect } from 'react-redux'
import { injectIntl } from "react-intl";
import DeleteLinkComponent from "../../components/DeleteLinkComponent";
import { FormattedMessage } from "react-intl";
import {permissionsExist} from "../../lib/utils/utils";
import DeleteModal from "../../components/modal/DeleteModal";
import DeleteIcon from "../../image/delete.svg";
import turn from '../../image/turn_icon_kikert.svg'
import EditIcon from "../../image/edit.svg";
import {CustomTable} from "../../components/KComponents";
import KButton from "../../components/common/elements/KButton/KButton";
import { CustomModal } from '../../components/MyComponents'
import CloseIcon from '../../image/close-outline.svg';
import MainLayoutv2 from "../../layouts/MainLayoutv2";
import TabsConfig from '../../components/common/elements/TabsMenuConfig/TabsMenuConfig';

const { Header, Content, Footer } = Layout;

const BrandsPage = ({ changeLangAction, intl, getBrandsAction, settings, saveBrandAction, deleteBrandAction, editBrandAction, permissions }) => {

    const {Title} = Typography

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentBrand, setCurrendBrand] = useState(false);
    const [actionView, setActionView] = useState(true);

    const [forDelete, setForDelete] = useState(null)
    const [modalDelete, setModalDelete] = useState(false)

    const [form] = Form.useForm();

    useEffect(() => {
        getBrandsAction()
    }, [])

    useEffect(() => {
      console.log("settings", settings);
    }, [settings]);
    

    const showModal = (isEdit = false) => {
        if (!isEdit) {
            form.resetFields()
            setCurrendBrand(null)
        }
        setIsModalVisible(true);
    };

    const handleOk = () => {
        form.submit();
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setCurrendBrand(null)
        form.resetFields()
    };

    const closeModal = () =>{
        setModalDelete(false);
        setForDelete(null);
    }

    const deleteItem = (current) => {
        console.log(current);
        setModalDelete(true);
        setForDelete(current);
    }

    const onDeleteBrand = async () => {
        try {
            const res = await deleteBrandAction(forDelete.id)
            if (res.success) {
                message.success(intl.formatMessage({ id: "item.deleteSuccess" }))
                await getBrandsAction()
                await closeModal()

            } else {
                message.error(intl.formatMessage({ id: "item.brand.registerErr" }))
                await closeModal()
            }
        } catch (e) {
            message.error(intl.formatMessage({ id: "item.brand.registerErr" }))
            await closeModal()
        }
    }


    const onSaveBrand = async values => {
        if (values) {
            let res = await saveBrandAction(values)
            if (res.success) {
                message.success(intl.formatMessage({ id: "item.saveSuccess" }))
                await getBrandsAction()
            } else {
                message.error(intl.formatMessage({ id: "item.brand.element" }))
            }

            form.resetFields()
            setCurrendBrand(null)
        }
        console.log('Received values of form: ', values);
    };

    const onEditBrand = async values => {
        if (values) {
            let req = {
                id: currentBrand.id,
                ...values
            }
            let res = await editBrandAction(req)
            if (res.success) {
                message.success(intl.formatMessage({ id: "item.editSuccess" }))
                await getBrandsAction()
            } else {
                message.error(intl.formatMessage({ id: "item.brand.element" }))
            }

            form.resetFields()
            setCurrendBrand(null)
        }
        console.log('Received values of form: ', values);
    };


    const editBrand = (brand = null) => {
        setCurrendBrand(brand)
        if (brand) {
            form.setFieldsValue({
                name: brand.name
            })
        }
        showModal(true)
    }

    const valActions=()=>{
        if (!permissionsExist(permissions.permissionsList,"change_vehiclebrand") &&
            !permissionsExist(permissions.permissionsList,"delete_vehiclebrand")){
            setActionView(false)
        }else {
            setActionView(true)
        }
    }

    useEffect(()=>{
        valActions()
    },[])

    const columns = [
        {
            title: <FormattedMessage id="layout.name" />,
            dataIndex: 'name',
            key: 'name',
        },
        ...(actionView ?
                [
                    {
                        title: <FormattedMessage id="layout.actions" />,
                        dataIndex: 'id',
                        key: 'actions',
                        render: (id, record) => {
                            return <Space size={30}>
                                {
                                    permissionsExist(permissions.permissionsList,"change_vehiclebrand") &&
                                    <img key="EditIcon" src={EditIcon} style={{cursor:'pointer',  width:12}}
                                    onClick={() => editBrand(record)}/>
                                }
                                {
                                    permissionsExist(permissions.permissionsList,"delete_vehiclebrand")&&
                                    <img key="DeleteIcon" src={DeleteIcon} style={{cursor:'pointer', width:12}} onClick={() => deleteItem(record)}/>

                                }
                            </Space>
                        }

                    }
                ]
                :
                []

        )
        ,
    ];





    const changePagination = (page) => {
        console.log('page', page)
        getBrandsAction(page);
    }

    return (
      <MainLayoutv2
        title={intl.formatMessage({ id: "services.brand" })}
        index="6"
        openKeys={["sub1"]}
      >
        <CustomModal
            title=""
            footer=""
          visible={isModalVisible}
          closeIcon={<img src={CloseIcon} style={{width:15}} />}
          onCancel={handleCancel}

        >
            <Row>
                <Col span={24}>
                    <Title level={2} style={{ marginBottom: 0 }}>
                        <img
                        src={turn}
                        alt="logo"
                        style={{ height: 60, width: 30, transform: "scaleX(-1)", marginRight:10 }}
                        />
                        <FormattedMessage id={currentBrand ? "item.brand.editBrand": "item.brand.addBrand" } />
                    </Title>
                </Col>
                <Col span={24}>
                    <Form
                        layout={"vertical"}
                        form={form}
                        onFinish={currentBrand ? onEditBrand : onSaveBrand}
                    >
                        <Form.Item
                        name={"name"}
                        label={intl.formatMessage({ id: "item.brand.nameBrand" })}
                        rules={[{ required: true }]}
                        >
                        <Input className='kinput'
                            placeholder={intl.formatMessage({ id: "item.brand.nameBrand" })}
                        />
                        </Form.Item>
                    </Form>
                </Col>
                <Col span={24} style={{ textAlign: "center" }}>
                        <Space>
                            <Button
                                size="large"
                                className="btn-string-blue btn-report-action"
                                onClick={() => handleCancel()}
                            >
                                <FormattedMessage id="layout.cancel" />
                            </Button>
                            <Button
                                className="btn-blue btn-report-action"
                                size="large"
                                onClick={() => handleOk()       }
                                >
                                <FormattedMessage id="services.members.create.save" />
                            </Button>
                        </Space>
                    </Col>

            </Row>
          
        </CustomModal>

        <Content
          style={{ padding: "10px 10px", background: "white" }}
        >
            <TabsConfig keyActive={'4'}/>
            {
                permissionsExist(permissions.permissionsList,"add_vehiclebrand") &&
                <KButton
                    onClick={showModal}
                    type="primary"
                    style={{ float: "right" }}
                    size={"large"}
                    text={<FormattedMessage id="layout.createNew" />}
                />
            }

            
          <CustomTable
            loading={settings.fetching}
            fixed={true}
            scroll={{ x: 1500 }}
            dataSource={settings.brandsList}
            columns={columns}
            pagination={{
              total: settings?.brands?.count,
              showSizeChanger:false,
              pageSize: 25,
              showQuickJumper:true, 
              onChange: changePagination,
            }}
          />
        </Content>
          <DeleteModal actionDelete={() => onDeleteBrand()} isVisible={modalDelete}
                       closeModal={closeModal}
                       modalTitle={<FormattedMessage id="deleted.ask"/>}
                       description={intl.formatMessage({ id:'deleted.description'},{ obj: forDelete && forDelete.name })} />
      </MainLayoutv2>
    );
}


const mapStateToProps = state => ({
    settings: state.settings,
    permissions:state.permissions
})


export default injectIntl(connect(mapStateToProps, {
    getBrandsAction,
    changeLangAction,
    saveBrandAction,
    deleteBrandAction,
    editBrandAction
})(BrandsPage))

