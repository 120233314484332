import React, {useLayoutEffect} from 'react';
import './App.css';
import './styles/global.css'
import "./styles/customs.css";
import "antd/dist/antd.css";
import AppRouter from './AppRouter'
import { langMessages } from "./lang/messages";
import { IntlProvider } from "react-intl";
import {connect} from   'react-redux'
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const  App=({...props})=> {
  return (
    <div>
            <IntlProvider locale={props.settings.lang} messages={langMessages[props.settings.lang]}>
                <AppRouter />
            </IntlProvider>
    </div>
  );
}

function mapState(state){
    return {
        settings:state.settings
    }
}

export default connect(mapState)(App)

