import { Card, Typography } from 'antd'
import React from 'react'

const Indicators = ({ title = "", value = 0, subtitle = "", color = "white" }) => {

    const {Title} = Typography

  return (
    <Card className='cardStatics' style={{backgroundColor: color}} title={
        <Title level={2} style={{ color: 'white' }} >
            { title }
        </Title>
    }>
        <Title  style={{textAlign:'center',marginBottom:0, color: 'white', padding:'10px 0px' }} >
            { value }
        </Title>
        <small>
            { subtitle }
        </small>
    </Card>
  )
}

export default Indicators