import React, {useEffect} from "react";
import {Form, Input, Modal} from "antd";
import {FormattedMessage} from "react-intl";

const EditNameModal =({visible,onOk,onCancel,form,onFinish,obj,...props})=>{

    useEffect(()=>{
        form.setFieldsValue({
            name: obj.name
        });
    },[obj])
    return(
        <Modal title={<FormattedMessage id="services.files.EditName"/>} visible={visible} onOk={onOk}
               onCancel={onCancel}>
            <Form
                layout={'vertical'}
                form={form}
                onFinish={onFinish}
            >
                <Form.Item name={'name'} label={<FormattedMessage id="layout.name"/>}>
                    <Input/>
                </Form.Item>
            </Form>
        </Modal>
    )
}
export default EditNameModal
