import React, { useEffect, useState } from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import { FormattedMessage, injectIntl } from "react-intl";
import { Col, Row, Input, Tooltip, Button, Table, Card, Form, Typography, Divider, Space } from "antd";
import {
  SearchOutlined,
  FilterOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import MembersTable from "../../components/members/MembersTable";
import UserTable from '../../components/members/UserTable';
import ModalMembers from "../../components/members/ModalMembers";
import DeleteModal from '../../components/modal/DeleteModal';
import styled from "styled-components";
import { connect } from 'react-redux';
import { showMembers, getProfiles, getUsers, changeScreen, userFormShow } from "../../redux/membersDuck";
import CreateGroup from "../../components/members/CreateGroup";
import UserForm from '../../components/members/forms/UserForm';

import {
  BrowserRouter as Router,
  Route,
  Link,
  useRouteMatch,
  useHistory,
  useParams,
} from "react-router-dom";

import FilterIcon from '../../image/filter_icon.svg';
import SearchIcon from '../../image/BUSCADOR.png';
import MainLayoutv2 from "../../layouts/MainLayoutv2";


const CustomCard = styled(Card)`
    .ant-card-body{
        padding: 20px;
    }
`;



const MembersCenter = ({ intl ,getProfiles, getUsers, membersState, changeScreen, userFormShow, ...props}) => {

  const {Text} = Typography
  const [openModalCreate, setOpenModalCreate] = useState(false);
  /* const [userFormShow, setUserFormShow] = useState(false); */

  const history = useHistory();

  useEffect(() => {
    getProfiles();
    getUsers();
  }, [])

  const closeModal = () => {
    setOpenModalCreate(false);
  }

  /* const changeScreen (screen) => {
    changeScreen(screen);
  } */
  

  return (
    <MainLayoutv2
      title={<FormattedMessage id="members.title" />}
      index="members"
    >
      <Col span={23} style={{ textAlign: "left" }}>
        <Space >
        <Button
            htmlType="button"
            onClick={() => history.push("/members_center_users")}
            className={ membersState.screenVisible === "users" && 'btn-string-blue-low' }
            /* onMouseEnter={() => setActive(0)}
            onMouseLeave={() => setActive(null)} */
          >
            <UsergroupAddOutlined /> &nbsp; &nbsp;{" "}
            <FormattedMessage id="members.table.users" />
          </Button>
          <Button
            onClick={() => {
              /* changeScreen("profiles") */
              userFormShow(false);
              }
            }
            className={ membersState.screenVisible === "profiles" && 'btn-string-blue-low' }
            /* onMouseEnter={() => setActive(0)}
            onMouseLeave={() => setActive(null)} */
          >
            <UsergroupAddOutlined /> &nbsp; &nbsp;{" "}
            <FormattedMessage id="members.table.profiles" />
          </Button>
          
        </Space>
        <Divider/>
      </Col>
      {
        membersState.screenVisible === "profiles" ?
          (<Row justify="space-between" align="bottom">
            <Col md={8}>
              <Row gutter={10} style={{ display: "flex" }} align="bottom">
                <Col flex="auto">
                  <Form layout="vertical">
                    <Form.Item
                      label={<Text style={{ paddingLeft: 10 }}>Perfil</Text>}
                      style={{ marginBottom: 0 }}
                    >
                      <Input
                        placeholder={intl.formatMessage({
                          id: "members.search_name",
                        })}
                        className="br-5"
                        suffix={
                          <Tooltip title="Buscar">
                            <img src={SearchIcon} style={{width:15}} />
                          </Tooltip>
                        }
                      />
                    </Form.Item>
                  </Form>
                </Col>
                <Col md={3}>
                    <img src={FilterIcon} width="30px" height="30px" />
                </Col>
              </Row>
            </Col>
            <Col>
              <Button
                className="btn-string-blue-low br-5 px-30"
                onClick={() => setOpenModalCreate(true)}
              >
                <FormattedMessage id="members.new_profile" />
              </Button>
            </Col>
            <Col md={24}>
              <CustomCard bordered={false} style={{ marginTop: 30 }}>
                <MembersTable history={history} />
              </CustomCard>
            </Col>
          </Row>)
        :
          <Row justify="space-between" align="bottom">
            {
              !membersState.formShow && (
                <>
                <Col md={8}>
              <Row gutter={10} style={{ display: "flex" }} align="bottom">
                <Col flex="auto">
                  <Form layout="vertical">
                    <Form.Item
                      label={<Text style={{ paddingLeft: 10 }}>Usuarios</Text>}
                      style={{ marginBottom: 0 }}
                    >
                      <Input
                        placeholder={intl.formatMessage({
                          id: "members.search_name",
                        })}
                        className="br-5"
                        suffix={
                          <Tooltip title="Buscar">
                            <img src={SearchIcon} style={{width:15}} />
                          </Tooltip>
                        }
                      />
                    </Form.Item>
                  </Form>
                </Col>
                <Col md={3}>
                    <img src={FilterIcon} width="30px" height="30px" />
                </Col>
              </Row>
          </Col>
          <Col>
            <Button
              className="btn-string-blue-low br-5 px-30"
              onClick={() => userFormShow(true)}
            >
              <FormattedMessage id="members.new_user" />
            </Button>
          </Col>
                </>
              )
            }
          <Col md={24}>
            <CustomCard bordered={false} style={!membersState.formShow?{marginTop: 30 }:null}>
              {
                membersState.formShow ?
                <UserForm />
                :
                <UserTable history={history} />
              }
              
            </CustomCard>
          </Col>
          </Row>
      }
      <ModalMembers />
      <CreateGroup isVisible={openModalCreate} closeModal={closeModal} />
    </MainLayoutv2>
  );
};

const mapState = (state) => ({
  membersState: state.members,
});



export default injectIntl(connect(mapState, { showMembers, getProfiles, getUsers, changeScreen, userFormShow })(MembersCenter));